import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment/moment";
import {
  getAlumniReport,
  getArticlesReport,
  getFamilyReport,
  getGraduateReport,
  getHomeReport,
  getHubReport,
  getOutcomesReport,
  getPagesReport,
  getQAReport,
  getReport,
  getStaffReport,
} from "../../api/reporting";
import {
  EngagementDetailsInterface,
  GraphState,
  OverallReportingInterface,
  ReportingProviderInterface,
} from "./types";

export const ReportingContext = createContext<ReportingProviderInterface | null>(null);

export function useReportingProvider() {
  const context = useContext(ReportingContext);

  if (context === null) {
    throw new Error("useReportingProvider must be used within a ReportingProvider");
  }

  return context;
}

const EmptyState: GraphState = {
  reportGraph: [],
  reportPages: [],
  reportCount: 0,
};

export const ReportingProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [userType, setUserType] = useState<string>("all");
  const [startDate, setStartDate] = useState<Date>(
    moment.utc().subtract(6, "days").startOf("day").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(moment.utc().endOf("day").add(1, "hours").toDate());
  const location = "all";
  const [gradeBand, setGradeBand] = useState<string>("all");

  const [overallReporting, setOverallReporting] = useState<OverallReportingInterface>({
    activeUserCount: 0,
    totalUserCount: 0,
    overallEngagementGraph: [],
  });

  const [familyReport, setFamilyReport] = useState<GraphState>(EmptyState);
  const [alumniReport, setAlumniReport] = useState<GraphState>(EmptyState);
  const [graduateReport, setGraduateReport] = useState<GraphState>(EmptyState);
  const [qaReport, setQAReport] = useState<GraphState>(EmptyState);
  const [pagesReport, setPagesReport] = useState<GraphState>(EmptyState);
  const [staffReport, setStaffReport] = useState<GraphState>(EmptyState);
  const [hubReport, setHubReport] = useState<GraphState>(EmptyState);
  const [articlesReport, setArticlesReport] = useState<GraphState>(EmptyState);
  const [outcomesReport, setOutcomesReport] = useState<GraphState>(EmptyState);
  const [homeReport, setHomeReport] = useState<GraphState>(EmptyState);

  const [engagementDetails, setEngagementDetails] = useState<EngagementDetailsInterface>({
    pageviewCount: 0,
    engagementDetailsGraph: [
      { label: "Home", value: 0 },
      { label: "Articles", value: 0 },
      { label: "Family", value: 0 },
      { label: "Alumni", value: 0 },
      { label: "Graduates", value: 0 },
      { label: "QA", value: 0 },
      { label: "Outcomes", value: 0 },
      { label: "Staff", value: 0 },
      { label: "Hub", value: 0 },
      { label: "Pages", value: 0 },
    ],
  });

  useEffect(() => {
    setEngagementDetails({
      engagementDetailsGraph: [
        { label: "Home", value: homeReport.reportCount },
        { label: "Articles", value: articlesReport.reportCount },
        { label: "Family", value: familyReport.reportCount },
        { label: "Alumni", value: alumniReport.reportCount },
        { label: "Graduates", value: graduateReport.reportCount },
        { label: "QA", value: qaReport.reportCount },
        { label: "Outcomes", value: outcomesReport.reportCount },
        { label: "Staff", value: staffReport.reportCount },
        { label: "Hub", value: hubReport.reportCount },
        { label: "Pages", value: pagesReport.reportCount },
      ],
      pageviewCount:
        articlesReport.reportCount +
        familyReport.reportCount +
        alumniReport.reportCount +
        graduateReport.reportCount +
        qaReport.reportCount +
        outcomesReport.reportCount +
        staffReport.reportCount +
        hubReport.reportCount +
        pagesReport.reportCount,
    });
  }, [
    familyReport.reportCount,
    alumniReport.reportCount,
    graduateReport.reportCount,
    qaReport.reportCount,
    pagesReport.reportCount,
    staffReport.reportCount,
    hubReport.reportCount,
    articlesReport.reportCount,
    outcomesReport.reportCount,
    homeReport.reportCount,
  ]);

  const getFilter = () => ({
    userType: userType,
    startDate: moment.utc(startDate).format("YYYY-MM-DD 00:00:01"),
    endDate: moment.utc(endDate).format("YYYY-MM-DD 23:59:59"),
    location: location,
    gradeBand: gradeBand,
  });

  const fetchOverallReporting = useCallback(async () => {
    const res = await getReport(getFilter());
    setOverallReporting({ ...overallReporting, ...res.data });
  }, [startDate, endDate, gradeBand]);

  const fetchFamilyReport = async () => {
    const response = await getFamilyReport(getFilter());
    setFamilyReport(response.data as GraphState);
  };
  const fetchAlumniReport = async () => {
    const response = await getAlumniReport(getFilter());
    setAlumniReport(response.data as GraphState);
  };
  const fetchGraduateReport = async () => {
    const response = await getGraduateReport(getFilter());
    setGraduateReport(response.data as GraphState);
  };
  const fetchQAReport = async () => {
    const response = await getQAReport(getFilter());
    setQAReport(response.data as GraphState);
  };
  const fetchPagesReport = async () => {
    const response = await getPagesReport(getFilter());
    setPagesReport(response.data as GraphState);
  };
  const fetchStaffReport = async () => {
    const response = await getStaffReport(getFilter());
    setStaffReport(response.data as GraphState);
  };
  const fetchHubReport = async () => {
    const response = await getHubReport(getFilter());
    setHubReport(response.data as GraphState);
  };
  const fetchArticlesReport = async () => {
    const response = await getArticlesReport(getFilter());
    setArticlesReport(response.data as GraphState);
  };
  const fetchOutcomesReport = async () => {
    const response = await getOutcomesReport(getFilter());
    setOutcomesReport(response.data as GraphState);
  };

  const fetchHomeReport = async () => {
    const response = await getHomeReport(getFilter());
    setHomeReport(response.data as GraphState);
  };

  useEffect(() => {
    fetchOverallReporting();
    fetchHomeReport();
  }, [startDate, endDate, gradeBand]);

  const value = useMemo(
    () => ({
      overallReporting,
      startDate,
      endDate,
      gradeBand,
      userType,
      location,
      familyReport,
      alumniReport,
      graduateReport,
      qaReport,
      pagesReport,
      staffReport,
      hubReport,
      articlesReport,
      outcomesReport,
      engagementDetails,
      setGradeBand,
      setEndDate,
      setStartDate,
      setUserType,
      fetchFamilyReport,
      fetchArticlesReport,
      fetchHomeReport,
      fetchOutcomesReport,
      fetchAlumniReport,
      fetchGraduateReport,
      fetchHubReport,
      fetchStaffReport,
      fetchQAReport,
      fetchPagesReport,
    }),
    [
      startDate,
      endDate,
      gradeBand,
      userType,
      overallReporting,
      familyReport,
      alumniReport,
      graduateReport,
      qaReport,
      pagesReport,
      staffReport,
      hubReport,
      articlesReport,
      outcomesReport,
      engagementDetails,
    ]
  );

  return <ReportingContext.Provider value={value}>{children}</ReportingContext.Provider>;
};
