import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import RoundButton from "app/components/button/RoundButton";
import StandardButton from "app/components/button/StandardButton";
import Checkbox from "app/components/checkbox/CheckBox";
import DropDown from "app/components/dropDown/DropDown";
import Input from "app/components/input/Input";
import QAResponseEditor from "app/components/qaResponseEditor/QAResponseEditor";
import Section from "app/components/section/Section";
import useQAEditor from "app/hooks/QAEditor";
import PrivateLayout from "app/layout/PrivateLayout";
import { ICategoryTag } from "app/models/common";
import { IGradeBand } from "app/models/program";
import { qaSchema } from "app/utils/validations";
import { Form, Formik } from "formik";
import React from "react";
import { Row, Col } from "react-bootstrap";
import ScreenNotification from "app/components/screenNotification/ScreenNotification";
import DropDownInput from "app/components/dropDownInput/DropDownInput";
import "./qa.scss";

const QAContentEditor: React.FC = () => {
  const _editor = useQAEditor();

  return (
    <PrivateLayout contentClassName="qa-editor">
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues}
        onSubmit={_editor.onSubmit}
        validationSchema={qaSchema}
      >
        {({ values, touched, errors, isValid, dirty }) => {
          return (
            <Form>
              <Row className="align-items-center">
                <Col>
                  <h3 className="font-weight-600 my-0">Q&amp;A Content Editor</h3>
                </Col>
                <Col md="auto">
                  <div className="top-button-container">
                    <StandardButton
                      standardBtnId="article-editor-save-btn"
                      label="Save Updates"
                      variant="outlined"
                      type="submit"
                      disabled={!dirty || !isValid}
                    />
                  </div>
                </Col>
              </Row>
              <ScreenNotification
                label="Errors"
                variant="danger"
                styles={{ container: { marginTop: "2rem" } }}
              />
              <Row className="mt-5">
                <Col>
                  <Input
                    id="qa-editor-title"
                    label="Question or Topic Title"
                    variant="secondary"
                    name="topic"
                    error={touched.topic && errors.topic ? errors.topic : ""}
                  />
                </Col>
                <Col md={3}>
                  <Input
                    id="qa-editor-id"
                    label="Q&amp;A Topic Id"
                    variant="secondary"
                    name="topicId"
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    id="qa-editor-url"
                    label="Q&amp;A URL"
                    variant="secondary"
                    name="url"
                    disabled
                  />
                </Col>
                <Col md={3}>
                  <DropDown<IGradeBand>
                    label="Grade Band"
                    id="qa-editor-grade-band"
                    labelAttribute="gradeBand"
                    options={_editor.gradeBands}
                    selected={values.gradeBandIds}
                    multiple
                    valueAttribute="id"
                    placeholder="Select Grade Band"
                    onOptionChange={_editor.onSelectionChange("gradeBandIds")}
                  />
                </Col>
                <Col md={3}>
                  <DropDown<ICategoryTag>
                    label="Content Category"
                    id="qa-editor-content-category"
                    labelAttribute="categoryTag"
                    options={_editor.contentCategories}
                    selected={values.categoryIds}
                    multiple
                    valueAttribute="id"
                    placeholder="Select Category"
                    onOptionChange={_editor.onSelectionChange("categoryIds")}
                  />
                </Col>
              </Row>

              <div className="response-container">
                {values.responses.map((response, index) => (
                  <QAResponseEditor
                    key={`response_${index}`}
                    index={index}
                    {...response}
                    onDelete={_editor.onDeleteResponse}
                    onChange={_editor.onChange}
                  />
                ))}
              </div>
              <RoundButton
                icon={<IcPlus />}
                variant="primary"
                size="lg"
                roundBtnId="qa-editor-add-response-btn"
                label="Add a new response video"
                onClick={_editor.addResponse}
              />
              <hr className="my-5" />
              <Section isEditable sectionId="qa-editor-quote" title="Quote">
                <Row className="align-items-center">
                  <Col>
                    <Input
                      id="qa-editor-quote-text"
                      label="Quote text"
                      name="quoteText"
                      as="textarea"
                      variant="secondary"
                      className="quote-text"
                      maxLength={100}
                      required
                    />
                  </Col>
                  <Col md="auto">
                    <Checkbox
                      id={`qa-edtior-quote-approval`}
                      checked={values.quoteApprove}
                      label="Approved"
                      className="flex-row-reverse"
                      onChange={_editor.onSelectionChange("quoteApprove")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <DropDownInput
                      label="User"
                      data={_editor.programUsers || [{ id: 9, name: "Test" }]}
                      value={values.quoteUserId || values.quoteUser}
                      dataKey="id"
                      textField="name"
                      searchTerm={values.quoteUser}
                      onSelect={(user: { id: number; name: string }) => {
                        _editor.onQuoteSelectUserChange(user);
                      }}
                      onSearch={(user: string, metadata: any) => {
                        const { action } = metadata;
                        if (action !== "clear") {
                          _editor.onQuoteInputUserChange(user);
                        }
                      }}
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <Input
                      id="qa-editor-quote-title"
                      label="Title"
                      variant="secondary"
                      name="quoteTitle"
                      required
                    />
                  </Col>
                </Row>
              </Section>
            </Form>
          );
        }}
      </Formik>
    </PrivateLayout>
  );
};

export default QAContentEditor;
