import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import { useAppState, appStateSelectors } from "app/state/AppState";
import StandardButton from "app/components/button/StandardButton";
import PrivateLayout from "app/layout/PrivateLayout";
import Section from "app/components/section/Section";
import { updateProgram, getProgram } from "app/api/adminApis";

const CustomCss: React.FC<{}> = () => {
  const appConfig = useAppState(appStateSelectors.appConfig);

  const [css, setCss] = useState("");

  const save = () => {
    if (!appConfig) {
      return;
    }
    let updatedConfig = {
      custom_css: css,
    };
    //@ts-ignore
    updateProgram(appConfig.id, updatedConfig).then(() => {
      window.location.reload();
    });
  };

  useEffect(() => {
    if (appConfig?.id) {
      getProgram(appConfig.id).then(response => {
        let program = response.data;
        if (program) {
          setCss(program.custom_css ? program.custom_css : "");
        }
      });
    }
  }, [appConfig]);

  return (
    <PrivateLayout contentClassName="configuration-editor">
      <Section
        title="Custom CSS"
        sectionId="configuration-editor-custom-css"
        titleSize="md"
        className="configuration-editor-custom-css mt-4"
      >
        <hr style={{ marginBottom: "2em" }} />
        <Row>
          <Col>
            <h5>Custom CSS Editor</h5>
            <textarea
              style={{ width: "100%", height: "16em" }}
              value={css}
              onChange={e => setCss(e.target.value)}
            />
          </Col>
        </Row>
        <br />

        <Row>
          <Col style={{ justifyContent: "center", display: "flex" }}>
            <StandardButton
              standardBtnId="save-custom-css"
              label="Save"
              variant="secondary"
              onClick={() => save()}
            >
              Save
            </StandardButton>
          </Col>
        </Row>
        <hr className="mt-5" />
      </Section>
    </PrivateLayout>
  );
};

export default CustomCss;
