import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Checkbox from "../../components/checkbox/CheckBox";
import Input from "../../components/input/Input";
import { Form, Formik, FormikProps } from "formik";
import UploadPopup, { UploadPopUpRef } from "../sitePhotos/uploadPopup/UploadPopup";
import { IUploadResponse } from "../../models/common";
import { ReactComponent as IcImage } from "app/assets/icons/images.svg";
import RoundButton from "../../components/button/RoundButton";
import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import { ReactComponent as IcCopy } from "app/assets/icons/edit-schedule.svg";
import { ReactComponent as DeleteIcon } from "app/assets/icons/delete.svg";
import StandardButton from "../../components/button/StandardButton";
import { CarouselLabel } from "./CarouselLabel";
import { createCarousel, updateCarousel, getByProgramId } from "../../api/adminApis";
import { TextAlign } from "chart.js";
import TextButton from "../../components/button/TextButton";
import { appStateSelectors, useAppState } from "../../state/AppState";
import ScreenNotification from "../../components/screenNotification/ScreenNotification";
import { CarouselType } from "../../constants/carousel";

type LabelConfigurator = {
  x?: number;
  y?: number;
  text?: string;
  url?: string;
  background?: string;
  color?: string;
  fontSize?: string;
  minFontSize?: string;
  borderWidth?: number;
  borderColor?: string;
  borderStyle?: string;
  minWidth?: number;
  upperPadding?: number;
  sidePadding?: number;
  textAlign?: TextAlign;
  opacity?: number;
  borderRadius?: number;
};
export type Slide = { image: string; alt?: string; labelConfigurator: LabelConfigurator };

export type CarouselModel = {
  id?: number;
  isActive: boolean;
  images: Array<Slide>;
  carouselConfigurator: {
    autoPlay: boolean;
    infiniteLoop: boolean;
    interval: number;
    minHeight: number;
    maxHeight: number;
  };
};

const defaultValues = {
  isActive: true,
  carouselConfigurator: {
    autoPlay: true,
    infiniteLoop: true,
    interval: 3,
    minHeight: 150,
    maxHeight: 500,
  },
  images: [
    {
      image: "",
      alt: "",
      labelConfigurator: {
        url: "",
        x: 0,
        y: 0,
        text: "",
        background: "",
        textAlign: "left" as TextAlign,
        fontSize: "14",
        minFontSize: "0",
        opacity: 100,
        borderWidth: 0,
      },
    },
  ],
};

export const CarouselConfiguration = () => {
  const selectedIndex = useRef(0);
  const _imageRef = useRef<UploadPopUpRef>(null);
  const formRef = useRef<FormikProps<CarouselModel>>(null);
  const [activeIndex, setActiveIndex] = useState<number>();
  const hideNotification = useAppState(appStateSelectors.hideNotification);
  const showNotificationWithVariant = useAppState(appStateSelectors.showNotificationWithVariant);

  useEffect(() => {
    const fetchCarousel = async () => {
      const { data: carousel } = await getByProgramId(CarouselType.carousel);
      if (Object.keys(carousel).length !== 0 && formRef) {
        formRef.current?.setValues(JSON.parse(JSON.stringify(carousel)));
      }
    };

    fetchCarousel();
  }, []);

  const addResponse = () => {
    const currentImages = formRef.current?.values?.images ?? [];
    formRef.current?.setFieldValue("images", [...currentImages, { image: "" }]);
  };
  function getFeaturedImage(image: string, alt?: string) {
    return image ? (
      <img src={image} style={{ width: "100%", borderRadius: 10 }} alt={alt || "Slide image"} />
    ) : (
      <IcImage className="p-1 drop-icon" />
    );
  }

  const onSubmitForm = async (formValues: CarouselModel) => {
    try {
      const newModel = { ...formValues, type: CarouselType.carousel };

      formValues.id ? await updateCarousel(newModel) : await createCarousel(newModel);
      showNotificationWithVariant(
        "success",
        formValues.id ? "Carousel updated" : "Carousel created"
      );
    } catch (e: any) {
      showNotificationWithVariant("danger", e?.message ? e.message : "Something went wrong");
    } finally {
      setTimeout(() => {
        hideNotification();
      }, 3000);
    }
  };

  const removeSlide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    const currentImages = formRef.current?.values?.images ?? [];
    formRef.current?.setFieldValue(
      "images",
      currentImages.filter((_, i) => i !== index)
    );
    e.stopPropagation();
  };

  const copySlideResponses = () => {
    const firstSlideStyles = formRef.current?.values.images[0].labelConfigurator;
    delete firstSlideStyles?.text;
    delete firstSlideStyles?.url;

    const images = formRef.current?.values.images;
    const newImages = images?.map(image => ({
      ...image,
      labelConfigurator: { ...image.labelConfigurator, ...firstSlideStyles },
    }));

    if (newImages) {
      formRef.current?.setFieldValue("images", [...newImages]);
    }
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={defaultValues}
      onSubmit={onSubmitForm}
    >
      {({ values, setFieldValue, dirty, isValid }) => {
        const { carouselConfigurator } = values;
        return (
          <Form>
            <ScreenNotification variant="danger" styles={{ container: { marginTop: "2rem" } }} />
            <Container className="mt-5" fluid>
              <Row>
                <Col xs={2}>
                  <Checkbox
                    onChange={e => setFieldValue("carouselConfigurator.autoPlay", e)}
                    checked={carouselConfigurator?.autoPlay}
                    id="autoplay-carousel"
                    label="Autoplay carousel"
                  />
                </Col>
                <Col className="mb-2" xs={2}>
                  <Checkbox
                    onChange={e => setFieldValue("carouselConfigurator.infiniteLoop", e)}
                    checked={carouselConfigurator?.infiniteLoop}
                    id="infinite-loop-carousel"
                    label="Infinite loop of the carousel"
                  />
                </Col>
                <Col className="mb-2" xs={2}>
                  <Checkbox
                    onChange={e => setFieldValue("isActive", e)}
                    checked={values.isActive}
                    id="infinite-loop-carousel"
                    label="Is carousel active?"
                  />
                </Col>

                {carouselConfigurator?.autoPlay && (
                  <Row className="mt-2">
                    <Col xs={3}>
                      <Input
                        type={"number"}
                        id="carousel-interval"
                        label="Interval to go to the next item"
                        value={values.carouselConfigurator.interval}
                        onChange={(e: any) =>
                          setFieldValue("carouselConfigurator.interval", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                )}
                <Row className="mt-1">
                  <Col xs={3}>
                    <Input
                      type={"number"}
                      value={values.carouselConfigurator.minHeight}
                      onChange={(e: any) =>
                        setFieldValue("carouselConfigurator.minHeight", e.target.value)
                      }
                      id="carousel-interval"
                      label="Carousel min. height"
                    />
                  </Col>
                  <Col xs={3}>
                    <Input
                      type={"number"}
                      value={values.carouselConfigurator.maxHeight}
                      onChange={(e: any) =>
                        setFieldValue("carouselConfigurator.maxHeight", e.target.value)
                      }
                      id="carousel-interval"
                      label="Carousel max. height"
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col xs={3}>
                    <RoundButton
                      onClick={copySlideResponses}
                      icon={<IcCopy />}
                      variant="secondary"
                      roundBtnId={"add-content"}
                      label="Copy styles"
                    />
                  </Col>
                </Row>
              </Row>
              {activeIndex !== undefined && <CarouselLabel index={activeIndex} />}

              <RoundButton
                onClick={addResponse}
                icon={<IcPlus />}
                variant="primary"
                roundBtnId={"add-content"}
                label="Add carousel image"
              />
              <Row className="mt-3">
                {values.images.map((slide, index) => (
                  <Col key={index} className="mb-2" xs={2}>
                    <div
                      className="drop-file-container"
                      onClick={() => {
                        _imageRef?.current?.show();
                        selectedIndex.current = index;
                      }}
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                        cursor: "pointer",
                      }}
                    >
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {getFeaturedImage(slide.image, slide?.alt)}
                        {!slide.image && <div>Set Featured Image</div>}
                      </div>
                      <div onClick={e => removeSlide(e, index)} className="delete-slide">
                        <DeleteIcon />
                      </div>
                    </div>

                    <div className="d-flex justify-center ">
                      <TextButton
                        className="w-100"
                        onClick={() => setActiveIndex(index)}
                        label="Edit slide label "
                        textBtnId={""}
                      />
                    </div>
                  </Col>
                ))}
              </Row>

              <UploadPopup
                id="response-video"
                onConfirm={(uploadedMedia: IUploadResponse) => {
                  setFieldValue(`images.${selectedIndex.current}.image`, uploadedMedia?.fileUrl);
                }}
                ref={_imageRef}
                mediaType="VIDEO"
              />
              <Row className="justify-content-end mt-3">
                <Col md="auto">
                  <StandardButton
                    standardBtnId="article-editor-save-btn"
                    label="Save Updates"
                    variant="outlined"
                    type="submit"
                    disabled={!dirty || !isValid}
                  />
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};
