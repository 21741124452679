import React, { useEffect, useState } from "react";
import PrivateLayout from "../../../layout/PrivateLayout";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IPage } from "../../../models/IPage";
import { CraftJsonRenderer } from "../PageRender/CraftRender";
import { getPage } from "../../../api/adminApis";

export const PreviewPage = () => {
  const params = useParams();
  const [page, setPage] = useState<IPage>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (params.id) {
      onGetById(params.id);
    }
  }, [params]);

  const onGetById = async (id: string) => {
    try {
      setLoading(true);
      const page = await getPage(id);
      setPage(page.data);
    } catch (error) {
      console.log("🚀 ~ file: usePageBuilder.ts ~ onGetById ~ error", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return null;

  return (
    <PrivateLayout>
      <Row className="align-items-center">
        <Col md={12}>
          {page?.pageContent && <CraftJsonRenderer craftJson={page?.pageContent} />}
        </Col>
      </Row>
    </PrivateLayout>
  );
};
