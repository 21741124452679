import PrivateLayout from "app/layout/PrivateLayout";
import { Formik, Form } from "formik";
import React from "react";
import { Row, Col } from "react-bootstrap";
import "./graduate-profile-manager.scss";
import StandardButton from "app/components/button/StandardButton";
import EditProfileImage from "app/components/profileSelector/EditProfileImage";
import { GeneralSection } from "./components/GeneralSection";
import { EducationSection } from "./components/EducationSection";
import { AboutSection } from "./components/AboutSection";
import ScreenNotification from "app/components/screenNotification/ScreenNotification";

//Graduate gallery profile editor
export const ProfileEditorPage = ({ editor, title }: { editor: any; title: string }) => {
  const _editor = editor;
  return (
    <PrivateLayout contentClassName="new-profile-page-editor">
      <h3 className="title">{title}</h3>
      <ScreenNotification variant="danger" styles={{ container: { marginTop: "2rem" } }} />
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues}
        onSubmit={_editor.onSubmit}
      >
        {({ values, isValid, dirty, errors, touched }) => {
          const params = {
            values,
            touched,
            errors,
            editor: _editor,
          };

          return (
            <Form>
              <Row>
                <Col md="auto">
                  <EditProfileImage
                    mode="vertical"
                    url={_editor.profileImageUrl ?? undefined}
                    id="graduate-builder-profile"
                    onConfirm={image => _editor.onChangeImage("profile_image", image)}
                  />
                </Col>
                <Col>
                  <GeneralSection {...params} />

                  <EducationSection {...params} />

                  <AboutSection {...params} />

                  <StandardButton
                    type="submit"
                    variant="secondary"
                    label="Save Changes"
                    disabled={!dirty || !isValid}
                    className="save-button"
                    standardBtnId="graduate-builder-save-btn"
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </PrivateLayout>
  );
};
