import React from "react";
import { Col, Row } from "react-bootstrap";
import { ToolbarSection } from "../../Editor/Toolbar/ToolbarSection";
import { ToolbarItem } from "../../Editor/Toolbar/ToolbarItem";
import "./ButtonSettings.scss";
import { Margin, Padding } from "../Common";
import { TextDecoration } from "../Common/TextDecoration";
import { Border } from "../Common/Border";
import { Dimensions } from "../Common/Dimensions";
import { Radio } from "../../Editor/Toolbar/ToolbarRadio";

export const ButtonSettings = () => {
  return (
    <>
      <ToolbarSection title="Title" props={["text"]} summary={({ text }: any) => text}>
        <Row>
          <Col xs={12} sm={12} md={12}>
            <div className="d-flex gap-3 mb-1">
              <ToolbarItem propKey="text" type="text" label="Button title" />
              <ToolbarItem propKey="url" type="text" label="Navigation url" />
            </div>
            <Row style={{ marginBottom: 8 }}>
              <Col xs={12} sm={12} md={6}>
                <ToolbarItem label="Navigation target" propKey="target" type={"radio"}>
                  <Radio value="_blank" label="New window" />
                  <Radio value="_self" label="Same window" />
                </ToolbarItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </ToolbarSection>
      <Dimensions />
      <Margin />
      <Padding />
      <ToolbarSection
        title="Colors"
        props={["background", "color"]}
        summary={({ background, color }: { background: string; color: string }) => (
          <div className="flex flex-row-reverse">
            <div
              style={{
                background: `${background}`,
                width: 30,
                height: 30,
                borderRadius: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color,
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                T
              </div>
            </div>
          </div>
        )}
      >
        <ToolbarItem propKey="background" type="color" label="Background color" />
        <ToolbarItem propKey="color" type="color" label="Color" />
      </ToolbarSection>
      <TextDecoration />
      <Border />
    </>
  );
};
