import React from "react";
import StandardButton from "app/components/button/StandardButton";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import DataGrid from "app/components/dataGrid/DataGrid";
import Filter from "app/components/filter/Filter";
import FilterOption from "app/components/filter/FilterOption";
import SearchBar from "app/components/searchBar/SearchBar";
import Switch from "app/components/switch/Switch";
import useAnnouncementListing from "app/hooks/AdminAnnouncementListing";
import PrivateLayout from "app/layout/PrivateLayout";
import {
  IAnnouncement,
  IActiveStatus,
  IAnnouncementLevel,
  AnnouncementLevel,
  AnnouncementStatus,
  AnnouncementType,
  IAnnouncementType,
} from "app/models/adminAnnouncement";
import "./announcement-profile-manager.scss";
import ArticleCard from "../../components/articleCard/ArticleCard";
import T12Modal from "../../components/modal/T12Modal";
import { AnnouncementPreviewModal } from "./AnnouncementPreviewModal";

const GRID_ID = "announcement-account-listing";

const AdminAnnouncementManager: React.FC = () => {
  const _adminAnnouncementListing = useAnnouncementListing();
  return (
    <PrivateLayout contentClassName="announcement-listing">
      <StandardButton
        label="+ New Announcement"
        variant="secondary"
        className="new-announcement-button"
        onClick={_adminAnnouncementListing.onNewAnnounceClick}
        standardBtnId="announcement-listing-new-btn"
      />
      <h3 className="title">Announcement Manager</h3>
      <SearchBar
        placeHolder="Search by title or content"
        searchBarId="announcement-listing-search"
        onChangeText={_adminAnnouncementListing.onSearchKeyChange}
        onSearch={_adminAnnouncementListing.onSearch}
      />
      <Filter
        id="admin-announcement-listing-filter"
        onFilterChange={_adminAnnouncementListing.onFilterChange}
        selectedFilters={_adminAnnouncementListing.filters}
      >
        <FilterOption<IAnnouncementType>
          disableMultipleSelection
          labelAttribute="label"
          valueAttribute="id"
          name="type"
          options={AnnouncementType}
          placeholder="Status"
        />
        <FilterOption<IAnnouncementLevel>
          disableMultipleSelection
          labelAttribute="label"
          valueAttribute="id"
          name="level"
          options={AnnouncementLevel}
          placeholder="Level"
        />
        <FilterOption<IActiveStatus>
          disableMultipleSelection
          labelAttribute="label"
          valueAttribute="id"
          name="status"
          options={AnnouncementStatus}
          placeholder="Status"
        />
      </Filter>
      <DataGrid<IAnnouncement>
        data={_adminAnnouncementListing.data}
        gridId={GRID_ID}
        rowIdAttribute="id"
        currentPage={_adminAnnouncementListing.currentPage}
        onPageChange={_adminAnnouncementListing.onPageChange}
        totalItems={_adminAnnouncementListing.totalItems}
        onAction={_adminAnnouncementListing.onGridAction}
      >
        <DataGrid.Column<IAnnouncement> attributeName="id" header="ID" />
        <DataGrid.Column<IAnnouncement> attributeName="title" header="Title" />
        <DataGrid.Column<IAnnouncement> attributeName="level" header="Level" />
        <DataGrid.Column<IAnnouncement> attributeName="status" header="Status" />
        <DataGrid.Column<IAnnouncement> attributeName="start_date" header="Start date" />
        <DataGrid.Column<IAnnouncement> attributeName="end_date" header="End date" />
        <DataGrid.Column<IAnnouncement>
          attributeName="status"
          header="Toggle Status"
          transform={announcement => (
            <Switch
              checked={announcement.status === "active"}
              onChange={_adminAnnouncementListing.onToggleActive(announcement)}
              switchId="announcement-listing-live-btn"
            />
          )}
        />
        <DataGrid.Column<IAnnouncement>
          action={DATA_GRID_ACTIONS.PREVIEW}
          attributeName="GRID.ACTIONS"
        />
        <DataGrid.Column<IAnnouncement>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName="GRID.ACTIONS"
        />
        <DataGrid.Column<IAnnouncement>
          action={DATA_GRID_ACTIONS.DELETE}
          attributeName="GRID.ACTIONS"
        />
      </DataGrid>
      <AnnouncementPreviewModal ref={_adminAnnouncementListing.previousModalRef} />
    </PrivateLayout>
  );
};

export default AdminAnnouncementManager;
