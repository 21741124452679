import React from "react";
import { Col, Row } from "react-bootstrap";
import "./CarouselLabel.scss";
import Input from "../../components/input/Input";
import { CarouselModel } from "./CarouselConfiguration";
import { useFormikContext } from "formik";
import { getElementSize } from "../PageBuilder/utils/numToMeasurement";

export const CarouselLabel = ({ index }: { index: number }) => {
  const {
    values: { images, carouselConfigurator },
  } = useFormikContext<CarouselModel>();

  const labelConfigurator = index !== undefined ? images[index].labelConfigurator : undefined;
  const image = index !== undefined ? images[index].image : undefined;

  return (
    <Row key={index}>
      <Col xs={2}>
        <Input
          name={`images.${index}.labelConfigurator.text`}
          id="carousel-interval"
          label="Label"
        />
        <Input name={`images.${index}.labelConfigurator.url`} id="carousel-interval" label="Url" />
        <Input name={`images.${index}.alt`} id="carousel-image-alt" label="Alt text" />
        <Input
          type={"number"}
          name={`images.${index}.labelConfigurator.x`}
          id="carousel-interval"
          label="X position %"
          min={0}
        />
        <Input
          type={"number"}
          name={`images.${index}.labelConfigurator.y`}
          id="carousel-interval"
          label="Y position %"
        />
      </Col>
      <Col xs={1}>
        <Input name={`labelConfigurator.textAlign`} id="carousel-interval" label="Text align" />
        <Input
          type={"color"}
          name={`images.${index}.labelConfigurator.background`}
          id="carousel-interval"
          label="Backgdround color"
        />
        <Input
          type={"color"}
          name={`images.${index}.labelConfigurator.color`}
          id="carousel-interval"
          label="Text color"
        />
        <Input
          name={`images.${index}.labelConfigurator.fontSize`}
          id="carousel-interval"
          label="Font size"
        />
        <Input
          name={`images.${index}.labelConfigurator.minFontSize`}
          id="carousel-interval"
          label="Mobile font size"
        />
      </Col>
      <Col xs={1}>
        <Input
          type={"number"}
          name={`images.${index}.labelConfigurator.borderWidth`}
          id="carousel-interval"
          label="Border width"
        />
        <Input
          type={"color"}
          name={`images.${index}.labelConfigurator.borderColor`}
          id="carousel-interval"
          label="Border color"
        />
        <Input
          name={`images.${index}.labelConfigurator.borderStyle`}
          id="carousel-interval"
          label="Border style"
        />
        <Input
          name={`images.${index}.labelConfigurator.borderRadius`}
          id="carousel-interval"
          label="Border radius"
        />
      </Col>
      <Col xs={1}>
        <Input
          type={"number"}
          name={`images.${index}.labelConfigurator.minWidth`}
          id="carousel-interval"
          label="Min. Width"
        />
        <Input
          type={"number"}
          name={`images.${index}.labelConfigurator.upperPadding`}
          id="carousel-interval"
          label="Padding Top-Bottom"
        />
        <Input
          type={"number"}
          name={`images.${index}.labelConfigurator.sidePadding`}
          id="carousel-interval"
          label="Padding Left-Right"
        />
        <Input
          type="number"
          name={`images.${index}.labelConfigurator.opacity`}
          id="carousel-interval"
          label="Opacity %"
        />
      </Col>
      <Col xs={6}>
        <div
          className="good-vibes-only"
          style={{
            minHeight: `${carouselConfigurator.minHeight}px`,
            height: `${carouselConfigurator.maxHeight}px`,
            maxHeight: `${carouselConfigurator.maxHeight}px`,
          }}
        >
          <img
            width={698}
            style={{
              minHeight: `${carouselConfigurator.minHeight}px`,
              maxHeight: `${carouselConfigurator.maxHeight}px`,
              height: `${carouselConfigurator.maxHeight}px`,
            }}
            src={image}
            alt={images[index]?.alt || index.toString()}
          />
          <div
            style={{
              bottom: `${labelConfigurator?.y}%` || 0,
              left: `${labelConfigurator?.x}%` || 0,
              background: `rgba(${parseInt(labelConfigurator?.background?.slice(1, 3) || "0", 16)}, ${parseInt(labelConfigurator?.background?.slice(3, 5) || "0", 16)}, ${parseInt(labelConfigurator?.background?.slice(5, 7) || "0", 16)}, ${labelConfigurator?.opacity}%)`,
              color: labelConfigurator?.color,
              borderWidth: Number(labelConfigurator?.borderWidth),
              borderColor: labelConfigurator?.borderColor,
              borderStyle: labelConfigurator?.borderStyle,
              minWidth: `${labelConfigurator?.minWidth}px`,
              padding: `${labelConfigurator?.upperPadding || 0}px ${labelConfigurator?.sidePadding || 0}px`,
              textAlign: labelConfigurator?.textAlign,
              borderRadius: `${labelConfigurator?.borderRadius}px`,
              fontSize: `max(${getElementSize(labelConfigurator?.minFontSize)}, ${getElementSize(labelConfigurator?.fontSize)})`,
              fontFamily: "Proxima-Nova, sans-serif",
              fontWeight: 900,
            }}
            className="label-position"
          >
            {images?.[index].labelConfigurator?.text}
          </div>
        </div>
      </Col>
    </Row>
  );
};
