import { Col, Row } from "react-bootstrap";
import { ToolbarItem } from "../../Editor/Toolbar/ToolbarItem";
import { ToolbarSection } from "../../Editor/Toolbar/ToolbarSection";
import React from "react";
import { getElementSize } from "../../utils/numToMeasurement";

export const Dimensions = () => {
  return (
    <ToolbarSection
      title="Dimensions"
      props={["width", "height"]}
      summary={({ width, height }: any) => `${getElementSize(width)} x ${getElementSize(height)}`}
    >
      <Row className="mb-1">
        <Col xs={10} sm={10} md={5}>
          <ToolbarItem propKey="overrideWidth" type="checkbox" label="Override width" />
        </Col>
        <Col xs={10} sm={10} md={5}>
          <ToolbarItem propKey="overrideHeight" type="checkbox" label="Override height" />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col xs={10} sm={10} md={5}>
          <ToolbarItem propKey="width" type="text" label="Width" />
        </Col>
        <Col xs={10} sm={10} md={5}>
          <ToolbarItem propKey="height" type="text" label="Height" />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col xs={10} sm={10} md={5}>
          <ToolbarItem propKey="minWidth" type="text" label="Min. width" />
        </Col>
        <Col xs={10} sm={10} md={5}>
          <ToolbarItem propKey="minHeight" type="text" label="Min. height" />
        </Col>
      </Row>
      <Row>
        <Col xs={10} sm={10} md={5}>
          <ToolbarItem propKey="maxWidth" type="text" label="Max. width" />
        </Col>
        <Col xs={10} sm={10} md={5}>
          <ToolbarItem propKey="maxHeight" type="text" label="Max. height" />
        </Col>
      </Row>
    </ToolbarSection>
  );
};
