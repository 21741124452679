import StandardButton from "app/components/button/StandardButton";
import Filter from "app/components/filter/Filter";
import FilterOption from "app/components/filter/FilterOption";
import SearchBar from "app/components/searchBar/SearchBar";
import useQAListing from "app/hooks/QAListing";
import PrivateLayout from "app/layout/PrivateLayout";
import { ICategoryTag, IStatus } from "app/models/common";
import { IGradeBand } from "app/models/program";
import React, { ReactNode } from "react";
import "./qa.scss";

export const QAListing = ({
  children,
  title,
  newAction = false,
  _qaListing,
}: {
  children: ReactNode;
  title: string;
  newAction?: boolean;
  _qaListing: any;
}) => {
  return (
    <PrivateLayout contentClassName="qa-listing">
      {newAction && (
        <StandardButton
          label="+ New Q&amp;A Topic"
          variant="secondary"
          className="new-qa-button"
          onClick={_qaListing.onNewQAClick}
          standardBtnId="qa-listing-new-btn"
        />
      )}
      <h3 className="title">{title}</h3>
      <SearchBar
        placeHolder="Search by topics, title or content ID"
        searchBarId="qa-listing-search"
        onChangeText={_qaListing.onSearchKeyChange}
        onSearch={_qaListing.onSearch}
      />
      <Filter
        id="article-listing-filter"
        onFilterChange={_qaListing.onFilterChange}
        selectedFilters={_qaListing.filters}
      >
        <FilterOption<IGradeBand>
          labelAttribute="gradeBand"
          valueAttribute="id"
          name="gradeBandIds"
          options={_qaListing.gradeBands}
          placeholder="Grade Band"
        />

        <FilterOption<ICategoryTag>
          labelAttribute="categoryTag"
          valueAttribute="id"
          name="categoryIds"
          options={_qaListing.categoryTags}
          placeholder="Category Tags"
        />
        <FilterOption<IStatus>
          labelAttribute="status"
          valueAttribute="id"
          name="statusIds"
          options={_qaListing.statuses}
          placeholder="Status"
        />
      </Filter>
      {children}
    </PrivateLayout>
  );
};
