import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import { useAppState, appStateSelectors } from "app/state/AppState";
import { useConfigurationEditor } from "app/hooks/ConfigurationEditor";
import SearchBar from "app/components/searchBar/SearchBar";
import StandardButton from "app/components/button/StandardButton";
import ScreenNotification from "app/components/screenNotification/ScreenNotification";
import PrivateLayout from "app/layout/PrivateLayout";
import Section from "app/components/section/Section";
import { getApiDomain } from "app/utils/common";
import HtmlContentEditor from "app/components/contentEditor/HtmlContentEditor";
import { updateProgram, getProgram } from "app/api/adminApis";

const LandingPage: React.FC<{}> = () => {
  const _editor = useConfigurationEditor();
  const appConfig = useAppState(appStateSelectors.appConfig);
  const [scriptContent, setScriptContent] = useState("");

  const [landingPageUrl, setLandingPageUrl] = useState("");
  const [htmlContent, setHtmlContent] = useState("");

  const save = () => {
    if (!appConfig) {
      return;
    }
    let updatedConfig = {
      ...appConfig,
      landing_page_url: landingPageUrl,
      landing_page_html: htmlContent,
    };
    //@ts-ignore
    updateProgram(appConfig.id, updatedConfig).then((response) => {
      window.location.reload();
    });
  };

  function getScriptUrl(programId: any): string {
    if (!programId) {
      return "";
    }
    return `<script src="${getApiDomain()}/script/landing_page?program_id=${programId}"></script>`;
  }

  useEffect(() => {
    if (appConfig?.id) {
      getProgram(appConfig.id).then((response) => {
        let program = response.data;
        if (program) {
          setLandingPageUrl(
            program.landing_page_url ? program.landing_page_url : ""
          );
          setHtmlContent(
            program.landing_page_html ? program.landing_page_html : ""
          );
        }
      });
    }
  }, [appConfig]);

  return (
    <PrivateLayout contentClassName="configuration-editor">
      <Section
        title="Landing Page"
        sectionId="configuration-editor-landing-page"
        titleSize="md"
        className="configuration-editor-landing-page mt-4"
      >
        <Row>
          <Col>
            Landing Page HTML
            <HtmlContentEditor
              value={htmlContent}
              onEditorChange={(content) => setHtmlContent(content)}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <div className="input-label-row">Display URL</div>
            <input
              className="input-container input"
              style={{ width: "50%" }}
              id="configuration-editor-landing_page_url"
              type="text"
              name="landing_page_url"
              value={landingPageUrl}
              onChange={(e) => setLandingPageUrl(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <a
              style={{ marginBottom: "2em" }}
              className="btn btn-primary"
              onClick={() => save()}
            >
              Confirm
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="input-container secondary">
              <div className="input-label-row">Script</div>
              <div
                id="configuration-banner-script-container"
                className="field false"
                style={{ width: "50%" }}
              >
                <pre>{getScriptUrl(appConfig?.id)}</pre>
              </div>
              <div>
                <a
                  style={{ marginTop: "1em" }}
                  className="btn btn-primary"
                  onClick={(e) => {
                    navigator.clipboard.writeText(getScriptUrl(appConfig?.id));
                    e.preventDefault();
                  }}
                >
                  Copy to Clipboard
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <hr className="mt-5" />
      </Section>
    </PrivateLayout>
  );
};

export default LandingPage;
