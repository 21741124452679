import { IAdmin } from "app/models/admin";
import { IArticle } from "app/models/article";
import { IQA } from "app/models/qa";
import { IProgramBuilder } from "app/models/program";
import { ILogin } from "app/models/user";
import {
  IEmailScheduleItemBuilder,
  FrequencyValues,
  NotificationScheduleFrequency,
} from "app/models/email";
import { mixed, array, boolean, number, object, SchemaOf, string } from "yup";
import { IPage } from "../models/IPage";
import { IClubEditor } from "../models/clubs";
import { ISamlConfiguration } from "app/models/samlConfiguration";

const emailValidation = () =>
  string().email("Please enter valid email").required("Email address is required");

/**
 * Password validation with regex with minimum 8 characters.
 * @param requiredMessage
 * @returns Password validation schema
 */
const passwordValidation = (requiredMessage = "Password is required") =>
  string().required(requiredMessage);

/**
 * Validates phone number of following format (999) 999-9999
 * @returns validations for US phone number
 */

export const loginValidationSchema: SchemaOf<ILogin> = object().shape({
  email: emailValidation(),
  password: passwordValidation(),
});

export const programBuilderSchema: SchemaOf<
  Omit<IProgramBuilder, "importGlobalArticles" | "importGlobalEmails" | "client_url">
> = object().shape({
  demographicIds: array(),
  features: array(),
  gradeBandIds: array(),
  landing_page_html: string(),
  landing_page_url: string(),
  programDescription: string().required("Required"),
  programName: string().required("Required"),
  programUrl: string()
    .matches(
      /[\w-]+[a-z0-9](.think-12.com)/g,
      "Please enter a valid url ending with '.think-12.com'"
    )
    .required("Required"),
  client_url: object(),
  schoolName: string(),
  schoolTypeIds: array(),
  shortSchoolName: string(),
  dnsStatus: boolean(),
  custom_css: string(),
  is_sso_enabled: boolean(),
});

export const articleSchema: SchemaOf<
  Omit<
    IArticle,
    "activeStatus" | "articleUrl" | "tmpFeaturedImage" | "versionId" | "programId" | "special"
  >
> = object().shape({
  approvalStatusId: number().required("Required"),
  articleContent: string().required("Required"),
  articleId: string(),
  articleTitle: string().required("Required"),
  categoryIds: array().min(1),
  contentDescription: string().required("Required"),
  designationId: number().required("Required"),
  gradeBandIds: array().min(1),
  schoolTypeIds: array().min(1),
  isActive: boolean(),
  mediaId: number().required("Featured image is required"),
  featuredImageUrl: string().required("Featured image is required"),
});

export const qaSchema: SchemaOf<
  Omit<
    IQA,
    "responsesStr" | "programUsers" | "topicId" | "url" | "quoteUser" | "id" | "deletedResponses"
  >
> = object().shape({
  gradeBandIds: array().min(0),
  categoryIds: array().min(0),
  quoteText: string().required(),
  quoteApprove: boolean().default(false),
  quoteUser: string().when("quoteUserId", (quoteUserId, schema) => {
    console.log(" -- considering ", quoteUserId);
    return !quoteUserId ? schema.required() : schema.nullable().optional();
  }),
  quoteUserId: mixed().optional(),
  // quoteUserId: mixed().when('quoteUser', (quoteUser, schema) => {
  //   return !quoteUser && schema.required();
  // }),
  quoteTitle: string().required(),
  responses: array(),
  // topic: string().required(),
  topic: string().optional(),
  responsesStr: string(),
  programUsers: array(),
  topicId: string(),
  activeStatus: boolean().default(false),
});

export const pageSchema: SchemaOf<Omit<IPage, "id" | "programId" | "roles">> = object().shape({
  pageContent: string().required(),
  pageTitle: string().required("Page title is required"),
  pageUrl: string().required("Page url is required"),
});

export const adminSchema: SchemaOf<Omit<IAdmin, "activeStatus">> = object().shape({
  firstName: string().required("Required"),
  lastName: string().required("Required"),
  email: emailValidation(),
  password: string().required("Required"),
  accountDesignationIds: array().min(1),
  verificationCode: string().required("Required"),
});

export const validateClubManager: SchemaOf<Pick<IClubEditor, "name" | "about">> = object().shape({
  name: string().required("Name is required"),
  about: string().required("About is required"),
});

export const educationScheduleEditorSchema: SchemaOf<
  Pick<
    IEmailScheduleItemBuilder,
    | "notification_template_id"
    | "delivery_frequency"
    | "recipient_frequency"
    | "start_at"
    | "end_at"
    | "grade_band_ids"
    | "role_ids"
  >
> = object().shape({
  notification_template_id: number().required("Please select a notification template"),
  delivery_frequency: mixed<NotificationScheduleFrequency>().test(value =>
    FrequencyValues.includes(value!)
  ),
  recipient_frequency: mixed<NotificationScheduleFrequency>().test(value =>
    FrequencyValues.includes(value!)
  ),
  start_at: mixed().optional(),
  end_at: mixed().optional(),
  grade_band_ids: array(),
  role_ids: array(),
});

export const samlConfigurationSchema: SchemaOf<ISamlConfiguration> = object().shape({
  sp_entity_id: string().required("Required"),
  sp_acs_url: string().required("Required"),
  sp_sls_url: string().optional(),
  sp_certificate: string().required("Required"),
  sp_private_key: string().required("Required"),
  idp_entity_id: string().required("Required"),
  idp_sso_url: string().required("Required"),
  idp_slo_url: string().optional(),
  idp_cert: string().required("Required")
});