import {
  deleteArticle,
  listArticles,
  updateArticle,
  listArticlesContent,
  deleteAssignedArticle
} from 'app/api/adminApis';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import ROUTES from 'app/constants/navigation';
import { IArticle } from 'app/models/article';
import { IArticleListItem } from 'app/models/article';
import { PreviousVersionsPopUpRef } from 'app/screens/articles/components/PreviousVersionsPopUp';
import { ArticlePreviewPopupRef } from 'app/screens/articles/components/ArticlePreviewPopup';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { useRef } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import usePagination from './Pagination';
import { getAppConfig } from 'app/api/commonApis';

const useArticlesListing = () => {
  const navigate = useNavigate();
  const _pagination = usePagination<IArticleListItem>({
    listFn: listArticlesContent,
    deleteFn: deleteArticle,
  });
  const appConfig = useAppState(appStateSelectors.appConfig);
  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const contentCategories = useAppState(appStateSelectors.contentCategories);
  const designations = useAppState(appStateSelectors.contentDesignations);
  const contentApprovals = useAppState(appStateSelectors.contentApprovals);

  const previousModalRef = useRef<PreviousVersionsPopUpRef>(null);
  const previewModalRef = useRef<ArticlePreviewPopupRef>(null);

  const onNewArticleClick = () => navigate(ROUTES.ARTICLE_EDITOR);

  const onToggleActive =
    (article: IArticleListItem) => async (checked: boolean) => {
      if (article.program_id !== appConfig?.id) {
        return;
      }
      const response = await updateArticle(article.id, {
        activeStatus: checked,
      });
      if (response) {
        _pagination.updateData(article.id, 'id', {
          activeStatus: checked,
        });
      }
    };

    const onRemoveAssigned = (article: IArticleListItem) => async () => {
      const response = await deleteAssignedArticle(article.id);
      if (response) {
        window.location.reload();
      }
    }

  const getArticleById = (id : number) => {
    const article = _pagination.data.filter((x) => x.id === id)[0];
    return article;
  }

  const onGridAction = (
    action: DATA_GRID_ACTIONS,
    id: number,
    item: any = null
  ) => {
    let article = getArticleById(id);
    switch (action) {
      case DATA_GRID_ACTIONS.PREVIOUS:
        previousModalRef.current?.show(id);
        break;
      case DATA_GRID_ACTIONS.EDIT:
        if (article?.program_id === appConfig?.id) {
          navigate(`${ROUTES.ARTICLE_EDITOR}/${id}`); 
        } else {
          alert('You may only edit articles from this program.')
        }
        
        break;
      case DATA_GRID_ACTIONS.DELETE:
        if (article?.program_id === appConfig?.id) {
          _pagination.onDelete?.(id);
        } else {
          alert('You may only delete articles from this program.')
        }
        break;
      case DATA_GRID_ACTIONS.PREVIEW:
        const previewItem =
          item || _pagination.data.filter((x) => x.id === id)[0];
        previewModalRef.current?.show(previewItem);
        break;
      default:
        break;
    }
  };

  return {
    ..._pagination,
    gradeBands,
    contentCategories,
    designations,
    contentApprovals,
    previousModalRef,
    previewModalRef,
    onNewArticleClick,
    onToggleActive,
    onGridAction,
    onRemoveAssigned
  };
};

export default useArticlesListing;
