import React, { useEffect } from "react";

import Section from "app/components/section/Section";
import DataGrid from "app/components/dataGrid/DataGrid";
import Switch from "app/components/switch/Switch";
import { IConfigurationEditorSection } from "app/hooks/ConfigurationEditor";
import {
  IProgramFeatureResponse,
} from "app/models/program";
import { getProgramRolePermissions, createProgramRolePermission, deleteProgramRolePermission } from "app/api/adminApis";
import { useOutletContext } from "react-router-dom";

export const RolePermissionsSection = () => {
  const { values } = useOutletContext<IConfigurationEditorSection>();

  const [rolePermissions, setRolePermissions] = React.useState<any>([]);

  const filterFeatures = (features: any[]) => {
    //only features starting with Admin
    return features.filter((feature) => feature.name.startsWith("Admin"));
  };

  useEffect(() => {
    //load role permissions
    if (values.id) {
      loadRolePermissions();
    }
  }, [values.id]);

  const loadRolePermissions = () => {
    if (!values.id) return;
    getProgramRolePermissions(values.id).then((response) => {
      setRolePermissions(response.data);
    });
  };

  const createRolePermission = (feature: any) => {
    if (!values.id) return;
    createProgramRolePermission(values.id, { feature_id: feature.id }).then(() => {
      loadRolePermissions();
    });
  };

  const deleteRolePermission = (feature: any) => {
    if (!values.id) return;
    deleteProgramRolePermission(values.id, { feature_id: feature.id }).then(() => {
      loadRolePermissions();
    });
  };

  const hasRolePermission = (feature: any) => {
    return rolePermissions.find((rolePermission: any) => rolePermission.feature.id === feature.id);
  };

  return (
    <Section
      title="Program Admin Role Permissions"
      sectionId="configuration-editor-features"
      titleSize="md"
      className="configuration-editor-features mt-4"
    >
      <DataGrid<IProgramFeatureResponse>
        data={values.features ? filterFeatures(values.features) : []}
        gridId="configuration-editor-feature-grid"
        loading={false}
        rowIdAttribute="id"
        currentPage={1}
      >
        <DataGrid.Column attributeName="name" header="Feature" />
        <DataGrid.Column<any>
          attributeName="is_active"
          header="Active"
          transform={(feature) => {
            return (
              <Switch
                checked={hasRolePermission(feature)}
                onChange={(checked: boolean) => {
                  if (checked) {
                    createRolePermission(feature);
                  } else {
                    deleteRolePermission(feature);
                  }
                }}
                switchId="feature-is-active-switch"
              />
            );
          }}
        />

      </DataGrid>
      <hr className="mt-5" />
    </Section>
  );
};