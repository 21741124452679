import {
  createStaffProfile,
  getStaffProfiles,
  updateStaffProfile,
} from 'app/api/adminApis';
import { FileUploaderRef } from 'app/components/fileUploader/FileUploader';
import { IEducation, IUploadResponse } from 'app/models/common';
import { IStaffBuilder, IStaffProfile } from 'app/models/staff';
import { IMediaUpload } from 'app/models/media';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { processErrorResponse } from 'app/utils/common';
import { FormikProps } from 'formik';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UploadPopUpRef } from 'app/screens/sitePhotos/uploadPopup/UploadPopupV2';
import { ConfirmPopupRef } from 'app/components/confirmPopup/ConfirmPopup';
import { IUser, IUserV2 } from 'app/models/user';
import { IStaffAbout } from 'app/models/staff';
import * as AppUtils from 'app/utils/common';

const useStaffEditor = () => {
  const navigate = useNavigate();

  const formRef = useRef<FormikProps<IStaffBuilder>>(null);
  const uploaderRef = useRef<FileUploaderRef>(null);
  const uploadPopupRef = useRef<UploadPopUpRef>(null);
  const accountTypePopupRef = useRef<ConfirmPopupRef>(null);

  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const showNotification = useAppState(appStateSelectors.showNotification);
  const hideNotification = useAppState(appStateSelectors.hideNotification);
  const appConfig = useAppState(appStateSelectors.appConfig);
  const showNotificationWithVariant = useAppState(
    appStateSelectors.showNotificationWithVariant
  );

  const params = useParams();

  useEffect(() => {
    return () => hideNotification();
  }, []);

  useEffect(() => {
    params?.id && _getStaffDetails();
  }, [params]);

  const _getStaffDetails = async () => {
    showLoader();
    try {
      const { data } = await getStaffProfiles(+params.id!);
      formRef.current?.setValues({
        ...data,
        profile_image: {
          ...data.profile_image,
          fileUrl: data?.profile_image?.path ?? undefined,
        },
        reviews: (data.reviews ?? []).map((review, index) => ({
          ...review,
          tmp_id: review.id,
        })),
        about_media: data.about_media
          ? {
              ...data.about_media,
              fileUrl: data?.about_media?.path,
            }
          : undefined,
        is_work_email_visible: data.is_work_email_visible ? 1 : 0,
        is_secondary_email_visible: data.is_secondary_email_visible ? 1 : 0,
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: StaffBuilder.ts  ~ const_getStaffDetails= ~ error',
        error
      );
    } finally {
      hideLoader();
    }
  };

  const onAccountSelect = (user: Partial<IUserV2>) => {
    formRef.current?.setValues((prevValues) => ({
      ...prevValues,
      user_id: user.id!,
      first_name: user.first_name ?? prevValues.first_name,
      last_name: user.last_name ?? prevValues.last_name,
    }));
  };

  const onSelectionChange =
    (key: keyof Pick<IStaffBuilder, 'grade_band_ids'>) =>
    (value: number | number[]) =>
      formRef.current?.setFieldValue(key, value);

  const onFeaturedStatusChange = (checked: boolean) => {
    formRef.current?.setFieldValue('is_standard', !checked);
  };

  const onChange = (key: string) => (value: boolean | string | number) => {
    const formattedValue = [
      'is_work_email_visible',
      'is_secondary_email_visible',
    ].includes(key)
      ? value
        ? 1
        : 0
      : value;
    formRef.current?.setFieldValue(key, formattedValue);
  };

  const onChangeImage = (key: string) => (media: IMediaUpload) => {
    console.log(key);
    console.log(media);
    formRef.current?.setFieldValue(key, media);
  };

  const onChangeSocialMedia = (e: any) => {
    const social_media_accounts = {
      url: e.target.value,
      provider: 'LinkedIn',
    };
    formRef.current?.setFieldValue('social_media_accounts', [
      social_media_accounts,
    ]);
  };

  const onChangeAboutEntry = (name: string, e: any) => {
    const updatedEntries = {
      ...(formRef.current?.values?.about ?? {}),
    } as IStaffAbout;
    let existingIndex = -1;
    for (let i = 0; i < updatedEntries?.fields?.length; i++) {
      if (updatedEntries.fields[i].name === name) {
        existingIndex = i;
      }
    }

    if (existingIndex !== -1) {
      updatedEntries.fields[existingIndex] = {
        ...updatedEntries.fields[existingIndex],
        value: e.target.value,
      };
    } else {
      updatedEntries.fields = updatedEntries.fields ?? [];
      updatedEntries.fields.push({ name, value: e.target.value });
    }

    formRef?.current?.setFieldValue('about', updatedEntries);
  };

  const onChangeReview = (index: number) => (e: any) => {
    const updatedReviews = [...(formRef.current?.values?.reviews ?? [])];
    updatedReviews[index] = {
      ...updatedReviews[index],
      body: e.target.value,
    };

    formRef.current?.setFieldValue('reviews', updatedReviews);
  };

  const addEducation = () => {
    formRef.current?.setFieldValue('educations', [
      ...(formRef.current.values.educations || []),
      { ...education },
    ]);
  };

  const onDeleteEducation = (index: number) => {
    const updated = [...(formRef.current?.values.educations ?? [])];
    const updatedDissociate = {
      ...(formRef.current?.values?.dissociate ?? {}),
    };
    const educationToRemove = updated[index];

    if (educationToRemove.id) {
      const exists = (updatedDissociate.educations ?? []).find(
        (education) => education.id === educationToRemove.id
      );
      if (!exists) {
        updatedDissociate.educations = updatedDissociate.educations ?? [];
        updatedDissociate.educations.push({ id: educationToRemove.id });
      }
    }

    updated.splice(index, 1);

    formRef.current?.setValues((values) => ({
      ...values,
      educations: updated,
      dissociate: updatedDissociate,
    }));
  };

  const addReview = () => {
    const currentReviews = formRef.current?.values?.reviews || [];
    const sorted = currentReviews
      .map((review) => review.id ?? review.tmp_id!)
      .sort((a, b) => (a < b ? -1 : 1));
    const tmp_id = sorted.length > 0 ? sorted[sorted.length - 1] + 1 : 1;

    formRef.current?.setFieldValue('reviews', [...currentReviews, { tmp_id }]);
  };

  const removeReview = (index: number) => () => {
    let updatedReviews = [...(formRef.current?.values?.reviews ?? [])];
    let updatedDissociates = {
      ...(formRef.current?.values?.dissociate ?? {}),
    };
    const reviewToRemove = updatedReviews[index];

    if (reviewToRemove.id) {
      const exists = (updatedDissociates.reviews ?? []).find(
        (review) => review.id === reviewToRemove.id
      );
      if (!exists) {
        updatedDissociates.reviews = updatedDissociates.reviews ?? [];
        updatedDissociates.reviews.push({ id: reviewToRemove.id });
      }
    }

    updatedReviews.splice(index, 1);

    formRef.current?.setValues((values) => ({
      ...values,
      reviews: updatedReviews,
      dissociate: updatedDissociates,
    }));
  };

  const onVideoSelected = (uploadedMedia: IUploadResponse) => {
    // TODO: Finish onVideoSelected...
    // if (uploadedMedia) {
    //   formRef.current?.setFieldValue('mediaUrl', uploadedMedia.fileUrl);
    // }
  };

  const onUploadComplete = (response: IUploadResponse[]) => {
    // TODO: Finish onVideoSelected...
    // formRef.current?.setFieldValue('mediaUrl', response[0].fileUrl);
  };

  const formatParams = (params: IStaffBuilder) => {
    const formatted: IStaffBuilder = { ...params };

    // Images
    formatted.about_media = AppUtils.formatMedia(
      params?.id,
      'Staff',
      formatted.about_media
    );

    // Reviews
    if (formatted.reviews) {
      formatted.reviews = (formatted.reviews ?? []).map((review) => {
        return review.id
          ? { id: review.id, body: review.body }
          : { body: review.body };
      });
    }

    // About
    formatted.about = (formatted.about?.fields ?? []).map((field) => ({
      name: field.name,
      value: field.value,
    })) as any;

    // Booleans
    formatted.is_work_email_visible = formatted.is_work_email_visible
      ? 1
      : (0 as any);
    formatted.is_secondary_email_visible = formatted.is_secondary_email_visible
      ? 1
      : (0 as any);
    formatted.is_standard = formatted.is_standard ? 1 : (0 as any);

    console.log({ FORMATTED: formatted });
    // Form data
    const formData = new FormData();
    AppUtils.buildFormData(formData, formatted, null);

    // Collections
    const grade_bands = formatted.grade_band_ids ?? [];
    delete (formatted as any).grade_band_ids;

    AppUtils.addCollection('grade_band_ids', grade_bands, formData);

    if (params.id) {
      formData.append('_method', 'PUT');
    } else {
      formData.append('_method', 'POST');
    }
    
    return formData;
  };

const onSubmit = async (staff: IStaffBuilder) => {
    showLoader();
    hideNotification();
    try {
      const formattedParams = formatParams(staff);
      const {} = await (staff.id
        ? updateStaffProfile(staff.id, formattedParams)
        : createStaffProfile(formattedParams));

      navigate(-1);
    } catch (error: any) {
      processErrorResponse({
        error,
        callback: (errorMessage) =>
          showNotificationWithVariant('danger', errorMessage),
      });
      console.log('🚀 ~ file: StaffEditor.ts ~ onSubmit ~ error', error);
    } finally {
      hideLoader();
    }
  };

  return {
    initialValues,
    schoolName: appConfig?.schoolShortName || appConfig?.schoolName,
    formRef,
    uploaderRef,
    uploadPopupRef,
    accountTypePopupRef,
    gradeBands,
    addEducation,
    onChange,
    onChangeImage,
    onChangeSocialMedia,
    onChangeAboutEntry,
    onChangeReview,
    onDeleteEducation,
    onSubmit,
    onAccountSelect,
    onSelectionChange,
    onFeaturedStatusChange,
    onVideoSelected,
    onUploadComplete,
    addReview,
    removeReview,
  };
};

export default useStaffEditor;
const education: IEducation = { degree: '', name: '', outcomeId: undefined };

const initialValues: Partial<IStaffBuilder> = {
  first_name: '',
  last_name: '',
  title: '',
  educations: [{ ...education }],
  is_standard: true,
};
