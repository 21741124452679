import { Form } from "formik";
import Section from "../../../components/section/Section";
import React, { FormEvent, useEffect, useState } from "react";
import { fetchNavigationItems } from "../../../api/features/features.api";
import { IProgramFeatureResponse } from "../../../models/program";
import DataGrid from "../../../components/dataGrid/DataGrid";
import Switch from "../../../components/switch/Switch";
import { useOutletContext } from "react-router-dom";
import { IConfigurationEditorSection } from "../../../hooks/ConfigurationEditor";

export const NavigationSettings = () => {
  const { navigationItems, setNavigationItems } = useNavigationItems();
  const { editor } = useOutletContext<IConfigurationEditorSection>();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await editor?.onSubmit?.({ features: navigationItems });
  };

  const onFeatureNameChange = (
    id: number,
    name: string,
    field: "display_name" | "redirect_to" | "order_number" = "display_name"
  ) => {
    const existingFeatures = [...(navigationItems || [])];
    const updatedFeatures = existingFeatures.map(feature => {
      if (feature.id === id) {
        feature[field] = name;
      }
      return feature;
    });
    setNavigationItems(updatedFeatures);
  };

  const onFeatureChange = (id: number, type: "permitted" | "active", checked: number) => {
    const existingFeatures = [...(navigationItems || [])];
    const updatedFeatures = existingFeatures.map(feature => {
      if (feature.id === id) {
        feature[`is_${type}`] = checked;
      }
      return feature;
    });
    setNavigationItems(updatedFeatures);
  };

  return (
    <Form id="config-form-submit" onSubmit={onSubmit}>
      <Section sectionId="staff-page" title="Program navigation settings">
        <DataGrid<IProgramFeatureResponse>
          data={navigationItems ?? []}
          gridId="configuration-editor-feature-grid"
          loading={false}
          rowIdAttribute="id"
          currentPage={1}
        >
          <DataGrid.Column attributeName="name" header="Feature" />
          <DataGrid.Column<any>
            attributeName="display_name"
            header="Display Name"
            transform={feature => (
              <input
                style={{
                  width: "80%",
                  marginTop: ".5em",
                  marginBottom: ".5em",
                  padding: ".3em",
                  borderColor: "#eee",
                  borderStyle: "solid",
                  borderWidth: "1px",
                }}
                id="feature-display-name"
                value={feature.display_name}
                onChange={(e: any) => onFeatureNameChange(feature.id, e.target.value)}
              />
            )}
          />
          <DataGrid.Column<any>
            attributeName="redirect_to"
            header="Redirect to"
            transform={feature => (
              <input
                style={{
                  width: "80%",
                  marginTop: ".5em",
                  marginBottom: ".5em",
                  padding: ".3em",
                  borderColor: "#eee",
                  borderStyle: "solid",
                  borderWidth: "1px",
                }}
                id="feature-redirect_to"
                value={feature.redirect_to}
                onChange={(e: any) =>
                  onFeatureNameChange(feature.id, e.target.value, "redirect_to")
                }
              />
            )}
          />
          <DataGrid.Column<any>
            attributeName="order_number"
            header="Order"
            transform={feature => (
              <input
                style={{
                  width: "20%",
                  marginTop: ".5em",
                  marginBottom: ".5em",
                  borderColor: "#eee",
                  borderStyle: "solid",
                  borderWidth: "1px",
                }}
                id="feature-order_number"
                value={feature.order_number}
                onChange={(e: any) =>
                  onFeatureNameChange(feature.id, e.target.value, "order_number")
                }
              />
            )}
          />
          <DataGrid.Column<any>
            attributeName="is_active"
            header="Active"
            transform={feature => {
              return (
                <Switch
                  checked={feature.is_active}
                  onChange={(checked: boolean) =>
                    onFeatureChange(feature.id, "active", checked ? 1 : 0)
                  }
                  switchId="feature-is-active-switch"
                />
              );
            }}
          />
          <DataGrid.Column<any>
            attributeName="is_enabled"
            header="Enabled"
            transform={feature => {
              return (
                <Switch
                  checked={feature.is_permitted}
                  onChange={(checked: boolean) =>
                    onFeatureChange(feature.id, "permitted", checked ? 1 : 0)
                  }
                  switchId="feature-is-is_permitted-switch"
                />
              );
            }}
          />
        </DataGrid>
      </Section>
    </Form>
  );
};

const useNavigationItems = () => {
  const [navigationItems, setNavigationItems] = useState<IProgramFeatureResponse[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await fetchNavigationItems();
      setNavigationItems(response.data);
    };
    fetch();
  }, []);

  return { navigationItems, setNavigationItems };
};
