import ReportingChart from "../../reporting/ReportingChart";
import React from "react";
import { useReportingProvider } from "../ReportingProvider";

export const OverallReporting = () => {
  const { overallReporting: reportData, engagementDetails } = useReportingProvider();
  return (
    <>
      <div className="engagement-graph">
        <div className="rotate-90">Total Engagements</div>
        {reportData.overallEngagementGraph ? (
          <ReportingChart data={reportData.overallEngagementGraph} type="Bar" />
        ) : (
          []
        )}
      </div>

      <div className="engagement-details">
        <h4>Engagement Details</h4>
        <div className="graph">
          {engagementDetails.engagementDetailsGraph ? (
            <ReportingChart data={engagementDetails.engagementDetailsGraph} type="Pie" />
          ) : (
            []
          )}
        </div>
        <div className="items">
          {engagementDetails.engagementDetailsGraph.map((item: any, i: number) => {
            if (item.value === 0) return [];
            return (
              <div className="item" key={i}>
                <span>{item.value}</span> {item.label}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
