import StandardButton from "../../components/button/StandardButton";
import PrivateLayout from "../../layout/PrivateLayout";
import React from "react";

export const ConfirmSelection = ({
  selectedEntities,
  selectedPrograms,
  entityType,
  onCancel,
  onSubmit,
}: {
  selectedEntities: number;
  selectedPrograms: number;
  entityType: string;
  onCancel: () => void;
  onSubmit: () => void;
}) => {
  return (
    <PrivateLayout contentClassName="article-listing">
      <h3 className="title">Content Sync - Confirm</h3>
      <p>
        Please confirm that you would like to sync {selectedEntities} {entityType}
        {selectedEntities > 1 ? "s" : ""} into {selectedPrograms} program
        {selectedPrograms > 1 ? "s" : ""}.
      </p>
      <div
        className="justify-content-center"
        style={{
          marginTop: "2em",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StandardButton
            label="Cancel"
            variant="outlined"
            onClick={onCancel}
            standardBtnId="content-sync"
          />{" "}
          &nbsp;
          <StandardButton
            label="Confirm"
            variant="secondary"
            onClick={onSubmit}
            standardBtnId="content-sync"
          />
        </div>
      </div>
    </PrivateLayout>
  );
};
