import AdminAreaButton from "app/components/button/AdminAreaButton";
import StandardButton from "app/components/button/StandardButton";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import DataGrid from "app/components/dataGrid/DataGrid";
import DropDown from "app/components/dropDown/DropDown";
import Filter from "app/components/filter/Filter";
import FilterOption from "app/components/filter/FilterOption";
import SearchBar from "app/components/searchBar/SearchBar";
import Switch from "app/components/switch/Switch";
import useProgramListing from "app/hooks/ProgramListing";
import PrivateLayout from "app/layout/PrivateLayout";
import { IGradeBand, IProgram } from "app/models/program";
import React from "react";
import SwitchStatusPopup from "./components/SwitchStatusPopup";
import "./program.scss";
import SwitchActivePopup from "./components/SwitchActivePopup";

const GRID_ID = "admin-program-listing";

const ProgramListing: React.FC = () => {
  const _pgmListing = useProgramListing();

  return (
    <PrivateLayout contentClassName="program-listing">
      <StandardButton
        label="+ New Program"
        variant="secondary"
        className="new-program-button"
        onClick={_pgmListing.onNewProgramClick}
        standardBtnId="program-listing-new-btn"
      />
      <h3 className="title">Program Listing</h3>
      <SearchBar
        placeHolder="Search by keyword, name of client/program"
        searchBarId="program-listing-search"
        onChangeText={_pgmListing.onSearchKeyChange}
        onSearch={_pgmListing.onSearch}
      />

      <DataGrid<IProgram>
        data={_pgmListing.data}
        gridId={GRID_ID}
        loading={_pgmListing.loading}
        rowIdAttribute="id"
        currentPage={_pgmListing.currentPage}
        onPageChange={_pgmListing.onPageChange}
        totalItems={_pgmListing.totalItems}
        onAction={_pgmListing.onGridAction}
      >
        <DataGrid.Column<IProgram> attributeName="schoolName" header="School Name" />
        <DataGrid.Column<IProgram> attributeName="programName" header="Program Name" />
        <DataGrid.Column<IProgram>
          attributeName="programUrl"
          header="Program URL"
          transform={program => (
            <a target="_blank" href={program.programUrl}>
              {program.programUrl}
            </a>
          )}
        />
        <DataGrid.Column<IProgram>
          attributeName="programDescription"
          header="Program Description"
        />
        <DataGrid.Column<IProgram>
          attributeName="isLive"
          header="Live"
          transform={program => (
            <Switch
              checked={program.isLive}
              onChange={_pgmListing.onToggleLive(program)}
              switchId="program-listing-live-btn"
            />
          )}
        />

        <DataGrid.Column<IProgram>
          attributeName="is_program_active"
          header="Active"
          transform={program => (
            <Switch
              checked={program.is_program_active}
              onChange={_pgmListing.onToggleActive(program)}
              switchId="program-listing-live-btn"
            />
          )}
        />
        <DataGrid.Column<IProgram> action={DATA_GRID_ACTIONS.EDIT} attributeName="GRID.ACTIONS" />
        <DataGrid.Column<IProgram>
          action={DATA_GRID_ACTIONS.CUSTOM}
          attributeName="GRID.ACTIONS"
          transform={program => (
            <AdminAreaButton
              id={`${GRID_ID}_${program.id}_admin_area_button`}
              key={`${GRID_ID}_${program.id}_admin_area_button`}
              onClick={_pgmListing.onAdminAreaClick(program)}
            />
          )}
        />
      </DataGrid>
      <SwitchStatusPopup
        id="program-listing-switch-status"
        ref={_pgmListing.switchStatusRef}
        onConfirm={_pgmListing.onConfirmStatusChange}
      />
      <SwitchActivePopup
        id="program-listing-switch-status"
        ref={_pgmListing.switchActiveRef}
        onConfirm={_pgmListing.onChangeActiveStatus}
      />
    </PrivateLayout>
  );
};

export default ProgramListing;
