import React from "react";
import { getElementSize } from "../../utils/numToMeasurement";
import "./Div.scss";
import styled from "styled-components";

const getStyles = (style: any) => {
  const {
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    background,
    color,
    padding,
    margin,
    shadow,
    borderRadius,
    height,
    width,
    maxWidth,
    maxHeight,
    minHeight,
    minWidth,
  }: any = style;

  return {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: justifyContent,
    flexDirection: flexDirection,
    alignItems: alignItems,
    background: background,
    color: color,
    boxShadow: shadow === 0 ? "none" : `0px 3px 100px ${shadow}px rgba(0, 0, 0, 0.13)`,
    flex: fillSpace === "yes" ? 1 : "unset",
    padding: getElementSize(padding),
    borderRadius: getElementSize(borderRadius),
    margin: getElementSize(margin),
    height: getElementSize(height),
    width: getElementSize(width),
    minHeight: getElementSize(minHeight),
    minWidth: getElementSize(minWidth),
    maxHeight: getElementSize(maxHeight),
    maxWidth: getElementSize(maxWidth),
  };
};
/**
 * @Deprecated
 */
export const DIV = ({ children, ...props }: any) => {
  return (
    <div {...props} className={"grid-container"} style={getStyles(props.style)}>
      {children}
    </div>
  );
};

const StyledGrid = styled.div<any>`
  ${props =>
    props.xs &&
    `
    flex-basis: ${(props.xs / 12) * 100}%!important;
    max-width: ${(props.xs / 12) * 100}%!important;
  `};

  @media (min-width: 576px) {
    ${props =>
      props.sm &&
      `
      flex-basis: ${(props.sm / 12) * 100}%!important;
      max-width: ${(props.sm / 12) * 100}%!important;
    `}
  }
  @media (min-width: 768px) {
    ${props =>
      props.md &&
      `
      flex-basis: ${(props.md / 12) * 100}%!important;
      max-width: ${(props.md / 12) * 100}%!important;
    `}
  }
  @media (min-width: 992px) {
    ${props =>
      props.lg &&
      `
      flex-basis: ${(props.lg / 12) * 100}%!important;
      max-width: ${(props.lg / 12) * 100}%!important;
    `}
  }
  @media (min-width: 1200px) {
    ${props =>
      props.xl &&
      `
      flex-basis: ${(props.xl / 12) * 100}%!important;
      max-width: ${(props.xl / 12) * 100}%!important;
    `}
  }
`;

/**
 * @Deprecated
 */
export const Grid = ({ children, xs, sm, md, lg, xl, ...props }: any) => {
  return (
    <StyledGrid
      {...props}
      className={`grid-item`}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      style={getStyles(props.style)}
    >
      {children}
    </StyledGrid>
  );
};
