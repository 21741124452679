import React from "react";
import { Col, Row } from "react-bootstrap";
import { Formik } from "formik";

import { useConfigurationEditor } from "app/hooks/ConfigurationEditor";
import StandardButton from "app/components/button/StandardButton";
import ScreenNotification from "app/components/screenNotification/ScreenNotification";
import PrivateLayout from "app/layout/PrivateLayout";

import "./configuration-editor.scss";
import { ITabs, Tabs } from "../../components/tabs/Tabs";
import ROUTES from "../../constants/navigation";
import { Outlet } from "react-router-dom";

const TabsOptions: ITabs[] = [
  { to: ROUTES.CONFIGURATION_MANAGER_ROLES_PERMISSIONS, label: "Roles and Features" },
  { to: ROUTES.CONFIGURATION_MANAGER_FEATURES, label: "Features" },
  { to: ROUTES.CONFIGURATION_MANAGER_STAFF_SECTION, label: "Staff and Color" },
  { to: ROUTES.CONFIGURATION_MANAGER_PROGRAM_DETAILS, label: "Program Details" },
  { to: ROUTES.CONFIGURATION_MANAGER_PROGRAM_ELEMENTS, label: "Program Elements" },
  { to: ROUTES.CONFIGURATION_MANAGER_PROGRAM_BANNER, label: "Program Banner" },
  { to: ROUTES.CONFIGURATION_MANAGER_PHOTOS_COPY, label: "Photos and Copy" },
  { to: ROUTES.CONFIGURATION_MANAGER_PAGES_CONFIGURATION, label: "Pages copy" },
  { to: ROUTES.CONFIGURATION_MANAGER_PAGES_SETTINGS, label: "Pages settings" },
  { to: ROUTES.CONFIGURATION_MANAGER_NAVIGATION, label: "Navigation" },
  { to: ROUTES.CONFIGURATION_MANAGER_3RD_PARTY, label: "Scripts" },
];

const ConfigurationEditor: React.FC<{}> = () => {
  const _editor = useConfigurationEditor();

  return (
    <PrivateLayout contentClassName="configuration-editor">
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues}
        onSubmit={_editor.onSubmit}
      >
        {formikProps => {
          return (
            <>
              <Row className="my-5">
                <Col>
                  <h3 className="title">Main Program Configuration</h3>
                </Col>
                <Tabs tabs={TabsOptions} />
                <Col className="mt-3">
                  <div className="top-button-container">
                    <StandardButton
                      form="config-form-submit"
                      standardBtnId="configuration-editor-save-btn"
                      label="Save Changes"
                      variant="primary"
                      type="submit"
                      disabled={false}
                    />
                  </div>
                </Col>
              </Row>
              <ScreenNotification variant="danger" styles={{ container: { marginTop: "2rem" } }} />
              <Row>
                <Outlet context={{ ...formikProps, editor: _editor }} />
              </Row>
            </>
          );
        }}
      </Formik>
    </PrivateLayout>
  );
};

export default ConfigurationEditor;
