import React from "react";
import { useAlumniEditor } from "../../hooks/AlumniEditor";
import ScreenNotification from "../../components/screenNotification/ScreenNotification";
import PrivateLayout from "../../layout/PrivateLayout";
import { Form, Formik } from "formik";
import { IAlumniBuilder } from "../../models/alumni";
import { GeneralSection } from "./components/GeneralSection";
import { Col, Row } from "react-bootstrap";
import EditProfileImage from "../../components/profileSelector/EditProfileImage";
import { EducationSection } from "./components/EducationSection";
import { ProfessionExperienceSection } from "./components/ProfessionExperienceSection";
import { AboutSection } from "./components/AboutSection";
import StandardButton from "../../components/button/StandardButton";

export const AlumniProfileEditor = () => {
  const _editor = useAlumniEditor();

  return (
    <PrivateLayout contentClassName="new-profile-page-editor">
      <h3 className="title">Alumni Network Manager</h3>
      <ScreenNotification variant="danger" styles={{ container: { marginTop: "2rem" } }} />
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues as IAlumniBuilder}
        onSubmit={_editor.onSubmit}
      >
        {({ isValid, dirty }) => (
            <Form>
              <Row>
                <Col md="auto">
                  <EditProfileImage
                    mode="vertical"
                    url={_editor.profileImageUrl ?? undefined}
                    id="graduate-builder-profile"
                    onConfirm={image => _editor.onChangeImage("profile_image", image)}
                  />
                </Col>
                <Col>
                  <GeneralSection editor={_editor} />
                  <ProfessionExperienceSection editor={_editor} />
                  <EducationSection editor={_editor} />
                  <AboutSection editor={_editor} />
                  <StandardButton
                    type="submit"
                    variant="secondary"
                    label="Save Changes"
                    disabled={!dirty || !isValid}
                    className="save-button"
                    standardBtnId="graduate-builder-save-btn"
                  />
                </Col>
              </Row>
            </Form>
          )
        }
      </Formik>
    </PrivateLayout>
  )
};
