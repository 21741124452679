import React from "react";
import { Col, Row } from "react-bootstrap";
import { ToolbarItem } from "../../Editor/Toolbar/ToolbarItem";
import { ToolbarSection } from "../../Editor/Toolbar/ToolbarSection";
import { Dimensions } from "../Common/Dimensions";
import { Margin } from "../Common";
import { Border } from "../Common/Border";

export const ImageSettings = () => {
  return (
    <>
      <ToolbarSection title="Image src & alt" props={["src", "alt"]}>
        <Row>
          <Col xs={12} sm={12} md={12}>
            <ToolbarItem mediaType="IMAGE" propKey="src" type="upload" label="Upload image" />
            <ToolbarItem propKey="alt" type="text" label="Image alt" />
          </Col>
        </Row>
      </ToolbarSection>
      <Dimensions />
      <Border />
      <Margin />
      <ToolbarSection title="Image fit">
        <ToolbarItem propKey="objectFit" type="text" label="Object fit" />
        <ToolbarItem propKey="overflow" type="text" label="Overflow" />
      </ToolbarSection>
    </>
  );
};
