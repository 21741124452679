import React from "react";
import { useState } from "react";
import styled from "styled-components";
import "./Toolbar.scss";
import classNames from "classnames";

const Input = styled.input.attrs({})`
  color: #000;
  margin-bottom: 0;
  border-radius: 5px;
  height: 28px;
  transition: 0.15s;
  padding-left: 8px;
`;

export type ToolbarTextInputProps = {
  prefix?: string;
  label?: string;
  type: string;
  onChange?: (value: any) => void;
  value?: any;
};

export const ToolbarTextInput = ({
  onChange,
  value,
  prefix,
  label,
  type,
  ...props
}: ToolbarTextInputProps) => {
  const [active, setActive] = useState(false);

  return (
    <div
      style={{ width: "100%", position: "relative" }}
      className={classNames({ InputColor: type === "color" })}
      onClick={() => {
        setActive(true);
      }}
    >
      <span>{label}</span>
      <Input
        className={classNames("Input", { Width: type !== "color" && type !== "bg" })}
        type={type}
        value={value}
        onChange={(e: any) => onChange?.(e.target.value)}
        {...props}
      />
    </div>
  );
};
