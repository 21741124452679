import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { FormikProps } from "formik";
import { appStateSelectors, useAppState } from "../../../state/AppState";
import { IOutcomeLogos } from "../../../models/outcomeLogos";
import {
  createOutcomeLogo,
  getOutcomeLogoById,
  updateOutcomeLogo,
} from "../../../api/outcome-logos/outcome-logs-api";

export const useOutcomeEditor = () => {
  const formRef = useRef<FormikProps<IOutcomeLogos>>(null);

  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const hideNotification = useAppState(appStateSelectors.hideNotification);
  const showNotificationWithVariant = useAppState(appStateSelectors.showNotificationWithVariant);

  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getOutcomeDetails(+params.id);
    }
  }, [params]);

  useEffect(() => {
    return () => hideNotification();
  }, []);

  const getOutcomeDetails = async (id: number) => {
    showLoader();
    try {
      const { data } = await getOutcomeLogoById(id);
      formRef.current?.setValues({
        ...data,
        media: data.media,
      });
    } catch (error) {
      console.log("🚀 ~ file: AlumniBuilder.ts  ~ const_getAlumniDetails= ~ error", error);
    } finally {
      hideLoader();
    }
  };

  const onSubmit = async () => {
    try {
      if (formRef?.current?.values) {
        showLoader();
        params.id
          ? await updateOutcomeLogo(Number(params.id), formRef.current.values)
          : await createOutcomeLogo(formRef.current.values);
        showNotificationWithVariant("success", `College updated`);
      }
    } catch (e: any) {
      if (e?.message) {
        showNotificationWithVariant("danger", e.message);
      }
      showNotificationWithVariant("danger", `Something went wrong`);
    } finally {
      hideLoader();
      setTimeout(() => {
        hideNotification();
      }, 3000);
    }
  };

  const onSetImage = (media_id: number) => {
    formRef.current?.setValues(prevValues => ({
      ...prevValues,
      media_id,
    }));
  };

  return {
    formRef,
    initialValues,
    onSubmit,
    onSetImage,
  };
};

const initialValues: Partial<IOutcomeLogos> = {
  outcome_title: "",
  outcome_abbreviation: "",
  outcome_type: "",
  outcome_url: "",
  logo_name: "",
  zip_code: "",
};
