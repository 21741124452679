import { FormikProps, FormikHelpers } from "formik";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { findIndex, uniqBy } from "lodash";

import { ConfirmPopupRef } from "app/components/confirmPopup/ConfirmPopup";
import { useAppState, appStateSelectors } from "app/state/AppState";
import { createFamily, getFamily, updateFamily } from "app/api/adminApis";
import { IEducation, IProfileField } from "app/models/common";
import { IAlbum, IFamilyEditorV2, IFamilyMember } from "app/models/family";
import { IUserV2 } from "app/models/user";
import { IMediaUpload } from "app/models/media";
import { processErrorResponse } from "app/utils/common";

const useFamilyEditor = () => {
  const navigate = useNavigate();

  const formRef = useRef<FormikProps<IFamilyEditorV2>>(null);
  const accountTypePopupRef = useRef<ConfirmPopupRef>(null);

  const params = useParams();
  const appConfig = useAppState(appStateSelectors.appConfig);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const showNotification = useAppState(appStateSelectors.showNotification);
  const hideNotification = useAppState(appStateSelectors.hideNotification);
  let programUsers: { id: number; name: string }[] = [];

  useEffect(() => {
    return () => hideNotification();
  }, []);

  useEffect(() => {
    params?.id && _getFamilyDetails();
  }, [params]);

  const _getFamilyDetails = async () => {
    showLoader();
    try {
      const { data } = await getFamily(+params.id!);

      const formatted = _formatFamilyResponse(data);
      console.log("Formatted : ", formatted);
      formRef.current?.setValues(_formatFamilyResponse(data));
    } catch (error) {
      console.error("Error getting family details", error);
    } finally {
      hideLoader();
    }
  };

  const _formatFamilyResponse = (data: IFamilyEditorV2) => {
    const profileFields = Object.entries(data.about?.values || {}).reduce(
      (final, [key, value]) => {
        final[key] = value;
        return final;
      },
      {} as Record<string, string>
    );

    return {
      ...data,
      ...profileFields,
    };
  };

  const onChange =
    (key: keyof Pick<IFamilyEditorV2, "isStandard" | "showEmail" | "showOtherEmail"> | string) =>
    (value: boolean | string | number) => {
      formRef.current?.setFieldValue(key, value);
    };

  const onChangeImage = (key: string) => (media: IMediaUpload) => {
    formRef.current?.setFieldValue(key, media.image);
  };

  const onFamilyHeadSelect = (head: IUserV2) => {
    // Remove this entry from being a member as it's not selected as a head
    let currentMembers = [...(formRef.current?.values?.["members"] || [])];
    const existingMemberIndex = findIndex(currentMembers, member => member.id === head.id);
    if (existingMemberIndex !== -1) {
      currentMembers.splice(existingMemberIndex, 1);
    }

    formRef.current?.setValues(prevValues => ({
      ...prevValues,
      head,
      head_user_id: head.id,
      last_name: head.last_name,
      primary_email: head.email,
      members: currentMembers,
    }));
  };

  const onUpdateMembers = (index: number, user: IUserV2 | null, name?: string) => {
    let members = [...(formRef.current?.values?.members || [])];
    let head = { ...(formRef.current?.values?.head || ({} as IUserV2)) };
    let replaceHeadOfFamily = false;

    if (!user && name) {
      // Increment nonAccountId for new non-account member
      const highestIndex = members
        .filter(member => !member.id)
        .reduce((last, member) => {
          return Math.max(last, member.nonAccountId ?? 0);
        }, 0);

      // Update non-account based user
      const [first_name, last_name] = name.split(/\s(.*)/);
      members[index].first_name = first_name;
      members[index].last_name = last_name;
      members[index].family_id = params?.id ? +params.id : null;
      members[index].id = undefined;
      members[index].nonAccountId = members[index].nonAccountId ?? highestIndex + 1;
    } else if (user) {
      // Update account based user
      members[index] = user;
      if (head.id === user.id) {
        replaceHeadOfFamily = true;
      }
    }

    formRef.current?.setValues(prevValues => ({
      ...prevValues,
      members,
      head: replaceHeadOfFamily ? null : head,
      head_user_id: replaceHeadOfFamily ? null : head.id,
    }));
  };

  const addFamilyMember = () => {
    formRef.current?.setFieldValue("members", [
      ...(formRef.current.values.members || []),
      { ...emptyFamilyMember },
    ]);
  };

  const deleteFamilyMembers = (index: number) => {
    let values = { ...(formRef.current?.values || {}) };
    let dissociate = values.dissociate || {};
    let members = values.members || [];
    const id = members[index]?.id;

    if (!id) return;

    members.splice(index, 1);
    dissociate.members = [...(dissociate.members || []), { id }];

    formRef.current?.setValues(prevValues => ({
      ...prevValues,
      members,
      dissociate,
    }));
  };

  const getConnectedUsers = () => {
    return formRef.current?.values["members"]?.map(member => member.id);
  };

  const addEducation = () => {
    formRef.current?.setFieldValue("educations", [
      ...(formRef.current.values.educations || []),
      { ...newEducationEntry },
    ]);
  };

  const onEducationUserSelect = (index: number, userString: string | number) => {
    const educations = [...(formRef.current?.values.educations || [])];

    const [type, id] = (userString as string).split("-");

    educations[index].user = {
      type: type as "account" | "nonaccount",
      id: +id,
    };

    formRef.current?.setFieldValue("educations", educations);
  };

  const addSocialLink = () => {
    console.log("Adding new link...");
    formRef.current?.setFieldValue("social_media_accounts", [
      ...(formRef.current.values.social_media_accounts || []),
      { ...newSocialMediaLinkEntry },
    ]);
  };

  const onAlbumCreated = (album: IAlbum) => {
    const albums = formRef.current?.values.albums ?? [];
    formRef.current?.setFieldValue("albums", [...albums, album]);
  };

  const onAlbumDeleted = (id: number) => {
    const albums = formRef.current?.values.albums ?? [];
    formRef.current?.setFieldValue(
      "albums",
      albums.filter(album => id !== album.id)
    );
  };

  const onSocialMediaDeleted = (index: number, id: number | null = null) => {
    const social_media_accounts = [...(formRef?.current?.values?.social_media_accounts || [])];
    social_media_accounts.splice(index, 1);

    let dissociate = formRef.current?.values?.dissociate || {};

    if (id) {
      dissociate.social_media_accounts = [...(dissociate.social_media_accounts || []), { id }];
    }

    formRef?.current?.setValues(prevValues => ({
      ...prevValues,
      social_media_accounts,
      dissociate,
    }));
  };

  const onSocialMediaConfirmed = (index: number) => {
    const currentLinks = [...(formRef?.current?.values?.social_media_accounts || [])];
    currentLinks[index].status = "confirmed";
    formRef?.current?.setFieldValue("social_media_accounts", currentLinks);
  };

  const onEducationDeleted = (id: number) => {
    const currentEducations = [...(formRef?.current?.values?.educations || [])];
    currentEducations.splice(id, 1);
    formRef?.current?.setFieldValue("educations", currentEducations);
  };

  const formatSocialMediaLinks = (params: IFamilyEditorV2) => {
    return uniqBy(
      [...(params.social_media_accounts || []), ...(params.socialMediasAdditionals || [])],
      socialMedia => socialMedia.provider
    );
  };

  const formatActivitiesResponses = (params: IFamilyEditorV2) => {
    return [
      "favorite_book",
      "favorite_restaurant",
      "favorite_food",
      "favorite_holiday",
      "favorite_sport",
      "perfect_family_plan",
      "favorite_vacation_place",
      "favorite_movie",
      "bio",
      "favorite_experience",
    ]
      .filter(activity => activity in params)
      .map(activity => {
        const key = activity as keyof IFamilyEditorV2;
        return {
          name: activity,
          value: params[key],
        } as IProfileField;
      });
  };

  const formatParams = (params: IFamilyEditorV2) => {
    const social_media_accounts = formatSocialMediaLinks(params);
    const about = formatActivitiesResponses(params);

    return { ...params, social_media_accounts, about };
  };

  const onSubmit = async (
    family: IFamilyEditorV2,
    formikHelpers: FormikHelpers<IFamilyEditorV2>,
    id?: number
  ) => {
    const familyId = id || family.id;
    hideNotification();
    showLoader();
    try {
      const formattedParams = formatParams(family);
      await (familyId ? updateFamily(+familyId, formattedParams) : createFamily(formattedParams));

      navigate(-1);
    } catch (error: any) {
      processErrorResponse({ error, callback: showNotification });
      console.log("🚀 ~ file: FamilyEditor.ts ~ onSubmit ~ error", error);
    } finally {
      hideLoader();
    }
  };

  return {
    formRef,
    accountTypePopupRef,
    onFamilyHeadSelect,
    onSubmit,
    onChange,
    onChangeImage,
    onUpdateMembers,
    onSocialMediaDeleted,
    onSocialMediaConfirmed,
    onEducationUserSelect,
    onEducationDeleted,
    addEducation,
    addSocialLink,
    addFamilyMember,
    deleteFamilyMembers,
    onAlbumCreated,
    onAlbumDeleted,
    getConnectedUsers,
    programUsers,
    schoolName: appConfig?.schoolShortName || appConfig?.schoolName,
    initialValues,
  };
};
export default useFamilyEditor;

const emptyFamilyMember: IFamilyMember = {
  id: null,
  userId: null,
  memberAge: "",
  memberGender: "",
  memberIconId: undefined,
  memberName: "",
};

const newEducationEntry: IEducation = {
  degree: "",
  name: "",
  outcomeId: undefined,
};

const newSocialMediaLinkEntry = { provider: "", url: "", status: "edit" };

const initialValues: IFamilyEditorV2 = {
  firstName: "",
  lastName: "",
  memberOfSchool: "",
  email: "",
  albums: [],
  otherEmail: "",
  familyMembers: [{} as IFamilyMember],
  schoolId: "",
  educations: [],
  isStandard: true,
  showEmail: false,
  showOtherEmail: false,
  head: null,
};
