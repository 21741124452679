import React, { FormEvent, useRef } from "react";
import { Col, Row } from 'react-bootstrap';
import { Form, getIn, useFormikContext } from "formik";

import Input from 'app/components/input/Input';
import Section from 'app/components/section/Section';
import { IConfigurationEditorSection } from 'app/hooks/ConfigurationEditor';
import DragFileUpload from 'app/components/dragFileUpload/DragFileUpload';
import UploadPopupV2 from '../sitePhotos/uploadPopup/UploadPopupV2';
import { IMediaSelected } from 'app/models/media';
import { getApiDomain } from 'app/utils/common';
import { useOutletContext } from "react-router-dom";
import { IMainProgramConfigBuilder } from "../../models/config";
import { buildJsonFromForm } from "../../utils/objectBuilder";

type BannerDetailsSection = {} & IConfigurationEditorSection;

export const BannerDetailsSection = () => {
  const {
    editor
  } = useOutletContext<IConfigurationEditorSection>()
  const { values, touched, errors } = useFormikContext<IMainProgramConfigBuilder>();
  const ref = useRef<HTMLFormElement>(undefined!);

  function _getErrors(path: string) {
    return getIn(touched, path) && getIn(errors, path)
      ? getIn(errors, path)
      : '';
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = buildJsonFromForm(ref.current);
    const body: Partial<IMainProgramConfigBuilder> = {
      ...data,
      banner_image: values.banner_image,
    }

    await editor?.onSubmit?.(body)
  }
  
  const scriptContent = "<script src=\"" + getApiDomain() + "/script/banner?program_id=" + values.id + "\"></script>";
  return (
    <Form ref={ref} id="config-form-submit" onSubmit={onSubmit}>
      <Section
        title='Program Banner Details'
        sectionId='configuration-editor-banner-details'
        titleSize='md'
        className='configuration-editor-banner-details mt-4'
      >
        <Row>
          <Col>
            <DragFileUpload
              id='configuration-editor-banner-details-image'
              label='Upload Banner Image'
              existingImage={
                values.banner_image?.tmpFileUrl ||
                values.banner_image?.fileUrl ||
                values.banner_image?.path
              }
              onClick={() => editor?.uploadBannerImageRef?.current?.show()}
            />
            <UploadPopupV2
              id='configuration-editor-upload-banner-image'
              onConfirm={() => { }}
              ref={editor?.uploadBannerImageRef}
              mediaType='IMAGE'
              autoUpload={false}
              owner={{
                id: editor?.appConfig?.id,
                type: 'Program',
              }}
              onFileSelect={(
                type,
                file?: File,
                tmpFileStr?: string,
                folderId?: number,
                existingMedia?: IMediaSelected
              ) => {
                if (file && folderId) {
                  editor?.onMediaUpload?.('banner_image', {
                    file,
                    tmpFileUrl: tmpFileStr ?? '',
                    folderId,
                  });
                } else if (existingMedia) {
                  editor?.onMediaUpload?.('banner_image', undefined, {
                    media: existingMedia,
                  });
                }
              }}
            />
          </Col>
          <Col>
            <Input
              id='configuration-editor-banner_alt'
              label='Image Alt Text'
              variant='secondary'
              name='banner_alt_text'
              error={_getErrors('think12_banner_alt_text')}
            />
          </Col>
          <Col>
            <Input
              id='configuration-editor-banner_link'
              label='Banner URL'
              variant='secondary'
              name='banner_link'
              error={_getErrors('think12_banner_link')}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <div className='input-container secondary'>
              <div className='input-label-row'>
                Banner Script
              </div>
              <div id='configuration-banner-script-container' className='field false'>
              <pre>
                {scriptContent}
              </pre>
              </div>
              <div>
                <a style={{marginTop:'1em'}} className='btn btn-primary' href='#' onClick={(e) => {navigator.clipboard.writeText(scriptContent); e.preventDefault();}} >
                  Copy to Clipboard
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <hr className='mt-5' />
      </Section>
    </Form>
  );
};
