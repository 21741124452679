import { api } from "../adminApis";
import { IAPIResponseV2 } from "../../models/api";
import { FormikPagesConfigurationInterface } from "../../models/pages-configuration";

export const postPagesCopy = (body: object) => {
  return api.post(`page-configuration`, body);
};

export const updateCagesCopy = (body: object) => {
  return api.put(`/page-configuration`, body);
};

export const fetchPageCopy = () => {
  return api.get<IAPIResponseV2<FormikPagesConfigurationInterface>>("/page-configuration");
};
