import React, { PropsWithChildren, useImperativeHandle, useState } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import { ReactComponent as IcClose } from "app/assets/icons/close.svg";
import RoundButton from "../button/RoundButton";
import If from "../If";
import "./modal.scss";
import StandardButton from "../button/StandardButton";

const T12Modal = React.forwardRef<T12ModalRef, PropsWithChildren<IT12ModalProps>>(
  (
    {
      title,
      size,
      description,
      header,
      confirmButtonLabel = "Confirm",
      cancelButtonLabel = "Cancel",
      hideFooter = false,
      showHeaderBorder = false,
      modalId,
      className = "",
      onCancel,
      onConfirm,
      onShow,
      onHide,
      children,
      closeOnConfirm = true,
      confirmButtonProps = {},
      fullScreen,
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false);
    useImperativeHandle(
      ref,
      () => ({
        show: () => {
          setVisible(true);
          onShow?.();
        },
        close: () => setVisible(false),
      }),
      []
    );

    const close = () => {
      onHide?.();
      setVisible(false);
    };

    const _onConfirm = () => {
      closeOnConfirm && close();
      onConfirm?.();
    };

    const _onShow = () => {
      onShow?.();
    };

    return (
      <Modal
        fullscreen={fullScreen ? true : ""}
        size={size}
        contentClassName={`t12-modal ${className}`}
        onHide={onCancel}
        show={visible}
        centered
        onMouseDown={(e: any) => e.stopPropagation()}
      >
        <div
          id={`${modalId}_modal_header`}
          className={`t12-modal-header ${showHeaderBorder ? "header-border" : ""}`}
        >
          <If condition={!!title}>
            <h5>{title}</h5>
          </If>
          {header}
          <RoundButton
            className="close-button"
            icon={<IcClose width={12} className="color-primary" />}
            onClick={close}
            roundBtnId="  t12-close-btn"
          />
        </div>
        <div id={`${modalId}_modal_body`} className="t12-modal-body">
          {children}
        </div>
        <If condition={!hideFooter}>
          <div id={`${modalId}_modal_footer`} className="t12-modal-footer">
            <StandardButton
              onClick={close}
              label={cancelButtonLabel}
              variant="outlined"
              standardBtnId="t12-cancel-btn"
            />
            <StandardButton
              onClick={_onConfirm}
              variant="secondary"
              label={confirmButtonLabel}
              standardBtnId="t12-confirm-btn"
              {...confirmButtonProps}
            />
          </div>
        </If>
      </Modal>
    );
  }
);

export default T12Modal;

export interface IT12ModalProps extends Pick<ModalProps, "size"> {
  title?: string;
  description?: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  header?: React.ReactNode;
  hideFooter?: boolean;
  showHeaderBorder?: boolean;
  closeOnConfirm?: boolean;
  modalId: string;
  className?: string;
  confirmButtonProps?: any;
  onCancel?: () => void;
  onConfirm?: () => void;
  onHide?: () => void;
  onShow?: () => void;
  fullScreen?: boolean;
}

export type T12ModalRef = {
  show: () => void;
  close: () => void;
} | null;
