import { appStateSelectors, useAppState } from "../state/AppState";

export const useFeatures = () => {
  const appConfig = useAppState(appStateSelectors.appConfig);

  const features = appConfig?.features;

  const hasFeature = (feature: string) => {
    const found = features?.find(ft => feature === ft.tag);
    return found?.isActive && found?.isEnabled;
  };

  return { hasFeature };
};
