import React, { useImperativeHandle, useRef } from "react";
import { Col, Row } from "react-bootstrap";

import T12Modal, { T12ModalRef } from "app/components/modal/T12Modal";
import { MergeFieldRow } from "./MergeFieldRow";

const EmailMergeFieldsPopup = React.forwardRef<
  EmailMergeFieldsPopupRef,
  IEmailMergeFieldsPopupProps
>(({ id }, ref) => {
  const modalRef = useRef<T12ModalRef>(null);

  useImperativeHandle(
    ref,
    () => ({
      show: () => modalRef.current?.show(),
      close: () => modalRef.current?.close(),
    }),
    []
  );

  return (
    <T12Modal
      modalId={id}
      hideFooter
      size="lg"
      ref={modalRef}
      title="Available Merge Fields"
      className="email-template-merge-fields-modal"
      showHeaderBorder
    >
      <div className={`email-template-merge-fields-container`}>
        <Row className="merge-field">
          <Col md={4}>
            <h5>Field</h5>
          </Col>
          <Col className="merge-code" md={5}>
            <h5>Merge Code</h5>
          </Col>
          <Col md={2}>
            <h5>Action</h5>
          </Col>
        </Row>
        <MergeFieldRow label="User First Name" field="first_name" />
        <MergeFieldRow label="User Last Name" field="last_name" />
        <MergeFieldRow label="User Email" field="email" />
        <MergeFieldRow label="User Family Name" field="family_name" />
        <MergeFieldRow label="Program School Name" model="program" field="school_name" />
        <MergeFieldRow
          label="Program School Short Name"
          model="program"
          field="school_name_short"
        />
        <MergeFieldRow label="Program Name" model="program" field="program_name" />
        <MergeFieldRow label="Program Url" model="program" field="program_url" />
        <MergeFieldRow label="Program Phone Number" model="program" field="school_phone_number" />
        <MergeFieldRow label="Program Address" model="program" field="school_address" />
        <MergeFieldRow label="Program City" model="program" field="school_city" />
        <MergeFieldRow label="Program State" model="program" field="school_state" />
        <MergeFieldRow label="Program Zip" model="program" field="school_zip_code" />
        <MergeFieldRow label="Program Color1" model="program" field="color1" />
        <MergeFieldRow label="Program Color2" model="program" field="color2" />
        <MergeFieldRow label="Program Color3" model="program" field="color3" />
        <MergeFieldRow label="Program Mascot" model="program" field="school_mascot" />
        <MergeFieldRow label="Verification Code" field="verification_code" />
        <MergeFieldRow label='Email Token' field='email_token' />
      </div>
    </T12Modal>
  );
});

export default EmailMergeFieldsPopup;

interface IEmailMergeFieldsPopupProps {
  id: string;
}

export type EmailMergeFieldsPopupRef = {
  show: () => void;
  close: () => void;
} | null;
