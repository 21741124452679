import React from "react";
import StandardButton from "app/components/button/StandardButton";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import DataGrid from "app/components/dataGrid/DataGrid";
import Filter from "app/components/filter/Filter";
import FilterOption from "app/components/filter/FilterOption";
import SearchBar from "app/components/searchBar/SearchBar";
import Switch from "app/components/switch/Switch";
import useArticlesListing from "app/hooks/ArticleListing";
import PrivateLayout from "app/layout/PrivateLayout";
import { IArticleListItem } from "app/models/article";
import { IDesignation, ICategoryTag, IStatus, ISpecial } from "app/models/common";
import { IGradeBand } from "app/models/program";
import PreviousVersionsPopUp from "./components/PreviousVersionsPopUp";
import ArticlePreviewPopup from "./components/ArticlePreviewPopup";
import "./articles.scss";
import { useAppState, appStateSelectors } from "../../state/AppState";
import { SpecialTags } from "../../constants/articles";
import { usePermission } from "../../utils/user";

const GRID_ID = "admin-article-listing";

const ArticleContentManager: React.FC = () => {
  const { isSuperAdmin } = usePermission();
  const _articleListing = useArticlesListing();

  const appConfig = useAppState(appStateSelectors.appConfig);

  return (
    <PrivateLayout contentClassName="article-listing">
      <StandardButton
        label="+ New Article"
        variant="secondary"
        className="new-article-button"
        onClick={_articleListing.onNewArticleClick}
        standardBtnId="article-listing-new-btn"
      />
      <h3 className="title">Article Content Manager</h3>
      <SearchBar
        placeHolder="Search by article title or content ID"
        searchBarId="article-listing-search"
        onChangeText={_articleListing.onSearchKeyChange}
        onSearch={_articleListing.onSearch}
      />
      <Filter
        id="article-listing-filter"
        onFilterChange={_articleListing.onFilterChange}
        selectedFilters={_articleListing.filters}
      >
        <FilterOption<IGradeBand>
          labelAttribute="gradeBand"
          valueAttribute="id"
          name="gradeBandIds"
          options={_articleListing.gradeBands}
          placeholder="Grade Band"
        />
        <FilterOption<IDesignation>
          labelAttribute="designationType"
          valueAttribute="id"
          name="designationIds"
          options={_articleListing.designations}
          placeholder="Designation"
        />
        <FilterOption<ICategoryTag>
          labelAttribute="categoryTag"
          valueAttribute="id"
          name="categoryIds"
          options={_articleListing.contentCategories}
          placeholder="Category Tags"
        />
        <FilterOption<IStatus>
          labelAttribute="status"
          valueAttribute="id"
          name="approval_status_ids"
          options={_articleListing.contentApprovals}
          placeholder="Status"
        />
        <FilterOption<ISpecial>
          labelAttribute="label"
          valueAttribute="id"
          name="specialTags"
          options={SpecialTags}
          placeholder="Special tags"
          isVisible={isSuperAdmin}
        />
      </Filter>
      <DataGrid<IArticleListItem>
        data={_articleListing.data}
        gridId={GRID_ID}
        loading={_articleListing.loading}
        confirmDeleteDescription="Please confirm that you would like to delete this article"
        rowIdAttribute="id"
        currentPage={_articleListing.currentPage}
        onPageChange={_articleListing.onPageChange}
        totalItems={_articleListing.totalItems}
        onAction={_articleListing.onGridAction}
      >
        <DataGrid.Column<IArticleListItem> attributeName="articleTitle" header="Article Title" />
        <DataGrid.Column<IArticleListItem> attributeName="articleId" header="Content ID" />
        <DataGrid.Column<IArticleListItem> attributeName="gradeBands" header="Grade Band" />
        <DataGrid.Column<IArticleListItem>
          attributeName="designationType"
          header="Designation Type"
        />
        <DataGrid.Column<IArticleListItem> attributeName="categoryTags" header="Category Tags" />
        <DataGrid.Column<IArticleListItem> attributeName="approveStatus" header="Approval Status" />
        <DataGrid.Column<IArticleListItem>
          attributeName="activeStatus"
          header="Assigned"
          transform={article => {
            return article.program_id !== appConfig?.id ? (
              <StandardButton
                standardBtnId="1"
                size="xs"
                variant="secondary"
                label="Remove"
                onClick={_articleListing.onRemoveAssigned(article)}
              />
            ) : (
              <div></div>
            );
          }}
        />
        <DataGrid.Column<IArticleListItem>
          attributeName="activeStatus"
          header="Live"
          transform={article => (
            <Switch
              checked={!!article.activeStatus}
              onChange={_articleListing.onToggleActive(article)}
              switchId="article-listing-live-btn"
            />
          )}
        />
        <DataGrid.Column<IArticleListItem>
          action={DATA_GRID_ACTIONS.PREVIEW}
          attributeName="GRID.ACTIONS"
        />
        <DataGrid.Column<IArticleListItem>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName="GRID.ACTIONS"
        />
        <DataGrid.Column<IArticleListItem>
          action={DATA_GRID_ACTIONS.PREVIOUS}
          attributeName="GRID.ACTIONS"
        />
        <DataGrid.Column<IArticleListItem>
          action={DATA_GRID_ACTIONS.DELETE}
          attributeName="GRID.ACTIONS"
        />
      </DataGrid>
      <PreviousVersionsPopUp
        ref={_articleListing.previousModalRef}
        id="article-listing-previous-versions"
        onVersionPreview={_articleListing.onGridAction}
      />
      <ArticlePreviewPopup ref={_articleListing.previewModalRef} id="article-listing-preview" />
    </PrivateLayout>
  );
};

export default ArticleContentManager;
