import React, { useRef, useState } from "react";
import "./upload-file.scss";

const UploadFile: React.FC<IUploadFile> = ({
  label,
  placeholder,
  onChange,
  onClick,
  uploadFileId,
  value,
}) => {
  const [fileName, setFileName] = useState("");

  const fileRef = useRef<HTMLInputElement>(null);
  const openFileSelector = () => (onClick ? onClick() : fileRef.current?.click());

  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files![0];
    setFileName(file.name);
    onChange?.(file);
  };
  return (
    <div className="uploadFile">
      <div className="uploadFile-label">{label}</div>
      <div className="uploadFile-inputbox">
        <input
          ref={fileRef}
          type="file"
          onChange={onFileSelected}
          className="d-none"
          id={`${uploadFileId}-file`}
        />
        <input type="text" readOnly value={fileName || value} placeholder={placeholder} />
        <button
          type="button"
          className="uploadFile-inputbox-btn"
          onClick={openFileSelector}
          id={`${uploadFileId}_btn`}
        >
          Choose File
        </button>
      </div>
    </div>
  );
};

export default UploadFile;

interface IUploadFile {
  uploadFileId: string;
  label: string;
  placeholder: string;
  onChange?: (media: File) => void;
  onClick?: () => void;
  value?: string;
}
