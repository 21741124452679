import React from "react";
import "./Tooltip.scss";

export const Tooltip = ({ children, text }: { text: string; children: any }) => {
  return (
    <div className="CustomTooltip">
      {children}
      <span className="tooltiptext">{text}</span>
    </div>
  );
};
