import React, { useEffect } from 'react';
import { HashRouter } from 'react-router-dom';

import Loader from './components/loader/Loader';
import ScrollToTop from './components/ScrollToTop';
import { NAVIGATION_STATE } from './constants/common';
import useApp from './hooks/App';
import BackEndAdminNavigations from './navigation/BackEndAdminNavigations';
import packageInfo from '../../package.json';
import { useAppState, appStateSelectors } from './state/AppState';
import 'react-widgets/styles.css';

const App: React.FC = () => {
  const { navState } = useApp();
  const Navigation = navStateMap[navState];

  const appConfig = useAppState(appStateSelectors.appConfig);

  useEffect(() => {
    console.log(`
    **********************
    *** Think12 v${packageInfo.version} ***
    **********************
    `);
  }, []);

  return (
    <HashRouter>
      <ScrollToTop />
      {appConfig && <Navigation /> }
      <Loader />
    </HashRouter>
  );
};

export default App;

const navStateMap: Record<NAVIGATION_STATE, React.FC> = {
  [NAVIGATION_STATE.BACK_END_ADMIN]: BackEndAdminNavigations,
};
