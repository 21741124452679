import React, { FormEvent } from "react";
import { Col, Row } from 'react-bootstrap';

import Input from 'app/components/input/Input';
import Section from 'app/components/section/Section';
import DropDown from 'app/components/dropDown/DropDown';
import { IStaffListItem } from 'app/models/staff';
import { IConfigurationEditorSection } from "app/hooks/ConfigurationEditor";
import { useOutletContext } from "react-router-dom";
import { Form, useFormikContext } from "formik";
import { IMainProgramConfigBuilder } from "../../models/config";
import ColorPicker from "../../components/colorPicker/ColorPicker";

export const StaffSection = () => {
  const {
    editor,
  } = useOutletContext<IConfigurationEditorSection>()
  const { values, touched, errors } = useFormikContext<IMainProgramConfigBuilder>();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const build = {
      staff_spotlight_title: values.staff_spotlight_title,
      staff_spotlight_title_2: values.staff_spotlight_title_2,
      primary_featured_staff_id: values.primary_featured_staff_id,
      color1: values.color1,
      color2: values.color2,
      color3: values.color3,
    }

    await editor?.onSubmit?.(build)
  }

  return (
      <Form id="config-form-submit" onSubmit={onSubmit}>
        <Section
          title='Staff'
          sectionId='configuration-editor-staff'
          titleSize='md'
          className='configuration-editor-staff mt-4'
        >
          <Row>
            <Col>
              <Input
                id='configuration-editor-spotlight-title'
                label='Staff Spotlight Title'
                variant='secondary'
                name='staff_spotlight_title'
                error={
                  touched.staff_spotlight_title && errors.staff_spotlight_title
                    ? errors.staff_spotlight_title
                    : ''
                }
              />
            </Col>
            <Col>
              <Input
                id='configuration-editor-spotlight-title'
                label='Staff Spotlight Sub Title'
                variant='secondary'
                name='staff_spotlight_title_2'
                error={
                  touched.staff_spotlight_title_2 && errors.staff_spotlight_title_2
                    ? errors.staff_spotlight_title_2
                    : ''
                }
              />
            </Col>
            <Col>
              <DropDown<IStaffListItem>
                id='configuration-editor-staff-member'
                label='Primary Featured Staff Member'
                labelAttribute='last_name'
                transformLabel={(option: IStaffListItem) =>
                  `${option.first_name} ${option.last_name}`
                }
                options={editor?.staffMembers?.data || []}
                selected={values.primary_featured_staff_id}
                // valueAttribute='account_id'
                valueAttribute='id'
                placeholder='Please select'
                onOptionChange={(id: string | number) =>
                  editor?.onChange?.('primary_featured_staff_id')(+id)
                }
              />
            </Col>
          </Row>
          <hr className='mt-5' />
        </Section>
        <Section
          title='Color Palette'
          sectionId='configuration-editor-color-palette'
          titleSize='md'
          className='configuration-editor-color-palette mt-4'
        >
          <Row>
            {[1, 2, 3].map((paletteNum) => {
              const colorValue = values[
                `color${paletteNum}` as 'color1' | 'color2' | 'color3'
                ]
                ? values[`color${paletteNum}` as 'color1' | 'color2' | 'color3']
                : null;

              return (
                <Col key={paletteNum} md={{ span: 2, offset: paletteNum > 1 ? 1 : 0 }}>
                  <ColorPicker
                    id={`color-picker-${paletteNum}`}
                    onConfirm={(color) =>
                      editor?.onChange?.(`color${paletteNum}`)(color!)
                    }
                    label={`Color variable ${paletteNum}`}
                    color={colorValue!}
                  />
                </Col>
              );
            })}
          </Row>
          <hr className='mt-5' />
        </Section>
      </Form>
  );
};

