import { IAlumniEditor } from "../../../hooks/AlumniEditor";
import { useFormikContext } from "formik";
import { IAlumniBuilder } from "../../../models/alumni";
import Section from "../../../components/section/Section";
import RoundButton from "../../../components/button/RoundButton";
import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import React from "react";
import ProfessionalExperience from "../../../components/ProfessionalExperience/ProfessionalExperience";

export const ProfessionExperienceSection = ({ editor }: { editor: IAlumniEditor }) => {
  const { values} = useFormikContext<IAlumniBuilder>();

  return (
    <Section
      title="Professional Experience"
      sectionId="profileEditorBuilderProfessionalExperience"
      titleSize="lg"
      className="profileEditor-builder-profession"
      isEditable={true}
    >
      {(values.professions ?? []).map((_: any, index: any) => (
        <ProfessionalExperience
          key={`profileEditor-builder-education-${index}`}
          index={index}
          onDelete={editor.onDeleteProfession}
        />
      ))}

      <RoundButton
        icon={<IcPlus />}
        className="add-icon"
        size="lg"
        variant="primary"
        label="Add more"
        onClick={editor.addProfession}
        roundBtnId="profileEditor-builder-add-btn"
      />
      <hr className="mb-5 mt-4" />
    </Section>
  )
}