import { uniqBy } from 'lodash';
import { FormikTouched, FormikErrors, getIn } from 'formik';
import moment from 'moment';

import { ICategoryTag } from './../models/common';
import { IFamilyListItem, IFamilyListItemV2 } from 'app/models/family';
import { IUser, IUserV2 } from 'app/models/user';
import { IAmbassadorV2 } from './../models/ambassador';
import { IStaffListItem } from 'app/models/staff';
import { AccountType } from 'app/models/accountNotification';
import { IGradeBand } from 'app/models/program';
import { IRole } from './../models/common';
import { IDesignation, IStatus } from './../models/common';
import { IScheduleLimit } from 'app/models/email';

/**
 * Format Account and Profile Level Subscription Status / Preferences
 */
// TODO: Remove
export const formatSubScriptionStatus = (data: AccountType) => {
  if (!data) return '';

  // let subscriptions = [];
  // data.notificationSubscription && subscriptions.push('Notifications');
  // data.promotionalSubscription && subscriptions.push('Promotional');
  // data.transactionalSubscription && subscriptions.push('Transactional');
  // return subscriptions.length === 3
  //   ? 'Full'
  //   : subscriptions.length === 0
  //   ? 'None'
  //   : subscriptions.join(', ');
};

export const formatSubScriptionStatusForFamilies = (
  data: IFamilyListItemV2 | IAmbassadorV2
) => {
  if (!data.head || !data.members) return 'None';

  const accountWidePreferences = [
    ...(data.head?.preferences || []),
    ...(data.members.map((member) => member.preferences)?.flat() || []),
  ];

  const preferences = uniqBy(
    accountWidePreferences,
    (pref) => pref.subcategory
  ).map((preference) => preference.subcategory);

  return preferences.length > 0 ? preferences.join(', ') : 'None';
};

export const formatUserNotificationPreferences = (data: IUserV2) => {
  if (!data) return 'None';

  const preferences = (data.preferences || []).map(
    (preference) => preference.subcategory
  );

  return preferences.length > 0 ? preferences.join(', ') : 'None';
};

/**
 * Format Grade Band display
 */

// From grade band ids
export const formatGradeBandIds = (collection: IGradeBand[], ids: number[]) => {
  const fullGradeBands = collection.filter((gb) => ids.includes(gb.id));
  return formatGradeBand(fullGradeBands);
};

// From grade bands
export const formatGradeBand = (gradeBands?: IGradeBand[] | null) => {
  if (!gradeBands) return '';
  return gradeBands.map((band) => band.gradeBand ?? band.grade_band).join(', ');
};

// From users
export const formatGradeBands = (users: IUserV2[]) => {
  const gradeBands = users.reduce((allBands: IGradeBand[], user: IUserV2) => {
    const updatedBands = (user.grade_bands || []).filter(
      (gb) => !allBands.includes(gb)
    );

    allBands = [...allBands, ...updatedBands];
    return allBands;
  }, [] as IGradeBand[]);

  return gradeBands.length === 0
    ? 'No Roles'
    : gradeBands.map((gb) => gb.grade_band).join(',');
};

/**
 * Format Categories
 */

export const formatCategoryTags = (categories?: ICategoryTag[] | null) => {
  if (!categories) return '';
  return categories.map((category) => category.categoryTag).join(', ');
};

export const formatCategoryTagsFromId = (
  categories: ICategoryTag[],
  ids: number[] | undefined
) => {
  if (!(categories && ids) || ids.length < 1) return '';

  const cats = categories.filter((category) => ids.includes(category.id));
  return cats.join(', ');
};

/**
 * Format Roles
 */

// From grade band ids
export const formatRoleIds = (collection: IRole[], ids: IRole[]) => {
  const selectedRoleIds = ids.map((r) => r.id);
  const fullRoles = collection.filter((role) =>
    selectedRoleIds.includes(role.id)
  );
  return formatRoles(fullRoles);
};

// From grade bands
export const formatRoles = (roles?: IRole[] | null) => {
  if (!roles) return '';
  return roles.map((role) => role.name ?? role.roleName).join(', ');
};

export const formatUserPermissions = (users: IUserV2[]) => {
  const roles = users.reduce((allRoles: IRole[], user: IUserV2) => {
    const updatedRoles = (user.roles || []).filter(
      (role) => !allRoles.map((r) => r.id).includes(role.id)
    );

    allRoles = [...allRoles, ...updatedRoles];
    return allRoles;
  }, [] as IRole[]);

  return roles.length === 0
    ? 'No Permissions'
    : roles.map((r) => r.roleName ?? r.name).join(', ');
};

/**
 * Format User attributes
 */

export const formatUserFullName = (user: Partial<IUserV2>) => {
  const firstAndLast = [user.first_name, user.last_name] as string[];
  return firstAndLast.filter((text: string) => !!text).join(' ');
};

export const getErrors = <T>(
  touched: FormikTouched<T>,
  errors: FormikErrors<T>,
  path: string
) => {
  return getIn(touched, path) && getIn(errors, path) ? getIn(errors, path) : '';
};

/**
 * Format content designations
 */

export const formatContentDesignation = (
  designations: IDesignation[],
  id: number | undefined
) => {
  if (!(designations && id)) return '';

  const designation = designations.find((designation) => designation.id === id);

  return designation?.designationType ?? '';
};

/**
 * Format approval status
 */

export const formatApprovalStatus = (
  statuses: IStatus[],
  id: number | undefined
) => {
  if (!(statuses && id)) return '';

  const status = statuses.find((status) => status.id === id);
  console.log({ status });
  return status?.status ?? '';
};

/**
 * Format emails schedule limit configurations
 */

export const formatEmailsScheduleLimit = (
  limitName: 'Registered' | 'Verified' | 'Authenticated',
  scheduleLimits?: IScheduleLimit[]
) => {
  if (!(scheduleLimits && limitName)) return '';

  const scheduleLimit = scheduleLimits.find(
    (scheduleLimit) => scheduleLimit.name === limitName
  );
  if (!scheduleLimit) return '';

  const operator =
    scheduleLimit.operator === '<'
      ? 'Less than'
      : scheduleLimit.operator === '>'
      ? 'Greater than'
      : 'Equal to';

  return `${operator} ${scheduleLimit.amount} ${scheduleLimit.unit}s`;
};
