import React, { useImperativeHandle, useRef, useState } from "react";
import T12Modal, { T12ModalRef } from "../modal/T12Modal";
import { ReactComponent as IcImage } from "app/assets/icons/image.svg";
import { ReactComponent as IcCamera } from "app/assets/icons/camera.svg";
import { ReactComponent as IcBrush } from "app/assets/icons/brush.svg";
import { IModalHeaderTab } from "../tabs/ModalHeaderTab";
import ModalHeaderTabs from "../tabs/ModalHeaderTabs";
import DragFileUpload from "../dragFileUpload/DragFileUpload";
import FileUploader, { FileUploaderRef } from "../fileUploader/FileUploader";
import { IUploadResponse } from "app/models/common";
import If from "../If";
import ProfileImage from "../profileImage/ProfileImage";
import { uploadImage } from "app/api/commonApis";
import { IMediaUpload } from "app/models/media";

const ProfileSelector = React.forwardRef<ProfileSelectorPopUpRef, IProfileSelectorProps>(
  ({ id, ownerType, ownerId, onConfirm, onClick, useNativeUploader }, ref) => {
    const modalRef = useRef<T12ModalRef>(null);
    const uploaderRef = useRef<FileUploaderRef>(null);
    const [activeTab, setActiveTab] = useState("image");
    const [profileUrl, setProfileUrl] = useState<IMediaUpload>();

    useImperativeHandle(
      ref,
      () => ({
        show: () => {
          modalRef.current?.show();
        },
      }),
      []
    );

    // const _onDrop = (files: File[]) => uploaderRef.current?.upload(files);
    const _onDrop = async (files: File[]) => {
      const image = files?.[0];
      if (!image) return;

      let uploaded = await uploadImage("Profile", image, ownerType, ownerId);
      const uploadedImage = uploaded.data as any;
      console.log("uploadedImage", uploadedImage);
      setProfileUrl({
        image: uploadedImage,
        fileUrl: uploadedImage.path,
        name: image.name,
      });
    };

    const _onUploadImage = (response: IUploadResponse[]) => {};

    const _onConfirm = () => profileUrl && onConfirm?.(profileUrl);

    const _getImage = (): string => {
      if (!profileUrl) return "";
      return profileUrl.tmpFileUrl ?? profileUrl.fileUrl ?? "";
    };

    return (
      <>
        <T12Modal
          size="lg"
          modalId={id}
          ref={modalRef}
          title=""
          className="profile-selector"
          confirmButtonProps={{ disabled: !profileUrl }}
          showHeaderBorder
          onConfirm={_onConfirm}
          header={
            <ModalHeaderTabs
              id="profile_selector"
              tabs={tabs}
              activeTab={activeTab}
              onTabChange={setActiveTab}
            />
          }
        >
          <If condition={!profileUrl}>
            <div className="drag-drop">
              <DragFileUpload
                id="profile-image-selector"
                label="Drop your file(s)"
                onDrop={_onDrop}
                buttonLabel="browse"
                onClick={onClick}
                useNativeUploader={useNativeUploader}
                multiple
              />
            </div>
          </If>
          <If condition={!!profileUrl}>
            <div className="d-flex justify-content-center">
              <ProfileImage imageUrl={_getImage()} />
            </div>
          </If>
          <FileUploader
            ownerType={ownerType}
            ownerId={ownerId}
            ref={uploaderRef}
            onUploadComplete={_onUploadImage}
          />
        </T12Modal>
      </>
    );
  }
);

export default ProfileSelector;

export interface IProfileSelectorProps {
  id: string;
  // onConfirm?: (url: string) => void;
  onConfirm?: (media: IMediaUpload) => void;
  useNativeUploader?: boolean;
  onClick?: () => void;
  ownerType?: string;
  ownerId?: number;
}

export type ProfileSelectorPopUpRef = {
  show: () => void;
} | null;

const tabs: IModalHeaderTab[] = [
  {
    id: "image",
    label: "Upload Image",
    icon: IcImage,
  },
  {
    id: "camera",
    label: "Camera",
    icon: IcCamera,
  },
  {
    id: "avatar",
    label: "Create Avatar",
    icon: IcBrush,
  },
];
