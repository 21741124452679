import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = "/assets/js/pdf.worker.js";

export const PdfViewer = ({ file, pageWidth }: { file: any; pageWidth: number }) => {
  return (
    <Document file={file}>
      <Page
        renderAnnotationLayer={false}
        renderTextLayer={false}
        pageNumber={1}
        width={pageWidth}
      />
    </Document>
  );
};
