import { ReactComponent as IcLoader } from 'app/assets/icons/loader.svg';
import React, { useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import DataColumn, { DataColumnProps, DATA_GRID_ACTIONS } from './DataColumn';
import { getColumns } from './DataGrid.utils';
import './data-grid.scss';
import { GRID_PAGE_SIZE } from 'app/constants/common';
import DataAction from './DataAction';
import Pagination from '../pagination/Pagination';
import DeletePopup, {
  DeletePopUpRef,
} from 'app/components/deletePopup/DeletePopup';
import If from '../If';
import Checkbox from '../checkbox/CheckBox';
import EmptyComponent from './EmptyComponent';

const DataGrid = <T,>({
  data,
  gridId,
  loading,
  rowIdAttribute,
  pageSize = GRID_PAGE_SIZE,
  totalItems = 0,
  currentPage = 0,
  children,
  confirmDeleteDescription = 'Please confirm that you would like to delete',
  selectionType,
  selectedRows = [],
  onPageChange,
  onAction,
  onSelectionChange,
  gridHeight,
}: IDataGridProps<T>) => {
  const [columns, gridActions] = getColumns(children);
  const deleteModalRef = useRef<DeletePopUpRef>(null);

  const _confirmDelete = (rowId: number) =>
    onAction?.(DATA_GRID_ACTIONS.DELETE, rowId);

  const _onAction = (action: DATA_GRID_ACTIONS, rowId: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.DELETE:
        deleteModalRef.current?.show(rowId);
        break;

      default:
        onAction?.(action, rowId);
        break;
    }
  };
  const _onSelectionChange = (rowId: number) => (checked: boolean) => {
    const updatedSelection = checked
      ? selectionType === 'single'
        ? [rowId]
        : [...selectedRows, rowId]
      : selectedRows.filter((_rowId) => _rowId !== rowId);
    onSelectionChange?.(updatedSelection);
  };

  const _onSelectAllChange = (checked: boolean) =>
    onSelectionChange?.(
      checked ? data.map((row) => row[rowIdAttribute] as unknown as number) : []
    );

  if (!data) return null;

  return (
    <div
      className={`data-grid-container${gridHeight ? ' scrollable' : ''}`}
      style={{ maxHeight: gridHeight || 'auto ' }}
    >
      <If condition={!data || (!data.length && !loading)}>
        <EmptyComponent />
      </If>
      <If condition={loading}>
        <div className='loading'>
          <IcLoader />
        </div>
      </If>
      <If condition={data && !!data.length}>
        <Table className='data-grid'>
          <thead>
            <tr>
              <If condition={selectionType === 'multiple'}>
                <th>
                  <Checkbox
                    id={`${gridId}_check_all`}
                    checked={selectedRows.length === data.length}
                    onChange={_onSelectAllChange}
                  />
                </th>
              </If>
              <If condition={selectionType === 'single'}>
                <th />
              </If>
              {columns.map(({ header = '', attributeName }, index) => (
                <th
                  key={`${gridId}_header_${attributeName as any}_${index}`}
                  id={`${gridId}_header_${attributeName as any}_${index}`}
                  dangerouslySetInnerHTML={{
                    __html: header.split(' ').join('<br />'),
                  }}
                />
              ))}
              <If condition={!!gridActions?.length}>
                <th />
              </If>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={`${gridId}_${row[rowIdAttribute]}`}>
                <If condition={!!selectionType}>
                  <td>
                    <Checkbox
                      id={`${gridId}_${row[rowIdAttribute]}_check`}
                      checked={selectedRows.includes(
                        row[rowIdAttribute] as unknown as number
                      )}
                      onChange={_onSelectionChange(
                        row[rowIdAttribute] as unknown as number
                      )}
                    />
                  </td>
                </If>
                {columns.map((col, index) => (
                  <td
                    key={`${gridId}_${row[rowIdAttribute]}_${col.attributeName as any}_${index}`}
                  >
                    {col.transform?.(row) ?? row[col.attributeName as keyof T]}
                  </td>
                ))}
                <If condition={!!gridActions?.length}>
                  <td>
                    <div className='data-grid-action-container'>
                      {gridActions?.map(
                        (gridAction) =>
                          gridAction?.transform?.(row) ?? (
                            <DataAction<T>
                              key={`${gridId}_${row[rowIdAttribute]}_${gridAction.action}`}
                              gridId={gridId}
                              rowId={row[rowIdAttribute] as unknown as number}
                              action={gridAction.action}
                              onClick={_onAction}
                            />
                          )
                      )}
                    </div>
                  </td>
                </If>
              </tr>
            ))}
          </tbody>
        </Table>
      </If>
      <If condition={!!totalItems}>
        <label className='data-items-count'>{totalItems} items</label>
        <Pagination
          activePage={currentPage}
          id={`${gridId}_pagination`}
          totalPages={Math.ceil(totalItems / pageSize)}
          onPageClick={onPageChange}
        />
      </If>
      <DeletePopup
        id={`${gridId}_delete_popup`}
        ref={deleteModalRef}
        confirmDeleteDescription={confirmDeleteDescription}
        onConfirm={_confirmDelete}
      />
    </div>
  );
};

DataGrid.Column = DataColumn;
export default DataGrid;

export interface IDataGridProps<T> {
  data: T[];
  loading?: boolean;
  gridId: string;
  rowIdAttribute: keyof T;
  children: React.ReactElement<DataColumnProps<T>>[];
  totalItems?: number;
  currentPage?: number;
  pageSize?: number;
  confirmDeleteDescription?: string;
  selectionType?: 'single' | 'multiple';
  selectedRows?: number[];
  gridHeight?: number;
  onPageChange?: (newPage: number) => void;
  onAction?: (action: DATA_GRID_ACTIONS, rowId: number) => void;
  onSelectionChange?: (rowIds: number[]) => void;
}
