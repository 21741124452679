import PrivateLayout from "../../layout/PrivateLayout";
import React from "react";

export const SuccessSubmission = ({ successMessage }: { successMessage?: string }) => {
  return (
    <PrivateLayout contentClassName="article-listing">
      <h3 className="title">Content Sync</h3>
      <div
        className="justify-content-center"
        style={{
          marginTop: "2em",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3>Your sync has been completed successfully!</h3>
          <br />
          <br />
          <p>{successMessage}</p>
        </div>
      </div>
    </PrivateLayout>
  );
};
