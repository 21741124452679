import React from "react";

export const IFRAME = ({
  src,
  style,
}: {
  src?: string;
  style: {
    height?: string | number;
    width?: string | number;
    margin?: any[];
    borderRadius?: number | string;
  };
}) => (
  <iframe
    title="External video"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    style={{
      borderRadius: `${style?.borderRadius}px`,
      margin: `${style?.margin?.[0]}px ${style?.margin?.[1]}px ${style?.margin?.[2]}px ${style?.margin?.[3]}px`,
      height: isNaN(Number(style?.height)) ? style?.height : `${style?.height}px`,
      width: isNaN(Number(style?.width)) ? style?.width : `${style?.width}px`,
    }}
    src={src}
  ></iframe>
);
