import { Row } from "react-bootstrap";
import { ToolbarItem } from "../../Editor/Toolbar/ToolbarItem";
import { ToolbarSection } from "../../Editor/Toolbar/ToolbarSection";
import React from "react";

export const Border = () => {
  return (
    <ToolbarSection title="Border Decoration" props={["borderRadius"]}>
      <div className="mb-3">
        <ToolbarItem propKey="borderColor" type="color" label="Border Color" />
      </div>
      <Row className="mb-3">
        <div className="d-flex justify-content-between gap-3">
          <ToolbarItem propKey="borderWidth" type="text" label="Border width" />
          <ToolbarItem propKey="borderStyle" type="text" label="Border style" />
        </div>
      </Row>
      <Row className={"MarginsGap"}>
        <div className="d-flex justify-content-between gap-3">
          <ToolbarItem propKey="borderRadius" type="text" label="Border radius" />
          <ToolbarItem propKey="boxSizing" type="text" label="Box sizing" />
        </div>
      </Row>
    </ToolbarSection>
  );
};
