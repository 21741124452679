import { Form, Formik, FormikProps } from "formik";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormikPagesConfigurationInterface } from "../../models/pages-configuration";
import { emptyFunction } from "../../utils/common";
import { fetchPageCopy, postPagesCopy, updateCagesCopy } from "../../api/pages-copy/pages-copy";
import Section from "../../components/section/Section";
import Switch from "../../components/switch/Switch";

const initialValues: FormikPagesConfigurationInterface = {
  staff: {
    page: "staff",
    configuration: {
      isSearchVisible: true,
    },
  },
};

export const PagesSettings = () => {
  const formRef = useRef<FormikProps<FormikPagesConfigurationInterface>>(null);
  const [configurationExists, setConfigurationExists] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formRef.current?.values && !configurationExists) {
      await postPagesCopy(formRef.current?.values);
    }
    if (formRef.current?.values && configurationExists) {
      await updateCagesCopy(formRef.current.values);
    }
  };

  return (
    <Formik innerRef={formRef} initialValues={initialValues} onSubmit={emptyFunction}>
      {({ setValues, values, setFieldValue }) => {
        useFetchPagesCopy(setValues, setConfigurationExists);
        return (
          <Form id="config-form-submit" onSubmit={onSubmit}>
            <Section sectionId="staff-page" title="Staff page">
              <StaffSection
                checked={values?.staff?.configuration?.isSearchVisible}
                onChange={() =>
                  setFieldValue(
                    "staff.configuration.isSearchVisible",
                    !values?.staff?.configuration?.isSearchVisible
                  )
                }
              />
            </Section>
          </Form>
        );
      }}
    </Formik>
  );
};

const useFetchPagesCopy = (setValues: any, setConfigurationExists: any) => {
  useEffect(() => {
    const fetch = async () => {
      const response = await fetchPageCopy();
      if (response.data) {
        await setValues((prev: any) => ({ ...prev, ...response.data }));
        setConfigurationExists(true);
      }
    };

    fetch();
  }, []);
};

const StaffSection = ({ checked, onChange }: { checked: boolean; onChange: () => void }) => {
  return (
    <Row>
      <Col className="d-flex align-items-center gap-2">
        <Switch checked={checked} onChange={onChange} switchId="toggle-staff-search" />
        <label className="cursor-pointer" htmlFor="toggle-staff-search">
          Toggle search input
        </label>
      </Col>
    </Row>
  );
};
