import { Form, Formik, FormikProps } from "formik";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import Section from "../../components/section/Section";
import Input from "../../components/input/Input";
import { Col, Row } from "react-bootstrap";
import { FormikPagesConfigurationInterface } from "../../models/pages-configuration";
import { emptyFunction } from "../../utils/common";
import { fetchPageCopy, postPagesCopy, updateCagesCopy } from "../../api/pages-copy/pages-copy";

const initialValues: FormikPagesConfigurationInterface = {
  families: {
    page: "families",
    configuration: {
      title: "",
      subtitle: "",
    },
  },
  thinkThank: {
    page: "thinkThank",
    configuration: {
      title: "",
    },
  },
};

export const PagesConfigurationCopy = () => {
  const formRef = useRef<FormikProps<FormikPagesConfigurationInterface>>(null);
  const options = useConfigurationOptions();
  const [configurationExists, setConfigurationExists] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formRef.current?.values && !configurationExists) {
      await postPagesCopy(formRef.current?.values);
    }
    if (formRef.current?.values && configurationExists) {
      await updateCagesCopy(formRef.current.values);
    }
  };

  return (
    <Formik innerRef={formRef} initialValues={initialValues} onSubmit={emptyFunction}>
      {({ setValues }) => {
        useFetchPagesCopy(setValues, setConfigurationExists);

        return (
          <Form id="config-form-submit" onSubmit={onSubmit}>
            {options.map(option => (
              <Section sectionId={option.key} title={option.section}>
                {option.component}
              </Section>
            ))}
          </Form>
        );
      }}
    </Formik>
  );
};

const useFetchPagesCopy = (setValues: any, setConfigurationExists: any) => {
  useEffect(() => {
    const fetch = async () => {
      const response = await fetchPageCopy();
      if (response.data) {
        await setValues(response.data);
        setConfigurationExists(true);
      }
    };

    fetch();
  }, []);
};

const FamilyNetwork = () => {
  return (
    <Row>
      <Col>
        <Input name="families.configuration.title" id="family-title" label="Page title" />
      </Col>
      <Col>
        <Input name="families.configuration.subTitle" id="family-subtitle" label="Page sub-title" />
      </Col>
    </Row>
  );
};

const ThinkThank = () => {
  return (
    <Row>
      <Col>
        <Input name="thinkThank.configuration.title" id="family-title" label="Middle copy" />
      </Col>
    </Row>
  );
};

const useConfigurationOptions = () => {
  return [
    { key: "families", section: "Family network", component: <FamilyNetwork /> },
    { key: "thinkThank", section: "ThinkThank", component: <ThinkThank /> },
  ];
};
