enum ROUTES {
  SIGN_IN = "/",
  HOME = "/home",
  T12_ADMIN_SIGN_IN = "t12-admin",

  TIPS_AND_ADVICE = "/tips-and-advice",
  FAMILY_NETWORK = "/family-network",
  QUESTION_AND_ANSWER = "/question-and-answer",
  OUTCOMES = "/outcomes",
  STAFF_DIRECTORY = "/staff-directory",
  CLUB_HUB = "/club-hub",

  FAMILY_PROFILE = "/family-profile",
  ADMIN_DASHBOARD = "/admin-dashboard",
  ADMIN_REPORTING = "/admin-reporting",

  ARTICLE_CONTENT_MANAGER = "/article-content-manager",
  CONTENT_SYNC_MANAGER = "/content-sync-manager",
  QA_CONTENT_SYNC_MANAGER = "/qa-content-sync-manager",
  ARTICLE_EDITOR = "/article-editor",
  ARTICLE_PREVIEW = "/article-preview",
  Q_AND_A_MANAGER = "/q-and-a-manager",
  Q_AND_A_EDITOR = "/q-and-a-editor",
  FAMILY_MANAGER = "/family-manager",
  FAMILY_EDITOR = "/family-editor",
  STAFF_MANAGER = "/staff-manager",
  STAFF_EDITOR = "/staff-editor",
  AMBASSADOR_PROFILE_MANAGER = "/ambassador-profile-manager",
  AMBASSADOR_EDITOR = "/ambassador-editor",
  USER_PROFILE_MANAGER = "/user-profile-manager",
  USER_PROFILE_EDITOR = "/user-profile-editor",
  ADMIN_ANNOUNCEMENT_MANAGER = "/admin-announcement-manager",
  ADMIN_ANNOUNCEMENT_MANAGER_CREATE = "/announcement-editor",
  ADMIN_ANNOUNCEMENT_EDITOR = "/admin-announcement-editor",

  CONFIGURATION_MANAGER = "/configuration-manager",
  CONFIGURATION_MANAGER_ROLES_PERMISSIONS = "/configuration-manager/roles-permissions",
  CONFIGURATION_MANAGER_FEATURES = "/configuration-manager/features",
  CONFIGURATION_MANAGER_STAFF_SECTION = "/configuration-manager/staff-section",
  CONFIGURATION_MANAGER_PROGRAM_DETAILS = "/configuration-manager/program-details",
  CONFIGURATION_MANAGER_PROGRAM_ELEMENTS = "/configuration-manager/program-elements",
  CONFIGURATION_MANAGER_PROGRAM_BANNER = "/configuration-manager/program-banner",
  CONFIGURATION_MANAGER_PHOTOS_COPY = "/configuration-manager/photos-copy",
  CONFIGURATION_MANAGER_PAGES_CONFIGURATION = "/configuration-manager/pages-configuration",
  CONFIGURATION_MANAGER_PAGES_SETTINGS = "/configuration-manager/pages-settings",
  CONFIGURATION_MANAGER_NAVIGATION = "/configuration-manager/navigation",
  CONFIGURATION_MANAGER_3RD_PARTY = "/configuration-manager/3rd-parties",
  CONFIGURATION_EDITOR = "/configuration-editor",

  SSO_MANAGER = "/sso-manager",

  CLUBS_MANAGER = "/clubs-manager",
  PAGE_BUILDER = "/page-builder",
  CLUBS_EDITOR = "/clubs-editor",
  NEW_TICKET = "/new-ticket",
  ADMIN_MANAGER = "/admin-manager",
  ADMIN_EDITOR = "/admin-editor",
  OUTCOMES_MANAGER = "/outcomes-manager",
  HOMEPAGE_MANAGER = "/homepage-manager",
  PHOTOS_MANAGER = "/photos-manager",
  EMAIL_MANAGER = "/email-manager",
  EMAIL_PREVIEW = "/email-preview",
  EMAIL_EDITOR = "/email-editor",
  GRADUATE_MANAGER = "/graduate-manager",
  ALUMNI_MANAGER = "/alumni-manager",
  GRADUATE_PROFILE_EDITOR = "/graduate-profile-editor",
  ALUMNI_PROFILE_EDITOR = "/alumni-profile-editor",
  NEW_EMAIL = "/new-email",
  EMAIL_SCHEDULE_MANAGER = "/email-schedule-manager",
  EMAIL_SCHEDULE_EDITOR = "/email-schedule-editor",
  EMAIL_REPORTING = "/email-reporting",
  ADMIN_TRIGGERED_EMAIL_MANAGER = "/admin-triggered-email-manager",
  PROGRAM_REPORTING = "/program-reporting",
  MESSAGES_MANAGER = "/messages-manager",
  PROGRAM_MANAGER = "/programs-manager",
  DATA_UPLOAD_MANAGER = "/data-upload-manager",
  COLLEGE_MANAGER = "/college-manager",
  COLLEGE_MANAGER_EDITOR = "/college-manager/editor",
  NEW_PROGRAM = "/new-program",
  PROGRAM_BUILDER = "/program-builder",
  LANDING_PAGE = "/landing-page",
  CUSTOM_CSS = "/custom-css",
  FEATURE_NOT_ACTIVE = "/feature-not-active",
  CONTENT_METADATA = "/content-metadata",
  NO_ACCESS = "/no-access",
}

export default ROUTES;
