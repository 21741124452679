import { ReactComponent as IcCheckConfig } from "app/assets/icons/check-config.svg";
import { ReactComponent as IcExclamation } from "app/assets/icons/exclamation.svg";
import { upload, uploadV2 } from "app/api/commonApis";
import useModifiedState from "app/hooks/ModifiedState";
import { IUploadResponse } from "app/models/common";
import React, { useEffect, useImperativeHandle } from "react";
import If from "../If";
import ProgressBar, { IProgressBarProps } from "../progressBar/ProgressBar";
import "./file-uploader.scss";

const FileUploader = React.forwardRef<FileUploaderRef, IFileUploaderProps>(
  (
    { hideProgress = false, ownerId, ownerType, horizontal, onUploadComplete, useV2 = false },
    ref
  ) => {
    let startTimestamp: number;
    const [state, dispatch] = useModifiedState<IProgress>(initialState);
    const [temp, setTemp] = useModifiedState<File[] | null>(null);

    useEffect(() => {
      state.status &&
        setTimeout(() => {
          dispatch({ status: undefined });
        }, 2000);
    }, [state.status]);

    const _onUploadProgress = (progressEvent: ProgressEvent) => {
      const timeStamp = new Date().getTime();
      //   bytes per second
      const speed = progressEvent.loaded / ((startTimestamp - timeStamp) / 1000);

      const secondsLeft = (progressEvent.total - progressEvent.loaded) / speed;

      const percent = Math.ceil((progressEvent.loaded * 100) / progressEvent.total);

      dispatch({ percent, secondsLeft });
    };

    useImperativeHandle(
      ref,
      () => ({
        tempFile: temp,
        holdFile: (files: File[]) => {
          setTemp(files);
        },
        upload: async (files, key, folderId = null, ownerId = 1, ownerType = "User") => {
          dispatch(initialState);
          startTimestamp = new Date().getTime();
          try {
            const { data } = useV2
              ? await uploadV2(
                  files,
                  `${folderId || ""}`,
                  ownerId,
                  ownerType,
                  !hideProgress ? _onUploadProgress : undefined
                )
              : await upload(
                  files,
                  `${folderId || ""}`,
                  !hideProgress ? _onUploadProgress : undefined
                );
            onUploadComplete?.(data, key, files);
            dispatch({ percent: 0, status: "success" });
          } catch (error) {
            //   TODO: Handle error in file upload
            console.log("🚀 ~ file: FileUploader.tsx ~ upload: ~ error", error);
            dispatch({ percent: 0, status: "error" });
          }
        },
      }),
      [onUploadComplete]
    );

    const Icon = state.status === "success" ? IcCheckConfig : IcExclamation;

    return (
      <If condition={!hideProgress}>
        <If condition={!!state.percent}>
          <ProgressBar
            label="Uploading"
            percent={state.percent}
            secondsLeft={state.secondsLeft}
            horizontal={horizontal}
            className="my-4"
          />
        </If>
        <If condition={!!state.status}>
          <div className="status-container">
            <div className={`status-container-icon ${state.status}`}>
              <Icon />
            </div>
            <span>
              {state.status === "success" ? "File uploaded successfully!" : "File upload failed!!!"}
            </span>
          </div>
        </If>
      </If>
    );
  }
);

export default FileUploader;

export interface IFileUploaderProps extends Pick<IProgressBarProps, "horizontal"> {
  hideProgress?: boolean;
  onUploadComplete?: (response: IUploadResponse[], extra?: any, files?: File[]) => void;
  useV2?: boolean;
  current?: any;
  ownerType?: string;
  ownerId?: number;
}

export type FileUploaderRef = {
  upload: (
    files: File[],
    key: string,
    folderId: string | null,
    ownerId: number | null,
    ownerType: string | null,
    extra?: any
  ) => void;
  tempFile: File[] | null;
  holdFile: (files: File[]) => void;
} | null;

interface IProgress {
  percent: number;
  secondsLeft: number;
  status?: "success" | "error";
}

const initialState: IProgress = {
  percent: 0,
  secondsLeft: 0,
  status: undefined,
};
