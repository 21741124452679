import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import ROUTES from "app/constants/navigation";
import { IAnnouncement } from "app/models/adminAnnouncement";
import { appStateSelectors, useAppState } from "app/state/AppState";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import usePagination from "./Pagination";
import {
  createAnnouncement,
  deleteAnnouncement,
  listAdminAnnouncement,
  updateAnnouncement,
} from "../api/announcement/announcement.api";
import { FormikProps } from "formik";

const useAnnouncementListing = () => {
  const navigate = useNavigate();
  const _pagination = usePagination<IAnnouncement>({
    listFn: listAdminAnnouncement,
    deleteFn: deleteAnnouncement,
  });

  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const categoryTags = useAppState(appStateSelectors.categoryTags);
  const designations = useAppState(appStateSelectors.designations);
  const statuses = useAppState(appStateSelectors.statuses);

  const previousModalRef = useRef<PreviewModalType>(null);
  const formRef = useRef<FormikProps<IAnnouncement>>(null);

  const params = useParams();

  useEffect(() => {
    if (params.id) {
      const foundItem = _pagination.data.find(item => item.id === params.id);
      if (foundItem) {
        console.log("####", foundItem);
        formRef?.current?.setValues(foundItem);
      }
    }
  }, [params.id]);

  const onNewAnnounceClick = () => navigate(ROUTES.ADMIN_ANNOUNCEMENT_MANAGER_CREATE);
  const onToggleActive = (announcement: IAnnouncement) => async (checked: boolean) => {
    if (announcement.id) {
      const response = await updateAnnouncement(announcement.id, {
        status: checked ? "active" : "inactive",
      });
      _pagination.updateData(announcement.id, "id", response.data);
    }
  };

  const onGridAction = async (action: DATA_GRID_ACTIONS, rowId: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.PREVIEW:
        const foundItem = _pagination.data.find(item => item.id === rowId);
        if (foundItem) {
          previousModalRef.current?.show(foundItem);
        }
        break;
      case DATA_GRID_ACTIONS.EDIT:
        navigate(`${ROUTES.ADMIN_ANNOUNCEMENT_MANAGER_CREATE}/${rowId}`);
        break;
      case DATA_GRID_ACTIONS.DELETE:
        await _pagination.onDelete(rowId);
        break;
      default:
        break;
    }
  };

  const onSubmitForm = async (formValue: IAnnouncement) => {
    if (formValue.id) {
      const response = await updateAnnouncement(formValue.id, formValue);
      _pagination.updateData(formValue.id, "id", response.data);
    } else {
      const response = await createAnnouncement(formValue);
      _pagination.addData(response.data);
    }
    navigate(ROUTES.ADMIN_ANNOUNCEMENT_MANAGER);
  };

  const populateForm = (id: number) => {
    const foundItem = _pagination.data.find(item => item.id === id);
    if (foundItem) {
      formRef?.current?.setValues(foundItem);
    }
  };

  return {
    ..._pagination,
    gradeBands,
    categoryTags,
    designations,
    statuses,
    previousModalRef,
    formRef,
    onNewAnnounceClick,
    onToggleActive,
    onGridAction,
    onSubmitForm,
    populateForm,
  };
};

export default useAnnouncementListing;

export const AnnouncementInitialValues: IAnnouncement = {
  level: "program",
  type: "info",
  status: "active",
};

export type PreviewModalType = {
  show: (announcement: IAnnouncement) => void;
} | null;
