import { ISpecial } from "../models/common";

export const SpecialTags: Array<ISpecial> = [
  { id: "virtual", label: "Virtual" },
  { id: "traditional", label: "Traditional" },
  { id: "hybrid", label: "Hybrid" },
  { id: "small_school", label: "Small School" },
  { id: "large_school", label: "Large School" },
  { id: "special_education", label: "Special Education" },
  { id: "early_childhood", label: "Early Childhood" },
  { id: "adult_ed", label: "Adult Ed" },
  { id: "january", label: "January" },
  { id: "february", label: "February" },
  { id: "march", label: "March" },
  { id: "april", label: "April" },
  { id: "may", label: "May" },
  { id: "june", label: "June" },
  { id: "july", label: "July" },
  { id: "august", label: "August" },
  { id: "september", label: "September" },
  { id: "october", label: "October" },
  { id: "november", label: "November" },
  { id: "december", label: "December" },
];
