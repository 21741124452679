import T12Modal, { T12ModalRef } from 'app/components/modal/T12Modal';
import React, { useImperativeHandle, useRef, useState } from 'react';
import './confirm-popup.scss';

const ConfirmPopup = React.forwardRef<ConfirmPopupRef, IConfirmPopupProps>(
  ({ id, confirmTitle, confirmDescription, onConfirm, onShow }, ref) => {
    const modalRef = useRef<T12ModalRef>(null);
    const [title, setTitle] = useState<string>(confirmTitle || '');
    const [description, setDescription] = useState<string>(
      confirmDescription || ''
    );

    useImperativeHandle(
      ref,
      () => ({
        show: ({ title, description }: ConfirmShowOptions) => {
          title && setTitle(title);
          description && setDescription(description);
          modalRef.current?.show();
          if (onShow) onShow();
        },
      }),
      []
    );

    const _onConfirm = () => onConfirm?.();

    return (
      <T12Modal
        modalId={id}
        ref={modalRef}
        // title='This is a title'
        title={title}
        className='confirm-popup'
        confirmButtonLabel='Confirm'
        cancelButtonLabel='Cancel'
        showHeaderBorder
        onConfirm={_onConfirm}
      >
        <div className='confirm-modal-body'>
          <p>{description}</p>
        </div>
      </T12Modal>
    );
  }
);

export default ConfirmPopup;

interface IConfirmPopupProps {
  id: string;
  confirmTitle?: string;
  confirmDescription?: string;
  onConfirm?: () => void;
  onShow?: () => void;
}

interface ConfirmShowOptions {
  title?: string;
  description?: string;
}

export type ConfirmPopupRef = {
  show: (options: ConfirmShowOptions) => void;
} | null;
