import { useEditor } from "@craftjs/core";
import React from "react";
import "./Toolbar.scss";

export const Toolbar = () => {
  const { active, related } = useEditor((state, query) => {
    // TODO: handle multiple selected elements
    const currentlySelectedNodeId = query.getEvent("selected").first();
    return {
      active: currentlySelectedNodeId,
      related: currentlySelectedNodeId && state.nodes[currentlySelectedNodeId].related,
    };
  });

  return (
    <div className="ToolbarContainer">
      {active && related.toolbar && React.createElement(related.toolbar)}
      {!active && (
        <div
          className="ToolbarBody"
          style={{
            color: "rgba(0, 0, 0, 0.5607843137254902)",
            fontSize: "11px",
          }}
        >
          <h4 className="CustomText">Click on a component to start editing.</h4>
          <h4 className="CustomText">
            You could also double click on the layers below to edit their names, like in Photoshop
          </h4>
        </div>
      )}
    </div>
  );
};
