import React from 'react';

import Section from 'app/components/section/Section';
import RadioButtonGroup from 'app/components/radioButtonGroup/RadioButtonGroup';
import RadioButtonOption from 'app/components/radioButtonGroup/RadioButtonOption';
import { IEmailScheduleItemBuilder } from 'app/models/email';
import { IEmailScheduleEditor } from 'app/hooks/EmailScheduleEditor';

interface IUserFrequencySection {
  editor: IEmailScheduleEditor;
  values: IEmailScheduleItemBuilder;
}

export const UserFrequencySection: React.FC<IUserFrequencySection> = ({
  editor,
  values,
}) => {
  return (
    <Section
      title='Select how many times a user should receive an email'
      sectionId='emailScheduleUserFrequency'
      titleSize='md'
      className='email-schedule-user-frequency mt-4'
    >
      <RadioButtonGroup<number>
        id='test'
        onSelect={editor.onSelect('userFrequency')}
      >
        {Object.keys(editor.emailScheduleUserFrequencyOptions).map(
          (frequency: string) => {
            const key =
              frequency as keyof typeof editor.emailScheduleUserFrequencyOptions;
            const option = editor.emailScheduleUserFrequencyOptions[key];
            return (
              <RadioButtonOption
                id={`email-schedule-user-frequency-option-${option.id}`}
                label={option.label}
                value={option.value}
                checked={values.recipient_frequency === option.value}
                onChange={(e: any) =>
                  editor.onChange('recipient_frequency')(e.target.value)
                }
              />
            );
          }
        )}
      </RadioButtonGroup>
      <hr className='mt-5' />
    </Section>
  );
};
