import { ProfileEditorPage } from "../GraduatesAlumniEditorPage/ProfilEditorPage";
import useGraduateEditor from "../../hooks/GraduateEditor";
import React from "react";

const GraduateBuilder: React.FC = () => {
  const _editor = useGraduateEditor();
  return <ProfileEditorPage editor={_editor} title="Graduate gallery profile editor" />;
};

export default GraduateBuilder;
