import { nonAdminApi } from "../adminApis";

export const uploadFile = async (formData: FormData) => {
  try {
    return await nonAdminApi.post("upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {}
};
