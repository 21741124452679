import { Col, Row } from "react-bootstrap";
import { ToolbarItem } from "../../Editor/Toolbar/ToolbarItem";
import { ToolbarSection } from "../../Editor/Toolbar/ToolbarSection";
import React from "react";
import { MARGINS } from "./index";
import { getElementSize } from "../../utils/numToMeasurement";

export const Margin = () => {
  return (
    <ToolbarSection
      title="Margin"
      props={["margin"]}
      summary={({ margin }: any) => getElementSize(margin)}
    >
      <Row className={"MarginsGap"}>
        {MARGINS.map((margin, index) => (
          <Col key={margin} xs={10} sm={10} md={5}>
            <ToolbarItem propKey="margin" index={index} type="text" label={margin} />
          </Col>
        ))}
      </Row>
    </ToolbarSection>
  );
};
