import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { IPage } from "../../models/IPage";
import { useParams } from "react-router-dom";
import { getPage } from "../../api/adminApis";
import { appStateSelectors, useAppState } from "../../state/AppState";

const PageBuilderContext = createContext<{ page?: IPage; loading: boolean } | null>(null);

export function usePageBuilderContext() {
  const context = useContext(PageBuilderContext);

  if (context === null) {
    throw new Error("You must wrap items in provider");
  }

  return context;
}

export const PageBuilderProvider = ({ children }: { children: React.ReactNode }) => {
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const [page, setPage] = useState<IPage>();
  const [loading, setLoading] = useState(false);

  const params = useParams();

  useEffect(() => {
    if (params && params.id) {
      setLoading(true);
      const { id } = params;
      (async () => {
        const page = await onGetById(id);
        setPage(page?.data);
        setLoading(false);
      })();
    }
  }, []);

  const onGetById = async (id: string) => {
    try {
      showLoader();
      return await getPage(id);
    } catch (error) {
      console.log("🚀 ~ file: usePageBuilder.ts ~ onGetById ~ error", error);
    } finally {
      hideLoader();
    }
  };

  if (loading) return null;

  return (
    <PageBuilderContext.Provider value={{ page, loading }}>{children}</PageBuilderContext.Provider>
  );
};
