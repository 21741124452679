import React, { ReactElement } from "react";
import styled from "styled-components";

import { ArrowIcon } from "../../assets";
import "./Sidebar.scss";

const SidebarItemDiv = styled.div<{ visible?: number; height?: string }>`
  height: ${props =>
    props.visible && props.height && props.height !== "full" ? `${props.height}` : "auto"};
  flex: ${props => (props.visible && props.height && props.height === "full" ? `1` : "unset")};
  color: #545454;
`;

const Chevron = styled.a<{ visible: number }>`
  transform: rotate(${props => (props.visible ? 180 : 0)}deg);
  svg {
    width: 8px;
    height: 8px;
  }
`;

export type SidebarItemProps = {
  title: string;
  height?: string;
  icon: ReactElement;
  visible?: boolean;
  onChange?: (bool: boolean) => void;
  children?: React.ReactNode;
};

const HeaderDiv = styled.div`
  color: #615c5c;
  height: 45px;
  svg {
    fill: #707070;
  }
`;

export const SidebarItem: React.FC<SidebarItemProps> = ({
  visible,
  icon,
  title,
  children,
  height,
  onChange,
}) => {
  return (
    <SidebarItemDiv visible={visible ? 1 : 0} height={height} className="SidebarContainer">
      <HeaderDiv
        onClick={() => {
          if (onChange) onChange(!visible);
        }}
        className={`HeaderDiv ${visible ? "shadow-sm" : ""}`}
      >
        <div className="SidebarTitle">
          {icon}
          <h3 className="Title">{title}</h3>
        </div>
        <Chevron visible={visible ? 1 : 0}>
          <ArrowIcon />
        </Chevron>
      </HeaderDiv>
      {visible ? <div className="Children">{children}</div> : null}
    </SidebarItemDiv>
  );
};
