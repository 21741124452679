import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Formik } from "formik";

import T12Modal from "app/components/modal/T12Modal";
import Input from "app/components/input/Input";
import StandardButton from "app/components/button/StandardButton";
import { ILocation } from "app/models/config";
import { getErrors } from "app/utils/data";
import "../address-popup.scss";
import { emptyFunction } from "../../../utils/common";

const getInitialValues = (location?: ILocation): ILocation => {
  return {
    line1: location?.line1 || "",
    line2: location?.line2 || "",
    city: location?.city || "",
    subdivision: location?.subdivision || "",
    code: location?.code || "",
    country: location?.country || "",
  };
};

const AddressPopup = React.forwardRef<AddressPopupRef, IAddressPopupProps>(
  ({ id, onLocationChange, location }, ref: any) => {
    return (
      <T12Modal
        modalId={id}
        hideFooter
        size="lg"
        ref={ref}
        title="Location Details"
        className="location-details-modal"
        showHeaderBorder
      >
        <Formik initialValues={getInitialValues(location)} onSubmit={emptyFunction}>
          {formikProps => {
            const { values, errors, touched } = formikProps;

            return (
              <Container>
                <Row>
                  <Col>
                    <Input
                      id="configuration-editor-details-program-name"
                      label="Line 1"
                      variant="secondary"
                      name="line1"
                      error={getErrors(touched, errors, "school.name")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      id="configuration-editor-details-program-abbreviation"
                      label="Line 2"
                      variant="secondary"
                      name="line2"
                      error={getErrors(touched, errors, "school.abbreviation")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      id="configuration-editor-details-program-abbreviation"
                      label="City"
                      variant="secondary"
                      name="city"
                      error={getErrors(touched, errors, "school.abbreviation")}
                    />
                  </Col>
                  <Col>
                    <Input
                      id="configuration-editor-details-program-abbreviation"
                      label="State"
                      variant="secondary"
                      name="subdivision"
                      error={getErrors(touched, errors, "school.abbreviation")}
                    />
                  </Col>
                  <Col>
                    <Input
                      id="configuration-editor-details-program-abbreviation"
                      label="Zip Code"
                      variant="secondary"
                      name="code"
                      error={getErrors(touched, errors, "school.abbreviation")}
                    />
                  </Col>
                </Row>
                <div className="action-row">
                  <StandardButton
                    standardBtnId="location-cancel-button"
                    size="sm"
                    label="Cancel"
                    onClick={() => ref.current?.close()}
                  />

                  <StandardButton
                    standardBtnId="location-confirm-button"
                    size="sm"
                    label="Confirm"
                    onClick={() => {
                      onLocationChange(values);
                      ref?.current?.close?.();
                    }}
                  />
                </div>
              </Container>
            );
          }}
        </Formik>
      </T12Modal>
    );
  }
);

export default AddressPopup;

interface IAddressPopupProps {
  id: string;
  location?: ILocation;
  onLocationChange: (location: ILocation) => void;
}

export type AddressPopupRef = {
  show: (location: ILocation) => void;
  close: () => void;
} | null;
