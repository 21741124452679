import {
  createGraduateProfile,
  updateGraduateProfile,
  getGraduateProfile,
} from 'app/api/adminApis';
import { FileUploaderRef } from 'app/components/fileUploader/FileUploader';
import { IEducation, IUploadResponse, IProfileAbout } from 'app/models/common';
import { IGraduateBuilder } from 'app/models/graduate';
import { IMediaUpload } from 'app/models/media';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { processErrorResponse } from 'app/utils/common';
import { FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UploadPopUpRef } from 'app/screens/sitePhotos/uploadPopup/UploadPopupV2';
import { ConfirmPopupRef } from 'app/components/confirmPopup/ConfirmPopup';
import { IUserV2 } from 'app/models/user';
import * as AppUtils from 'app/utils/common';

const useGraduateEditor = () => {
  const navigate = useNavigate();

  const formRef = useRef<FormikProps<IGraduateBuilder>>(null);
  const uploaderRef = useRef<FileUploaderRef>(null);
  const uploadPopupRef = useRef<UploadPopUpRef>(null);
  const accountTypePopupRef = useRef<ConfirmPopupRef>(null);

  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const showNotification = useAppState(appStateSelectors.showNotification);
  const hideNotification = useAppState(appStateSelectors.hideNotification);
  const appConfig = useAppState(appStateSelectors.appConfig);
  const showNotificationWithVariant = useAppState(
    appStateSelectors.showNotificationWithVariant
  );

  const [profileImageUrl, setProfileImageUrl] = useState<string | undefined>();

  const params = useParams();

  useEffect(() => {
    return () => hideNotification();
  }, []);

  useEffect(() => {
    if (params.id) {
      _getGraduateDetails();
    }
  }, [params]);

  const _getGraduateDetails = async () => {
    showLoader();
    try {
      const { data } = await getGraduateProfile(+params.id!);
      if (data?.profile_image) {
        setProfileImageUrl(data.profile_image.path)
      }
      formRef.current?.setValues({
        ...data,
        is_primary_email_visible: data.is_primary_email_visible ? 1 : 0,
        email: data.email?.value ?? '',
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: GraduateBuilder.ts  ~ const_getGraduateDetails= ~ error',
        error
      );
    } finally {
      hideLoader();
    }
  };

  const onAccountSelect = (user: Partial<IUserV2>) => {
    formRef.current?.setValues((prevValues) => ({
      ...prevValues,
      user_id: user.id!,
      first_name: user.first_name ?? prevValues.first_name,
      last_name: user.last_name ?? prevValues.last_name,
    }));
  };

  const onChange =
    (key: string) => (value: boolean | string | number | null) => {
      const formattedValue =
        key === 'is_primary_email_visible' ? (value ? 1 : 0) : value;

      formRef.current?.setFieldValue(key, formattedValue);
    };

  const onChangeImage = (key: string, media: IMediaUpload) => {
    const profile_image = media.image
      ? { image: media.image, url: media.fileUrl }
      : media.id
      ? { image_id: media.id, url: media.fileUrl }
      : undefined;
    formRef.current?.setFieldValue(key, profile_image);

    let url = (media.image as any).path;
    setProfileImageUrl(url);
  };

  const onChangeSocialMedia = (e: any) => {
    const social_media_accounts = {
      url: e.target.value,
      provider: 'LinkedIn',
    };
    formRef.current?.setFieldValue('social_media_accounts', [
      social_media_accounts,
    ]);
  };

  const onChangeAboutEntry = (name: string, e: any) => {
    const updatedEntries = {
      ...(formRef.current?.values?.about ?? {}),
    } as IProfileAbout;

    let existingIndex = -1;

    for (let i = 0; i < updatedEntries?.fields?.length ?? 0; i++) {
      if (updatedEntries.fields[i].name === name) {
        existingIndex = i;
      }
    }

    if (existingIndex !== -1) {
      updatedEntries.fields[existingIndex] = {
        ...updatedEntries.fields[existingIndex],
        value: e.target.value,
      };
    } else {
      updatedEntries.fields = updatedEntries.fields ?? [];
      updatedEntries.fields.push({ name, value: e.target.value });
    }

    formRef?.current?.setFieldValue('about', updatedEntries);
  };

  const addEducation = () => {
    formRef.current?.setFieldValue('educations', [
      ...(formRef.current.values.educations || []),
      { ...education },
    ]);
  };

  const onDeleteEducation = (index: number) => {
    const updated = [...(formRef.current?.values.educations ?? [])];
    const updatedDissociate = {
      ...(formRef.current?.values?.dissociate ?? {}),
    };
    const educationToRemove = updated[index];

    if (educationToRemove.id) {
      const exists = (updatedDissociate.educations ?? []).find(
        (education) => education.id === educationToRemove.id
      );
      if (!exists) {
        updatedDissociate.educations = updatedDissociate.educations ?? [];
        updatedDissociate.educations.push({ id: educationToRemove.id });
      }
    }

    updated.splice(index, 1);

    formRef.current?.setValues((values) => ({
      ...values,
      educations: updated,
      dissociate: updatedDissociate,
    }));
  };

  const formatParams = (params: IGraduateBuilder) => {
    const formatted: IGraduateBuilder = { ...params };

    // Images
    formatted.profile_image = AppUtils.formatMedia(
      params?.id,
      'Graduate',
      formatted.profile_image
    );

    // About
    formatted.about = (formatted.about?.fields ?? []).map((field) => ({
      name: field.name,
      value: field.value,
    })) as any;

    // Booleans
    formatted.is_primary_email_visible = formatted.is_primary_email_visible
      ? 1
      : (0 as any);

    // Form data
    const formData = new FormData();
    AppUtils.buildFormData(formData, formatted, null);

    formData.append('_method', 'PUT');
    return formData;
  };

  const onSubmit = async (staff: IGraduateBuilder) => {
    showLoader();
    hideNotification();
    try {
      const formattedParams = formatParams(staff);
      await (params.id
        ? updateGraduateProfile(+params.id, formattedParams)
        : createGraduateProfile(formattedParams));

      showNotificationWithVariant(
        'success',
        `Graduate profile updated successfully`
      );
      setTimeout(() => {
        console.log('hiding');
        hideNotification();
        navigate(-1);
      }, 3000);
    } catch (error: any) {
      processErrorResponse({
        error,
        callback: (errorMessage) =>
          showNotificationWithVariant('danger', errorMessage),
      });
      console.log('🚀 ~ file: StaffEditor.ts ~ onSubmit ~ error', error);
    } finally {
      hideLoader();
    }
  };

  return {
    initialValues,
    schoolName: appConfig?.schoolShortName || appConfig?.schoolName,
    formRef,
    uploaderRef,
    uploadPopupRef,
    accountTypePopupRef,
    gradeBands,
    profileImageUrl,
    addEducation,
    onChange,
    onChangeImage,
    onChangeSocialMedia,
    onChangeAboutEntry,
    onDeleteEducation,
    onSubmit,
    onAccountSelect,
  };
};

export default useGraduateEditor;
const education: IEducation = { degree: '', name: '', outcomeId: undefined };

const initialValues: Partial<IGraduateBuilder> = {
  first_name: '',
  last_name: '',
  title: '',
  educations: [{ ...education }],
  is_standard: true,
};
