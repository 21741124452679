import React, { useRef } from "react";
import Input from "app/components/input/Input";
import { Col, Row } from "react-bootstrap";
import DragFileUpload from "app/components/dragFileUpload/DragFileUpload";
import DatePicker from "react-datepicker";
import StandardButton from "app/components/button/StandardButton";
import "./club-components.scss";
import { ImageUploader } from "app/components/imageUploader/ImageUploader";
import ImageUploaderRef from "app/components/imageUploader/ImageUploaderRef";

import { useFormikContext } from "formik";
import { IClubEditor } from "../../../models/clubs";

const ClubEvent: React.FC<IClubEventProps> = ({ index }) => {
  const uploaderRef = useRef<ImageUploaderRef>(null);
  const { values, setFieldValue } = useFormikContext<IClubEditor>();
  const { events } = values;
  const event = events?.[index];

  const removeEvent = async () => {
    const newEvents = events?.filter(e => e.id !== event.id);
    await setFieldValue("events", newEvents);
  };

  const removeImage = async () => {
    await setFieldValue(`event.${index}.image_id`, "");
    await setFieldValue(`event.${index}.image`, "");
  };

  return (
    <Row>
      <Col md={4} className="club-event-image-container">
        {event.image_id ? (
          <div>
            <img
              className="club-event-image"
              src={event.image ? event.image.path : ""}
              onClick={() => removeImage()}
              alt={event.image_id}
            />
          </div>
        ) : (
          <div>
            <DragFileUpload
              id="club-editor-image"
              label="Add a representative image of your club"
              onDrop={files => {
                uploaderRef.current?.upload("club_event", files[0]);
              }}
              useNativeUploader={true}
            />
            <ImageUploader
              ref={uploaderRef}
              hideProgress={true}
              onUploadComplete={(file: any) => {
                setFieldValue(`events.${index}.image_id`, file?.id);
                setFieldValue(`events.${index}.image`, file);
              }}
            />
          </div>
        )}
      </Col>
      <Col md={8} className="mt-5">
        <Row>
          <Col sm={6}>
            <Input
              id="event-name"
              type="text"
              value={event.name}
              label="Event Name"
              as="input"
              variant="secondary"
              onChange={(e: any) => {
                setFieldValue(`events.${index}.name`, e.target.value);
              }}
            />
          </Col>
          <Col sm={6}>
            <Input
              type="text"
              value={event.location || ""}
              id={"event-location-" + index}
              label="Location"
              as="input"
              variant="secondary"
              onChange={(e: any) => {
                setFieldValue(`events.${index}.location`, e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            Date
            <DatePicker
              selected={event.event_date ? new Date(event.event_date) : new Date()}
              onChange={(date: Date) =>
                setFieldValue(`event.${index}.event_date`, date.toISOString())
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="remove-event">
            <StandardButton
              onClick={() => removeEvent()}
              standardBtnId="article-editor-save-btn"
              label="Remove Event"
              variant="outlined"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ClubEvent;

interface IClubEventProps {
  index: number;
  className?: string;
}
