import { ISelectedOption } from '../selectedOption/SelectedOptionContainer';
import { IFilterOptionProps } from './FilterOption';

export const getFilterOptions = (
  children:
    | React.ReactElement<IFilterOptionProps<any>>
    | React.ReactElement<IFilterOptionProps<any>>[]
) => {
  if (Array.isArray(children)) {
    return children.map(({ props }) => props);
  } else {
    return [children.props];
  }
};

export const getSelectedOptions = (
  filterOptions: IFilterOptionProps<any>[] | null,
  filters?: Record<string, number[]>
): ISelectedOption[] => {
  if (!filters) {
    return [];
  }

  // Get selected filter values based on selected filter ids...
  const standardFilters = Object.entries(filters)
    .map(([name, ids]) => {
      const results = filterOptions?.filter((option) => !option.getSelectedOptions)
        .find((option) => option.name === name);
      if (!results) return { id: -1, label: 'unknown', categ: 'unknown' };

      const { options, labelAttribute, valueAttribute } = results;
      return ids.map((id) => ({
        id,
        label: options.find((option) => option[valueAttribute] === id)?.[
          labelAttribute
        ],
        categ: name,
      })) as ISelectedOption[];
    })
    .flat()
    .filter((option) => option.id !== -1);

  // Get selected filter values for a custom component when that filter is not based on an array of selected ids...
  const customFilters = filterOptions?.filter((option) => option.getSelectedOptions)
    .map((option) => {
      return option.getSelectedOptions
        ? option.getSelectedOptions(filters)
        : ({} as ISelectedOption);
    })
    .flat();

  if (customFilters === undefined) {
    return [...standardFilters]
  }

  return [...standardFilters, ...customFilters];
};
