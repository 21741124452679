import { createArticle, getArticle, getArticleId, updateArticle } from "app/api/adminApis";
import { IArticle, IArticleVersion } from "app/models/article";
import { ArticlePreviewPopupRef } from "app/screens/articles/components/ArticlePreviewPopup";
import { useAppState, appStateSelectors } from "app/state/AppState";
import { processErrorResponse } from "app/utils/common";
import { UploadPopUpRef } from "app/screens/sitePhotos/uploadPopup/UploadPopup";
import { FormikProps } from "formik";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

const useArticleEditor = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<IArticle>>(null);
  const previewModalRef = useRef<ArticlePreviewPopupRef>(null);
  const featuredImagePopupRef = useRef<UploadPopUpRef>(null);

  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const contentCategories = useAppState(appStateSelectors.contentCategories);
  const contentDesignations = useAppState(appStateSelectors.contentDesignations);
  const contentApprovals = useAppState(appStateSelectors.contentApprovals);
  const schoolTypes = useAppState(appStateSelectors.schoolTypes);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const programId = useAppState(appStateSelectors.programId);
  const showNotification = useAppState(appStateSelectors.showNotification);
  const hideNotification = useAppState(appStateSelectors.hideNotification);

  const params = useParams();

  useEffect(() => {
    return () => hideNotification();
  }, []);

  useEffect(() => {
    params.id ? _getArticleDetails(+params.id) : _getArticleId();
  }, [params]);

  const _getArticleId = async () => {
    showLoader();
    try {
      const {
        data: { articleId },
      } = await getArticleId();
      formRef.current?.setFieldValue("articleId", articleId);
      formRef.current?.setFieldValue("articleUrl", `/article/${articleId}`);
    } catch (error) {
      console.log("🚀 ~ file: ArticleEditor.ts ~ _getArticleId ~ error", error);
    } finally {
      hideLoader();
    }
  };

  const _getArticleDetails = async (id: number) => {
    showLoader();
    try {
      const { data } = await getArticle(id);
      formRef.current?.setValues(data);
      initialValues = {
        ...initialValues,
        ...data,
      };
    } catch (error) {
      console.log("🚀 ~ file: ArticleEditor.ts ~ const_getArticleDetails= ~ error", error);
    } finally {
      hideLoader();
    }
  };

  const onVersionChange = (versions: IArticleVersion[]) => (versionId: number) => {
    const version = versions.find(version => version.id === versionId);
    if (version) {
      formRef.current?.setValues(prevValues => ({
        ...prevValues,
        versionId: versionId,
        articleId: `${version.articleId}`,
        articleTitle: version.articleTitle,
        articleContent: version.articleContent,
        featuredImageUrl: version.featuredImageUrl,
      }));
    }
  };

  const onBackToCurrentVersion = () => {
    if (initialValues) {
      formRef.current?.setValues(initialValues);
      formRef.current?.setFieldValue("versionId", null);
    }
  };

  const onSelectionChange =
    (
      key: keyof Pick<
        IArticle,
        | "approvalStatusId"
        | "categoryIds"
        | "gradeBandIds"
        | "schoolTypeIds"
        | "designationId"
        | "versionId"
        | "special"
      >
    ) =>
    (value: number | number[]) =>
      formRef.current?.setFieldValue(key, value);

  const onFeaturedImageChange = (mediaId: number, imageUri: string) => {
    formRef.current?.setValues(prevValues => ({
      ...prevValues,
      mediaId: mediaId,
      featuredImageUrl: imageUri,
    }));
  };

  const onContentEditorChange = (value: string) => {
    formRef.current?.setFieldValue("articleContent", value);
  };

  const onActiveStatusChange = (checked: boolean) =>
    formRef.current?.setFieldValue("activeStatus", checked);

  const onSubmit = async (article: IArticle) => {
    showLoader();
    hideNotification();

    try {
      const createParams = { ...article, programId: programId || undefined };
      await (params.id ? updateArticle(+params.id, article) : createArticle(createParams));

      navigate(-1);
    } catch (error: any) {
      processErrorResponse({ error, callback: showNotification });
      console.log("🚀 ~ file: ArticleEditor.ts ~ onSubmit ~ error", error);
    } finally {
      hideLoader();
    }
  };

  const resetForm = () => {
    formRef.current?.setValues(emptyArticle);
    initialValues = emptyArticle;
  };

  return {
    formRef,
    previewModalRef,
    featuredImagePopupRef,
    initialValues,
    gradeBands,
    contentApprovals,
    contentCategories,
    contentDesignations,
    schoolTypes,
    onSubmit,
    onActiveStatusChange,
    onSelectionChange,
    onContentEditorChange,
    onFeaturedImageChange,
    onVersionChange,
    onBackToCurrentVersion,
    resetForm,
  };
};

export default useArticleEditor;

const emptyArticle: IArticle = {
  activeStatus: false,
  approvalStatusId: undefined,
  // approvalStatusId: 2,
  articleContent: "",
  articleId: "",
  articleTitle: "",
  articleUrl: "",
  categoryIds: [],
  contentDescription: "",
  designationId: undefined,
  mediaId: undefined,
  gradeBandIds: [],
  schoolTypeIds: [],
  special: [],
  featuredImageUrl: "",
};
let initialValues: IArticle = emptyArticle;
