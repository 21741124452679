import React from "react";
import { useReportingProvider } from "../ReportingProvider";

export const ReportingFilter = () => {
  const { userType, setUserType } = useReportingProvider();
  return (
    <div>
      <div className="tabs">
        <div
          className={"tab" + (userType == "all" ? " active" : "")}
          onClick={() => setUserType("all")}
        >
          All Program Users
        </div>
        <div
          className={"tab" + (userType == "validated" ? " active" : "")}
          onClick={() => setUserType("validated")}
        >
          Verified
        </div>
        <div
          className={"tab" + (userType == "registered" ? " active" : "")}
          onClick={() => setUserType("registered")}
        >
          Registered
        </div>
        <div
          className={"tab" + (userType == "anonymous" ? " active" : "")}
          onClick={() => setUserType("anonymous")}
        >
          Anonymous
        </div>
      </div>
    </div>
  );
};
