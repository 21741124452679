import React from "react";
import PrivateLayout from "../../layout/PrivateLayout";
import { Editor } from "./Editor/Editor";
import { PageBuilderProvider } from "./PageBuilderProvider";
import "./grid.scss";

export const PageBuilder = () => {
  return (
    <PageBuilderProvider>
      <PrivateLayout contentClassName="page-builder">
        <Editor />
      </PrivateLayout>
    </PageBuilderProvider>
  );
};
