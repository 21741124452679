import React, { FormEvent } from "react";

import Section from "app/components/section/Section";
import DataGrid from "app/components/dataGrid/DataGrid";
import Switch from "app/components/switch/Switch";
import { IConfigurationEditorSection } from "app/hooks/ConfigurationEditor";
import { IProgramFeatureResponse } from "app/models/program";
import { Form, useFormikContext } from "formik";
import { useOutletContext } from "react-router-dom";
import { IMainProgramConfigBuilder } from "../../models/config";

export const FeatureSection = () => {

  const {
    editor
  } = useOutletContext<IConfigurationEditorSection>()
  const { values } = useFormikContext<IMainProgramConfigBuilder>();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await editor?.onSubmit?.({ features: values.features })
  }

  return (
    <Form id="config-form-submit" onSubmit={onSubmit}>
      <Section
        title="Features"
        sectionId="configuration-editor-features"
        titleSize="md"
        className="configuration-editor-features mt-4"
      >
        <DataGrid<IProgramFeatureResponse>
          data={values.features ?? []}
          gridId="configuration-editor-feature-grid"
          loading={false}
          rowIdAttribute="id"
          currentPage={1}
        >
          <DataGrid.Column attributeName="name" header="Feature" />
          <DataGrid.Column<any>
            attributeName="display_name"
            header="Display Name"
            transform={feature => (
              <input
                style={{
                  width: "80%",
                  marginTop: ".5em",
                  marginBottom: ".5em",
                  padding: ".3em",
                  borderColor: "#eee",
                  borderStyle: "solid",
                  borderWidth: "1px",
                }}
                id="feature-display-name"
                value={feature.display_name}
                onChange={(e: any) => editor?.onFeatureNameChange?.(feature.id, e.target.value)}
              />
            )}
          />
          <DataGrid.Column<any>
            attributeName="redirect_to"
            header="Redirect to"
            transform={feature => (
              <input
                style={{
                  width: "80%",
                  marginTop: ".5em",
                  marginBottom: ".5em",
                  padding: ".3em",
                  borderColor: "#eee",
                  borderStyle: "solid",
                  borderWidth: "1px",
                }}
                id="feature-redirect_to"
                value={feature.redirect_to}
                onChange={(e: any) => editor?.onFeatureNameChange?.(feature.id, e.target.value, "redirect_to")}
              />
            )}
          />
          <DataGrid.Column<any>
            attributeName="is_active"
            header="Active"
            transform={feature => {
              return (
                <Switch
                  checked={feature.is_active}
                  onChange={(checked: boolean) =>
                    editor?.onFeatureChange?.(feature.id, "active", checked ? 1 : 0)
                  }
                  switchId="feature-is-active-switch"
                />
              );
            }}
          />
          <DataGrid.Column<any>
            attributeName="is_enabled"
            header="Enabled"
            transform={feature => {
              return (
                <Switch
                  checked={feature.is_permitted}
                  onChange={(checked: boolean) =>
                    editor?.onFeatureChange?.(feature.id, "permitted", checked ? 1 : 0)
                  }
                  switchId="feature-is-is_permitted-switch"
                />
              );
            }}
          />
        </DataGrid>
        <hr className="mt-5" />
      </Section>
    </Form>
  );
};
