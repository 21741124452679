import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import PrivateLayout from "../../../layout/PrivateLayout";
import { Col, Row } from "react-bootstrap";
import StandardButton from "../../../components/button/StandardButton";
import { useNavigate } from "react-router-dom";
import DataGrid from "../../../components/dataGrid/DataGrid";
import { DATA_GRID_ACTIONS } from "../../../components/dataGrid/DataColumn";
import { IPage } from "../../../models/IPage";
import { usePageBuilder } from "../../../hooks/usePageBuilder";
import usePagination from "../../../hooks/Pagination";
import { copyPageInPrograms, fetchPages, listPageBuilderPages } from "../../../api/adminApis";
import SearchBar from "../../../components/searchBar/SearchBar";
import T12Modal, { T12ModalRef } from "../../../components/modal/T12Modal";
import { usePermission } from "../../../utils/user";
import useProgramListing from "../../../hooks/ProgramListing";
import { IProgram } from "../../../models/program";
import { MultiDropdown } from "../../../components/dropDown/MultipleDropDown";
import { appStateSelectors, useAppState } from "../../../state/AppState";
import ScreenNotification from "../../../components/screenNotification/ScreenNotification";

export const PageBuilderList = () => {
  const navigate = useNavigate();
  const onNewPage = () => navigate("new");

  return (
    <PrivateLayout>
      <Row className="align-items-center">
        <Col xs={10}>
          <h3 className="font-weight-600 my-0">Pages List</h3>
        </Col>
        <Col xs={2}>
          <StandardButton
            label="+ New Page"
            variant="secondary"
            className="new-club-button"
            onClick={onNewPage}
            standardBtnId="clubmanager-listing-new-btn"
          />
        </Col>
      </Row>
      <Row className="justify-center">
        <Col xs={12}>
          <PageList />
        </Col>
      </Row>
    </PrivateLayout>
  );
};

const PageList = () => {
  const { isSuperAdmin } = usePermission();
  const navigate = useNavigate();
  const { onDeletePage, onCreatePage } = usePageBuilder();
  const modalRef = useRef<ModalRefType>(null);
  const paginatedData = usePagination<IPage>({
    listFn: listPageBuilderPages,
  });
  const showNotificationWithVariant = useAppState(appStateSelectors.showNotificationWithVariant);
  const hideNotification = useAppState(appStateSelectors.hideNotification);

  const onGridAction = async (action: DATA_GRID_ACTIONS, rowId: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.EDIT:
        return navigate(`${rowId}`);
      case DATA_GRID_ACTIONS.DELETE: {
        await onDeletePage(rowId.toString());
        return fetchPages();
      }
      case DATA_GRID_ACTIONS.PREVIEW:
        return navigate(`preview/${rowId}`);

      case DATA_GRID_ACTIONS.SEND:
        const { data: pages } = paginatedData;
        const page = pages.find(page => page.id === rowId);
        if (page) {
          if (process.env.REACT_APP_ENV === "local") {
            return window.open(`http://localhost:3000/page/${page.pageUrl}`, "_blank");
          }

          const client = location.origin.replace("admin.", "");
          window.open(`${client}/page/${page.pageUrl}`, "_blank");
        }
        break;
      case DATA_GRID_ACTIONS.DUPLICATE:
        const foundPage = paginatedData.data.find(page => page.id === rowId);
        if (foundPage) {
          const duplicatePage: IPage = {
            pageTitle: `Copy of ${foundPage?.pageTitle}`,
            pageUrl: `${foundPage?.pageUrl}-${new Date().getTime()}`,
            pageContent: foundPage.pageContent,
            programId: foundPage.programId,
          };
          await onCreatePage(duplicatePage, false);
          await paginatedData.onSearch();
        }
        break;
      case DATA_GRID_ACTIONS.COPY:
        const copyPage = paginatedData.data.find(page => page.id === rowId);
        modalRef?.current?.show(copyPage);
        break;
    }
  };

  const onShowCopySuccess = () => {
    showNotificationWithVariant("success", "Page template successfully copied!");

    setTimeout(() => {
      hideNotification();
    }, 3000);
  };

  return (
    <>
      <SearchBar
        placeHolder="Search by page title or url"
        searchBarId="pages-listing-search"
        onChangeText={paginatedData.onSearchKeyChange}
        onSearch={paginatedData.onSearch}
      />
      <ScreenNotification variant="danger" styles={{ container: { marginTop: "2rem" } }} />
      <DataGrid<IPage>
        onAction={onGridAction}
        data={paginatedData.data}
        gridId={"Page-listing"}
        rowIdAttribute="id"
        currentPage={paginatedData.currentPage}
        onPageChange={paginatedData.onPageChange}
        totalItems={paginatedData.totalItems}
      >
        <DataGrid.Column<IPage> attributeName="id" header="Id" />
        <DataGrid.Column<IPage> attributeName="pageTitle" header="Page title" />
        <DataGrid.Column<IPage> attributeName="pageUrl" header="Page Url" />
        <DataGrid.Column<IPage> attributeName="programId" header="Program" />

        <DataGrid.Column<IPage> action={DATA_GRID_ACTIONS.PREVIEW} attributeName="GRID.ACTIONS" />
        <DataGrid.Column<IPage> action={DATA_GRID_ACTIONS.SEND} attributeName="GRID.ACTIONS" />
        <DataGrid.Column<IPage> action={DATA_GRID_ACTIONS.DUPLICATE} attributeName="GRID.ACTIONS" />
        {isSuperAdmin ? (
          <DataGrid.Column<IPage> action={DATA_GRID_ACTIONS.COPY} attributeName="GRID.ACTIONS" />
        ) : (
          <></>
        )}
        <DataGrid.Column<IPage> attributeName="GRID.ACTIONS" action={DATA_GRID_ACTIONS.EDIT} />
        <DataGrid.Column<IPage> action={DATA_GRID_ACTIONS.DELETE} attributeName="GRID.ACTIONS" />
      </DataGrid>
      {isSuperAdmin && <DuplicateModal onSuccess={onShowCopySuccess} ref={modalRef} />}
    </>
  );
};

const DuplicateModal = forwardRef(function ({ onSuccess }: { onSuccess: () => void }, ref) {
  const modalRef = useRef<T12ModalRef>(null);
  const [page, setPage] = useState<IPage>();
  const [programIds, setProgramIds] = useState<number[]>([]);
  const { data } = useProgramListing();

  useImperativeHandle(ref, () => ({
    show: (page: IPage) => {
      setPage(page);
      modalRef?.current?.show();
    },
  }));

  const onCopyPages = async () => {
    const copyPage = {
      pageTitle: `Copy of ${page?.pageTitle}`,
      pageUrl: `${page?.pageUrl}-${new Date().getTime()}`,
      pageContent: page?.pageContent,
      programIds,
    };
    await copyPageInPrograms(copyPage);
    onSuccess();
  };

  return (
    <T12Modal
      modalId="CopyPagesToProgram"
      ref={modalRef}
      title="Copy page"
      confirmButtonLabel="Confirm"
      cancelButtonLabel="Cancel"
      showHeaderBorder
      onConfirm={onCopyPages}
    >
      <MultiDropdown<IProgram>
        id="page-builder-page-roles"
        label="Programs"
        labelAttribute="programName"
        valueAttribute="id"
        placeholder="Select programs"
        options={data}
        selected={(programIds || [])?.map(role => role)}
        onOptionChange={value => setProgramIds(value as number[])}
        multiple
      />
    </T12Modal>
  );
});

type ModalRefType = {
  show: (page?: IPage) => void;
};
