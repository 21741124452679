import StandardButton from "../../components/button/StandardButton";
import React from "react";
import { usePageBuilder } from "../../hooks/usePageBuilder";
import { Col, Row } from "react-bootstrap";
import Input from "../../components/input/Input";
import { pageSchema } from "../../utils/validations";
import { Formik } from "formik";
import { useEditor } from "@craftjs/core";
import Alert from "react-bootstrap/Alert";
import { usePageBuilderContext } from "./PageBuilderProvider";
import "./app.scss";
import { MultiDropdown } from "../../components/dropDown/MultipleDropDown";
import { IRole } from "../../models/common";

export const PageBuilderSettings = () => {
  const { roles, initialValue, formRef, error, setError, onRoleChange, onCreatePage } =
    usePageBuilder();
  const { query } = useEditor();
  const { loading, page } = usePageBuilderContext();

  if (loading) return null;

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        ...initialValue,
        pageTitle: page?.pageTitle || "",
        pageUrl: page?.pageUrl ? page.pageUrl : initialValue.pageUrl,
        roles: page?.roles ? page.roles : initialValue.roles,
      }}
      onSubmit={values => onCreatePage(values)}
      validationSchema={pageSchema}
    >
      {({ values, touched, errors }) => (
        <>
          {error && (
            <Alert key="danger" variant="danger" onClose={() => setError("")} dismissible>
              {error}
            </Alert>
          )}
          <div className="HeaderBuilder">
            <h3 className="font-weight-600 my-0 ps-3">Page builder</h3>
            <StandardButton
              standardBtnId="page-builder-submit"
              variant="outlined"
              type="submit"
              size="sm"
              label="Save Page"
              onClick={() => {
                onCreatePage({ ...values, pageContent: query.serialize() });
              }}
            />
          </div>
          <Row>
            <Col xs={0} sm={0} md={1} />
            <Col xs={12} sm={12} md={11}>
              <Row>
                <Col xs={12} sm={12} md={11} lg={6}>
                  <Input
                    id={"page-builder-pageTitle"}
                    label="Page name"
                    variant="secondary"
                    name="pageTitle"
                    error={touched.pageTitle && errors.pageTitle ? errors.pageTitle : ""}
                  />
                </Col>
                <Col xs={12} sm={12} md={11} lg={3}>
                  <MultiDropdown<IRole>
                    id="page-builder-page-roles"
                    label="Access Roles"
                    labelAttribute="roleName"
                    valueAttribute="roleName"
                    placeholder="Select roles"
                    options={roles}
                    selected={(values.roles || [])?.map(role => role.name!)}
                    onOptionChange={value => onRoleChange(value as string[])}
                    multiple
                  />
                </Col>
              </Row>
              <Row className="PageForm">
                <Col xs={12} sm={12} md={11} lg={6}>
                  <Input
                    id={"page-builder-pageUrl"}
                    label="Page url"
                    variant="secondary"
                    name="pageUrl"
                    error={touched.pageUrl && errors.pageUrl ? errors.pageUrl : ""}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
};
