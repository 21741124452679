import React from "react";
import { useNode } from "@craftjs/core";
import { ButtonSettings } from "./ButtonSettings";
import styled from "styled-components";
import { getElementSize } from "../../utils/numToMeasurement";

type IProps = {
  text?: string;
  margin?: any[];
  padding?: any[];
  color?: string;
  background?: string;
  buttonStyle?: string;
  textComponent?: any;
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: string;
  textDecoration?: string;
  textAlign?: string;
  borderWidth?: string;
  borderStyle?: string;
  borderColor?: string;
  boxSizing?: string;
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  borderRadius?: string;

  url?: string;
  target?: string;
};

const StyledButton = styled.button<IProps>`
  color: ${({ color }) => `${color}!important`};
  background: ${({ background }) => `${background}!important`};
  margin: ${({ margin }) => getElementSize(margin)};
  padding: ${({ padding }) => getElementSize(padding)};
  font-family: ${({ fontFamily }) => `${fontFamily}!important`};
  font-size: ${({ fontSize }) => `${fontSize}px!important`};
  font-weight: ${({ fontWeight }) => `${fontWeight}!important`};
  text-decoration: ${({ textDecoration }) => `${textDecoration}!important`};
  text-align: ${({ textAlign }) => `${textAlign}!important`};
  border-width: ${({ borderWidth }) => getElementSize(borderWidth)};
  border-style: ${({ borderStyle }) => borderStyle};
  border-color: ${({ borderColor }) => borderColor};
  box-sizing: ${({ boxSizing }) => boxSizing};
  width: ${({ width }) => getElementSize(width)};
  height: ${({ height }) => getElementSize(height)};
  max-width: ${({ maxWidth }) => getElementSize(maxWidth)};
  max-height: ${({ maxHeight }) => getElementSize(maxHeight)};
  min-width: ${({ minWidth }) => getElementSize(minWidth)};
  min-height: ${({ minHeight }) => getElementSize(minHeight)};
  border-radius: ${({ borderRadius }) => getElementSize(borderRadius)};
`;

export const Button = ({ text, ...otherProps }: IProps) => {
  const {
    connectors: { connect },
  } = useNode(node => ({
    selected: node.events.selected,
  }));
  return (
    <StyledButton data-cy="toolbox-button" ref={connect as any} {...otherProps}>
      {text}
    </StyledButton>
  );
};

Button.craft = {
  displayName: "Button",
  props: {
    text: "Click me",
    margin: ["5", "0", "5", "0"],
    padding: ["5", "0", "5", "0"],
  },
  related: {
    settings: ButtonSettings,
    toolbar: ButtonSettings,
  },
};
