import { IClubEditor } from "app/models/clubs";
import { appStateSelectors, useAppState } from "app/state/AppState";
import { FormikProps } from "formik";
import { useEffect, useRef } from "react";
import { Api } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { CLUBS_CATEGORY } from "../utils/ClubConstants";
import { getClub } from "../api/adminApis";

const api = new Api("");

const useClubEditor = () => {
  const params = useParams();

  const navigate = useNavigate();
  const formRef = useRef<FormikProps<IClubEditor>>(null);
  const grades = useAppState(appStateSelectors.gradeBands);

  useEffect(() => {
    if (params.id) {
      const fetchClub = async () => {
        const club = await getClub(parseInt(params.id || ""));
        const clubEditor = JSON.parse(JSON.stringify(club.data));
        formRef.current?.setValues({
          ...clubEditor,
          gradeBandIds: clubEditor.gradeBands.map(({ gradeBand }: any) => gradeBand.id),
        });
      };
      fetchClub();
    }
  }, [params]);

  const onSubmit = async () => {
    try {
      let vals = formRef.current?.values;
      if (!vals) return;
      vals.id ? await api.put(`/admin/club/${vals.id}`, vals) : await api.post("/admin/club", vals);
      navigate("/clubs-manager");
    } catch (e: any) {
      console.error(e);
    }
  };

  const setData = (key: string, val: any) => {
    formRef.current?.setFieldValue(key, val);
  };

  return { initialValues, formRef, grades, onSubmit, setData };
};

export default useClubEditor;

export const CATEGORIES = CLUBS_CATEGORY.map((c: string, i: number) => {
  return {
    id: i,
    value: i,
    label: c,
  };
});

const initialValues: IClubEditor = {
  image: "",
  image_id: 0,
  families: [],
  gradeBands: [],
  gradeBandIds: [],
  files: [],
  about: "",
  requirements: "",
  name: "",
  status: "",
  clubLeaderName: "",
  clubActiveStatus: false,
  featured: false,
  num_students: "",
  meeting_times: "",
  profile_image_url: "",
  start_time: "",
  announcements: "",
  gallery: [],
  events: [],
  members: [],
  leaders: [],
  benefits: "",
};
