
export type DropDownType = { value: string, label: string }
export const SchoolActivitiesList = [
  { value: "arts", label: "Arts" },
  { value: "band", label: "Band" },
  { value: "baseball", label: "Baseball" },
  { value: "basketball", label: "Basketball" },
  { value: "boy_scouts", label: "Boy Scouts" },
  { value: "cheerleading", label: "Cheerleading" },
  { value: "choir_chorus", label: "Choir/Chorus" },
  { value: "computer_science", label: "Computer Science" },
  { value: "creative_writing", label: "Creative Writing" },
  { value: "cross_country", label: "Cross Country" },
  { value: "debate_team", label: "Debate Team" },
  { value: "environmental_club", label: "Environmental Club" },
  { value: "film_club", label: "Film Club" },
  { value: "football", label: "Football" },
  { value: "girl_scouts", label: "Girl Scouts" },
  { value: "golf", label: "Golf" },
  { value: "gymnastics", label: "Gymnastics" },
  { value: "key_club", label: "Key Club" },
  { value: "lacrosse", label: "Lacrosse" },
  { value: "math_club", label: "Math Club" },
  { value: "mock_trial", label: "Mock Trial" },
  { value: "model_united_nations", label: "Model United Nations" },
  { value: "national_honor_society", label: "National Honor Society (NHS)" },
  { value: "orchestra", label: "Orchestra" },
  { value: "photography_club", label: "Photography Club" },
  { value: "robotics", label: "Robotics" },
  { value: "science_club", label: "Science Club" },
  { value: "soccer", label: "Soccer" },
  { value: "softball", label: "Softball" },
  { value: "student_government", label: "Student Government" },
  { value: "student_newspaper", label: "Student Newspaper" },
  { value: "swimming", label: "Swimming" },
  { value: "tennis", label: "Tennis" },
  { value: "theater_drama", label: "Theater/Drama" },
  { value: "track_and_field", label: "Track and Field" },
  { value: "volleyball", label: "Volleyball" },
  { value: "wrestling", label: "Wrestling" },
  { value: "yearbook_committee", label: "Yearbook Committee" },
];

export const VolunteerActivitiesList = [
  { value: "arts", label: "Arts" },
  { value: "assist_teachers", label: "Assist Teachers" },
  { value: "athletics", label: "Athletics" },
  { value: "chaperone", label: "Chaperone" },
  { value: "fundraising", label: "Fundraising" },
  { value: "guest_speaker", label: "Guest Speaker" },
  { value: "internships", label: "Internships" },
  { value: "mentor", label: "Mentor" },
  { value: "pta_pto", label: "PTA/PTO" },
  { value: "special_projects", label: "Special Projects" },
  { value: "tutor", label: "Tutor" },
];

export const IndustryList = [
  { value: "agriculture", label: "Agriculture" },
  { value: "arts", label: "Arts" },
  { value: "automotive", label: "Automotive" },
  { value: "construction", label: "Construction" },
  { value: "consumer_goods", label: "Consumer Goods" },
  { value: "corporate_services", label: "Corporate Services" },
  { value: "design", label: "Design" },
  { value: "education", label: "Education" },
  { value: "energy_mining", label: "Energy & Mining" },
  { value: "entertainment", label: "Entertainment" },
  { value: "finance", label: "Finance" },
  { value: "government", label: "Government" },
  { value: "hardware_networking", label: "Hardware & Networking" },
  { value: "health_care", label: "Health Care" },
  { value: "legal", label: "Legal" },
  { value: "manufacturing", label: "Manufacturing" },
  { value: "media_communications", label: "Media & Communications" },
  { value: "military", label: "Military" },
  { value: "nonprofit", label: "Nonprofit" },
  { value: "public_administration", label: "Public Administration" },
  { value: "public_safety", label: "Public Safety" },
  { value: "real_estate", label: "Real Estate" },
  { value: "recreation_travel", label: "Recreation & Travel" },
  { value: "retail", label: "Retail" },
  { value: "software_it_services", label: "Software & IT Services" },
  { value: "technology", label: "Technology" },
  { value: "transportation_logistics", label: "Transportation & Logistics" },
  { value: "wellness_fitness", label: "Wellness & Fitness" },
];
