import React, { ReactNode, useRef } from "react";
import "./tabs.scss";
import { NavLink } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";

export type ITabs = {
  to?: string;
  active?: string;
  label: string;
  disabled?: boolean;
  icon?: ReactNode;
};

export const Tabs = ({
  tabs,
  onClick,
  activeLabel,
}: {
  tabs: Array<ITabs>;
  onClick?: (active: string) => void;
  activeLabel?: string;
}) => {
  const tabsRef = useRef<any>(null);

  const scrollLeft = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollBy({
        left: -150, // Adjust the scroll amount as needed
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollBy({
        left: 150, // Adjust the scroll amount as needed
        behavior: "smooth",
      });
    }
  };

  return (
    <Row className="tabs-container">
      <div className="tabs-sub-container">
        <Button className="arrow left" onClick={scrollLeft}>
          &lt;
        </Button>
        <Col md={10} lg={10} xl={10} sm={9} xs={9} ref={tabsRef} className="tabs">
          {tabs.map(tab => {
            if (tab.active) {
              return (
                <Button
                  disabled={tab.disabled}
                  key={tab.to || tab.active}
                  onClick={() => (tab.active ? onClick?.(tab.active) : undefined)}
                  className={activeLabel === tab.active ? "tab active" : "tab"}
                >
                  <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    {tab.icon}
                    {tab.label}
                  </div>
                </Button>
              );
            }
            if (tab.to) {
              return (
                <NavLink
                  to={tab.to}
                  className={({ isActive }) => (isActive ? "tab active" : "tab")}
                  key={tab.to}
                >
                  {tab.label}
                </NavLink>
              );
            }
          })}
        </Col>
        <Button className="arrow right" onClick={scrollRight}>
          &gt;
        </Button>
      </div>
    </Row>
  );
};
