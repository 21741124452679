import DragFileUpload from "app/components/dragFileUpload/DragFileUpload";
import { ImageUploader } from "app/components/imageUploader/ImageUploader";
import ImageUploaderRef from "app/components/imageUploader/ImageUploaderRef";
import Input from "app/components/input/Input";
import Section from "app/components/section/Section";
import useClubEditor, { CATEGORIES } from "app/hooks/ClubEditor";
import PrivateLayout from "app/layout/PrivateLayout";
import { Form, Formik } from "formik";
import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import "./club.scss";
import ClubLeader from "./components/ClubLeader";
import StandardButton from "app/components/button/StandardButton";
import RoundButton from "app/components/button/RoundButton";
import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import DropDown from "app/components/dropDown/DropDown";
import { IGradeBand } from "app/models/program";
import { appStateSelectors, useAppState } from "app/state/AppState";
import ClubEvent from "./components/ClubEvent";

import "react-datepicker/src/stylesheets/datepicker.scss";
import { FamilySection } from "./components/FamilySection";
import { GallerySection } from "./components/GallerySection";
import { deleteImage } from "../../api/commonApis";
import { validateClubManager } from "../../utils/validations";
import { ClubFiles } from "./components/ClubFiles";
import ContentEditor from "../../components/contentEditor/HtmlContentEditor";

type ICategory = {
  id: number;
  value: number;
  label: string;
};

const ClubEditor: React.FC = () => {
  const _editor = useClubEditor();
  const gradeBands = useAppState(appStateSelectors.gradeBands);

  const profileUploaderRef = useRef<ImageUploaderRef>(null);

  const removeImage = (image: any) => {
    _editor.formRef.current?.setFieldValue("image", null);
    _editor.formRef.current?.setFieldValue("image_path", null);
    deleteImage(image);
  };

  const handleRemoveFiles = (index: number) => {
    if (_editor.formRef.current && _editor.formRef.current.values.files) {
      const newFiles = [..._editor.formRef.current.values.files];
      newFiles.splice(index, 1);
      _editor.formRef.current.setFieldValue("files", [...newFiles]);
    }
  };

  return (
    <PrivateLayout contentClassName="club-editor">
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues}
        onSubmit={_editor.onSubmit}
        validationSchema={validateClubManager}
      >
        {({ values, isValid, dirty, setFieldValue, touched, errors }) => {
          return (
            <Form>
              <h3 className="title">Club Editor Page</h3>
              <Section sectionId="club-editor-general-info" title="General Information">
                <Row>
                  <Col>
                    <Input
                      required
                      type="text"
                      id="club-editor-name"
                      label="Club Name"
                      name="name"
                      variant="secondary"
                      error={touched.name && errors.name ? errors.name : ""}
                    />
                    <Input
                      required
                      type="text"
                      id="club-editor-about"
                      label="About Our Club"
                      name="about"
                      as=""
                      variant="secondary"
                      className="about"
                      error={touched.about && errors.about ? errors.about : ""}
                    />

                    <Input
                      type="text"
                      id="club-editor-requirements"
                      label="Requirements"
                      name="requirements"
                      as=""
                      variant="secondary"
                      className="requirements"
                    />

                    <label>Benefits</label>
                    <ContentEditor
                      value={values.benefits}
                      height="20rem"
                      onEditorChange={(_, editor) => {
                        const editorText = editor.getContent();
                        setFieldValue("benefits", editorText);
                      }}
                      id="club-benefits-editor"
                    />
                    <br />
                  </Col>

                  <Col className="club-editor-profile-image">
                    {!values?.image_id ? (
                      <DragFileUpload
                        className="profile-image-upload"
                        id={`profile-image-upload`}
                        label="Drop your file(s)"
                        onDrop={(files: File[]) => {
                          profileUploaderRef.current?.upload("Club Profile", files[0]);
                        }}
                        buttonLabel="browse"
                        multiple
                        useNativeUploader={true}
                      />
                    ) : (
                      <div>
                        <img
                          className="club-profile-image"
                          src={values.image ? values.image.path : ""}
                          onClick={() => removeImage(values.image)}
                          alt="club-profile-image"
                        />
                      </div>
                    )}

                    <ImageUploader
                      ref={profileUploaderRef}
                      hideProgress={true}
                      onUploadComplete={(file: any) => {
                        setFieldValue("image_id", file.id);
                        setFieldValue("image", file);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Input
                      type="text"
                      id="club-editor-numstudents"
                      label="Number of students"
                      name="num_students"
                      as="input"
                      variant="secondary"
                      className="students"
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      type="text"
                      id="club-editor-meetingtimes"
                      label="Meeting Times"
                      name="meeting_times"
                      as="input"
                      variant="secondary"
                      className="meetingtimes"
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      type="text"
                      id="club-editor-starttime"
                      label="Start Time"
                      name="start_time"
                      as="input"
                      variant="secondary"
                      className="starttime"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <DropDown<IGradeBand>
                      label="Grade Band"
                      id="article-editor-grade-band"
                      labelAttribute="gradeBand"
                      options={gradeBands}
                      selected={values.gradeBandIds}
                      multiple
                      valueAttribute="id"
                      placeholder="Select Grade"
                      onOptionChange={gradeBandIds => setFieldValue("gradeBandIds", gradeBandIds)}
                    />
                  </Col>
                  <Col md={3}>
                    <DropDown<ICategory>
                      label="Category"
                      id="category"
                      labelAttribute="label"
                      options={CATEGORIES}
                      selected={values.category}
                      valueAttribute="value"
                      placeholder="Select Category"
                      onOptionChange={val => setFieldValue("category", val)}
                    />
                  </Col>
                </Row>
              </Section>
              <hr />
              <Section sectionId="club-editor-staff" title="The Club Leaders">
                <Row>
                  <Col md={4}>
                    <ClubLeader
                      className="big"
                      leader={values?.leaders?.[0]}
                      id="club-editor-staff-1"
                      size="lg"
                      onChange={data => setFieldValue("leaders.0", data)}
                    />
                  </Col>
                  <Col md={3} className="mt-5">
                    <ClubLeader
                      leader={values.leaders?.[1]}
                      id="club-editor-staff-2"
                      onChange={data => setFieldValue("leaders.1", data)}
                    />
                    <ClubLeader
                      leader={values.leaders?.[2]}
                      id="club-editor-staff-3"
                      onChange={data => setFieldValue("leaders.2", data)}
                    />
                  </Col>
                </Row>
              </Section>
              {values?.files && (
                <Section sectionId="club-editor-staff" title="Files">
                  <Row>
                    <ClubFiles
                      files={values.files}
                      onChange={(file: File) => {
                        setFieldValue("files", [...(values.files || []), file]);
                      }}
                      onRemoveFile={(index: number) => handleRemoveFiles(index)}
                    />
                  </Row>
                </Section>
              )}

              <Section sectionId="club-editor-staff" title="Upcoming Events">
                {values.events?.map((event, index) => {
                  return <ClubEvent key={index} index={index} />;
                })}
                <Row>
                  <Col sm={4}></Col>
                  <Col sm={8} className="add-event">
                    <RoundButton
                      onClick={() => {
                        setFieldValue("events", [
                          ...(values.events || []),
                          {
                            name: "",
                            location: "",
                            event_date: new Date().toISOString(),
                          },
                        ]);
                      }}
                      icon={<IcPlus />}
                      variant="primary"
                      roundBtnId={"add-event"}
                      label="Add a new event"
                    />
                  </Col>
                </Row>
              </Section>
              <Section sectionId="club-editor-announcements" title="Announcements">
                <Row>
                  <Col>
                    <Input
                      as="textarea"
                      id="club-editor-announcements"
                      label=""
                      name="announcements"
                      variant="secondary"
                      className="about"
                    />
                  </Col>
                </Row>
              </Section>
              <FamilySection />
              <GallerySection />
              <Col md="auto">
                <div className="top-button-container">
                  <StandardButton
                    standardBtnId="article-editor-save-btn"
                    label="Save Updates"
                    variant="outlined"
                    type="submit"
                    disabled={!dirty || !isValid}
                  />
                </div>
              </Col>
            </Form>
          );
        }}
      </Formik>
    </PrivateLayout>
  );
};

export default ClubEditor;
