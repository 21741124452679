import React from "react";
import { FormikTouched, FormikErrors } from "formik";

import Section from "app/components/section/Section";
import EducationEditor from "app/components/educationEditor/EducationEditor";
import RoundButton from "app/components/button/RoundButton";
import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";

interface IEducationSection {
  editor: any;
  touched: FormikTouched<any>;
  errors: FormikErrors<any>;
  values: any;
}

export const EducationSection: React.FC<IEducationSection> = ({ editor, values }) => {
  return (
    <Section
      title="Education"
      sectionId="profileEditorBuilderEducation"
      titleSize="lg"
      className="profileEditor-builder-education"
      isEditable={true}
    >
      {(values.educations ?? []).map((education: any, index: any) => (
        <EducationEditor
          key={`profileEditor-builder-education-${index}`}
          index={index}
          variant="secondary"
          selected={education.outcome_id}
          onChange={editor.onChange(`educations[${index}].outcome_id`)}
          onDelete={editor.onDeleteEducation}
        />
      ))}

      <RoundButton
        icon={<IcPlus />}
        className="add-icon"
        size="lg"
        variant="primary"
        label="Add new education"
        onClick={editor.addEducation}
        roundBtnId="profileEditor-builder-add-btn"
      />
      <hr className="mb-5 mt-4" />
    </Section>
  );
};
