import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Calendar from 'react-widgets/Calendar';
// import moment from 'moment';
import moment from 'moment-timezone';

import { IEmailScheduleItemBuilder } from 'app/models/email';
import { IEmailScheduleEditor } from 'app/hooks/EmailScheduleEditor';
import Section from 'app/components/section/Section';
import RadioButtonGroup from 'app/components/radioButtonGroup/RadioButtonGroup';
import RadioButtonOption from 'app/components/radioButtonGroup/RadioButtonOption';
import DropDown from 'app/components/dropDown/DropDown';
import { SendTimeComparisonGrid } from './SendTimeComparisonGrid';
import { useAppState, appStateSelectors } from 'app/state/AppState';
import { TIME_OPTIONS } from 'app/constants/common';

interface IEmailSendTimeSection {
  editor: IEmailScheduleEditor;
  values: IEmailScheduleItemBuilder;
}

export const EmailSendTimeSection: React.FC<IEmailSendTimeSection> = ({
  editor,
  values,
}) => {
  const appConfig = useAppState(appStateSelectors.appConfig);

  function formatSendDate() {
    const startDate = moment(values.start_at || undefined);
    const day = startDate.format('DD');
    const monthYear = startDate.format('MMMM Y');

    return (
      <Col>
        <span className='send-day'>{day}</span>
        <span>{monthYear}</span>
      </Col>
    );
  }

  function formatSelectedTimeValue() {
    return values.start_at ? moment(values.start_at).format('h:mm a') : null;
  }

  function formatTime(date: Date | string | undefined) {
    if (!date) return '';
    return moment(date).format('MMM D, YYYY h:mm a');
  }

  return (
    <Section
      title='Select when the email should be sent'
      sectionId='emailScheduleCalendar'
      titleSize='md'
      className='email-schedule-calendar mt-4'
    >
      <Row>
        <Col>
          <RadioButtonGroup<string>
            id='schedule-select'
            onSelect={editor.onSelectSendOption}
          >
            {['Now', 'Scheduled'].map((scheduleType: string, index: number) => {
              const checked =
                scheduleType === 'Now' ? !values.start_at : !!values.start_at;

              return (
                <RadioButtonOption
                  id={`email-schedule-calendar-send-${scheduleType}`}
                  checked={checked}
                  label={
                    <Row className='calendar-send-label'>
                      <Col className='send-start calendar-label'>
                        {scheduleType}
                      </Col>
                      {index === 1 && (
                        <Col className='spacer calendar-label'>Time</Col>
                      )}
                      {scheduleType === 'Scheduled' && (
                        <Col className='send-end calendar-label'>
                          Stop Sending On{' '}
                          {/* <span className='end-date'>
                            (
                            {values.end_at
                              ? moment(values.end_at).format(
                                  'MMM D, yyyy hh:mm a'
                                )
                              : 'end date not set'}
                            )
                          </span> */}
                        </Col>
                      )}
                    </Row>
                  }
                  value={scheduleType}
                />
              );
            })}
          </RadioButtonGroup>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <Calendar
            value={values.start_at ? new Date(values.start_at) : new Date()}
            onChange={editor.onSelect('start_at')}
            disabled={values.start_at === undefined}
          />
        </Col>
        <Col className='send-date-select-time'>
          <Row className='pt-4'>{formatSendDate()}</Row>
          <Row class='calendar-time-row'>
            <Col>
              <DropDown
                id='calendar-time-dropdown'
                labelAttribute='label'
                valueAttribute='label'
                options={TIME_OPTIONS}
                selected={formatSelectedTimeValue() as any}
                onOptionChange={editor.onTimeChange}
                className='calendar-time-selector'
                disabled={values.start_at === undefined}
              />
            </Col>
          </Row>
          <Row className='current-time-row'>
            <Col>
              <span>* Your time {moment().format('h:mma')}</span>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              {appConfig?.timezone && (
                <div>
                  <h6>
                    Emails will be sent based on the current timezone for{' '}
                    {appConfig?.schoolName ?? "this program's"}
                  </h6>

                  <h4 className='time-zone-send-time'>
                    {values.start_at && (
                      <>
                        <Row>
                          <Col xs={3}>Start Time:</Col>
                          <Col>{formatTime(values.start_at)}</Col>
                        </Row>
                        <Row>
                          <Col xs={3}>End Time:</Col>
                          <Col>{formatTime(values.end_at)}</Col>
                        </Row>
                        <Row>
                          <Col xs={3}>Timezone:</Col>
                          <Col>{appConfig.timezone}</Col>
                        </Row>
                      </>
                    )}
                    {values.start_at === undefined && (
                      <>
                        {moment().tz(appConfig.timezone).format('hh:mma')}{' '}
                        {appConfig.timezone}
                      </>
                    )}
                  </h4>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <Calendar
            // ref={}
            value={values.end_at ? new Date(values.end_at) : null}
            onChange={editor.onSelect('end_at')}
            min={
              moment(values.start_at).toDate() ??
              moment().subtract(1, 'day').toDate()
            }
            disabled={
              editor.sendNow ||
              (!editor.sendNow &&
                !!values.delivery_frequency &&
                values.delivery_frequency === 'Once')
            }
          />
        </Col>
      </Row>
      <hr className='mt-5' />
    </Section>
  );
};
