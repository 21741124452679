import React from "react";
import "./radio-box.scss";

const RadioBox: React.FC<IRadioBox> = ({
  label,
  description,
  onSelectItem,
  radioBoxId,
  selected,
}) => {
  return (
    <div className={`radio-box ${selected && "selected"}`} onClick={() => {
      onSelectItem(label);
    }}>
      <div className="round">
        <input checked={selected} type="checkbox" id={`checkbox${radioBoxId}`} />
        <label htmlFor="checkbox"></label>
      </div>
      <p className="radio-box-label">{label}</p>
      <p className="radio-box-description">{description}</p>
    </div>
  );
};

export default RadioBox;

export interface IRadioBox {
  radioBoxId: string;
  label: string;
  description: string;
  onSelectItem: (label: string) => void;
  selected?: boolean;
}
