import { useNode } from "@craftjs/core";
import React from "react";

import { ToolbarTextInput } from "./ToolbarTextInput";
import { RadioGroup } from "./ToolbarRadio";
import { IUploadResponse } from "../../../../models/common";
import { usePageBuilder } from "../../../../hooks/usePageBuilder";
import StandardButton from "../../../../components/button/StandardButton";
import UploadPopup from "../../../sitePhotos/uploadPopup/UploadPopup";
import ContentEditor from "../../../../components/contentEditor/HtmlContentEditor";
import Checkbox from "../../../../components/checkbox/CheckBox";

export type ToolbarItemProps = {
  prefix?: string;
  label?: string;
  full?: boolean;
  propKey?: string;
  index?: number;
  children?: React.ReactNode;
  type: string;
  onChange?: (value: any) => any;
  mediaType?: "IMAGE" | "VIDEO";
};
export const ToolbarItem = ({
  full = false,
  propKey,
  type,
  onChange,
  index,
  mediaType,
  ...props
}: ToolbarItemProps) => {
  const {
    actions: { setProp },
    propValue,
  } = useNode(node => ({
    propValue: node.data.props[propKey || 0],
  }));
  const value = Array.isArray(propValue) ? propValue[index || 0] : propValue;
  const { uploadLogoRef } = usePageBuilder();

  return (
    <>
      {["text", "color", "bg", "number"].includes(type) ? (
        <ToolbarTextInput
          {...props}
          type={type}
          value={value}
          onChange={value => {
            setProp((props: any) => {
              if (Array.isArray(propValue)) {
                props[propKey || 0][index || 0] = onChange ? onChange(value) : value;
              } else {
                props[propKey || 0] = onChange ? onChange(value) : value;
              }
            }, 500);
          }}
        />
      ) : type === "slider" ? (
        <>{props.label ? <h4 className="text-sm text-light-gray-2">{props.label}</h4> : null}</>
      ) : type === "radio" ? (
        <>
          <>{props.label ? <b>{props.label}</b> : null}</>
          <RadioGroup
            propKey={propKey}
            name={propKey}
            onClickRadioButton={(value: any) => {
              setProp((props: any) => {
                props[propKey || 0] = onChange ? onChange(value) : value;
              });
            }}
            selectedValue={value}
          >
            {props.children as any}
          </RadioGroup>
        </>
      ) : type === "select" ? (
        <div>Dropdown</div>
      ) : type === "upload" ? (
        <>
          <StandardButton
            size="sm"
            variant="secondary"
            label="Upload file"
            onClick={() => uploadLogoRef.current?.show()}
            standardBtnId="OpenFileUploader"
            className="mb-1"
          />
          <UploadPopup
            id="response-video"
            onConfirm={(uploadedMedia: IUploadResponse) => {
              if (uploadedMedia) {
                setProp((props: any) => {
                  props[propKey || 0] = onChange
                    ? onChange(uploadedMedia?.fileUrl)
                    : uploadedMedia?.fileUrl;
                });
              }
            }}
            ref={uploadLogoRef}
            mediaType={mediaType}
          />
        </>
      ) : type === "rich-editor" ? (
        <>
          <ContentEditor
            id="article-editor-content"
            height="32rem"
            onEditorChange={value => {
              setProp((props: any) => {
                props[propKey || 0] = onChange ? onChange(value) : value;
              });
            }}
            value={value}
          />
        </>
      ) : type === "checkbox" ? (
        <Checkbox
          {...props}
          onChange={value => {
            setProp((props: any) => {
              props[propKey || 0] = onChange ? onChange(value) : value;
            });
          }}
          checked={!!value}
          id={new Date().getTime().toString()}
        />
      ) : null}
    </>
  );
};
