import React, { useEffect, useState } from "react";
import "./Logos.scss";
import { useFormikContext } from "formik";
import { LogoScrollerInterface } from "./LogoConfigurator";

export const LogosDemo = () => {
  const { values } = useFormikContext<LogoScrollerInterface>();
  const { carouselConfigurator } = values;
  const [key, setKey] = useState(0); // A key to re-trigger the animation

  useEffect(() => {
    const timer = setTimeout(() => {
      setKey(prevKey => prevKey + 1);
    }, carouselConfigurator.autoPlaySpeed * 1000);

    return () => clearTimeout(timer);
  }, [key, carouselConfigurator.speed, carouselConfigurator.autoPlaySpeed]); // Depend on key, speed, and delay so it recalculates when any of these change

  return (
    <div className="animation-container" key={key}>
      <div
        className="animated-box"
        style={{
          animationDuration: `${carouselConfigurator.speed}s`,
        }}
      ></div>
    </div>
  );
};
