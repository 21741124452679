import React, { FormEvent, useRef } from "react";

import Section from 'app/components/section/Section';
import { IConfigurationEditorSection } from 'app/hooks/ConfigurationEditor';
import PhotoCopyRow from './components/PhotoCopyRow';
import { useOutletContext } from "react-router-dom";
import { Form, useFormikContext } from "formik";
import { IMainProgramConfigBuilder } from "../../models/config";
import { buildJsonFromForm } from "../../utils/objectBuilder";


export const PhotosCopySection = () => {
  const {
    editor
  } = useOutletContext<IConfigurationEditorSection>()
  const { values, touched, errors } = useFormikContext<IMainProgramConfigBuilder>();
  const ref = useRef<HTMLFormElement>(undefined!);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = buildJsonFromForm(ref.current);
    const body: Partial<IMainProgramConfigBuilder> = {
      ...data,
      guest_welcome_image: values.guest_welcome_image,
      default_user_profile_image: values.default_user_profile_image,
      family_hero_image: values.family_hero_image,
      staff_hero_image: values.staff_hero_image,
      outcomes_hero_image: values.outcomes_hero_image
    }
    await editor?.onSubmit?.(body)
  }

  return (
    <Form  ref={ref} id="config-form-submit" onSubmit={onSubmit}>
      <Section
        title='Photos & Copy'
        sectionId='configuration-editor-photo-copy'
        titleSize='md'
        className='configuration-editor-photo-copy mt-4'
      >
        <PhotoCopyRow
          editor={editor}
          values={values}
          subLabel='School Welcome Image'
          imageAttribute='guest_welcome_image'
          imageRef={editor.uploadWelcomeImageRef}
          touched={touched}
          errors={errors}
        />
        <PhotoCopyRow
          editor={editor}
          values={values}
          subLabel='Profile Backup Image'
          imageAttribute='default_user_profile_image'
          imageRef={editor.uploadProfileBackupImageRef}
          touched={touched}
          errors={errors}
        />

        <PhotoCopyRow
          editor={editor}
          values={values}
          label='Tips and advice'
          elements={[
            { id: 'article-header-copy', label: 'Header Copy' },
            { id: 'article-subheader-copy', label: 'Subheader Copy' },
          ]}
          touched={touched}
          errors={errors}
        />
        <PhotoCopyRow
          editor={editor}
          values={values}
          label='Q&A Videos'
          elements={[
            { id: 'qa-header-copy', label: 'Header Copy' },
            { id: 'qa-subheader-copy', label: 'Subheader Copy' },
          ]}
          touched={touched}
          errors={errors}
        />
        <PhotoCopyRow
          editor={editor}
          values={values}
          label='Family Network'
          elements={[
            { id: 'family-header-copy', label: 'Header Copy' },
            { id: 'family-subheader-copy', label: 'Subheader Copy' },
          ]}
          touched={touched}
          errors={errors}
          imageAttribute='family_hero_image'
          imageRef={editor.uploadFamilyImageRef}
        />
        <PhotoCopyRow
          editor={editor}
          values={values}
          label='Staff Directory'
          elements={[
            { id: 'staff-header-copy', label: 'Header Copy' },
            { id: 'staff-subheader-copy', label: 'Subheader Copy' },
          ]}
          touched={touched}
          errors={errors}
          imageAttribute='staff_hero_image'
          imageRef={editor.uploadStaffImageRef}
        />
        <PhotoCopyRow
          editor={editor}
          values={values}
          label='Club Hub'
          elements={[
            { id: 'club-header-copy', label: 'Header Copy' },
            { id: 'club-subheader-copy', label: 'Subheader Copy' },
          ]}
          touched={touched}
          errors={errors}
        />
        <PhotoCopyRow
          editor={editor}
          values={values}
          label='Outcomes'
          elements={[
            { id: 'outcomes-header-copy', label: 'Header Copy' },
            { id: 'outcomes-subheader-copy', label: 'Subheader Copy' },
          ]}
          touched={touched}
          errors={errors}
          imageAttribute='outcomes_hero_image'
          imageRef={editor.uploadOutcomeImageRef}
        />
        <hr className='mt-5' />
      </Section>
    </Form>
  );
};
