import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ToolbarItem } from "../../Editor/Toolbar/ToolbarItem";
import { ToolbarSection } from "../../Editor/Toolbar/ToolbarSection";
import { Dimensions } from "../Common/Dimensions";
import { Margin, Padding } from "../Common";
import StandardButton from "../../../../components/button/StandardButton";
import { CustomModal } from "./CustomModal";
import { TextDecoration } from "../Common/TextDecoration";

export const TextSettings = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <>
      <div style={{ padding: 8 }}>
        <StandardButton
          onClick={openModal}
          label="Open text editor"
          standardBtnId="open-modal"
          size="sm"
        />
      </div>
      <Dimensions />
      <Margin />
      <ToolbarSection
        title="Colors"
        props={["background", "color"]}
        summary={({ background, color }: { background: string; color: string }) => (
          <div className="flex flex-row-reverse">
            <div
              style={{
                background: `${background}`,
                width: 30,
                height: 30,
                borderRadius: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color,
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                T
              </div>
            </div>
          </div>
        )}
      >
        <ToolbarItem propKey="background" type="color" label="Background color" />
        <ToolbarItem propKey="color" type="color" label="Color" />
      </ToolbarSection>

      <TextDecoration />

      <CustomModal show={showModal} onClose={closeModal}>
        <Row>
          <Col xs={12} sm={12} md={12}>
            <ToolbarItem propKey="text" type="rich-editor" label="Add text" />
          </Col>
        </Row>
      </CustomModal>
    </>
  );
};
