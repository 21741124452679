import React from "react";

import StandardButton from "app/components/button/StandardButton";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import DataGrid from "app/components/dataGrid/DataGrid";
import Filter from "app/components/filter/Filter";
import FilterOption from "app/components/filter/FilterOption";
import SearchBar from "app/components/searchBar/SearchBar";
import Switch from "app/components/switch/Switch";
import { ACTIVE_OPTIONS } from "app/utils/common";
import useGraduateListing from "app/hooks/GraduateProfileListing";
import PrivateLayout from "app/layout/PrivateLayout";
import { IStaffListItem } from "app/models/staff";
import { IGraduateListItem } from "app/models/graduate";
import { IActiveStatus } from "app/models/common";
import "../GraduatesAlumniEditorPage/graduate-profile-manager.scss";
import { useDownloadCsv } from "../../hooks/ExportApi";
import { exportUserModelCsvFetcher } from "../../api/adminApis";
import "./graduate-manager.scss";

const GRID_ID = "graduate-account-listing";

const StaffListing: React.FC = () => {
  const _graduateListing = useGraduateListing();
  const { exportCsv } = useDownloadCsv(() => exportUserModelCsvFetcher("graduate"));

  return (
    <PrivateLayout contentClassName="graduate-profile-listing">
      <div className="action-buttons">
        <StandardButton
          label="Export csv"
          variant="primary"
          className="new-staff-button"
          onClick={exportCsv}
          standardBtnId="staff-listing-new-btn"
        />
        <StandardButton
          label="+ New Graduate Profile"
          variant="secondary"
          className="new-graduate-profile-button"
          onClick={_graduateListing.onNewGraduateClick}
          standardBtnId="graduate-listing-new-btn"
        />
      </div>
      <h3 className="title">Graduate Gallery Profile Manager</h3>
      <SearchBar
        placeHolder="Search by user name"
        searchBarId="graduate-listing-search"
        onChangeText={_graduateListing.onSearchKeyChange}
        onSearch={_graduateListing.onSearch}
      />
      <Filter
        id="graduate-listing-filter"
        onFilterChange={_graduateListing.onFilterChange}
        selectedFilters={_graduateListing.filters}
      >
        <FilterOption<{ id: number; label: number }>
          labelAttribute="label"
          valueAttribute="id"
          name="class_years"
          options={_graduateListing.classYears}
          placeholder="Class Years"
        />
        <FilterOption<IActiveStatus>
          labelAttribute="label"
          valueAttribute="isActive"
          name="isActive"
          options={ACTIVE_OPTIONS}
          placeholder="Active Status"
          disableMultipleSelection
        />
      </Filter>
      <DataGrid<IGraduateListItem>
        data={_graduateListing.data}
        gridId={GRID_ID}
        rowIdAttribute="id"
        loading={_graduateListing.loading}
        currentPage={_graduateListing.currentPage}
        onPageChange={_graduateListing.onPageChange}
        totalItems={_graduateListing.totalItems}
        onAction={_graduateListing.onGridAction}
      >
        <DataGrid.Column<IGraduateListItem> attributeName="last_name" header="User Name" />
        <DataGrid.Column<IGraduateListItem>
          attributeName="attending_school_since"
          header="Class Year"
        />
        <DataGrid.Column<IGraduateListItem>
          attributeName="is_enabled"
          header="Active Status"
          transform={graduate => (
            <Switch
              checked={graduate.is_enabled}
              onChange={_graduateListing.onToggleActive(graduate)}
              switchId="graduate-listing-active-btn"
            />
          )}
        />
        <DataGrid.Column<IStaffListItem>
          action={DATA_GRID_ACTIONS.PREVIEW}
          attributeName="GRID.ACTIONS"
        />
        <DataGrid.Column<IStaffListItem>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName="GRID.ACTIONS"
        />
      </DataGrid>
    </PrivateLayout>
  );
};

export default StaffListing;
