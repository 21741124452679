import RoundButton from "../../../components/button/RoundButton";
import DragFileUpload from "../../../components/dragFileUpload/DragFileUpload";
import { ImageUploader } from "../../../components/imageUploader/ImageUploader";
import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import Section from "../../../components/section/Section";
import React, { useRef } from "react";
import { useFormikContext } from "formik";
import { IClubEditor } from "../../../models/clubs";
import ImageUploaderRef from "../../../components/imageUploader/ImageUploaderRef";
import { deleteImage } from "../../../api/commonApis";

export const GallerySection = () => {
  const galleryUploaderRef = useRef<ImageUploaderRef>(null);
  const { values, setFieldValue } = useFormikContext<IClubEditor>();
  const { gallery } = values;

  const addGalleryImage = (image: any) => {
    const newGallery = [
      ...(gallery || []),
      {
        image: image,
        image_id: image.id,
      },
    ];
    setFieldValue("gallery", newGallery);
  };

  const removeGalleryImage = (image: any) => {
    deleteImage(image.image);
    const newGallery = gallery?.splice(gallery.indexOf(image), 1);
    setFieldValue("gallery", newGallery);
  };

  return (
    <Section
      sectionId="club-editor-gallery"
      title="Gallery"
      className="club-editor-gallery-container"
    >
      <div>
        <div className="club-editor-gallery">
          {gallery?.map((gImage, index) => {
            return (
              <div key={index} className="club-editor-gallery-image">
                <img src={gImage.image.path} alt={gImage.image.path} />
                <div
                  style={{
                    position: "absolute",
                    top: ".5em",
                    right: ".5em",
                  }}
                >
                  <RoundButton
                    onClick={() => removeGalleryImage(gImage)}
                    icon={<IcPlus style={{ transform: "rotate(45deg)" }} />}
                    variant="primary"
                    roundBtnId={"remove-image"}
                  />
                </div>
              </div>
            );
          })}

          <DragFileUpload
            className="gallery-image-upload"
            id={`profile-image-upload`}
            label="Drop your file(s)"
            onDrop={(files: File[]) => {
              galleryUploaderRef.current?.upload("Club Gallery", files[0]);
            }}
            buttonLabel="browse"
            multiple
            useNativeUploader={true}
          />

          <ImageUploader
            ref={galleryUploaderRef}
            hideProgress={true}
            onUploadComplete={(file: any) => {
              addGalleryImage(file);
            }}
          />
        </div>
      </div>
    </Section>
  );
};
