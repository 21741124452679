import React from "react";
import { Col, Row } from "react-bootstrap";

import Section from "app/components/section/Section";
import DropDown from "app/components/dropDown/DropDown";
import { IEmailScheduleItemBuilder } from "app/models/email";
import { IGradeBand } from "app/models/program";
import { IRole } from "app/models/common";
import { IEmailScheduleEditor } from "app/hooks/EmailScheduleEditor";
import { YEARS2 } from "../../../../constants/common";

interface IPopulationSelectionSection {
  editor: IEmailScheduleEditor;
  values: IEmailScheduleItemBuilder;
}

export const PopulationSelectionSection: React.FC<IPopulationSelectionSection> = ({
  editor,
  values,
}) => {
  return (
    <Section
      title="Select the population you would like to send the email to"
      sectionId="emailScheduleFrequency"
      titleSize="md"
      className="email-schedule-population mt-1"
    >
      <Row>
        <Col xs={3}>
          <h6>Grade Band</h6>
          <DropDown<IGradeBand>
            id="email-schedule-grade-bands"
            labelAttribute="gradeBand"
            options={editor.gradeBands}
            selected={values.grade_band_ids ?? []}
            valueAttribute="id"
            placeholder="Select Grade Bands"
            onOptionChange={editor.onDropdownChange("grade_band_ids")}
            multiple
          />
        </Col>
        <Col xs={3}>
          <h6>User Roles</h6>
          <DropDown<IRole>
            id="email-schedule-roles"
            labelAttribute="roleName"
            options={editor.roles}
            selected={(values.roles ?? []).map(role => role.id)}
            valueAttribute="id"
            placeholder="Select User Roles"
            onOptionChange={editor.onRoleChange}
            multiple
          />
        </Col>
        <Col xs={3}>
          <h6>Class year</h6>
          <DropDown
            valueAttribute={"class_year"}
            labelAttribute={"class_year"}
            id="notification-builder-classYear"
            options={YEARS2.map(year => ({ class_year: +year }))}
            multiple
            selected={values.class_year || []}
            placeholder="Select Year"
            onOptionChange={editor.onDropdownChange("class_year")}
          />
        </Col>
      </Row>
      <hr className="mt-5" />
    </Section>
  );
};
