

import React from "react";

export const Move = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <defs>
      <style>
      </style>
    </defs>
    <title>S Move 18 N</title>
    <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18"/>
    <path className="a"
          d="M17,9a.25.25,0,0,0-.0565-.158L16,8.0145V8h-.0165L14.927,7.0735A.245.245,0,0,0,14.75,7a.25.25,0,0,0-.25.25V8H10V3.5h.75A.25.25,0,0,0,11,3.25a.24448.24448,0,0,0-.0735-.175L10,2.0165V2H9.9855L9.158,1.0565a.25.25,0,0,0-.316,0L8.0145,2H8v.0165L7.0735,3.073A.24449.24449,0,0,0,7,3.25a.25.25,0,0,0,.25.25H8V8H3.5V7.25A.25.25,0,0,0,3.25,7a.245.245,0,0,0-.175.0735L2.0165,8H2v.0145l-.9435.8275a.25.25,0,0,0,0,.316L2,9.9855V10h.0165l1.0565.926A.24552.24552,0,0,0,3.25,11a.25.25,0,0,0,.25-.25V10H8v4.5H7.25a.25.25,0,0,0-.25.25.24352.24352,0,0,0,.0735.175L8,15.9835V16h.0145l.8275.9435a.25.25,0,0,0,.316,0L9.9855,16H10v-.0165l.9265-1.057A.24349.24349,0,0,0,11,14.75a.25.25,0,0,0-.25-.25H10V10h4.5v.75a.25.25,0,0,0,.25.25.24549.24549,0,0,0,.175-.074L15.9835,10H16V9.9855l.9435-.8275A.25.25,0,0,0,17,9Z"/>
  </svg>
)
