import React from "react";
import styled, { css } from "styled-components";

const RadioButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const RadioButtonLabel = styled.label`
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const HiddenRadioButton = styled.input.attrs({ type: "radio" })`
  opacity: 0;
  position: fixed;
  width: 0;
`;

const StyledRadioButton = styled.div<any>`
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid #555;
  border-radius: 50%;
  background-color: ${({ checked }) => (checked ? "#00449E" : "transparent")};
  transition: background-color 0.2s;
  padding: 2px;
`;

export const RadioGroup = (props: any) => {
  const { name, selectedValue, onClickRadioButton, children } = props;

  return (
    <>
      {React.Children.map(children, element =>
        React.cloneElement(element, {
          ...element.props,
          checked: selectedValue === element.props.value,
          onChange: () => onClickRadioButton(element.props.value),
          name,
        })
      )}
    </>
  );
};

export const Radio = (props: any) => {
  const { onChange, value, label, checked, name } = props;
  return (
    <RadioButtonContainer>
      <RadioButtonLabel>
        <HiddenRadioButton name={name} value={value} checked={checked} onChange={onChange} />
        <StyledRadioButton checked={checked} />
        {label}
      </RadioButtonLabel>
    </RadioButtonContainer>
  );
};
