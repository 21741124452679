import React from "react";

import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import DataGrid from "app/components/dataGrid/DataGrid";
import SearchBar from "app/components/searchBar/SearchBar";
import PrivateLayout from "app/layout/PrivateLayout";
import { IOutcomeLogos } from "../../models/outcomeLogos";
import { useOutcomeLogosList } from "./hooks/useOutcomeLogosList";
import StandardButton from "../../components/button/StandardButton";
import "./outcomes-logos.scss";
import { IArticleListItem } from "../../models/article";

const GRID_ID = "graduate-account-listing";

export const OutcomeLogosList = () => {
  const outcomeLogos = useOutcomeLogosList();

  return (
    <PrivateLayout contentClassName="outcomes-logos-listing">
      <div className="action-buttons">
        <StandardButton
          label="+ New College"
          variant="secondary"
          className="new-staff-button"
          onClick={outcomeLogos.onNewStaffClick}
          standardBtnId="staff-listing-new-btn"
        />
      </div>
      <h3 className="title">College Manager</h3>
      <SearchBar
        placeHolder="Search by user name"
        searchBarId="graduate-listing-search"
        onChangeText={outcomeLogos.onSearchKeyChange}
        onSearch={outcomeLogos.onSearch}
      />
      <DataGrid<IOutcomeLogos>
        data={outcomeLogos.data}
        gridId={GRID_ID}
        rowIdAttribute="id"
        loading={outcomeLogos.loading}
        currentPage={outcomeLogos.currentPage}
        onPageChange={outcomeLogos.onPageChange}
        totalItems={outcomeLogos.totalItems}
        onAction={outcomeLogos.onGridAction}
      >
        <DataGrid.Column<IOutcomeLogos> attributeName="outcome_title" header="School name" />
        <DataGrid.Column<IOutcomeLogos>
          attributeName="outcome_abbreviation"
          header="School abbreviation"
        />
        <DataGrid.Column<IOutcomeLogos> attributeName="outcome_state" header="School state" />
        <DataGrid.Column<IOutcomeLogos> attributeName="zip_code" header="Zip code" />
        <DataGrid.Column<IOutcomeLogos> attributeName="logo_name" header="Logo ID name" />
        <DataGrid.Column<IOutcomeLogos>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName="GRID.ACTIONS"
        />
      </DataGrid>
    </PrivateLayout>
  );
};
