import React from "react";
import DatePicker from "react-datepicker";
import { ReactComponent as CalendarIcon } from "app/assets/icons/calander.svg";
import { useReportingProvider } from "../ReportingProvider";
import { appStateSelectors, useAppState } from "../../../state/AppState";

export const OverallReportingFilter = ({
  downloadReportCsv,
}: {
  downloadReportCsv: (entity: string, url?: string) => void;
}) => {
  const appConfig = useAppState(appStateSelectors.appConfig);
  // @ts-ignore
  const availableGradeBands = appConfig.grade_bands ? appConfig.grade_bands : [];
  const {
    userType,
    overallReporting: reportData,
    engagementDetails,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setGradeBand,
  } = useReportingProvider();

  return (
    <div className="overall-engagement">
      <div className="left">
        <h1>
          Overall <br />
          <b>Engagement</b>
        </h1>
        <p>
          This is an accumulative listing of all engagement across&nbsp;
          {userType === "all" ? <b>all user types</b> : []}
          {userType === "registered" ? <b>all registered users</b> : []}
          {userType === "validated" ? <b>all verified users</b> : []}
          {userType === "anonymous" ? <b>all anonymous users</b> : []}
        </p>
      </div>
      <div className="right">
        <div className="item big-num">
          <span className="num report-download" onClick={() => downloadReportCsv("", "/%")}>
            {engagementDetails.pageviewCount ? engagementDetails.pageviewCount : 0}
          </span>
          <span className="small-text">
            Total
            <br />
            Engagements
          </span>
        </div>
        <div className="small-nums">
          <div className="item">
            <span className="num">{reportData.activeUserCount}</span>
            <span className="small-text">Total Users Active</span>
          </div>
          <div className="item">
            <span className="num">{reportData.totalUserCount}</span>
            <span className="small-text">Total Users Accounts</span>
          </div>
          <div className="select-filters">
            <div>
              <select onChange={e => setGradeBand(e.target.value)}>
                {availableGradeBands
                  .sort()
                  .reverse()
                  .map((gradeBand: any) => {
                    return (
                      <option key={gradeBand.id} value={gradeBand.id}>
                        {gradeBand.grade_band}
                      </option>
                    );
                  })}
                <option value="all" selected>
                  All Grade Bands
                </option>
              </select>
            </div>
            <div>
              From:{" "}
              <DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} />
              <CalendarIcon />
            </div>
            <div>
              To: <DatePicker selected={endDate} onChange={(date: Date) => setEndDate(date)} />
              <CalendarIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
