import Input from "../../../components/input/Input";
import Section from "../../../components/section/Section";
import React from "react";
import { useFormikContext } from "formik";
import { IAlumniEditor } from "../../../hooks/AlumniEditor";
import { IAlumniBuilder } from "../../../models/alumni";


export const AboutSection = ({ editor }: { editor: IAlumniEditor }) => {
  const { values } = useFormikContext<IAlumniBuilder>();

  return (
    <Section
      title="About"
      sectionId="profileEditorBuilderAbout"
      titleSize="lg"
      className="profileEditor-builder-about"
      isEditable={true}
    >
      <Input
        id="profileEditor-builder-about-me"
        variant="secondary"
        as="textarea"
        maxLength={1024}
        label="About Me"
        className="profileEditor-builder-textarea"
        placeholder=""
        value={
          (values?.about?.fields ?? []).find((entry: any) => entry.name === "about_me")?.value ?? ""
        }
        onChange={(e: any) => editor.onChangeAboutEntry("about_me", e)}
      />
      <Input
        id="profileEditor-builder-fav-experience"
        variant="secondary"
        as="textarea"
        maxLength={1024}
        label={`Favorite Experience at ${editor.schoolName}`}
        className="profileEditor-builder-textarea"
        placeholder=""
        value={
          (values.about?.fields ?? []).find((entry: any) => entry.name === "favorite_experience")
            ?.value ?? ""
        }
        onChange={(e: any) => editor.onChangeAboutEntry("favorite_experience", e)}
      />
    </Section>
  )
}