import React, { useRef, useEffect, useState } from 'react';
import DropdownList from 'react-widgets/DropdownList';
import { pick, isEmpty, uniqBy } from 'lodash';

import If from '../If';
import { IUserV2 } from 'app/models/user';
import {
  getAvailableUsersFor,
  getAvailableStaffUsers,
  getAvailableGraduateUsers,
} from 'app/api/adminApis';
import { emptyFunction } from 'app/utils/common';
import './user-account-dropdown-input.scss';

const UserAccountDropDownInput = <T,>({
  id,
  options,
  name,
  label,
  value,
  key,
  scope = 'all',
  excludeAccounts = [],
  dataKey,
  textField,
  onSelect,
  onSearch,
  onChange = emptyFunction,
  onCreate = emptyFunction,
  searchTerm,
  className = '',
  required = false,
  persistedUser = {},
}: any) => {
  const [userOptions, setUserOptions] = useState<Partial<IUserV2>[] | null>(
    null
  );

  useEffect(() => {
    _getProgramUsers();
  }, []);

  const _getProgramUsers = async () => {
    try {
      const api =
        scope === 'staff'
          ? getAvailableStaffUsers
          : scope === 'graduate'
          ? getAvailableGraduateUsers
          : getAvailableUsersFor;

      const { data: users } = await api(scope);
      setUserOptions(users ?? []);
    } catch (error) {
      console.log('🚀 ~ file: data.ts ~ getAllUsers= ~ error', error);
      setUserOptions([]);
    }
  };

  const _formatUserOptions = () => {
    const options = !isEmpty(persistedUser)
      ? [...(userOptions || []), persistedUser]
      : userOptions || [];
    const allOptions = uniqBy(options, (option) => option.id);
    return isEmpty(excludeAccounts)
      ? allOptions
      : allOptions.filter((option) => !excludeAccounts.includes(option.id));
  };

  const allUserOptions = _formatUserOptions();

  return (
    <div key={key} className={`t12-drop-down-input ${className}`}>
      <If condition={!!label}>
        <label htmlFor=''>
          {label} {required ? '*' : ''}
        </label>
      </If>
      <DropdownList
        data={allUserOptions}
        busy={!userOptions}
        name={name}
        value={value}
        dataKey={dataKey || 'id'}
        textField={textField}
        searchTerm={searchTerm}
        onSelect={onSelect}
        onSearch={onSearch}
        onChange={onChange}
        onCreate={onCreate}
        onBlur={() => console.log('On Blur called')}
      />
    </div>
  );
};

export interface IProgramUserOptions {
  id: number;
  name: string;
  email: string;
}

export default UserAccountDropDownInput;
