import { useMemo, useRef } from "react";
import { listAlumniProfiles } from "app/api/adminApis";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import ROUTES from "app/constants/navigation";
import { appStateSelectors, useAppState } from "app/state/AppState";
import { useNavigate } from "react-router-dom";
import usePagination from "./Pagination";
import { AccountNotificationPopupRef } from "app/components/accountNotification/AccountNotificationPopup";
import * as AppUtils from "app/utils/common";
import { IAlumniListItem } from "../models/alumni";

export const useAlumniListing = () => {
  const navigate = useNavigate();

  const subscriptionModalRef = useRef<AccountNotificationPopupRef>(null);

  const _pagination = usePagination<IAlumniListItem>({
    listFn: listAlumniProfiles,
  });

  const grades = useAppState(appStateSelectors.gradeBands);
  const roles = useAppState(appStateSelectors.roles);
  const preferences = useAppState(appStateSelectors.preferences)?.filter(
    pref => pref.category === "Contact"
  );

  const classYears = useMemo(() => {
    let years = [];
    for (let i = 1960; i <= 2023; i++) {
      years.push({ id: i, label: i });
    }

    return years;
  }, []);

  // TODO: need to fix the action
  const onToggleActive = (alumniList: IAlumniListItem) => async (checked: boolean) => {
    try {
      const formData = new FormData();
      AppUtils.buildFormData(formData, { is_enabled: checked }, null);
      formData.append("_method", "PUT");

      _pagination.updateData(alumniList.id, "id", { is_enabled: checked });
    } catch (error) {
      console.log("🚀 ~ file: AlumniListing.ts ~ const_changeActiveStatus= ~ error", error);
    }
  };

  const onGridAction = (action: DATA_GRID_ACTIONS, id: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.UNSUBSCRIBE:
        const staff = _pagination.data.find((item: IAlumniListItem) => item.id === id);
        staff && subscriptionModalRef?.current?.show(id, staff);
        break;
      case DATA_GRID_ACTIONS.EDIT:
        navigate(`${ROUTES.ALUMNI_PROFILE_EDITOR}/${id}`);
        break;
      default:
        break;
    }
  };

  const processFilters = (filters: Record<string, number[]>) => {
    const paramMap = [
      { id_param: "preference_ids", param: "preferences" },
      { id_param: "grade_band_ids", param: "grade_bands" },
    ];

    // delete filters.preference_ids;
    // delete filters.grade_band_ids;

    return paramMap.reduce((final, filter) => {
      if (filters[filter.id_param]) {
        const ids = filters[filter.id_param];
        ids.forEach(() => {});
        final[`filter[${filter.param}][has][id][in]`] = ids;
      }

      return final;
    }, {} as any);
  };

  const onFilterChange = (filters: Record<string, number[]>) => {
    const queryFilters = processFilters(filters);
    _pagination.onFilterChange({
      ...filters,
      ...queryFilters,
    });
  };

  return {
    ..._pagination,
    grades,
    roles,
    classYears,
    preferences,
    onFilterChange,
    onToggleActive,
    onGridAction,
    subscriptionModalRef,
  };
};
