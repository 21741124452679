import { api } from "../adminApis";
import { IAPIResponseV2 } from "../../models/api";
import { ScriptsImporterInterface } from "../../models/scripts-importer";

export const postScriptImporter = (body: object) => {
  return api.post(`script-importers`, body);
};

export const updateScriptImporter = (programId: number, body: object) => {
  return api.put(`/script-importers/${programId}`, body);
};

export const fetchScriptImporter = () => {
  return api.get<IAPIResponseV2<ScriptsImporterInterface[]>>("/script-importers");
};

export const deleteScriptImporter = (id: number) => {
  return api.delete(`/script-importers/${id}`);
};
