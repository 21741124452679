import React, { FormEvent, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { Form } from "formik";

import Input from "app/components/input/Input";
import Section from "app/components/section/Section";
import UploadPopupV2 from "../sitePhotos/uploadPopup/UploadPopupV2";
import { IMediaSelected } from "app/models/media";
import AddressPopup, { AddressPopupRef } from "./components/AddressPopup";
import DragFileUpload from "app/components/dragFileUpload/DragFileUpload";
import { getErrors } from "app/utils/data";
import { useOutletContext } from "react-router-dom";
import { ILocation, ProgramDetails } from "../../models/config";
import { buildJsonFromForm } from "../../utils/objectBuilder";
import Switch from "../../components/switch/Switch";

export const ProgramDetailsSection = () => {
  const { editor, values, touched, errors } = useOutletContext<any>();
  const addressRef = useRef<AddressPopupRef>(null);
  const ref = useRef<HTMLFormElement>(undefined!);

  function _formatSchoolAddress() {
    const location = values?.school?.location;
    if (!location) return "";

    return `${location.line1 || ""}\n${location.line2 || ""}\n${location.city || ""}, ${location.subdivision || ""}, ${location.code || ""}`;
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (ref.current) {
      const data = buildJsonFromForm(ref.current);

      const patchProgramDetails: ProgramDetails = {
        ...data,
        logo: values.logo,
        default_logo: values.default_logo,
        logo_image_id: values.logo_image_id,
        enroll_url: values.enroll_url,
        school: values.school,
        is_program_active: values.is_program_active ? 1 : 0,
      };
      await editor?.onSubmit?.(patchProgramDetails);
    }
  };

  const onAddressChange = (location: ILocation) => {
    editor.formRef?.current?.setValues((prevValues: any) => ({
      ...prevValues,
      school: {
        ...(prevValues.school || {}),
        location: {
          ...(prevValues?.school?.location || {}),
          ...location,
        },
      },
    }));
  };

  return (
    <Form ref={ref} id="config-form-submit" onSubmit={onSubmit}>
      <Section
        title="Program Details"
        sectionId="configuration-editor-details"
        titleSize="md"
        className="configuration-editor-details mt-4"
      >
        <Row>
          <Col>
            <div className="admin-editor-toggle">
              <p>{values.is_program_active ? "Deactivate" : "Activate"} program</p>
              <Switch
                checked={values.is_program_active}
                onChange={() => {
                  if (values.is_program_active) {
                    if (confirm("Are you sure you want to deactivate program?")) {
                      editor.onChange("is_program_active")(!values.is_program_active);
                    }
                  } else {
                    editor.onChange("is_program_active")(!values.is_program_active);
                  }
                }}
                switchId="admin-editor-live-btn"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              id="configuration-editor-details-program-name"
              label="Program Name"
              variant="secondary"
              name="name"
              error={getErrors(touched, errors, "name")}
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-details-school-name"
              label="School Name"
              variant="secondary"
              name="school.name"
              error={getErrors(touched, errors, "school.name")}
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-details-program-abbreviation"
              label="School Abbreviation"
              variant="secondary"
              name="school.abbreviation"
              error={getErrors(touched, errors, "school.abbreviation")}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              disabled
              id="configuration-editor-details-school-url"
              label="Think-12 URL"
              variant="secondary"
              name="program_url"
              error={getErrors(touched, errors, "program_url")}
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-details-school-url"
              label="Client URL"
              variant="secondary"
              name="client_url.client_url"
              error={getErrors(touched, errors, "client_url")}
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-details-school-tagline"
              label="Enter School Tagline"
              variant="secondary"
              name="school.tagline"
              error={getErrors(touched, errors, "school.tagline")}
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-details-family-ambassador"
              label="Family Ambassador Name or Title"
              variant="secondary"
              name="family_ambassador_name"
              error={getErrors(touched, errors, "family_ambassador_name")}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              id="configuration-editor-details-program-phone"
              label="School Phone Number"
              variant="secondary"
              name="school.phone_number"
              error={getErrors(touched, errors, "school.phone_number")}
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-details-program-contact-email"
              label="School Contact Email"
              variant="secondary"
              name="school.email"
              error={getErrors(touched, errors, "school.email")}
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-details-program-mascot"
              label="School Mascot"
              variant="secondary"
              name="school.mascot"
              error={getErrors(touched, errors, "school.mascot")}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DragFileUpload
              id="configuration-editor-details-logo"
              label="Upload School Logo"
              existingImage={values.logo?.path}
              onClick={() => editor?.uploadLogoRef?.current?.show()}
            />
            <UploadPopupV2
              id="configuration-editor-upload-logo"
              onConfirm={() => {}}
              ref={editor?.uploadLogoRef}
              mediaType="IMAGE"
              autoUpload={false}
              owner={{
                id: editor?.appConfig?.id,
                type: "Program",
              }}
              onFileSelect={(
                _,
                file?: File,
                tmpFileStr?: string,
                folderId?: number,
                existingMedia?: IMediaSelected
              ) => {
                if (file && folderId) {
                  editor?.onMediaUpload?.("logo", {
                    file,
                    tmpFileUrl: tmpFileStr ?? "",
                    folderId,
                  });
                } else if (existingMedia) {
                  editor?.onMediaUpload?.("logo", undefined, {
                    media: existingMedia,
                  });
                }
              }}
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-details-program-description"
              label="School Description"
              as="textarea"
              variant="secondary"
              name="description"
              value={values?.description ?? ""}
              error={getErrors(touched, errors, "description")}
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-details-program-address"
              label="School Address"
              as="textarea"
              variant="secondary"
              value={_formatSchoolAddress()}
              onClick={() => {
                const locationDetails = values?.school?.location || {};
                addressRef?.current?.show?.(locationDetails);
              }}
              error={getErrors(touched, errors, "school.location.line1")}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Input
              id="configuration-editor-details-top-banner-1-content"
              label="Top Banner Link 1 Content"
              as="textarea"
              variant="secondary"
              name="top_banner_link_1_content"
              value={values?.top_banner_link_1_content ?? ""}
              error={getErrors(touched, errors, "top_banner_link_1_content")}
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-details-top-banner-1-url"
              label="Top Banner Link 1 Url"
              as="textarea"
              variant="secondary"
              name="top_banner_link_1_url"
              value={values?.top_banner_link_1_url ?? ""}
              error={getErrors(touched, errors, "top_banner_link_1_url")}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Input
              id="configuration-editor-details-top-banner-2-content"
              label="Top Banner Link 2 Content"
              as="textarea"
              variant="secondary"
              name="top_banner_link_2_content"
              value={values?.top_banner_link_2_content ?? ""}
              error={getErrors(touched, errors, "top_banner_link_2_content")}
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-details-top-banner-2-url"
              label="Top Banner Link 2 Url"
              as="textarea"
              variant="secondary"
              name="top_banner_link_2_url"
              value={values?.top_banner_link_2_url ?? ""}
              error={getErrors(touched, errors, "top_banner_link_2_url")}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Input
              id="configuration-editor-details-program-verification-code"
              style={{ minHeight: "4em" }}
              label="Verification Code"
              as="textarea"
              variant="secondary"
              name="verification_code"
              value={values["verification_code"]}
              // @ts-ignore
              onChange={(e: any) => editor?.onVerificationCodeChange?.(e.target.value)}
              error={getErrors(touched, errors, "verification_code")}
            />
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Input
              id="configuration-editor-details-program-footer"
              style={{ minHeight: "4em" }}
              label="Footer"
              as="textarea"
              variant="secondary"
              name="custom_footer"
              value={values["custom_footer"]}
              // @ts-ignore
              onChange={(e: any) => editor?.onCustomFooterChange?.(e.target.value)}
              error={getErrors(touched, errors, "custom_footer")}
            />
          </Col>
        </Row>
        <hr className="mt-5" />
        <AddressPopup
          id="configuration-editor-address-popup"
          ref={addressRef}
          onLocationChange={onAddressChange}
          location={values.school?.location}
        />
      </Section>
    </Form>
  );
};
