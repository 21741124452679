import React from 'react';
import { Col, Row } from 'react-bootstrap';
import If from '../If';
import Input from '../input/Input';
import RoundButton from 'app/components/button/RoundButton';
import { ReactComponent as IcClose } from 'app/assets/icons/close.svg';
import './profession-editor.scss';
import { useFormikContext } from "formik";
import { DropDownType, IndustryList } from "../../constants/constants";
import DropDown from "../dropDown/DropDown";

export const ProfessionalExperience = ({
  index,
  label,
  onDelete,
}: IProfessionEditorProps) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const professionId = 'profession-editor';

  return (
    <div className='education-editor-container'>
      <If condition={!!label}>
        <div className='education-member'>{`${label} ${index + 1}`}</div>
      </If>
      <Row className='align-items-center'>
        <Col className={"education-name-entry"}>
          <DropDown<DropDownType>
            id='configuration-editor-state-member'
            label="Industry"
            labelAttribute='label'
            placeholder="Industry"
            valueAttribute='value'
            transformLabel={(option: DropDownType) => option.label}
            options={IndustryList.map(state => state)}
            selected={values.professions[index].industry}
            onOptionChange={value => setFieldValue(`professions[${index}].industry`, value)}
          />
        </Col>
        <Col>
          <Input
            id={`${professionId}-company`}
            label='Company'
            variant='secondary'
            name={`professions[${index}].company`}
          />
        </Col>
        <Col>
          <Input
            id={`${professionId}-job_title`}
            label='Job title'
            variant='secondary'
            name={`professions[${index}].job_title`}
          />
        </Col>
        <Col md='auto'>
          <div className='d-flex align-items-center'>
            <RoundButton
              roundBtnId={`${index}-education-delete`}
              icon={<IcClose />}
              className='button'
              onClick={() => onDelete && onDelete(index)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProfessionalExperience;

interface IProfessionEditorProps {
  index: number;
  label?: string;
  onDelete?: (index: number) => void;
}
