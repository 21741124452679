import React from "react";
import "./CustomModal.scss";
import StandardButton from "../../../../components/button/StandardButton"; // Assuming you have a CSS file for styles

export const CustomModal = ({ show, onClose, children }: any) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <button className="modal-close-button" onClick={onClose}>
          X
        </button>
        {children}

        <div className="modal-action-button">
          <StandardButton
            onClick={onClose}
            variant="secondary"
            label="Save"
            standardBtnId="t12-confirm-btn"
          />
        </div>
      </div>
    </div>
  );
};
