import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHref } from 'react-router-dom';
import moment from 'moment';

import T12Modal, { T12ModalRef } from 'app/components/modal/T12Modal';
import Input from 'app/components/input/Input';
import { IEmailScheduleItemBuilder } from 'app/models/email';
import { IEmailScheduleEditor } from 'app/hooks/EmailScheduleEditor';
import {
  formatGradeBandIds,
  formatRoleIds,
  formatEmailsScheduleLimit,
} from 'app/utils/data';
import ROUTES from 'app/constants/navigation';
import { ReactComponent as IcPreview } from 'app/assets/icons/preview.svg';
import { ReactComponent as IcEmail } from 'app/assets/icons/email-duplicate.svg';
import './email-schedule-review-popup.scss';

import { getApiDomain } from 'app/utils/common';

import { getEmailSchedulePreview } from 'app/api/adminApis'

const EmailScheduleReviewPopup = React.forwardRef<
  ConfirmPopupRef,
  IEmailScheduleReviewPopupProps
>(({ id, editor, onCancel, onConfirm, onShow, schedule }, ref) => {
  const modalRef = useRef<T12ModalRef>(null);

  const previewHref = useHref(ROUTES.EMAIL_PREVIEW);

  const [preview, setPreview] = useState<any>(null);

  useImperativeHandle(
    ref,
    () => ({
      show: () => {
        modalRef.current?.show();
      },
      close: () => {
        modalRef.current?.close();
      },
    }),
    []
  );
  
  const _onPreview = () => {
    if (!schedule?.template?.id) return;

    const hostname = window.location.hostname;
    const domain = window.location.host;
    const protocol = hostname === 'localhost' ? 'http' : 'https';

    window.open(
      `${protocol}://${domain}/${previewHref}/${schedule.template.id}`,
      '_blank'
    );
  };

  const _onConfirm = () => onConfirm?.();

  const _onShow  = () => {
    onShow?.();
  };

  const downloadUserList = () => {
    getEmailSchedulePreview({id:schedule.id}).then((res) => {
      //res.data is now the contents of the csv file
      const blob = new Blob([res.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'user_list.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }

  return (
    <T12Modal
      modalId={id}
      ref={modalRef}
      title='Email Schedule Review'
      className='confirm-popup'
      confirmButtonLabel='Confirm & Schedule'
      cancelButtonLabel='Cancel & Edit'
      showHeaderBorder
      size='lg'
      onConfirm={_onConfirm}
      onShow={(_onShow)}
    >
      {schedule && (
        <>
          <Row className='user-select'>
            <Col xs={8} className='preview'>
              <button className='preview-section'>
                <div>
                  <IcPreview onClick={_onPreview} />
                </div>
                <span className='preview-text'>Preview Email</span>
              </button>
            </Col>
            <Col xs={4}>
              <div className='user-reach'>
                <div>
                  <div className='user-reach-count'>
                    {schedule.total_notifiables_count ?? 0}
                  </div>
                  <div className='user-reach-message'>
                    Users will receive this email. <br/>
                    <a style={{color:'#999', cursor:'pointer'}} onClick={() => downloadUserList()} target="_blank">Download User List</a>
                  </div>
                </div>
                <IcEmail />
              </div>
            </Col>
          </Row>
          <Row className='user-select'>
            <Col>
              <Input
                id='email-schedule-subject'
                label='Subject Line'
                variant='secondary'
                value={schedule.template?.subject}
              />
            </Col>
          </Row>
          <Row className='user-select'>
            <Col>
              <Input
                id='email-schedule-preheader-text'
                label='Pre-Header Text'
                variant='secondary'
                value={schedule.template?.preheader ?? ''}
              />
            </Col>
          </Row>
          <Row className='user-select'>
            <Col>
              <Input
                id='email-schedule-grade-bands'
                label='Population (Grade Bands)'
                variant='secondary'
                value={formatGradeBandIds(
                  editor.gradeBands,
                  schedule.grade_band_ids ?? []
                )}
              />
            </Col>
          </Row>
          <Row className='user-select'>
            <Col>
              <Input
                id='email-schedule-roles'
                label='Population (Roles)'
                variant='secondary'
                value={formatRoleIds(editor.roles, schedule.roles ?? [])}
              />
            </Col>
          </Row>
          <Row className='user-select'>
            <Col>
              <Input
                id='email-schedule-registration-limit'
                label='Number of days since registration'
                variant='secondary'
                value={formatEmailsScheduleLimit('Registered', schedule.limits)}
              />
            </Col>
          </Row>
          <Row className='user-select'>
            <Col>
              <Input
                id='email-schedule-verifed-limit'
                label='Number of days since validation'
                variant='secondary'
                value={formatEmailsScheduleLimit('Verified', schedule.limits)}
              />
            </Col>
          </Row>
          <Row className='user-select'>
            <Col>
              <Input
                id='email-schedule-authentication-limit'
                label='Number of days since last log in'
                variant='secondary'
                value={formatEmailsScheduleLimit(
                  'Authenticated',
                  schedule.limits
                )}
              />
            </Col>
          </Row>

          <Row className='user-select'>
            <Col>
              <Input
                id='email-schedule-frequency'
                label='Frequency with which an email sent'
                variant='secondary'
                value={schedule.delivery_frequency ?? ''}
              />
            </Col>
          </Row>
          <Row className='user-select'>
            <Col>
              <Input
                id='email-schedule-user-frequency'
                label='How many times a user should receive the email'
                variant='secondary'
                value={schedule.recipient_frequency ?? ''}
              />
            </Col>
          </Row>

          <Row className='user-select'>
            <Col>
              <Input
                id='email-schedule-start-at'
                label='When the email should be sent'
                variant='secondary'
                value={moment(schedule.start_at).format(
                  'MMM D yyyy [at] hh:mm a'
                )}
              />
            </Col>
          </Row>
          {schedule.end_at && (
            <Row className='user-select'>
              <Col>
                <Input
                  id='email-schedule-end-at'
                  label='Stop sending on'
                  variant='secondary'
                  value={moment(schedule.end_at).format(
                    'MMM D yyyy [at] hh:mm a'
                  )}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </T12Modal>
  );
});

export default EmailScheduleReviewPopup;

interface IEmailScheduleReviewPopupProps {
  id: string;
  editor: IEmailScheduleEditor;
  onConfirm?: () => void;
  onCancel?: () => void;
  onShow?: () => void;
  schedule: IEmailScheduleItemBuilder;
}

interface ShowOptions {
  schedule: IEmailScheduleItemBuilder;
}

export type ConfirmPopupRef = {
  show: (schedule: IEmailScheduleItemBuilder) => void;
  close: () => void;
} | null;
