import { Col, Row } from "react-bootstrap";
import Input from "../../../components/input/Input";
import { getErrors } from "../../../utils/data";
import Checkbox from "../../../components/checkbox/CheckBox";
import DropDownBase from "../../../components/dropDown/DropDownBase";
import { YEARS } from "../../../constants/common";
import Section from "../../../components/section/Section";
import React from "react";
import { useFormikContext } from "formik";
import { IAlumniBuilder } from "../../../models/alumni";
import { LocationRow } from "../../../components/LocationSection/LocationRow";
import { IAlumniEditor } from "../../../hooks/AlumniEditor";
import { DropDownType, SchoolActivitiesList, VolunteerActivitiesList } from "../../../constants/constants";
import { MultiDropdown } from "../../../components/dropDown/MultipleDropDown";


export const GeneralSection = ({editor}: { editor: IAlumniEditor }) => {
  const {touched, errors, values, setValues} = useFormikContext<IAlumniBuilder>();

  const onSelectValue = (key: "open_volunteering" | "school_activities", value: string[]) => {
    setValues({ ...values, [key]: { ...values[key], name: value }})
  }

  return (
    <Section
      title="General"
      sectionId="staffBuilderGeneral"
      titleSize="lg"
      className="staff-builder-general mt-4"
      isEditable={true}
    >
      <Row className="mt-4">
        <Col>
          <Input
            id="profile-editor-builder-first-name"
            variant="secondary"
            label="Name*"
            placeholder="Enter your name here"
            name="first_name"
            error={getErrors(touched, errors, "first_name")}
          />
        </Col>
        <Col>
          <Input
            id="profile-editor-builder-last-name"
            variant="secondary"
            label="Last Name*"
            placeholder="Enter your last name here"
            name="last_name"
            error={getErrors(touched, errors, "last_name")}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={8}>
          <Input
            id="profile-editor-builder-email"
            variant="secondary"
            label="Email Address*"
            name="email"
            error={getErrors(touched, errors, "email")}
          />
          <Checkbox
            id="primaryEmailConsent"
            label="Allow this email addresses to be visible in my profile"
            checked={(values?.is_primary_email_visible ?? 0) === 1}
            onChange={editor.onChange("is_primary_email_visible")}
            className="profile-editor-builder-checkbox mb-5"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <DropDownBase<number | null>
            id="profile-editor-builder-class"
            options={YEARS.map(year => +year)}
            labelOnly
            selected={values.attending_school_since}
            placeholder="Select Year"
            label="Class "
            onOptionChange={editor.onChange("attending_school_since")}
          />
        </Col>
        <Col>
          <MultiDropdown
            id="profile-editor-builder-class"
            label="School activities I participated in"
            labelAttribute="label"
            valueAttribute="value"
            options={SchoolActivitiesList}
            selected={values.school_activities?.name || []}
            onOptionChange={value => onSelectValue("school_activities", value as string[])}
            multiple
          />
        </Col>
        <Col>
          <MultiDropdown<DropDownType>
            id="article-editor-approval-status"
            label="Open to volunteering"
            placeholder="Open to volunteering"
            labelAttribute="label"
            valueAttribute="value"
            options={VolunteerActivitiesList}
            selected={values.open_volunteering?.name || []}
            multiple
            onOptionChange={value => onSelectValue("open_volunteering", value as string[])}
          />
        </Col>
      </Row>
      <LocationRow />
      <Row>
        <Col>
          <Input
            id="profile-editor-builder-linkedin"
            variant="secondary"
            label="LinkedIn Profile"
            placeholder=""
            name="social_media_accounts[0].url"
            onChange={editor.onChangeSocialMedia}
          />
        </Col>
      </Row>
      <hr className="mb-5 mt-4" />
    </Section>
  )
}