import React from "react";

export const ButtonIcon = () => (
  <svg width="30px" height="30px" xmlns="http://www.w3.org/2000/svg">
    <rect x="0" y="0" width="30" height="30" fill="#fff" stroke="black" strokeWidth="2" />
    <text
      x="15"
      y="15"
      dominantBaseline="middle"
      textAnchor="middle"
      fontFamily="Arial"
      fontSize="18"
      fill="black"
    >
      B
    </text>
  </svg>
);
