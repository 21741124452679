import React from "react";

const DataColumn = <T,>({}: DataColumnProps<T>) => {
  return <></>;
};

export default DataColumn;

export interface IDataColumnValueProps<T> {
  header?: string;
  attributeName: keyof T;
  transform?: (data: T) => JSX.Element | string;
}

export interface IDataColumnActionProps<T> {
  attributeName: "GRID.ACTIONS";
  action: DATA_GRID_ACTIONS;
  transform?: (data: T) => JSX.Element | string;
}

export type DataColumnProps<T> = IDataColumnValueProps<T> | IDataColumnActionProps<T>;

export enum DATA_GRID_ACTIONS {
  PREVIEW = "PREVIEW",
  SEND = "SEND",
  EDIT = "EDIT",
  PREVIOUS = "PREVIOUS",
  DELETE = "DELETE",
  DUPLICATE = "DUPLICATE",
  EDIT_SCHEDULE = "EDIT SCHEDULE",
  UNSUBSCRIBE = "UNSUBSCRIBE",
  CUSTOM = "CUSTOM",
  COPY = "COPY",
}
