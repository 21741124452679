import StandardButton from "app/components/button/StandardButton";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import DataGrid from "app/components/dataGrid/DataGrid";
import Filter from "app/components/filter/Filter";
import FilterOption from "app/components/filter/FilterOption";
import SearchBar from "app/components/searchBar/SearchBar";
import Switch from "app/components/switch/Switch";
import useClubManagerListing from "app/hooks/ClubManagerListing";
import PrivateLayout from "app/layout/PrivateLayout";
import { IClub } from "app/models/clubs";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./club.scss";

const GRID_ID = "club-listing";

const ClubManager: React.FC = () => {
  const _clubManagerListing = useClubManagerListing();
  const navigate = useNavigate();
  return (
    <PrivateLayout contentClassName="clubmanager-listing">
      <StandardButton
        label="+ New Club"
        variant="secondary"
        className="new-club-button"
        onClick={_clubManagerListing.onNewClubClick}
        standardBtnId="clubmanager-listing-new-btn"
      />
      <h3 className="title">Club Manager</h3>
      <SearchBar
        placeHolder="Search by club name or associated staff member"
        searchBarId="club-listing-search"
        onChangeText={_clubManagerListing.onSearchKeyChange}
        onSearch={_clubManagerListing.onSearch}
      />
      {/* TODO: This is for sample only. Remove this after filter is created in other pages */}
      <Filter
        id="club-listing-filter"
        onFilterChange={_clubManagerListing.onFilterChange}
        selectedFilters={_clubManagerListing.filters}
      >
        <FilterOption<any>
          labelAttribute="status"
          valueAttribute="clubActiveStatus"
          name="active"
          options={[
            {
              status: "Active",
              clubActiveStatus: 1,
            },
            {
              status: "Inactive",
              clubActiveStatus: 0,
            },
          ]}
          placeholder="Active Status"
        />
      </Filter>
      <DataGrid<IClub>
        data={_clubManagerListing.data}
        gridId={GRID_ID}
        rowIdAttribute="id"
        currentPage={_clubManagerListing.currentPage}
        onPageChange={_clubManagerListing.onPageChange}
        totalItems={_clubManagerListing.totalItems}
        onAction={_clubManagerListing.onGridAction}
      >
        <DataGrid.Column<IClub> attributeName="name" header="CLUB NAME" />
        <DataGrid.Column<IClub> attributeName="id" header="CLUB ID" />
        <DataGrid.Column<IClub> attributeName="clubLeaderName" header="CLUB LEADER NAME" />
        <DataGrid.Column<IClub>
          attributeName="clubActiveStatus"
          header="ACTIVE"
          transform={club => (
            <Switch
              checked={club.clubActiveStatus}
              onChange={_clubManagerListing.onToggleStatus(club)}
              switchId="club-listing-live-btn"
            />
          )}
        />
        <DataGrid.Column<IClub>
          attributeName="featured"
          header="FEATURED"
          transform={club => (
            <Switch
              checked={club.featured}
              onChange={_clubManagerListing.onToggleFeatured(club)}
              switchId="club-listing-live-btn"
            />
          )}
        />

        <DataGrid.Column<IClub> action={DATA_GRID_ACTIONS.PREVIEW} attributeName="GRID.ACTIONS" />
        <DataGrid.Column<IClub> attributeName="GRID.ACTIONS" action={DATA_GRID_ACTIONS.EDIT} />
        <DataGrid.Column<IClub> action={DATA_GRID_ACTIONS.DELETE} attributeName="GRID.ACTIONS" />
      </DataGrid>
    </PrivateLayout>
  );
};

export default ClubManager;
