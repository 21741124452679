import React, { useEffect, useState } from "react";
import PrivateLayout from "app/layout/PrivateLayout";
import StandardButton from "app/components/button/StandardButton";
import RoundButton from "app/components/button/RoundButton";
import { debounce } from "lodash";

import {
  createHomepageContent,
  listHomepageContent,
  updateHomepageContent,
  deleteHomepageContent,
} from "app/api/adminApis";

import CustomHtmlEditor from "app/components/outcomesEditor/OutcomesCustomHtml";
import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import { ReactComponent as IcTrash } from "app/assets/icons/delete.svg";
import { CarouselConfiguration } from "./CarouselConfiguration";
import { LogoConfigurator } from "../LogoConfigurator/LogoConfigurator";
import { Tabs } from "../../components/tabs/Tabs";

const HomepageManager: React.FC = () => {
  const locations = [
    { label: "Hero", active: "hero" },
    { label: "Carousel", active: "carousel" },
    { label: "Logo", active: "logo" },
    { label: "Questions", active: "questions" },
    { label: "Students videos", active: "students_videos" },
    { label: "Ambassadors", active: "ambassadors" },
    { label: "Explore", active: "explore" },
    { label: "Enroll", active: "enroll" },
  ];

  const [contents, setContents] = useState<any[]>([]);
  const [activeLocation, setActiveLocation] = useState(locations[0].active);
  const [activeContent, setActiveContent] = useState<any | null>(null);

  useEffect(() => {
    loadAll();
  }, []);

  useEffect(() => {
    const contentForLocation = contents.find(content => content.location === activeLocation);

    setActiveContent(contentForLocation || null);
  }, [activeLocation, contents]);

  const loadAll = () => {
    listHomepageContent().then(response => {
      setContents(response.data);
    });
  };

  const handleAddContent = () => {
    const existingContent = contents.find(content => content.location === activeLocation);
    if (existingContent) {
      return;
    }
    createHomepageContent({ location: activeLocation }).then(() => {
      loadAll();
    });
  };

  const handleRemoveContent = (content: any) => {
    deleteHomepageContent(content).then(() => {
      loadAll();
    });
  };

  const handleSave = debounce((content: any) => {
    //find this content in contents and update it there
    const index = contents.findIndex(c => c.id === content.id);
    if (index > -1) {
      const newContents = [...contents];
      newContents[index] = content;
      setContents(newContents);
    }
    updateHomepageContent(content).then(response => {
      console.log(response);
    });
  }, 400);

  return (
    <PrivateLayout>
      <h1 style={{ marginBottom: "50px" }}>Homepage Manager</h1>
      <div className="homepage-editor">
        <Tabs
          activeLabel={activeLocation}
          onClick={active => setActiveLocation(active)}
          tabs={locations}
        />

        {activeLocation === "carousel" && <CarouselConfiguration />}
        {activeLocation === "logo" && <LogoConfigurator type="homepage" />}

        {activeContent ? (
          <>
            <CustomHtmlEditor
              value={activeContent.content}
              onEditorChange={newContent =>
                setActiveContent({ ...activeContent, content: newContent })
              }
            />
            <div
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <StandardButton
                standardBtnId="outcomes-editor-save-btn"
                label="Save"
                variant="secondary"
                type="submit"
                onClick={() => handleSave(activeContent)}
              />
            </div>
            <div
              style={{
                marginTop: "-30px",
                paddingBottom: "100px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <RoundButton
                onClick={() => handleRemoveContent(activeContent)}
                icon={<IcTrash color={"#fff"} />}
                variant="secondary"
                roundBtnId={"remove-content"}
                label="Remove homepage content"
              />
            </div>
          </>
        ) : (
          (activeContent !== "logo" || activeContent !== "carousel") &&
          !activeContent && (
            <div style={{ paddingTop: "100px", display: "flex", justifyContent: "center" }}>
              <RoundButton
                onClick={handleAddContent}
                icon={<IcPlus />}
                variant="primary"
                roundBtnId={"add-content"}
                label="Add homepage content"
              />
            </div>
          )
        )}
      </div>
    </PrivateLayout>
  );
};

export default HomepageManager;
