import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import RoundButton from "app/components/button/RoundButton";
import If from "app/components/If";
import React, { useState, useRef, useEffect } from "react";
import T12Modal, { T12ModalRef } from "app/components/modal/T12Modal";
import Input from "app/components/input/Input";
import { Col, Row } from "react-bootstrap";
import DragFileUpload from "app/components/dragFileUpload/DragFileUpload";
import { searchUsers } from "app/api/adminApis";
import { ImageUploader } from "app/components/imageUploader/ImageUploader";
import ImageUploaderRef from "app/components/imageUploader/ImageUploaderRef";
import { ReactComponent as IcClose } from "app/assets/icons/close.svg";
import placeholder from "app/assets/images/placeholder.png";
import "./club-components.scss";

import debounce from "lodash/debounce";

const leader: React.FC<IleaderProps> = props => {
  const modalRef = useRef<T12ModalRef>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [leader, setLeader] = useState<any>(
    props.leader || {
      user: null,
      id: null,
      image: null,
      title: "",
    }
  );

  useEffect(() => {
    doSearch();
  }, []);

  useEffect(() => {
    if (props.leader) setLeader(props.leader);
  }, [props.leader]);

  const show = () => {
    modalRef.current?.show();
    setModalOpen(true);
  };

  const setDefaultLeader = () => {
    let def: any = {
      user: null,
      id: null,
      image: null,
      title: "",
    };
    setLeader(def);
    if (props.onChange) props.onChange(def);
  };

  const doSearch = debounce(() => {
    searchUsers({
      page: 0,
      limit: 10,
      searchKey: search,
      roles: "Club",
    }).then(res => {
      setSearchResults(res.data);
    });
  }, 250);

  const selectUser = (user: any) => {
    setSelectedUser(user);
  };

  const confirmLeader = () => {
    let l = {
      ...leader,
      user_id: selectedUser.id,
      user: selectedUser,
    };
    setLeader(l);
    if (props.onChange) props.onChange(l);
  };

  const imageUploaderRef = useRef<ImageUploaderRef>(null);

  return (
    <div className={`staff-member club-leader-container ${props.className}`}>
      <If condition={!modalOpen}>
        <If condition={leader?.user}>
          <div>
            <img src={leader.image ? leader.image.path : placeholder} alt={leader?.user} />
            <RoundButton
              className={"club-leader-remove"}
              variant="primary"
              roundBtnId={`delete`}
              icon={<IcClose style={{ stroke: "white" }} />}
              onClick={() => setDefaultLeader()}
            />
            <div className="club-leader-text">
              <span>
                {leader.user?.first_name} {leader.user?.last_name}
                {leader.title ? " (" + leader.title + ")" : ""}
              </span>
            </div>
          </div>
        </If>
        <If condition={!leader.user}>
          <div className={`empty ${props.size}`}>
            <RoundButton
              icon={<IcPlus />}
              variant="primary"
              roundBtnId={`add-staff`}
              label="Add Club Leader"
              onClick={() => show()}
            />
          </div>
        </If>
      </If>
      <T12Modal
        size={"lg"}
        modalId={"add-staff"}
        ref={modalRef}
        // title='This is a title'
        title={"Add Club Leader"}
        className="staff-member-modal"
        confirmButtonLabel="Confirm"
        cancelButtonLabel="Cancel"
        showHeaderBorder
        onConfirm={() => {
          confirmLeader();
          setModalOpen(false);
        }}
        onHide={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      >
        <div className="staff-member-modal-body">
          <div className="staff-member-image">
            {leader ? (
              !leader.image_id ? (
                <DragFileUpload
                  className="profile-image-upload"
                  id={`profile-image-upload`}
                  label="Drop your file(s)"
                  onDrop={(files: File[]) => {
                    imageUploaderRef.current?.upload("Club Leader", files[0]);
                  }}
                  buttonLabel="browse"
                  multiple
                  useNativeUploader={true}
                />
              ) : (
                <div>
                  <img className="club-profile-image" src={leader.image.path} alt={leader?.user} />
                </div>
              )
            ) : (
              []
            )}

            <ImageUploader
              ref={imageUploaderRef}
              hideProgress={true}
              onUploadComplete={(file: any) => {
                let l = {
                  ...leader,
                  image_id: file.id,
                  image: file,
                };
                setLeader(l);
                if (props.onChange) props.onChange(l);
              }}
            />
          </div>
          <div className="staff-member-search">
            <Input
              value={search}
              onChange={(e: any) => {
                setSearch(e.target.value);
                doSearch();
              }}
              type="text"
              placeholder="Search"
              id="staff-member-search-input"
              label="Search"
            />

            <div className="staff-member-search-results">
              <If condition={searchResults}>
                {searchResults.slice(0, 5).map((result: any, index: number) => {
                  return (
                    <div key={index} className="staff-member-search-result">
                      <div>
                        <input
                          type="radio"
                          checked={selectedUser?.id == result.id}
                          onClick={() => selectUser(result)}
                        />
                      </div>
                      <div>
                        {result.first_name} {result.last_name}
                      </div>
                    </div>
                  );
                })}
              </If>
            </div>

            <br />
            <br />
            <Row>
              <Col sm="6">
                <Input value={selectedUser?.first_name} id="" label="First Name" type="text" />
              </Col>
              <Col sm="6">
                <Input value={selectedUser?.last_name} id="" label="Last Name" type="text" />
              </Col>
            </Row>
            <Input
              value={leader.title}
              onChange={(e: any) =>
                setLeader({
                  ...leader,
                  title: e.target.value,
                })
              }
              id=""
              label="Title"
              type="text"
            />
          </div>
        </div>
      </T12Modal>
    </div>
  );
};

export default leader;

interface IleaderProps {
  id: string;
  className?: string;
  size?: "lg" | "md";
  leader: any;
  onChange?: (staffId: number) => void;
}
