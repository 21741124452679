import React from "react";
import { useNode } from "@craftjs/core";
import styled from "styled-components";
import { IFrameSettings } from "./IFrameSettings";
import { getElementSize } from "../../utils/numToMeasurement";

type IProps = {
  src?: string;
  height?: string | number;
  width?: string | number;
  margin?: any[];
  borderRadius?: string;
};

const StyledVideo = styled.video<IProps>`
  margin: ${({ margin }) => `${margin?.[0]}px ${margin?.[1]}px ${margin?.[2]}px ${margin?.[3]}px`};
  border-radius: ${({ borderRadius }) => getElementSize(borderRadius)};
`;

export const IFrame = ({ src, width, height, ...otherProps }: IProps) => {
  const {
    connectors: { connect },
  } = useNode(node => ({
    selected: node.events.selected,
  }));

  return (
    <iframe
      width={width}
      height={height}
      src={src}
      title="External video"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      data-cy="toolbox-video"
      ref={connect as any}
      {...otherProps}
    ></iframe>
  );
};

IFrame.craft = {
  displayName: "IFrame",
  props: {
    src: "https:example.com",
    width: 150,
    height: 150,
    borderRadius: 0,
    margin: ["0", "0", "0", "0"],
  },
  related: {
    settings: IFrameSettings,
    toolbar: IFrameSettings,
  },
};
