import React from "react";
import { FormikTouched, FormikErrors } from "formik";
import { Col, Row } from "react-bootstrap";

import Input from "app/components/input/Input";
import Section from "app/components/section/Section";
import Checkbox from "app/components/checkbox/CheckBox";
import DropDownBase from "app/components/dropDown/DropDownBase";
import { getErrors } from "app/utils/data";
import { YEARS } from "app/constants/common";

interface IGeneralSection {
  editor: any;
  touched: FormikTouched<any>;
  errors: FormikErrors<any>;
  values: any;
}

export const GeneralSection: React.FC<IGeneralSection> = ({ editor, touched, errors, values }) => {
  return (
    <Section
      title="General"
      sectionId="staffBuilderGeneral"
      titleSize="lg"
      className="staff-builder-general mt-4"
      isEditable={true}
    >
      <Row className="mt-4">
        <Col>
          <Input
            id="profile-editor-builder-first-name"
            variant="secondary"
            label="Name*"
            placeholder="Enter your name here"
            name="first_name"
            error={getErrors(touched, errors, "first_name")}
          />
        </Col>
        <Col>
          <Input
            id="profile-editor-builder-last-name"
            variant="secondary"
            label="Last Name*"
            placeholder="Enter your last name here"
            name="last_name"
            error={getErrors(touched, errors, "last_name")}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Input
            id="profile-editor-builder-email"
            variant="secondary"
            label="Email Address*"
            name="email"
            error={getErrors(touched, errors, "email")}
          />
          <Checkbox
            id="primaryEmailConsent"
            label="Allow this email addresses to be visible in my profile"
            checked={(values?.is_primary_email_visible ?? 0) === 1}
            onChange={editor.onChange("is_primary_email_visible")}
            className="profile-editor-builder-checkbox mb-5"
          />
        </Col>
        <Col>
          <DropDownBase<number | null>
            id="profile-editor-builder-class"
            options={YEARS.map(year => +year)}
            labelOnly
            selected={values.attending_school_since}
            placeholder="Select Year"
            label="Class "
            onOptionChange={editor.onChange("attending_school_since")}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Input
            id="profile-editor-builder-linkedin"
            variant="secondary"
            label="LinkedIn Profile"
            placeholder=""
            name="social_media_accounts[0].url"
            onChange={editor.onChangeSocialMedia}
          />
        </Col>
      </Row>
      <hr className="mb-5 mt-4" />
    </Section>
  );
};
