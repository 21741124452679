import React from "react";
import { useNode } from "@craftjs/core";
import styled from "styled-components";
import { TextSettings } from "./TextSettings";
import { getElementSize } from "../../utils/numToMeasurement";

type IProps = {
  text?: string;
  height?: string | number;
  width?: string | number;
  margin?: any[];
  color?: string;
  background?: string;
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: string;
  textDecoration?: string;
  textAlign?: string;
};

const StyledText = styled.div<IProps>`
  color: ${({ color }) => `${color}!important`};
  background: ${({ background }) => `${background}!important`};
  margin: ${({ margin }) => getElementSize(margin)};
  width: ${({ width }) => `${width}px!important`};
  height: ${({ height }) => `${height}px!important`};
  font-family: ${({ fontFamily }) => `${fontFamily}!important`};
  font-size: ${({ fontSize }) => `${fontSize}px!important`};
  font-weight: ${({ fontWeight }) => `${fontWeight}!important`};
  text-decoration: ${({ textDecoration }) => `${textDecoration}!important`};
  text-align: ${({ textAlign }) => `${textAlign}!important`};
`;

export const Text = ({ text, ...otherProps }: IProps) => {
  const {
    connectors: { connect },
  } = useNode(node => ({
    selected: node.events.selected,
  }));

  return (
    <StyledText
      data-cy="toolbox-text"
      ref={connect as any}
      dangerouslySetInnerHTML={{ __html: text as string }}
      {...otherProps}
    />
  );
};

Text.craft = {
  displayName: "Text",
  props: {
    text: "<p>This is a simple text</p>",
    margin: ["5", "0", "5", "0"],
  },
  related: {
    settings: TextSettings,
    toolbar: TextSettings,
  },
};
