import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormikTouched, FormikErrors } from 'formik';

import Input from 'app/components/input/Input';
import UploadPopupV2 from 'app/screens/sitePhotos/uploadPopup/UploadPopupV2';
import { IMediaSelected, IMediaUpload } from 'app/models/media';
import RoundButton from 'app/components/button/RoundButton';
import { IMainProgramConfigBuilder } from 'app/models/config';
import { UploadPopUpRef } from 'app/screens/sitePhotos/uploadPopup/UploadPopup';
import {
  IConfigurationEditor,
  IConfigurationEditorSection,
} from 'app/hooks/ConfigurationEditor';
import { ReactComponent as IcImage } from 'app/assets/icons/images.svg';
import { ReactComponent as IcDelete } from 'app/assets/icons/delete.svg';

interface IRowDetails {
  id: string;
  label: string;
}

interface IPhotoCopyRow {
  editor: Partial<IConfigurationEditor>;
  imageRef?: React.MutableRefObject<UploadPopUpRef>;
  label?: string;
  subLabel?: string;
  omitSubLabel?: boolean;
  elements?: IRowDetails[];
  imageAttribute?: keyof IMainProgramConfigBuilder;
  touched: FormikTouched<IMainProgramConfigBuilder>;
  errors: FormikErrors<IMainProgramConfigBuilder>;
}

const PhotoCopyRow: React.FC<IPhotoCopyRow & IConfigurationEditorSection> = ({
  editor,
  label,
  values,
  elements,
  subLabel,
  omitSubLabel = false,
  imageAttribute,
  touched,
  errors,
  imageRef,
}) => {
  function _getImage() {
    if (!imageAttribute || !values) return '';

    const img = values?.[imageAttribute] as IMediaUpload;

    return img?.tmpFileUrl ?? img?.path;
  }

  const headerId = elements ? elements[0].id.replaceAll('_', '-') : null;
  const headerParam = elements
    ? elements[0].id.replaceAll('-', '_')
    : (null as keyof IMainProgramConfigBuilder | null);

  const subHeaderId = elements ? elements[1].id.replaceAll('_', '-') : null;
  const subHeaderParam = elements
    ? elements[1].id.replaceAll('-', '_')
    : (null as keyof IMainProgramConfigBuilder | null);

  return (
    <>
      <Row className='mt-5'>{label && <Col>{label}</Col>}</Row>
      <Row className={`${imageAttribute ? 'with-photo-upload' : ''} mt-3`}>
        {imageAttribute && (
          <Col md={4}>
            <div>
              <div className='upload-default-image-label-row'>
                {!omitSubLabel && (subLabel ?? 'Upload Default Photo')}
                {values[imageAttribute] && (
                  <RoundButton
                    roundBtnId={`${imageAttribute}-delete`}
                    icon={<IcDelete />}
                    className='remove-photo-icon'
                    onClick={() => {
                      editor?.onMediaUpload?.(
                        imageAttribute,
                        undefined,
                        undefined,
                        true
                      );
                    }}
                  />
                )}
              </div>
              <div className='mt-2'>
                <button
                  id={`configuration-editor-upload-${headerId}-photo`}
                  type='button'
                  onClick={() => imageRef?.current?.show()}
                  className={`photo-selector ${
                    values[imageAttribute] ? 'filled' : ''
                  }`}
                >
                  {_getImage() && (
                    <img
                      src={_getImage()}
                      className='photo-selector-thumbnail'
                    />
                  )}
                  {!values[imageAttribute] && (
                    <>
                      <IcImage />
                      Default Photo
                    </>
                  )}
                </button>
              </div>
            </div>
          </Col>
        )}
        {elements && headerParam && (
          <Col md={4} className='bottom-aligned'>
            <Input
              id={`configuration-editor-${headerId}`}
              label={elements[0].label}
              variant='secondary'
              name={headerParam}
            />
          </Col>
        )}
        {elements && subHeaderParam && (
          <Col md={4} className='bottom-aligned'>
            <Input
              id={`configuration-editor-${subHeaderId}`}
              label={elements[1].label}
              variant='secondary'
              name={subHeaderParam}
            />
          </Col>
        )}
      </Row>
      <UploadPopupV2
        id={`${headerParam}-upload-default-photo`}
        onConfirm={() => {}}
        ref={imageRef}
        mediaType='IMAGE'
        autoUpload={false}
        owner={{
          id: editor?.appConfig?.id,
          type: 'Program',
        }}
        onFileSelect={(
          type,
          file?: File,
          tmpFileStr?: string,
          folderId?: number,
          existingMedia?: IMediaSelected
        ) => {
          if (file && folderId) {
            imageAttribute &&
              editor?.onMediaUpload?.(imageAttribute, {
                file,
                tmpFileUrl: tmpFileStr ?? '',
                folderId,
              });
          } else if (imageAttribute && existingMedia) {
            editor?.onMediaUpload?.(imageAttribute, undefined, {
              media: existingMedia,
            });
          }
        }}
      />
    </>
  );
};

export default PhotoCopyRow;
