import EducationEditor from "../../../components/educationEditor/EducationEditor";
import RoundButton from "../../../components/button/RoundButton";
import Section from "../../../components/section/Section";
import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import React from "react";
import { useFormikContext } from "formik";
import { IAlumniBuilder } from "../../../models/alumni";
import { IAlumniEditor } from "../../../hooks/AlumniEditor";


export const EducationSection = ({ editor }: { editor: IAlumniEditor }) => {
  const { values} = useFormikContext<IAlumniBuilder>();

  return (
    <Section
      title="Education"
      sectionId="profileEditorBuilderEducation"
      titleSize="lg"
      className="profileEditor-builder-education"
      isEditable={true}
    >
      {(values.educations ?? []).map((education: any, index: any) => (
        <EducationEditor
          key={`profileEditor-builder-education-${index}`}
          index={index}
          variant="secondary"
          selected={education.outcome_id}
          onChange={editor.onChange(`educations[${index}].outcome_id`)}
          onDelete={editor.onDeleteEducation}
        />
      ))}

      <RoundButton
        icon={<IcPlus />}
        className="add-icon"
        size="lg"
        variant="primary"
        label="Add new education"
        onClick={editor.addEducation}
        roundBtnId="profileEditor-builder-add-btn"
      />
      <hr className="mb-5 mt-4" />
    </Section>
  )
}