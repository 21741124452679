import { createPage, deletePage, fetchPages, updatePage } from "../api/adminApis";
import { processErrorResponse } from "../utils/common";
import { appStateSelectors, useAppState } from "../state/AppState";
import { useRef, useState } from "react";
import { FormikProps } from "formik";
import { IPage } from "../models/IPage";
import { useNavigate, useParams } from "react-router-dom";
import { UploadPopUpRef } from "../screens/sitePhotos/uploadPopup/UploadPopup";

export const usePageBuilder = () => {
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const showNotification = useAppState(appStateSelectors.showNotification);
  const hideNotification = useAppState(appStateSelectors.hideNotification);
  const appConfig = useAppState(appStateSelectors.appConfig);
  const roles = useAppState(appStateSelectors.roles);
  const uploadLogoRef = useRef<UploadPopUpRef>(null);
  const formRef = useRef<FormikProps<IPage>>(null);
  const navigate = useNavigate();
  const params = useParams();
  const [error, setError] = useState("");

  const initialValue: IPage = {
    pageTitle: "",
    pageContent: "",
    pageUrl: "",
    programId: 0,
    roles: [],
  };

  const onChange = (key: string, checked: boolean | string) =>
    formRef.current?.setFieldValue(key, checked);

  const onCreatePage = async (page: IPage, withNavigate = true) => {
    if (!page) return;

    hideNotification();
    showLoader();

    try {
      let id = null;
      if (params) {
        id = params.id;
      }

      const pagesWithRoles = {
        ...page,
        role_ids: page.roles?.map(role => role.id),
      };

      id ? await updatePage(id, pagesWithRoles) : await createPage(pagesWithRoles);
      if (withNavigate) {
        navigate(-1);
      }
    } catch (error: any) {
      setError(error.response.data.message);
      processErrorResponse({ error, callback: showNotification });
      console.log(
        "🚀 ~ file: usePageBuilder.ts ~ onCreatePage ~ error",
        JSON.parse(JSON.stringify(error.response))
      );
    } finally {
      hideLoader();
    }
  };

  const onFetchPages = async () => {
    try {
      return await fetchPages();
    } catch (error) {
      processErrorResponse({ error, callback: showNotification });
      console.log("🚀 ~ file: usePageBuilder.ts ~ onFetchPages ~ error", error);
    }
  };

  const onDeletePage = async (id: string) => {
    try {
      showLoader();
      await deletePage(id);
    } catch (error) {
      processErrorResponse({ error, callback: showNotification });
      console.log("🚀 ~ file: usePageBuilder.ts ~ onFetchPages ~ error", error);
    } finally {
      hideLoader();
    }
  };

  const onRoleChange = (selected: string[]) => {
    const selectedRoles = roles.filter(role => selected.includes(role.roleName));
    // console.log(' -- selectedRoles -- ', selectedRoles);

    formRef.current?.setValues(prevValues => ({
      ...prevValues,
      roles: selectedRoles.map(role => ({ ...role, name: role.roleName })),
      role_ids: selectedRoles.map(role => role.id),
    }));
  };

  return {
    initialValue,
    roles,
    formRef,
    error,
    setError,
    onCreatePage,
    onChange,
    onFetchPages,
    onDeletePage,
    onRoleChange,
    uploadLogoRef,
    appConfig,
  };
};
