export const CLUBS_CATEGORY = [
  "Community",
  "Extracurriculars",
  "Family Groups",
  "Sports",
  "Teacher Groups",
  "The Arts",
  "Academic Programs",
  "Electives",
  "Faith",
  "Faith, Service & Retreats",
];
