import React, { useRef, useState } from "react";
import { ReactComponent as IcImage } from "app/assets/icons/images.svg";
import { IUploadResponse } from "../../models/common";
import UploadPopup, { UploadPopUpRef } from "../../screens/sitePhotos/uploadPopup/UploadPopup";

export const FeaturedUploader = ({
  imageUrl,
  onFeaturedImageChange,
}: {
  imageUrl?: string;
  onFeaturedImageChange: (id: number) => void;
}) => {
  const featuredImagePopupRef = useRef<UploadPopUpRef>(null);
  const [tempImage, setTempImage] = useState("");

  const handleUpload = (uploadedMedia: IUploadResponse) => {
    if (uploadedMedia) {
      const id = uploadedMedia.mediaId ?? (uploadedMedia as any).id;
      const tempImage = uploadedMedia.fileUrl;
      setTempImage(tempImage);
      onFeaturedImageChange(id);
    }
  };

  return (
    <>
      <div className="box-container">
        <h6>Featured Image</h6>
        <div
          className="drop-file-container"
          onClick={() => featuredImagePopupRef?.current?.show()}
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            cursor: "pointer",
          }}
        >
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            {imageUrl || tempImage ? (
              <img
                src={tempImage || imageUrl}
                style={{ width: "100%", borderRadius: 10 }}
                alt={`featured-image-${new Date().getTime()}`}
              />
            ) : (
              <IcImage className="p-1 drop-icon" />
            )}
            {<div>Set Featured Image</div>}
          </div>
        </div>
      </div>
      <UploadPopup id="featured-image" onConfirm={handleUpload} ref={featuredImagePopupRef} />
    </>
  );
};
