import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Input from 'app/components/input/Input';
import Section from 'app/components/section/Section';
import RadioButtonGroup from 'app/components/radioButtonGroup/RadioButtonGroup';
import RadioButtonOption from 'app/components/radioButtonGroup/RadioButtonOption';
import {
  IEmailScheduleLimitOption,
  IEmailScheduleItemBuilder,
  IScheduleLimit,
} from 'app/models/email';
import { IEmailScheduleEditor } from 'app/hooks/EmailScheduleEditor';

interface EmailScheduleLimitsSection {
  editor: IEmailScheduleEditor;
  values: IEmailScheduleItemBuilder;
  onRegistrationLimitChange?: (
    type: keyof IEmailScheduleLimitOption,
    value: any
  ) => void;
  onValidationLimitChange?: (
    type: keyof IEmailScheduleLimitOption,
    value: any
  ) => void;
  onLoginLimitChange?: (
    type: keyof IEmailScheduleLimitOption,
    value: any
  ) => void;
}

export const EmailScheduleLimitsSection = ({
  editor,
  values,
}: EmailScheduleLimitsSection) => {
  const _onOptionSelect =
    (param: 'Authenticated' | 'Registered' | 'Verified') =>
    (operator: string) => {
      editor.onLimitChange(param)(operator as '>' | '<' | '=');
    };

  return (
    <Section
      title='Limit who will receive an email (optional)'
      sectionId='emailScheduleLimits'
      titleSize='md'
      className='email-schedule-limits mt-4'
    >
      <Row>
        {editor.emailScheduleLimitOptions.map((option, index) => {
          const type = option.type;
          const selected = (values.limits ?? ([] as IScheduleLimit[])).find(
            (limit: any) => limit.name === type
          );

          return (
            <Col>
              <h6 className='mt-5'>{option.title}</h6>
              <RadioButtonGroup<string>
                id={`email-schedule-${option.type}-limit`}
                onSelect={_onOptionSelect(type)}
              >
                {editor.emailScheduleLimitOperators.map((operator) => {
                  const checked =
                    selected && selected?.operator === operator.value;

                  return (
                    <RadioButtonOption
                      id={`email-schedule-limit-option-${operator.value}`}
                      key={`email-schedule-limit-option-${operator.value}`}
                      onClick={() => {
                        checked && _onOptionSelect(type)(operator.value);
                      }}
                      checked={checked}
                      label={
                        <div className='limit-option'>
                          <div className='limit-option-label'>
                            {operator.label}
                          </div>
                          <Input
                            id={`${type}-${operator.value}-value`}
                            variant='secondary'
                            label=''
                            placeholder={checked ? 'Number' : ''}
                            value={checked ? selected?.amount : ''}
                            disabled={!checked}
                            name={`limits.amount`}
                            onChange={editor.onLimitAmountChange(type)}
                            type='number'
                            className='limit-option-value'
                            min={0}
                          />
                        </div>
                      }
                      value={operator.value}
                    />
                  );
                })}
              </RadioButtonGroup>
            </Col>
          );
        })}
      </Row>
      <hr className='mt-5' />
    </Section>
  );
};
