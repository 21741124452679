import { api } from "../adminApis";
import { IPaginationParams, IPaginationResponse } from "../../models/api";
import { AxiosRequestConfig } from "axios";
import { IQAListItem } from "../../models/qa";

export const syncQAVideos = (qasIds: number[], programIds: number[]) =>
  api.post<{ qaIds: number[]; programIds: number[] }, { message: string }>("/qa/sync", {
    qaIds: qasIds,
    programIds: programIds,
  });

export const deleteQASync = (qaId: number) =>
  api.delete<{ qaIds: number }, { data: { message: string } }>(`qa/sync/${qaId}`);

export const listPaginatedQA = (params?: IPaginationParams, config?: AxiosRequestConfig) => {
  const { page, limit } = params || ({} as IPaginationParams);
  const paginationParams = api.encodeQueryParams({
    page,
    limit,
  });

  return api.get<IPaginationResponse<IQAListItem>>(
    `/qa-content-paginated${paginationParams}&owner=${params?.ownVideos ? 1 : 0}`,
    params,
    config
  );
};
