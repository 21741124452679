import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Navigate, Outlet } from "react-router-dom";

import { appStateSelectors, useAppState, useFeaturesGate } from "app/state/AppState";
import { useAuthState, authStateSelectors } from "app/state/AuthState";
import ROUTES from "app/constants/navigation";
import { FEATURES } from "app/constants/features";
import { getAvailableFeatures } from "app/api/adminApis";
import "./layout.scss";
import { usePermission } from "../utils/user";

export const SUPER_ADMIN = [ROUTES.COLLEGE_MANAGER, ROUTES.QA_CONTENT_SYNC_MANAGER];

const FeatureGate: React.FC<FeatureGateProps> = () => {
  const location = useLocation();
  const user = useAuthState(authStateSelectors.user);
  const appConfig = useAppState(appStateSelectors.appConfig);
  const rolePermissions = appConfig?.rolePermissions || [];
  const { isSuperAdmin } = usePermission();

  const { features, setFeatures } = useFeaturesGate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAvailableFeatures().then((response: any) => {
      setFeatures(response.data);
      if (user) {
        setLoading(false);
      }
    });
  }, [user]);

  const _isFeatureActive = () => {
    if (SUPER_ADMIN.includes(location.pathname as ROUTES) && !isSuperAdmin) {
      return false;
    }

    const featureTag = FEATURES[location.pathname];
    if (!featureTag) return true;
    const matchedFeature = features.find(f => f.tag === featureTag && f.type === "admin");

    // If the feature isn't found, or not active/enabled, return false
    if (!matchedFeature || !matchedFeature.current_program_enabled) {
      return false;
    }

    if (user?.roles?.some(role => role.name === "Think12 Admin")) return true;

    const matchedRolePermission = rolePermissions.find(rp => rp.feature_id === matchedFeature.id);
    console.log("Matched Role Permission:", matchedRolePermission);

    // If there's no rolePermission for the feature, the default is no access
    if (!matchedRolePermission) {
      return false;
    }

    const hasMatchingRole = user?.roles?.some(role => role.id === matchedRolePermission.role_id);
    console.log("User has matching role:", hasMatchingRole);

    return hasMatchingRole || false;
  };

  if (loading) {
    return <div></div>;
  } else {
    if (!localStorage.getItem("token")) {
      return <Navigate to={ROUTES.SIGN_IN} replace />;
    } else if (!_isFeatureActive()) {
      return <Navigate to={ROUTES.NO_ACCESS} replace />;
    } else {
      return <Outlet />;
    }
  }
};

export default FeatureGate;

interface FeatureGateProps {}
