import { CSSStyleDeclaration } from "cssstyle";

const isCSSProperty = (property: string, cssDeclaration: CSSStyleDeclaration): boolean => {
  return property in cssDeclaration;
};

// Function to extract CSS style properties from an object
export const extractCSSProperties = <T extends Record<string, any>>(obj: T): Partial<T> => {
  const cssDeclaration = new CSSStyleDeclaration();
  const styleObj: Partial<T> = {};

  Object.keys(obj).forEach(key => {
    if (isCSSProperty(key, cssDeclaration)) {
      // TypeScript type assertion to ensure property assignment is type-safe
      styleObj[key as keyof T] = obj[key];
    }
  });

  return styleObj;
};
