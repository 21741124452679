import { useEditor } from "@craftjs/core";
import { Layers } from "@craftjs/layers";
import React, { useState } from "react";
import styled from "styled-components";

import { SidebarItem } from "./SidebarItem";

import { Toolbar } from "../Toolbar/Toolbar";
import { CustomizeIcon, LayersIcon } from "../../assets";
import "./Sidebar.scss";

export const SidebarDiv = styled.div<{ enabled: number }>`
  width: 100%;
  opacity: ${props => (props.enabled ? 1 : 0)};
  background: #fff;
  margin-right: ${props => (props.enabled ? 0 : -280)}px;
`;

export const Sidebar = () => {
  const [layersVisible, setLayerVisible] = useState(true);
  const [toolbarVisible, setToolbarVisible] = useState(true);
  const { enabled } = useEditor(state => ({
    enabled: state.options.enabled,
  }));

  return (
    <SidebarDiv enabled={enabled ? 1 : 0} className="SidebarDiv">
      <div className="SidebarDiv2">
        <SidebarItem
          icon={<CustomizeIcon />}
          title="Customize"
          height={!layersVisible ? "full" : "55%"}
          visible={toolbarVisible}
          onChange={val => setToolbarVisible(val)}
        >
          <Toolbar />
        </SidebarItem>
        <SidebarItem
          icon={<LayersIcon />}
          title="Layers"
          height={!toolbarVisible ? "full" : "45%"}
          visible={layersVisible}
          onChange={val => setLayerVisible(val)}
        >
          <div>
            <Layers expandRootOnLoad={true} />
          </div>
        </SidebarItem>
      </div>
    </SidebarDiv>
  );
};
