import React from "react";
import { Col, Row } from "react-bootstrap";
import { getErrors } from "../../utils/data";
import Input from "../input/Input";
import { COUNTRIES, ICountry, IState, STATES } from "../../constants/location";
import { useFormikContext } from "formik";
import DropDown from "../dropDown/DropDown";


export const LocationRow = () => {
  const {values, touched, errors, setValues} = useFormikContext<any>();

  const onChange = (key: string, value: string) => {
    setValues({ ...values, location: { ...values.location, [key]: value } })
  }

  return (
    <Row>
      <Col>
        <Input
          id="profile-editor-builder-city"
          variant="secondary"
          label="City"
          name="location.city"
          error={getErrors(touched, errors, "city")}
        />
      </Col>
      <Col>
        <DropDown<IState>
          id='configuration-editor-state-member'
          label="State"
          labelAttribute='name'
          transformLabel={(option: IState) => option.name}
          options={STATES.map(state => state)}
          selected={values.location?.subdivision}
          valueAttribute='abbreviation'
          placeholder="Select state"
          onOptionChange={(value) => onChange("subdivision", value as string)}
        />
      </Col>
      <Col>
        <DropDown<ICountry>
          id='configuration-editor-state-member'
          label="Country"
          labelAttribute='name'
          placeholder="Select country"
          valueAttribute='code'
          transformLabel={(option: ICountry) => option.name}
          options={COUNTRIES.map(state => state)}
          selected={values.location?.country}
          onOptionChange={(value) => onChange("country", value as string)}
        />
      </Col>
    </Row>
  )
}