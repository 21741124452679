import { Col, Row } from "react-bootstrap";
import React from "react";
import { ToolbarItem } from "../../Editor/Toolbar/ToolbarItem";
import { ToolbarSection } from "../../Editor/Toolbar/ToolbarSection";
import { Margin, Padding } from "../Common";
import { Radio } from "../../Editor/Toolbar/ToolbarRadio";
import { Dimensions } from "../Common/Dimensions";
import { useEditor, useNode } from "@craftjs/core";

export const ContainerSettings = () => {
  const { id } = useNode();

  const {
    query: { node },
  } = useEditor();
  const {
    data: { name },
  } = node(id).get();

  return (
    <>
      <Dimensions />

      <ToolbarSection
        title="Colors"
        props={["background"]}
        summary={({ background }: { background: string }) => (
          <div
            style={{
              background: `${background}`,
              width: 30,
              height: 30,
              borderRadius: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        )}
      >
        <ToolbarItem propKey="background" type="color" label="Background color" />
      </ToolbarSection>

      <ToolbarSection title="Decorations" props={["borderRadius", "shadow"]}>
        <ToolbarItem propKey="borderRadius" type="text" label="Radius" />
        <ToolbarItem propKey="shadow" type="text" label="Shadow" />
      </ToolbarSection>

      <Margin />
      <Padding />
      <ToolbarSection title="Directions">
        <Row style={{ marginBottom: 8 }}>
          <Col xs={12} sm={12} md={6}>
            <ToolbarItem label="Flex Direction" propKey="flexDirection" type={"radio"}>
              <Radio value="row" label="Row" />
              <Radio value="column" label="Column" />
            </ToolbarItem>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <ToolbarItem label="Fill space" propKey="fillSpace" type={"radio"}>
              <Radio value="yes" label="Yes" />
              <Radio value="no" label="No" />
            </ToolbarItem>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6}>
            <ToolbarItem label="Align Items" propKey="alignItems" type={"radio"}>
              <Radio value="flex-start" label="Flex start" />
              <Radio value="center" label="Center" />
              <Radio value="flex-end" label="Flex end" />
              <Radio value="space-between" label="Space between" />
              <Radio value="space-around" label="Space around" />
            </ToolbarItem>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <ToolbarItem label="Justify Content" propKey="justifyContent" type={"radio"}>
              <Radio value="flex-start" label="Flex start" />
              <Radio value="center" label="Center" />
              <Radio value="flex-end" label="Flex end" />
              <Radio value="space-between" label="Space between" />
              <Radio value="space-around" label="Space around" />
            </ToolbarItem>
          </Col>
        </Row>
      </ToolbarSection>
      {name === "Col" && (
        <ToolbarSection title="Responsive">
          <ToolbarItem propKey="lg" type="number" label="Number of spaces" />
        </ToolbarSection>
      )}
    </>
  );
};
