import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import { useAppState, appStateSelectors } from "app/state/AppState";
import PrivateLayout from "app/layout/PrivateLayout";
import Section from "app/components/section/Section";
import Switch from "app/components/switch/Switch";
import { getProgram, updateProgram } from "app/api/adminApis";
import { Form, Formik } from "formik";
import Input from "app/components/input/Input";
import StandardButton from "app/components/button/StandardButton";
import useSamlConfiguration from "app/hooks/SamlConfiguration";
import { samlConfigurationSchema } from "app/utils/validations";

const SsoManager: React.FC<{}> = () => {
  const _samlConfiguration = useSamlConfiguration();
  const appConfig = useAppState(appStateSelectors.appConfig);

  // State to track SSO status
  const [isSsoEnabled, setIsSsoEnabled] = useState<boolean>(false);


  useEffect(() => {
    if (appConfig?.id) {
      const fetchProgram = async () => {
        try {
          let response = await getProgram(appConfig.id);
          let program = response.data;
          if (program) {
            setIsSsoEnabled(program.is_sso_enabled ? program.is_sso_enabled : false);
          }
        } catch (error) {
          console.error("Error fetching program:", error);
        }
      };
      
      fetchProgram();
      
    }
  }, [appConfig]);

  // Handler for toggle change
  const handleToggleChange = (checked: boolean) => {
    if (!appConfig) {
      return;
    }
    let updatedConfig = {
      is_sso_enabled: checked,
    };
    setIsSsoEnabled(checked);
    //@ts-ignore
    updateProgram(appConfig.id, updatedConfig);
  };

  return (
    <PrivateLayout contentClassName="sso-manager">
      <Section
        title="Single Sign On Manager"
        sectionId="sso-manager-landing-page"
        titleSize="md"
        className="sso-manager-landing-page mt-4"
      >
        <Row>
          <Col>
            <div className="input-container secondary">
              <div className="admin-editor-switch">
                <div className="input-label-row"></div>
                <div className="admin-editor-toggle">
                  <p>Enable SSO Connection</p>
                  <Switch
                    checked={isSsoEnabled} // Use dynamic state
                    onChange={handleToggleChange} // Update handler
                    switchId="admin-editor-live-btn"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <hr className="mt-5" />
        <Formik
          innerRef={_samlConfiguration.formRef}
          initialValues={_samlConfiguration.initialValues}
          onSubmit={_samlConfiguration.onSubmit}
          validationSchema={samlConfigurationSchema}
        >
          {({ values, isValid, dirty, errors, touched }) => (
            <Form>
              <Row>
                <Col>
                  <Input
                    id="sp_entity_id"
                    variant="secondary"
                    label="Service Provider Entity Name"
                    placeholder="Enter the SP entity name here"
                    name="sp_entity_id"
                    error={touched.sp_entity_id && errors.sp_entity_id ? errors.sp_entity_id : ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    id="sp_acs_url"
                    label="Service Provider ACS URL"
                    placeholder="Enter the SP ACS URL"
                    variant="secondary"
                    name="sp_acs_url"
                    error={touched.sp_acs_url && errors.sp_acs_url ? errors.sp_acs_url : ""}
                  />
                </Col>
                <Col>
                  <Input
                    id="sp_sls_url"
                    label="Service Provider SLS URL"
                    placeholder="Enter the SP SLS URL"
                    variant="secondary"
                    name="sp_sls_url"
                    error={touched.sp_sls_url && errors.sp_sls_url ? errors.sp_sls_url : ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    id="sp_certificate"
                    label="Service Provider Certificate"
                    placeholder="Enter the SP certificate"
                    variant="secondary"
                    name="sp_certificate"
                    as="textarea"
                    error={touched.sp_certificate && errors.sp_certificate ? errors.sp_certificate : ""}
                  />
                </Col>
                <Col>
                  <Input
                    id="sp_private_key"
                    label="Service Provider Private Key"
                    placeholder="Enter the SP private key"
                    variant="secondary"
                    name="sp_private_key"
                    as="textarea"
                    error={touched.sp_private_key && errors.sp_private_key ? errors.sp_private_key : ""}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Input
                    id="idp_entity_id"
                    label="Identity Provider Entity Name"
                    placeholder="Enter the IdP entity name"
                    variant="secondary"
                    name="idp_entity_id"
                    error={touched.idp_entity_id && errors.idp_entity_id ? errors.idp_entity_id : ""}
                  />
                </Col>
                <Col>
                  <Input
                    id="idp_sso_url"
                    label="Identity Provider SSO URL"
                    placeholder="Enter the IdP SSO URL"
                    variant="secondary"
                    name="idp_sso_url"
                    error={touched.idp_sso_url && errors.idp_sso_url ? errors.idp_sso_url : ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    id="idp_slo_url"
                    label="Identity Provider SLO URL"
                    placeholder="Enter the IdP SLO URL"
                    variant="secondary"
                    name="idp_slo_url"
                    error={touched.idp_slo_url && errors.idp_slo_url ? errors.idp_slo_url : ""}
                  />
                </Col>
                <Col>
                  <Input
                    id="idp_cert"
                    label="Identity Provider Certificate"
                    placeholder="Enter the IdP certificate"
                    variant="secondary"
                    name="idp_cert"
                    as="textarea"
                    error={touched.idp_cert && errors.idp_cert ? errors.idp_cert : ""}
                  />
                </Col>
              </Row>
              <hr />
              <StandardButton
                type="submit"
                variant="secondary"
                label={"Save"}
                disabled={!dirty || !isValid}
                className="create-button"
                standardBtnId="saml-configuration-save-btn"
              />
            </Form>
          )}
        </Formik>

      </Section>
    </PrivateLayout>
  );
};

export default SsoManager;