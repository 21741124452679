import React from "react";
import moment from "moment";

import PrivateLayout from "app/layout/PrivateLayout";
import { getReportData } from "app/api/reporting";

import "./admin.scss";
import "react-datepicker/src/stylesheets/datepicker.scss";

import { SectionChart } from "./components/SectionChart";
import { ReportingFilter } from "./components/ReportingFilter";
import { OverallReportingFilter } from "./components/OverallReportingFilter";
import { OverallReporting } from "./components/OverallReporting";
import { useReportingProvider } from "./ReportingProvider";
import { useFeatures } from "../../hooks/useFeatures";
import { FeaturesEnum } from "../../constants/features";

const AdminReporting: React.FC = () => {
  const { hasFeature } = useFeatures();
  const {
    familyReport,
    articlesReport,
    alumniReport,
    graduateReport,
    qaReport,
    pagesReport,
    staffReport,
    hubReport,
    outcomesReport,
    fetchAlumniReport,
    fetchGraduateReport,
    fetchQAReport,
    fetchPagesReport,
    fetchStaffReport,
    fetchHubReport,
    fetchOutcomesReport,
    fetchFamilyReport,
    fetchArticlesReport,
    userType,
    startDate,
    endDate,
    gradeBand,
  } = useReportingProvider();

  const downloadReportCsv = (type: string, url?: string) => {
    getReportData({
      type: type,
      url: url,
      userType: userType,
      startDate: moment.utc(startDate).format("YYYY-MM-DD 00:00:01"),
      endDate: moment.utc(endDate).format("YYYY-MM-DD 23:59:59"),
      location: location,
      gradeBand: gradeBand,
    }).then((res: any) => {
      let csvContent = res.data;
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");

      link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
      link.setAttribute("download", "reporting_data_" + type + ".csv");

      document.body.appendChild(link);
      link.click();
    });
  };

  const HasArticles = hasFeature(FeaturesEnum.ARTICLE);
  const HasFamily = hasFeature(FeaturesEnum.FAMILY);
  const HasAlumni = hasFeature(FeaturesEnum.ALUMNI_NETWORK);
  const HasGraduates = hasFeature(FeaturesEnum.GRADUATE);
  const HasQA = hasFeature(FeaturesEnum.QA);
  const HasStaff = hasFeature(FeaturesEnum.STAFF);
  const HasHub = hasFeature(FeaturesEnum.CLUB);
  const HasOutcomes = hasFeature(FeaturesEnum.OUTCOME);
  const HasPages = hasFeature(FeaturesEnum.PAGE_BUILDER);

  return (
    <PrivateLayout>
      <div className="admin-reporting">
        <div className="reporting-top">
          <h1>Program Reporting</h1>
          <ReportingFilter />
        </div>
        <div className="report-container">
          <OverallReportingFilter downloadReportCsv={downloadReportCsv} />
          <OverallReporting />

          <div className="areas-detail">
            <h4>Areas in Detail</h4>

            {HasArticles && (
              <SectionChart
                reportData={articlesReport}
                fetcher={fetchArticlesReport}
                title="Article Views"
                downloadReportCsv={downloadReportCsv}
              />
            )}

            {HasFamily && (
              <SectionChart
                reportData={familyReport}
                fetcher={fetchFamilyReport}
                title="Family Network Views"
                downloadReportCsv={downloadReportCsv}
              />
            )}

            {HasAlumni && (
              <SectionChart
                title="Alumni Network Views"
                reportData={alumniReport}
                fetcher={fetchAlumniReport}
                downloadReportCsv={downloadReportCsv}
              />
            )}

            {HasGraduates && (
              <SectionChart
                title="Graduates Views"
                downloadReportCsv={downloadReportCsv}
                reportData={graduateReport}
                fetcher={fetchGraduateReport}
              />
            )}

            {HasQA && (
              <SectionChart
                title="Q & A Views"
                downloadReportCsv={downloadReportCsv}
                fetcher={fetchQAReport}
                reportData={qaReport}
              />
            )}

            {HasStaff && (
              <SectionChart
                title="Staff Directory Views"
                downloadReportCsv={downloadReportCsv}
                fetcher={fetchStaffReport}
                reportData={staffReport}
              />
            )}

            {HasHub && (
              <SectionChart
                title="Hub Views"
                downloadReportCsv={downloadReportCsv}
                fetcher={fetchHubReport}
                reportData={hubReport}
              />
            )}

            {HasOutcomes && (
              <SectionChart
                title="Outcomes Views"
                fetcher={fetchOutcomesReport}
                reportData={outcomesReport}
                downloadReportCsv={downloadReportCsv}
              />
            )}

            {HasPages && (
              <SectionChart
                title="Custom pages"
                downloadReportCsv={downloadReportCsv}
                fetcher={fetchPagesReport}
                reportData={pagesReport}
              />
            )}
          </div>
        </div>
      </div>
    </PrivateLayout>
  );
};
export default AdminReporting;
