import { useRef } from "react";
import { deleteQA, updateQA, listQA } from "app/api/adminApis";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import { QuestionAnswerPreviewPopupRef } from "app/screens/questionAnswer/components/QuestionAnswerPreviewPopup";
import ROUTES from "app/constants/navigation";
import { IQAListItem } from "app/models/qa";
import { appStateSelectors, useAppState } from "app/state/AppState";
import { useNavigate } from "react-router-dom";
import usePagination from "./Pagination";
import { deleteQASync, listPaginatedQA, syncQAVideos } from "../api/qa/qa-videos";

const useQAListing = (ownVideos?: boolean) => {
  const navigate = useNavigate();
  const _pagination = usePagination<IQAListItem>({
    listFn: (params, config) => listPaginatedQA({ ...params, ownVideos } as any, config),
    deleteFn: deleteQA,
  });

  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const categoryTags = useAppState(appStateSelectors.contentCategories);
  const statuses = useAppState(appStateSelectors.contentApprovals);
  const appConfig = useAppState(appStateSelectors.appConfig);

  const previewPopupRef = useRef<QuestionAnswerPreviewPopupRef>(null);

  const onNewQAClick = () => navigate(ROUTES.Q_AND_A_EDITOR);

  const onToggleActive = (question: IQAListItem) => async (checked: boolean) => {
    const response = await updateQA(question.id, {
      activeStatus: checked,
    });
    if (response) {
      _pagination.updateData(question.id, "id", {
        activeStatus: checked,
      });
    }
  };

  const getQAById = (id: number) => {
    return _pagination.data.filter(x => x.id === id)[0];
  };

  const onGridAction = (action: DATA_GRID_ACTIONS, rowId: number) => {
    const video = getQAById(rowId);
    switch (action) {
      case DATA_GRID_ACTIONS.DELETE:
        if (video.programId === appConfig?.id) {
          _pagination.onDelete(rowId);
        } else {
          alert("You may only delete qa's from this program.");
        }
        break;
      case DATA_GRID_ACTIONS.EDIT:
        if (video.programId === appConfig?.id) {
          navigate(`${ROUTES.Q_AND_A_EDITOR}/${rowId}`);
        } else {
          alert("You may only edit qa's from this program.");
        }
        break;
      case DATA_GRID_ACTIONS.PREVIEW:
        const qa = _pagination.data.find(item => item.id === rowId);
        qa && previewPopupRef.current?.show(qa);
        break;
      default:
        break;
    }
  };

  const syncVideos = async (selectedIds: number[], programIds: number[]) => {
    const response = await syncQAVideos(selectedIds, programIds);
    return response.data.message;
  };

  const deleteSyncVideo = async (qaId: number) => {
    const response = await deleteQASync(qaId);
    await _pagination.onSearch();

    return response?.data?.message;
  };

  return {
    ..._pagination,
    gradeBands,
    onNewQAClick,
    onToggleActive,
    onGridAction,
    syncVideos,
    deleteSyncVideo,
    categoryTags,
    statuses,
    previewPopupRef,
  };
};

export default useQAListing;
