import DataGrid from "app/components/dataGrid/DataGrid";
import useQAListing from "app/hooks/QAListing";
import QuestionAnswerPreviewPopup from "./components/QuestionAnswerPreviewPopup";
import { IQAListItem } from "app/models/qa";
import React, { useState } from "react";
import "./qa.scss";
import { QAListing } from "./QAListing";
import Checkbox from "../../components/checkbox/CheckBox";
import StandardButton from "../../components/button/StandardButton";
import { SelectPrograms } from "../contentSync/SelectProgramStep";
import { ConfirmSelection } from "../contentSync/ConfirmSelection";
import { SuccessSubmission } from "../contentSync/SuccessSubmission";

const GRID_ID = "qa-account-listing";

export const QAContentSync = () => {
  const _qaListing = useQAListing(true);
  const [selectedVideos, setSelectedVideos] = useState<number[]>([]);
  const [selectedPrograms, setSelectedPrograms] = useState<number[]>([]);
  const [message, setMessage] = useState<string>("");
  const [step, setStep] = useState(0);

  const onSubmit = async () => {
    const response = await _qaListing.syncVideos(selectedVideos, selectedPrograms);
    setMessage(response);
    setStep(3);
  };

  return (
    <>
      {step === 0 && (
        <QAListing _qaListing={_qaListing} title="Q&amp;A Content Sync">
          <DataGrid<IQAListItem>
            data={_qaListing.data}
            gridId={GRID_ID}
            rowIdAttribute="id"
            loading={_qaListing.loading}
            currentPage={_qaListing.currentPage}
            onPageChange={_qaListing.onPageChange}
            totalItems={_qaListing.totalItems}
            onAction={_qaListing.onGridAction}
            confirmDeleteDescription="Please confirm that you would like to delete this question/topic."
          >
            <DataGrid.Column<IQAListItem> attributeName="topic" header="Question or Topic" />
            <DataGrid.Column<IQAListItem> attributeName="topicId" header="Content Id" />
            <DataGrid.Column<IQAListItem> attributeName="gradeBands" header="Grade Band" />

            <DataGrid.Column<IQAListItem> attributeName="categoryTags" header="Category Tags" />
            <DataGrid.Column<IQAListItem> attributeName="approveStatus" header="Approval Status" />

            <DataGrid.Column<any>
              attributeName="selected"
              header="selected"
              transform={(qa: any) => (
                <Checkbox
                  id={`program-listing-${qa.id}`}
                  checked={selectedVideos.indexOf(qa.id) > -1}
                  onChange={val => {
                    let newSelectedArticles = [...selectedVideos];
                    if (!val) {
                      newSelectedArticles.splice(selectedVideos.indexOf(qa.id), 1);
                    } else {
                      newSelectedArticles.push(qa.id);
                    }
                    setSelectedVideos(newSelectedArticles);
                  }}
                />
              )}
            />
          </DataGrid>
          <div className="justify-content-center">
            <StandardButton
              label="Next"
              variant="secondary"
              onClick={() => setStep(prevState => prevState + 1)}
              standardBtnId="article-listing-new-btn"
            />
          </div>
        </QAListing>
      )}
      {step === 1 && (
        <SelectPrograms
          selectedPrograms={selectedPrograms}
          setSelectedPrograms={setSelectedPrograms}
          onNextStep={() => setStep(prevState => prevState + 1)}
        />
      )}
      {step === 2 && (
        <ConfirmSelection
          entityType="video"
          selectedEntities={selectedVideos.length}
          selectedPrograms={selectedPrograms.length}
          onCancel={() => setStep(0)}
          onSubmit={onSubmit}
        />
      )}
      {step === 3 && <SuccessSubmission successMessage={message} />}

      <QuestionAnswerPreviewPopup ref={_qaListing.previewPopupRef} id="qa-listing-preview" />
    </>
  );
};
