import { listPrograms, updateActiveStatus, updateLiveStatus } from "app/api/adminApis";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import ROUTES from "app/constants/navigation";
import { IProgram } from "app/models/program";
import { SwitchStatusPopupRef } from "app/screens/program/components/SwitchStatusPopup";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import usePagination from "./Pagination";

const useProgramListing = () => {
  const navigate = useNavigate();
  const switchStatusRef = useRef<SwitchStatusPopupRef>(null);
  const switchActiveRef = useRef<SwitchStatusPopupRef>(null);

  const _pagination = usePagination<IProgram>({
    listFn: listPrograms,
  });

  const onNewProgramClick = () => navigate(ROUTES.PROGRAM_BUILDER);

  const onToggleLive = (program: IProgram) => (checked: boolean) => {
    if (checked) {
      switchStatusRef.current?.show(program.id);
    } else {
      _changeLiveStatus(program.id, checked);
    }
  };

  const onToggleActive = (program: IProgram) => (checked: boolean) => {
    switchActiveRef.current?.show(program.id, checked);
  };

  const onConfirmStatusChange = (id: number, password: string) => {
    _changeLiveStatus(id, true);
  };

  const _changeLiveStatus = async (id: number, isLive: boolean) => {
    try {
      const { data } = await updateLiveStatus(id, isLive);
      _pagination.updateData(id, "id", data);
    } catch (error) {
      console.log("🚀 ~ file: ProgramListing.ts ~ const_changeLiveStatus= ~ error", error);
    }
  };

  const onChangeActiveStatus = async (id: number, isActive?: boolean) => {
    try {
      const { data } = await updateActiveStatus(id, !!isActive);
      _pagination.updateData(id, "id", data);
    } catch (error) {
      console.log("🚀 ~ file: ProgramListing.ts ~ const_changeLiveStatus= ~ error", error);
    }
  };

  const onAdminAreaClick = (program: IProgram) => () =>
    window.open(`https://${program.programUrl}`, "_blank");

  const onGridAction = (action: DATA_GRID_ACTIONS, rowId: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.EDIT:
        navigate(`${ROUTES.PROGRAM_BUILDER}/${rowId}`);
        break;

      default:
        break;
    }
  };

  return {
    ..._pagination,
    switchStatusRef,
    switchActiveRef,
    onConfirmStatusChange,
    onNewProgramClick,
    onToggleLive,
    onGridAction,
    onAdminAreaClick,
    onToggleActive,
    onChangeActiveStatus,
  };
};

export default useProgramListing;
