import { Form, Formik, FormikProps } from "formik";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { emptyFunction } from "../../utils/common";
import Section from "../../components/section/Section";
import UploadFile from "../../components/uploadFile/UploadFile";
import { ScriptsImporterInterface } from "../../models/scripts-importer";
import Input from "../../components/input/Input";
import { MultiDropdown } from "../../components/dropDown/MultipleDropDown";
import { IUploadResponse } from "../../models/common";
import RoundButton from "../../components/button/RoundButton";
import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import { ReactComponent as IcDelete } from "app/assets/icons/delete.svg";
import "./configuration-editor.scss";
import FileUploader, { FileUploaderRef } from "../../components/fileUploader/FileUploader";
import { AvailablePages } from "../../constants/script-importer";
import {
  deleteScriptImporter,
  fetchScriptImporter,
  postScriptImporter,
  updateScriptImporter,
} from "../../api/script-importer/script-importer";
import { appStateSelectors, useAppState } from "../../state/AppState";
import Switch from "../../components/switch/Switch";

const emptyState = {
  html: "",
  script: "",
  pages: ["homepage"],
  fileName: "",
  is_active: true,
};

const initialValues: ScriptsImporterInterface[] = [emptyState];

export const ScriptsImporter = () => {
  const showNotificationWithVariant = useAppState(appStateSelectors.showNotificationWithVariant);
  const hideNotification = useAppState(appStateSelectors.hideNotification);

  const { appConfig } = useAppState();
  const formRef = useRef<FormikProps<{ scripts: ScriptsImporterInterface[] }>>(null);
  const [hasScripts, setHasScripts] = useState<boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      const response = await fetchScriptImporter();
      if (response.data && response.data.length) {
        formRef.current?.setFieldValue("scripts", [...response.data]);
        setHasScripts(true);
      }
    };

    fetch();
  }, []);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (hasScripts && formRef.current?.values) {
      const response = await updateScriptImporter(
        appConfig?.id || -1,
        formRef.current?.values.scripts
      );
      if (response.data) {
        formRef.current?.setFieldValue("scripts", [
          ...(response.data as ScriptsImporterInterface[]),
        ]);
      }
    }
    if (!hasScripts && formRef.current?.values) {
      const response = await postScriptImporter(formRef.current?.values.scripts);
      if (response.data) {
        formRef.current?.setFieldValue("scripts", [
          ...(response.data as ScriptsImporterInterface[]),
        ]);
      }
      setHasScripts(true);
    }
    showNotificationWithVariant("success", `Changes has been successfully saved`);
    setTimeout(() => {
      hideNotification();
    }, 3000);
  };

  const deleteScript = async (id?: number) => {
    if (id) {
      await deleteScriptImporter(id);
      const newValues = formRef.current?.values.scripts.filter(script => {
        if (script.id && script.id !== id) return script;
        else if (!script.id) return script;
      });
      formRef.current?.setFieldValue("scripts", [...(newValues as ScriptsImporterInterface[])]);
      showNotificationWithVariant("success", `Script has been deleted`);
      setTimeout(() => {
        hideNotification();
      }, 3000);
    }
  };

  const removeItemFromList = (index: number) => {
    if (formRef.current?.values) {
      const scripts = formRef.current?.values.scripts;
      const newItems = [...scripts.slice(0, index), ...scripts.slice(index + 1)];
      formRef.current.setFieldValue("scripts", newItems);
    }
  };

  const addNewScript = () => {
    const currentResponses = formRef.current?.values?.scripts ?? [];
    formRef.current?.setFieldValue("scripts", [...currentResponses, emptyState]);
  };

  const onUploaded = (response: IUploadResponse[], extra?: any) => {
    formRef.current?.setFieldValue(extra, response[0].fileUrl);
    formRef.current?.setFieldValue(extra.replace(/\bscript\b/, "fileName"), response[0].fileName);
  };

  return (
    <Formik innerRef={formRef} initialValues={{ scripts: initialValues }} onSubmit={emptyFunction}>
      {({ values, setFieldValue }) => {
        return (
          <Form id="config-form-submit" onSubmit={onSubmit}>
            <Section sectionId="staff-page" title="">
              {values.scripts.map((script, index) => {
                return (
                  <React.Fragment key={index}>
                    <Row key={index}>
                      <Col md={7}>
                        <Col md={12}>
                          <Input
                            style={{ height: "16rem" }}
                            as="textarea"
                            value={script.html}
                            name={`scripts[${index}].html`}
                            variant="secondary"
                            className="about"
                            label="Html"
                            placeholder="Insert html"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue(`scripts[${index}].html`, e.target.value);
                            }}
                            id="3rd-party-html"
                          />
                        </Col>
                        <Col md={12}>
                          <Uploader
                            fileName={script.fileName}
                            index={index}
                            onUploadComplete={onUploaded}
                          />
                        </Col>
                      </Col>
                      <Col md={3}>
                        <Row className="align-items-center">
                          <Col md={10}>
                            <MultiDropdown<{ label: string; value: string }>
                              id="page-builder-page-roles"
                              label="Page(s)"
                              labelAttribute="label"
                              valueAttribute="value"
                              placeholder="Select page"
                              options={AvailablePages}
                              selected={script.pages}
                              onOptionChange={value =>
                                setFieldValue(`scripts[${index}].pages`, value as string[])
                              }
                              multiple
                            />
                          </Col>
                          <Col md={2}>
                            <RoundButton
                              roundBtnId={`-delete-click`}
                              icon={<IcDelete />}
                              size="sm"
                              className="action-circle"
                              onClick={() =>
                                script.id ? deleteScript(script?.id) : removeItemFromList(index)
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="admin-editor-toggle">
                              <p>Is script enabled</p>
                              <Switch
                                checked={script.is_active}
                                onChange={() => {
                                  setFieldValue(`scripts[${index}].is_active`, !script.is_active);
                                }}
                                switchId="admin-editor-live-btn"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                  </React.Fragment>
                );
              })}
              <Row>
                <Col className="justify-content-center" md={12}>
                  <RoundButton
                    icon={<IcPlus />}
                    variant="primary"
                    size="lg"
                    roundBtnId="qa-editor-add-response-btn"
                    label="Add a new response video"
                    onClick={addNewScript}
                  />
                </Col>
              </Row>
            </Section>
          </Form>
        );
      }}
    </Formik>
  );
};

const Uploader = ({
  fileName = "",
  onUploadComplete,
  index,
}: {
  onUploadComplete: (response: IUploadResponse[], extra?: string) => void;
  index: number;
  fileName: string;
}) => {
  const _uploaderRef = useRef<FileUploaderRef>(null);

  const onUpload = (media: File, key: string) => {
    _uploaderRef.current?.upload([media], key, null, null, null);
  };

  const onUploadScript = (media: File) => {
    onUpload(media, `scripts[${index}].script`);
  };

  return (
    <>
      <UploadFile
        value={fileName}
        label="Upload script file"
        placeholder="Upload script"
        uploadFileId={`${index}-caption`}
        onChange={onUploadScript}
      />
      <FileUploader ref={_uploaderRef} onUploadComplete={onUploadComplete} />
    </>
  );
};
