import React from "react";
import { FormikTouched, FormikErrors } from "formik";

import Section from "app/components/section/Section";
import Input from "app/components/input/Input";

interface IAboutSection {
  editor: any;
  touched: FormikTouched<any>;
  errors: FormikErrors<any>;
  values: any;
}

export const AboutSection: React.FC<IAboutSection> = ({ editor, values }) => {
  return (
    <Section
      title="About"
      sectionId="profileEditorBuilderAbout"
      titleSize="lg"
      className="profileEditor-builder-about"
      isEditable={true}
    >
      <Input
        id="profileEditor-builder-about-me"
        variant="secondary"
        as="textarea"
        maxLength={1024}
        label="About Me"
        className="profileEditor-builder-textarea"
        placeholder=""
        value={
          (values?.about?.fields ?? []).find((entry: any) => entry.name === "about_me")?.value ?? ""
        }
        onChange={(e: any) => editor.onChangeAboutEntry("about_me", e)}
      />
      <Input
        id="profileEditor-builder-fav-experience"
        variant="secondary"
        as="textarea"
        maxLength={1024}
        label={`Favorite Experience at ${editor.schoolName}`}
        className="profileEditor-builder-textarea"
        placeholder=""
        value={
          (values.about?.fields ?? []).find((entry: any) => entry.name === "favorite_experience")
            ?.value ?? ""
        }
        onChange={(e: any) => editor.onChangeAboutEntry("favorite_experience", e)}
      />
      <Input
        id="profileEditor-builder-advice"
        variant="secondary"
        as="textarea"
        className="profileEditor-builder-textarea"
        maxLength={1024}
        label="Advice for future students"
        placeholder=""
        value={
          (values.about?.fields ?? []).find((entry: any) => entry.name === "advice")?.value ?? ""
        }
        onChange={(e: any) => editor.onChangeAboutEntry("advice", e)}
      />
    </Section>
  );
};
