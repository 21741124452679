import usePagination from "../../../hooks/Pagination";
import { deleteOutcomeLogos, listOutcomeLogos } from "../../../api/outcome-logos/outcome-logs-api";
import { IOutcomeLogos } from "../../../models/outcomeLogos";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../constants/navigation";
import { DATA_GRID_ACTIONS } from "../../../components/dataGrid/DataColumn";

export const useOutcomeLogosList = () => {
  const navigate = useNavigate();
  const _pagination = usePagination<IOutcomeLogos>({
    listFn: listOutcomeLogos,
    deleteFn: deleteOutcomeLogos,
  });

  const onGridAction = (action: DATA_GRID_ACTIONS, rowId: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.EDIT:
        navigate(`${ROUTES.COLLEGE_MANAGER_EDITOR}/${rowId}`);
    }
  };
  const onNewStaffClick = () => {
    navigate(ROUTES.COLLEGE_MANAGER_EDITOR);
  };

  return {
    ..._pagination,
    onGridAction,
    onNewStaffClick,
  };
};
