import StandardButton from "app/components/button/StandardButton";
import { Col, Row } from "react-bootstrap";
import PrivateLayout from "app/layout/PrivateLayout";
import { listArticlesContent, listQA, listFamily, listAmbassadorProfile } from "app/api/adminApis";
import { getUser } from "app/api/authApis";
import { useEffect, useState } from "react";
import { IUserV2 } from "app/models/user";
import React from "react";
import { useNavigate } from "react-router-dom";

import "./admin.scss";

import { ReactComponent as ArticlesIcon } from "app/assets/icons/articles.svg";
import { ReactComponent as EyeIcon } from "app/assets/icons/eye.svg";
import { ReactComponent as EditIcon } from "app/assets/icons/edit.svg";
import { ReactComponent as VideosIcon } from "app/assets/icons/video-camera.svg";
import { ReactComponent as FamilyIcon } from "app/assets/icons/family.svg";

import disabledImage from "app/assets/images/dashboard-feature-disabled.png";
import reportingImage from "app/assets/images/reporting.png";
import { NewTicketModal } from "./NewTicketModal";

interface Props {
  children?: React.ReactNode;
}
const DisabledModule: React.FC<Props> = () => {
  return (
    <div className="feature-disabled">
      <img src={disabledImage} alt="img" />
    </div>
  );
};

const AdminDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [newTicketOpen, setNewTicketOpen] = useState(false);

  const [user, setUser] = useState<IUserV2>();
  useEffect(() => {
    getUser().then(res => {
      setUser(res.data);
    });
  }, []);

  const [articles = [], setArticles] = useState<any>([]);
  useEffect(() => {
    listArticlesContent({
      limit: 6,
      page: 0,
      orderBy: "id",
      orderDirection: "desc",
    }).then(res => {
      setArticles(res.data);
    });
  }, []);

  const [qaVideos, setQaVideos] = useState<any>([]);
  useEffect(() => {
    listQA({ limit: 3, page: 0 }).then(res => {
      setQaVideos(res.data);
    });
  }, []);

  const [families, setFamilies] = useState<any>([]);
  useEffect(() => {
    listFamily({ limit: 2, page: 0 }).then(res => {
      setFamilies(res.data);
    });
  }, []);

  const [ambassadors, setAmbassadors] = useState<any>([]);
  useEffect(() => {
    listAmbassadorProfile({ limit: 1, page: 0 }).then(res => {
      setAmbassadors(res.data);
    });
  }, []);

  return (
    <PrivateLayout contentClassName="admin-dashboard">
      <div className="admin-top">
        <StandardButton
          label="+ New Ticket"
          variant="secondary"
          className="new-ticket-button"
          onClick={() => setNewTicketOpen(true)}
          standardBtnId="trigger-email-listing-new-btn"
        />
        {user ? (
          <div>
            <h1 className="title">Welcome, {user.first_name}!</h1>
            <div className="subtitle">
              {/* {user.firstName} {user.lastName} · Think-12 */}
              {user.firstname} {user.lastname} · Think-12
            </div>
            <div className="subtitle">
              {user.email} · {user.phone}
            </div>
          </div>
        ) : (
          []
        )}
      </div>

      <div className="admin-content-container">
        <div className="admin-content">
          <Row>
            <Col lg={6} className="dashboard-section">
              <h2>
                <ArticlesIcon /> Recent Articles
              </h2>
              <div className="articles-list">
                {articles.slice(0, 6).map((a: any) => {
                  return (
                    <div className="item" key={a.id}>
                      <div>
                        {a.articleTitle}
                        <br />
                        <span className="item-id">ID: {a.id}</span>
                      </div>
                      <div className="actions">
                        <a onClick={() => navigate("/article-preview/" + a.id)} target="_blank">
                          <EyeIcon />
                        </a>
                        <a onClick={() => navigate("/article-editor/" + a.id)} target="_blank">
                          <EditIcon />
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="dashboard-section-label">Manage ThinKTank article content.</div>
              <StandardButton
                label="Manage Articles"
                variant="outlined"
                size="sm"
                standardBtnId={`manage-articles`}
                onClick={() => navigate("/article-content-manager")}
              />
            </Col>

            <Col lg={4} className="dashboard-section">
              <h2>Reporting</h2>
              <div className="reporting-card">
                <img src={reportingImage} alt="" />
              </div>
              <div className="dashboard-section-label">
                Review engagement data and schedule reports.
              </div>
              <StandardButton
                label="View Engagement"
                variant="outlined"
                size="sm"
                standardBtnId={`reporting-view-engagement`}
                onClick={() => navigate("/program-reporting")}
              />
            </Col>

            <Col lg={12} className="dashboard-section">
              <h2>
                <VideosIcon /> Q&A Videos
              </h2>
              <div className="qa-videos">
                {qaVideos.slice(0, 3).map((qa: any) => {
                  return (
                    <div className="item" key={qa.id}>
                      <div>
                        {qa && qa.responses.length > 0 ? (
                          <video className="qa-video" src={qa.responses[0].responseUrl} />
                        ) : (
                          []
                        )}
                      </div>
                      <div className="item-content">
                        <p>{qa.topic}</p>
                        <span className="item-id">ID {qa.id}</span>
                        <div className="actions">
                          <a onClick={() => navigate("/q-and-a-editor/" + qa.id)} target="_blank">
                            <EditIcon />
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="dashboard-section-label">
                Manage Q&A Video questions and responses.
              </div>
              <StandardButton
                label="Manage Q&A Videos"
                variant="outlined"
                size="sm"
                standardBtnId={`manage-qa-videos`}
                onClick={() => navigate("/q-and-a-manager")}
              />
            </Col>

            <Col lg={6} className="dashboard-section">
              <h2>
                <FamilyIcon /> Family Network
              </h2>
              <div className="family-network">
                {families.slice(0, 3).map((family: any) => {
                  return (
                    <div className="item" key={family.id}>
                      <div>
                        <img src={family.profileImageUrl} alt={family.lastName} />
                      </div>
                      <div className="item-content">
                        <h6>{family.lastName}</h6>
                        <span className="item-id">ID {family.id}</span>
                        <div className="actions">
                          <a onClick={() => navigate("/family-editor/" + family.id)}>
                            <EditIcon />
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="dashboard-section-label">Manage Family Network profiles.</div>
              <StandardButton
                label="Manage Network"
                variant="outlined"
                size="sm"
                standardBtnId={`manage-network`}
                onClick={() => navigate("/family-manager")}
              />
            </Col>

            <Col lg={3} className="dashboard-section">
              <h2>
                <FamilyIcon /> Ambassadors
              </h2>
              <div className="ambassadors">
                {ambassadors.slice(0, 1).map((ambassador: any) => {
                  return (
                    <div className="ambassadors" key={ambassador.id}>
                      <img src={ambassador.profileImageUrl} alt={ambassador.name} />
                      <div className="item-content">
                        <h6>{ambassador.name}</h6>
                        <span className="item-id">ID {ambassador.id}</span>
                        <div className="actions">
                          <a onClick={() => navigate("/ambassador-editor/" + ambassador.id)}>
                            <EditIcon />
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="dashboard-section-label">Manage Ambassador profiles.</div>
              <StandardButton
                label="Manage Ambassadors"
                variant="outlined"
                size="sm"
                standardBtnId={`manage-ambassadors`}
                onClick={() => navigate("/ambassador-profile-manager")}
              />
            </Col>

            {/*
            <Col lg={9} className="dashboard-section">
              <h2>
                <GraduateIcon /> Graduate Gallery
              </h2>
              <div className="graduate-gallery">
                {[1, 2, 3].map(() => {
                  return (
                    <div className="item">
                      <img src="http://picsum.photos/250/250" />
                      <div className="item-content">
                        <h6>Ambassador Name</h6>
                        <span className="item-id">ID 1234</span>
                        <div className="actions">
                          <EyeIcon />
                          <EditIcon />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <StandardButton
                label="Manage Gallery"
                variant="outlined"
                size="sm"
                standardBtnId={`manage-gallery`}
              />
            </Col>
            */}

            {/*
            <Col lg={3} className="dashboard-section">
              <h2>
                <MessageIcon /> Messages
              </h2>
              <div className="messages">
                {[1, 2, 3, 4, 5].map(() => {
                  return (
                    <div className="item">
                      <div className="item-content">
                        <h6>Message Title</h6>
                        <span className="item-id">ID 1234</span>
                        <div className="actions">
                          <EyeIcon />
                          <EditIcon />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <StandardButton
                label="Manage Messages"
                variant="outlined"
                size="sm"
                standardBtnId={`manage-messages`}
              />
            </Col>
            */}

            {/*
            <Col lg={6} className="dashboard-section">
              <h2>Website Integration</h2>
              {DisabledModule}
              <StandardButton label="Manage Integrations" variant="outlined" size="sm" standardBtnId={`manage-messages`} />
            </Col>
            */}

            <Col lg={6} className="dashboard-section">
              <h2>Outcomes</h2>
              <DisabledModule />
              <div className="dashboard-section-label">
                Edit and update your Outcomes page to showcase your success stories.
              </div>
              <StandardButton
                label="Manage Outcomes"
                variant="outlined"
                size="sm"
                standardBtnId={`manage-outcomes`}
              />
            </Col>

            <Col lg={6} className="dashboard-section">
              <h2>Email Messaging</h2>
              <DisabledModule />
              <div className="dashboard-section-label">
                Use ThinK-12 Connect to create and manage robust email campaigns designed to drive
                engagement.
              </div>
              <StandardButton
                label="Manage messaging"
                variant="outlined"
                size="sm"
                standardBtnId={`manage-messages`}
              />
            </Col>

            <Col lg={6} className="dashboard-section">
              <h2>Data Manager</h2>
              <DisabledModule />
              <div className="dashboard-section-label">
                Use the data upload system to mass import data.
              </div>
              <StandardButton
                label="Manage Data"
                variant="outlined"
                size="sm"
                standardBtnId={`manage-data`}
              />
            </Col>
          </Row>
        </div>
      </div>
      <NewTicketModal open={newTicketOpen} setOpen={setNewTicketOpen} />
    </PrivateLayout>
  );
};

export default AdminDashboard;
