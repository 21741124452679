import { exportFamilyCsvFetcher, exportStaffCsvFetcher } from "../api/adminApis";

export const useDownloadCsv = (
  fetcher: typeof exportStaffCsvFetcher | typeof exportFamilyCsvFetcher
) => {
  const exportCsv = async () => {
    try {
      const response: any = await fetcher();
      const file = new Blob([response.data], { type: "text/csv" }); // Adjust the type based on your file
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", "users.csv"); // Set the file name
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up by revoking the Object URL and removing the link
      URL.revokeObjectURL(fileURL);
      link.remove();
    } catch {}
  };

  return {
    exportCsv,
  };
};
