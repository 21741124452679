import { IMediaSelected, IMediaUpload } from "../models/media";
import { IAppConfig, IThirdPartyJS } from "app/models/config";
import { IDemographic, IProgramFeature } from "../models/program";
import { useEffect, useRef } from "react";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { capitalize } from "lodash";

import { IMainProgramConfigBuilder, IMainProgramConfigResponse } from "../models/config";
import { IGradeBand, IProgramFeatureResponse } from "app/models/program";
import { appStateSelectors, useAppState } from "app/state/AppState";
import { processErrorResponse } from "app/utils/common";
import { UploadPopUpRef } from "app/screens/sitePhotos/uploadPopup/UploadPopup";
import usePagination, { IPaginationState } from "./Pagination";
import useApi from "./Api";
import { IStaffListItem } from "app/models/staff";
import {
  getAvailableFeatures,
  getProgramConfigurations,
  listStaffs,
  updateProgramConfigurations,
} from "app/api/adminApis";

export interface IConfigurationEditorSection {
  editor: Partial<IConfigurationEditor>;
  values: IMainProgramConfigBuilder;
  touched: FormikTouched<IMainProgramConfigBuilder>;
  errors: FormikErrors<IMainProgramConfigBuilder>;
}

export interface IConfigurationEditor {
  staffMembers: IPaginationState<IStaffListItem>;
  initialValues: IMainProgramConfigBuilder;
  appConfig: IAppConfig;
  gradeBands: IGradeBand[];
  demographics: IDemographic[];
  allAvailableFeatures: IProgramFeature[];
  programFeatures: IProgramFeature[];
  uploadLogoRef: React.MutableRefObject<UploadPopUpRef>;
  uploadBannerImageRef: React.MutableRefObject<UploadPopUpRef>;
  uploadFamilyImageRef: React.MutableRefObject<UploadPopUpRef>;
  uploadStaffImageRef: React.MutableRefObject<UploadPopUpRef>;
  uploadOutcomeImageRef: React.MutableRefObject<UploadPopUpRef>;
  uploadWelcomeImageRef: React.MutableRefObject<UploadPopUpRef>;
  uploadProfileBackupImageRef: React.MutableRefObject<UploadPopUpRef>;
  onFunctionalitySearch: (text: string) => void;
  onChange: (
    key: keyof IMainProgramConfigBuilder | string
  ) => (value: boolean | string | number | number[] | File) => void;
  onChangeGradeBand: (id: number, checked: boolean) => void;
  onFeatureChange: (featureId: number, type: "active" | "permitted", state: number) => void;
  onFeatureNameChange(featureId: number, name: string, field?: string): void;
  onMediaUpload: (
    attribute: keyof IMainProgramConfigBuilder,
    uploaded?: {
      file: File;
      tmpFileUrl: string;
      folderId: number;
    },
    attached?: {
      media: IMediaSelected;
    },
    remove?: boolean
  ) => void;
  onUpdateScript: (provider: string, body: string) => void;
  onSubmit: (email: IMainProgramConfigBuilder) => Promise<void>;
}

export const useConfigurationEditor = () => {
  const formRef = useRef<FormikProps<IMainProgramConfigBuilder>>(null);
  const uploadLogoRef = useRef<UploadPopUpRef>(null);
  const uploadBannerImageRef = useRef<UploadPopUpRef>(null);
  const uploadFamilyImageRef = useRef<UploadPopUpRef>(null);
  const uploadStaffImageRef = useRef<UploadPopUpRef>(null);
  const uploadOutcomeImageRef = useRef<UploadPopUpRef>(null);
  const uploadWelcomeImageRef = useRef<UploadPopUpRef>(null);
  const uploadProfileBackupImageRef = useRef<UploadPopUpRef>(null);

  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const demographics = useAppState(appStateSelectors.demographics);
  const programFeatures = useAppState(appStateSelectors.programFeatures);
  const appConfig = useAppState(appStateSelectors.appConfig);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const showNotificationWithVariant = useAppState(appStateSelectors.showNotificationWithVariant);
  const hideNotification = useAppState(appStateSelectors.hideNotification);

  const staffMembers = usePagination({ listFn: listStaffs });
  const [currentConfigs, , call] = useApi(getProgramConfigurations, {}, true, false);

  const [availableFeatures, featureApierror, featureApiCall] = useApi(
    getAvailableFeatures,
    {},
    true,
    false
  );

  useEffect(() => {
    getCurrentProgramConfigurations();
    getFeatures();
    return () => hideNotification();
  }, []);

  useEffect(() => {
    if (currentConfigs && availableFeatures) {
      prepopulateForm(currentConfigs, availableFeatures);
    } else if (currentConfigs && featureApierror) {
      prepopulateForm(currentConfigs, []);
    }
  }, [currentConfigs, availableFeatures]);

  const getCurrentProgramConfigurations = () => {
    call({ id: appConfig?.id });
  };

  const getFeatures = () => {
    featureApiCall();
  };

  const prepopulateForm = (
    configs: Partial<IMainProgramConfigResponse>,
    availableFeatures: IProgramFeatureResponse[]
  ) => {
    const formattedGradeBandIds = formatGradeBandResponse(configs);

    formRef.current?.setValues(prevValues => ({
      ...prevValues,
      ...configs,
      features: availableFeatures?.map(feature => {
        const matchingProgramFeature = (configs.features ?? []).find(f => f.id === feature.id);

        return {
          ...feature,
          is_togglable: feature?.is_togglable ? 1 : 0,
          is_active: matchingProgramFeature?.is_active ? 1 : 0,
          is_enabled: matchingProgramFeature?.is_enabled ? 1 : 0,
          is_permitted: matchingProgramFeature?.is_permitted ? 1 : 0,
        };
      }),
      grade_band_ids: formattedGradeBandIds,
    }));
  };

  const formatGradeBandResponse = (configs: Partial<IMainProgramConfigResponse> = {}) => {
    return (configs.grade_bands || []).map(gb => gb.id);
  };

  const onChange =
    (key: keyof IMainProgramConfigBuilder | string) =>
    (value: boolean | string | number | number[] | File) => {
      formRef.current?.setFieldValue(key, value);
    };

  const onChangeGradeBand = (id: number, checked: boolean) => {
    let updatedGradeBandIds = [...(formRef.current?.values.grade_band_ids || [])];
    if (checked) {
      if (!updatedGradeBandIds.includes(id)) {
        updatedGradeBandIds.push(id);
      }
    } else {
      updatedGradeBandIds = updatedGradeBandIds.filter(gradeBandId => gradeBandId !== id);
    }

    // Update the form field with new list
    formRef.current?.setFieldValue("grade_band_ids", updatedGradeBandIds);
  };

  const onFeatureChange = (id: number, type: "permitted" | "active", checked: number) => {
    const existingFeatures = [...(formRef?.current?.values?.features || [])];
    const updatedFeatures = existingFeatures.map(feature => {
      if (feature.id === id) {
        feature[`is_${type}`] = checked;
      }
      return feature;
    });
    formRef?.current?.setFieldValue("features", updatedFeatures);
  };

  const onFeatureNameChange = (
    id: number,
    name: string,
    field: "display_name" | "redirect_to" | "order_number" = "display_name"
  ) => {
    const existingFeatures = [...(formRef?.current?.values?.features || [])];
    const updatedFeatures = existingFeatures.map(feature => {
      if (feature.id === id) {
        feature[field] = name;
      }
      return feature;
    });
    formRef?.current?.setFieldValue("features", updatedFeatures);
  };

  const onMediaUpload = (
    attribute: keyof IMainProgramConfigBuilder,
    uploaded?: {
      file: File;
      tmpFileUrl: string;
      folderId: number;
    },
    attached?: {
      media: IMediaSelected;
    },
    remove?: boolean
  ) => {
    const { file, tmpFileUrl, folderId } = uploaded ?? {};
    const { media } = attached ?? {};

    const updates = media
      ? { image_id: media.id, ...(media as IMediaUpload) }
      : {
          image: file,
          folder_id: folderId,
          tmpFileUrl: tmpFileUrl ?? undefined,
        };

    formRef.current?.setValues(prevValues => ({
      ...prevValues,
      [attribute]: remove ? undefined : updates,
    }));
  };

  const onUpdateScript = (provider: string, body: string) => {
    let updatedDisassociate = formRef.current?.values?.dissociate ?? {};
    let updatedScripts = formRef.current?.values?.scripts ?? [];
    const index = updatedScripts.findIndex(script => script.name === capitalize(provider));

    if (body) {
      if (index === -1) {
        updatedScripts.push({
          program_id: appConfig!.id,
          name: capitalize(provider),
          provider: capitalize(provider),
          is_enabled: true,
          body,
        } as IThirdPartyJS);
      } else {
        updatedScripts[index].body = body;
      }

      updatedDisassociate.scripts = [];
    } else {
      if (index !== -1) {
        const id = updatedScripts[index].id;
        updatedScripts.splice(index, 1);
        if (id) {
          updatedDisassociate.scripts = [...(updatedDisassociate.scripts ?? []), { id }];
        }
      }
    }

    formRef.current?.setValues(prevValues => ({
      ...prevValues,
      disassociate: updatedDisassociate,
      scripts: updatedScripts,
    }));
  };

  const formatMedia = (media?: IMediaUpload): IMediaUpload => {
    if (!media) return {} as IMediaUpload;

    return media.id
      ? { image_id: media.id }
      : {
          image: media.image!,
          folder_id: media.folder_id,
          owner_id: appConfig?.id!,
          owner_type: "Program",
        };
  };

  const onCustomFooterChange = (content: string) => {
    formRef.current?.setValues(prevValues => ({
      ...prevValues,
      custom_footer: content,
    }));
  };

  const onVerificationCodeChange = (content: string) => {
    formRef.current?.setValues(prevValues => ({
      ...prevValues,
      verification_code: content,
    }));
  };

  const addCollection = (
    collectionName: string,
    collection: any[],
    formData: FormData
  ): FormData => {
    collection.forEach((item, index) => {
      formData.append(`${collectionName}[${index}]`, item);
    });
    return formData;
  };

  const buildFormData = (formData: FormData, data: any, parentKey: string | null) => {
    if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else if (parentKey) {
      const value = data == null ? "" : data;
      formData.append(parentKey, value);
    }
  };

  const formatParams = (params: IMainProgramConfigBuilder) => {
    const formatted = { ...params };

    const grade_bands = formatted.grade_band_ids ?? [];
    delete formatted.grade_band_ids;

    formatted.logo = formatMedia(formatted.logo);
    formatted.staff_hero_image = formatMedia(formatted.staff_hero_image);
    formatted.family_hero_image = formatMedia(formatted.family_hero_image);
    formatted.outcomes_hero_image = formatMedia(formatted.outcomes_hero_image);

    const formData = new FormData();
    buildFormData(formData, formatted, null);

    addCollection("grade_band_ids", grade_bands, formData);
    formData.append("_method", "PUT");

    return formData;
  };

  const onSubmit = async (config: Pick<IMainProgramConfigBuilder, "staff_spotlight_title">) => {
    showLoader();
    hideNotification();

    try {
      const programId = appConfig?.id;
      const paramAsFormData = formatParams(config);

      const result = await updateProgramConfigurations(programId!, paramAsFormData as FormData);
      result?.data && prepopulateForm(result.data, availableFeatures);
      showNotificationWithVariant("success", `Program Configuration updated successfully`);
      setTimeout(() => {
        hideNotification();
      }, 3000);
    } catch (error: any) {
      processErrorResponse({
        error,
        callback: errorMessage => showNotificationWithVariant("danger", errorMessage),
      });
      console.log("🚀 ~ file: ConfigurationEditor.ts ~ onSubmit ~ error", error);
    } finally {
      hideLoader();
    }
  };

  return {
    onCustomFooterChange,
    onVerificationCodeChange,
    staffMembers,
    initialValues,
    formRef,
    uploadLogoRef,
    uploadBannerImageRef,
    uploadFamilyImageRef,
    uploadStaffImageRef,
    uploadOutcomeImageRef,
    uploadWelcomeImageRef,
    uploadProfileBackupImageRef,
    gradeBands,
    demographics,
    programFeatures,
    onChange,
    onChangeGradeBand,
    onFeatureChange,
    onFeatureNameChange,
    onMediaUpload,
    onUpdateScript,
    onSubmit,
    appConfig,
  };
};

const initialValues: IMainProgramConfigBuilder = {
  id: undefined,
  dissociate: {},
};
