import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
  useMemo,
} from 'react';
import DragFileUpload from 'app/components/dragFileUpload/DragFileUpload';
import FileUploader, {
  FileUploaderRef,
} from 'app/components/fileUploader/FileUploader';
import { ITempMediaFile, IMediaExpanded } from 'app/models/common';
import { IMedia } from 'app/models/media';
import { IFolder } from 'app/models/folders';
import { IMediaSelected, IMAGE_MODES, MEDIA_TYPE } from 'app/models/media';
import T12Modal, { T12ModalRef } from 'app/components/modal/T12Modal';
import useApi from 'app/hooks/Api';
import {
  listFolders,
  listFoldersV2,
  listMedia,
  listMediaV2,
} from 'app/api/adminApis';
import { getBase64 } from 'app/utils/common';
import ModalHeaderTabs from 'app/components/tabs/ModalHeaderTabs';
import { IModalHeaderTab } from 'app/components/tabs/ModalHeaderTab';
import VideoPlay from 'app/components/videoPlay/VideoPlay';
import MediaUploader from 'app/components/mediaUploader/mediaUploader';
import FolderView from './FolderView';
import { ReactComponent as IcImage } from 'app/assets/icons/images.svg';
import { ReactComponent as IcFolderOpen } from 'app/assets/icons/folder-open.svg';
import { ReactComponent as IcFolder } from 'app/assets/icons/folder.svg';
import { ReactComponent as IcEdit } from 'app/assets/icons/edit.svg';
import { ReactComponent as IcPlay } from 'app/assets/icons/play.svg';
import { ReactComponent as IcImages } from 'app/assets/icons/images.svg';
import './upload-popup.scss';

const UploadPopup = React.forwardRef<UploadPopUpRef, IUploadPopupProps>(
  (
    {
      onConfirm,
      mediaType = MEDIA_TYPE.IMAGE,
      id,
      title,
      autoUpload = true,
      onFileSelect,
      owner,
      album = false,
    },
    ref
  ) => {
    const [activeTab, setActiveTab] = useState<string>(IMAGE_MODES.UPLOAD);
    const [imageMode, setImageMode] = useState<string>(IMAGE_MODES.UPLOAD);
    const [expanded, setExpanded] = useState<IMediaExpanded | null>(null);
    const [tempFile, setTempFile] = useState<ITempMediaFile | null>(null);
    const [fileSelected, setFileSelected] = useState<IMediaSelected | null>(
      null
    );

    const modalRef = useRef<T12ModalRef>(null);
    const uploaderRef = useRef<FileUploaderRef>(null);

    const [folders] = useApi(listFoldersV2, undefined, false);
    const [media, error, getMediaList] = useApi(
      listMediaV2,
      undefined,
      false,
      false
    );

    useImperativeHandle(
      ref,
      () => ({
        show: () => modalRef.current?.show(),
        close: () => modalRef.current?.close(),
      }),
      []
    );

    useEffect(() => {
      return resetState;
    }, []);

    useEffect(() => {
      if (expanded) {
        getMediaList({ id: expanded.id });
      }
    }, [expanded]);

    const resetState = () => {
      setImageMode(IMAGE_MODES.UPLOAD);
      setActiveTab(IMAGE_MODES.UPLOAD);
      setExpanded(null);
      setFileSelected(null);
      setTempFile(null);
    };

    const getFolderName = () => {
      if (!expanded) return '';
      const folder = folders.find((f) => f.id === expanded.id);
      return folder ? folder.name : '';
    };

    const getTempImage = () => {
      const image =
        activeTab === IMAGE_MODES.UPLOAD
          ? { uri: tempFile?.base64Str, name: tempFile?.file.name }
          : { uri: fileSelected?.path, name: fileSelected?.fileName };

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {mediaType === MEDIA_TYPE.IMAGE && (
            <img src={image.uri} style={{ height: 50, borderRadius: 10 }} />
          )}
          {mediaType === MEDIA_TYPE.VIDEO && (
            <VideoPlay
              videoPlayerId={`upload-video-player`}
              height='3.375rem'
              videoURL={image.uri || ''}
            />
          )}
          <div>{image.name}</div>
        </div>
      );
    };

    const disableUpload = () => {
      return activeTab === IMAGE_MODES.SELECT
        ? !fileSelected
        : !(tempFile && expanded);
    };

    const _onImageModeChange = (active: string) => {
      setFileSelected(null);
      setTempFile(null);
      setExpanded(null);
      setActiveTab(active);
    };

    const _onDrop = async (files: File[]) => {
      const base64Str = await getBase64(files[0]);
      setTempFile({ file: files[0], base64Str });
    };

    const _onSubmit = async () => {
      console.log('_onSubmit --');
      const useNewFileUpload = !!(tempFile && expanded);
      const useFileFromLibrary = !!(fileSelected && expanded);

      console.log('Autoupload is ', id, autoUpload);
      if (autoUpload) {
        _manageUploadOrSelection(useNewFileUpload, useFileFromLibrary);
        return;
      }

      console.log('Autoupload is false !! ', tempFile);
      if (useNewFileUpload) {
        onFileSelect?.(
          'UPLOAD',
          tempFile!.file,
          tempFile!.base64Str,
          +expanded.id
        );
        modalRef.current?.close();
      } else if (useFileFromLibrary) {
        onFileSelect?.('SELECT', undefined, undefined, undefined, {
          ...fileSelected,
          filename: fileSelected.fileName,
        });
        modalRef.current?.close();
      }
    };

    const _manageUploadOrSelection = (
      useNewFileUpload: boolean,
      useFileFromLibrary: boolean
    ) => {
      if (useNewFileUpload) {
        if (expanded?.id) {
          // uploaderRef.current?.upload([tempFile!.file], undefined, expanded?.id);
          uploaderRef.current?.upload(
            [tempFile!.file],
            '',
            expanded.id,
            owner.id!,
            owner.type,
            expanded?.id || ''
          );
        }
      } else if (useFileFromLibrary) {
        onConfirm({ fileUrl: fileSelected!.url, mediaId: fileSelected!.id });
        modalRef?.current?.close();
        resetState();
      }
    };

    const _onUploadComplete = useMemo(() => {
      return (uploadedData: any, key: any, files: any) => {
        if (uploadedData && uploadedData[0]) {
          onConfirm(uploadedData[0]);
        }
        modalRef?.current?.close();
        resetState();
      };
    }, []);

    return (
      <T12Modal
        size='lg'
        modalId={id}
        ref={modalRef}
        title={title}
        className='upload-popup'
        confirmButtonLabel='Upload'
        cancelButtonLabel='Cancel'
        showHeaderBorder
        closeOnConfirm={false}
        onConfirm={_onSubmit}
        confirmButtonProps={{ disabled: disableUpload() }}
        header={
          <ModalHeaderTabs
            id='profile_selector'
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={_onImageModeChange}
          />
        }
      >
        <div className='upload-modal'>
          {activeTab === IMAGE_MODES.UPLOAD && (
            <>
              <div className='drag-drop'>
                <DragFileUpload
                  id={`${id}-image-selector`}
                  label='Drop your file(s)'
                  onDrop={_onDrop}
                  buttonLabel='browse'
                  multiple
                  useNativeUploader
                />
              </div>
              <FileUploader
                ref={uploaderRef}
                onUploadComplete={_onUploadComplete}
                // useV2
                useV2={!autoUpload}
              />
            </>
          )}

          <div className='select-location'>
            <p className='select-location-title'>Select location</p>
            <div className='show-location'>
              <div style={{ marginTop: 0 }}>
                <FolderView
                  folders={folders}
                  selectedFolder={expanded}
                  selectedFile={fileSelected}
                  fileMedia={media?.images ?? []}
                  mode={activeTab}
                  onExpand={setExpanded}
                  onFileSelected={setFileSelected}
                />
              </div>
            </div>
          </div>

          <div className='uploaded-details'>
            {((!!tempFile && !!tempFile.file) || !!fileSelected) && (
              <>
                <p className='uploaded-details-title'>Media Name</p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {getTempImage()}
                  {expanded && imageMode === IMAGE_MODES.UPLOAD && (
                    <IcPlay style={{ marginLeft: 50 }} />
                  )}
                  {expanded && imageMode === IMAGE_MODES.UPLOAD && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: 50,
                      }}
                    >
                      <IcFolder />
                      <div>{getFolderName()}</div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </T12Modal>
    );
  }
);
export default UploadPopup;

interface IUploadPopupProps {
  id: string;
  onConfirm: (media?: any) => void;
  mediaType?: string;
  album?: boolean;
  title?: string;
  autoUpload?: boolean;
  owner: {
    id?: number;
    type: string;
  };
  onFileSelect?: (
    type: keyof typeof IMAGE_MODES,
    file?: File,
    tmpFileBase64?: string,
    folderId?: number,
    existingMedia?: IMediaSelected
  ) => void;
}

export type UploadPopUpRef = {
  show: () => void;
} | null;

const tabs: IModalHeaderTab[] = [
  {
    id: IMAGE_MODES.UPLOAD,
    label: 'Upload Image',
    icon: IcImage,
  },
  {
    id: IMAGE_MODES.SELECT,
    label: 'Choose from Photo Manager',
    icon: IcImages,
  },
];
