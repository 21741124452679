import { getSamlConfig, updateSamlConfig } from 'app/api/adminApis';
import { ISamlConfiguration } from 'app/models/samlConfiguration';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { FormikProps } from 'formik';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const useSamlConfiguration = () => {
  const navigate = useNavigate();
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const appConfig = useAppState(appStateSelectors.appConfig);

  const programId = appConfig?.id;

  const formRef = useRef<FormikProps<ISamlConfiguration>>(null);

  useEffect(() => {
    if (programId) {
      _getSamlConfigDetails();
    }
  }, [programId]);

  const _getSamlConfigDetails = async () => {
    showLoader();
    try {
      const { data } = await getSamlConfig(+programId!);
      // Map expected field names from API to the form fields
      const newData = {
        sp_entity_id: data.sp_entity_id,
        sp_acs_url: data.sp_acs_url,
        sp_sls_url: data.sp_sls_url,
        sp_certificate: data.sp_certificate,
        sp_private_key: data.sp_private_key,
        idp_entity_id: data.idp_entity_id,
        idp_sso_url: data.idp_sso_url,
        idp_slo_url: data.idp_slo_url,
        idp_cert: data.idp_cert,
      };
      formRef.current?.setValues(newData);
    } catch (error) {
      console.error('Error fetching SAML config details:', error);
    } finally {
      hideLoader();
    }
  };

  const onSubmit = async (
    values: ISamlConfiguration
  ) => {
    showLoader();
    try {
      const formData = {
        sp_entity_id: values.sp_entity_id,
        sp_acs_url: values.sp_acs_url,
        sp_sls_url: values.sp_sls_url,
        sp_certificate: values.sp_certificate,
        sp_private_key: values.sp_private_key,
        idp_entity_id: values.idp_entity_id,
        idp_sso_url: values.idp_sso_url,
        idp_slo_url: values.idp_slo_url,
        idp_cert: values.idp_cert,
      };

      await updateSamlConfig(+programId!, formData);
      navigate(-1);
    } catch (error) {
      console.error('Error submitting SAML configuration:', error);
    } finally {
      hideLoader();
    }
  };

  return {
    initialValues: initialState,
    formRef,
    onSubmit,
  };
};

export default useSamlConfiguration;

const initialState: ISamlConfiguration = {
  sp_entity_id: '',
  sp_acs_url: '',
  sp_sls_url: '',
  sp_certificate: '',
  sp_private_key: '',
  idp_entity_id: '',
  idp_sso_url: '',
  idp_slo_url: '',
  idp_cert: '',
};
