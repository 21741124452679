import ReportingSection from "../../reporting/ReportingSection";
import React, { useEffect } from "react";
import { useReportingProvider } from "../ReportingProvider";
import { GraphState } from "../types";

export const SectionChart = ({
  downloadReportCsv,
  title,
  fetcher,
  reportData,
}: {
  downloadReportCsv: (entity: string, url?: string) => void;
  title: string;
  reportData: GraphState;
  fetcher: () => void;
}) => {
  const { startDate, endDate, gradeBand, userType } = useReportingProvider();

  useEffect(() => {
    fetcher();
  }, [startDate, endDate, gradeBand, userType]);

  return (
    <ReportingSection
      title={title}
      onDownload={(url: any) => downloadReportCsv("family", url)}
      graph={reportData.reportGraph}
      pages={reportData.reportPages}
      count={reportData.reportCount}
    />
  );
};
