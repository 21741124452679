import React, { useState } from "react";
import StandardButton from "app/components/button/StandardButton";
import DataGrid from "app/components/dataGrid/DataGrid";
import Filter from "app/components/filter/Filter";
import FilterOption from "app/components/filter/FilterOption";
import SearchBar from "app/components/searchBar/SearchBar";
import useArticlesListing from "app/hooks/ArticleListing";
import PrivateLayout from "app/layout/PrivateLayout";
import { IArticleListItem } from "app/models/article";
import { IDesignation, ICategoryTag, IStatus, ISpecial } from "app/models/common";
import { IGradeBand } from "app/models/program";
import Checkbox from "app/components/checkbox/CheckBox";
import { useAppState, appStateSelectors } from "../../state/AppState";

import { assignArticlesToPrograms } from "app/api/adminApis";
import { SpecialTags } from "../../constants/articles";
import { usePermission } from "../../utils/user";
import { SelectPrograms } from "./SelectProgramStep";
import { ConfirmSelection } from "./ConfirmSelection";
import { SuccessSubmission } from "./SuccessSubmission";

const GRID_ID = "admin-content-sync";

const ContentSyncManager: React.FC = () => {
  const _articleListing = useArticlesListing();
  const { isSuperAdmin } = usePermission();
  const appConfig = useAppState(appStateSelectors.appConfig);

  const [selectedArticles, setSelectedArticles] = useState<number[]>([]);
  const [selectedPrograms, setSelectedPrograms] = useState<number[]>([]);
  const [step, setStep] = useState<number>(0);
  const [successMessage, setSuccessMesssage] = useState<string | undefined>("");

  const assignArticles = () => {
    assignArticlesToPrograms(selectedArticles, selectedPrograms).then(res => {
      setSuccessMesssage(res.data.message);
      setStep(3);
    });
  };

  const onlyValidArticles = (articles: any) => {
    return articles.filter((article: any) => {
      return (
        article.approveStatus === "Approved" &&
        article.activeStatus &&
        article.program_id === appConfig?.id
      );
    });
  };

  if (step === 3) {
    return <SuccessSubmission successMessage={successMessage} />;
  }

  if (step === 2) {
    return (
      <ConfirmSelection
        entityType="article"
        selectedEntities={selectedArticles.length}
        selectedPrograms={selectedPrograms.length}
        onCancel={() => setStep(0)}
        onSubmit={assignArticles}
      />
    );
  }

  if (step === 1) {
    return (
      <SelectPrograms
        onNextStep={() => setStep(2)}
        selectedPrograms={selectedPrograms}
        setSelectedPrograms={setSelectedPrograms}
      />
    );
  }

  return (
    <PrivateLayout contentClassName="article-listing">
      <h3 className="title">Content Sync - Select Articles</h3>
      <SearchBar
        placeHolder="Search by article title or content ID"
        searchBarId="article-listing-search"
        onChangeText={_articleListing.onSearchKeyChange}
        onSearch={_articleListing.onSearch}
      />
      <Filter
        id="article-listing-filter"
        onFilterChange={_articleListing.onFilterChange}
        selectedFilters={_articleListing.filters}
      >
        <FilterOption<IGradeBand>
          labelAttribute="gradeBand"
          valueAttribute="id"
          name="gradeBandIds"
          options={_articleListing.gradeBands}
          placeholder="Grade Band"
        />
        <FilterOption<IDesignation>
          labelAttribute="designationType"
          valueAttribute="id"
          name="designationIds"
          options={_articleListing.designations}
          placeholder="Designation"
        />
        <FilterOption<ICategoryTag>
          labelAttribute="categoryTag"
          valueAttribute="id"
          name="categoryIds"
          options={_articleListing.contentCategories}
          placeholder="Category Tags"
        />
        <FilterOption<IStatus>
          labelAttribute="status"
          valueAttribute="id"
          name="approval_status_ids"
          options={_articleListing.contentApprovals}
          placeholder="Status"
        />
        <FilterOption<ISpecial>
          labelAttribute="label"
          valueAttribute="id"
          name="specialTags"
          options={SpecialTags}
          placeholder="Special tags"
          isVisible={isSuperAdmin}
        />
      </Filter>
      <DataGrid<IArticleListItem>
        data={onlyValidArticles(_articleListing.data)}
        gridId={GRID_ID}
        loading={_articleListing.loading}
        confirmDeleteDescription="Please confirm that you would like to delete this article"
        rowIdAttribute="id"
        currentPage={_articleListing.currentPage}
        onPageChange={_articleListing.onPageChange}
        totalItems={_articleListing.totalItems}
        onAction={_articleListing.onGridAction}
      >
        <DataGrid.Column<IArticleListItem> attributeName="articleTitle" header="Article Title" />
        <DataGrid.Column<IArticleListItem> attributeName="articleId" header="Content ID" />
        <DataGrid.Column<IArticleListItem> attributeName="gradeBands" header="Grade Band" />
        <DataGrid.Column<IArticleListItem>
          attributeName="designationType"
          header="Designation Type"
        />
        <DataGrid.Column<IArticleListItem> attributeName="categoryTags" header="Category Tags" />
        <DataGrid.Column<IArticleListItem> attributeName="approveStatus" header="Approval Status" />
        <DataGrid.Column<IArticleListItem>
          attributeName="activeStatus"
          header="selected"
          transform={(article: any) => (
            <Checkbox
              id={`article-listing-active-status-${article.id}`}
              checked={selectedArticles.indexOf(article.id) > -1}
              onChange={val => {
                let newSelectedArticles = [...selectedArticles];
                if (!val) {
                  newSelectedArticles.splice(selectedArticles.indexOf(article.id), 1);
                } else {
                  newSelectedArticles.push(article.id);
                }
                setSelectedArticles(newSelectedArticles);
              }}
            />
          )}
        />
      </DataGrid>

      <div
        className="justify-content-center"
        style={{
          marginTop: "2em",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StandardButton
            label="Next"
            variant="secondary"
            onClick={() => setStep(1)}
            standardBtnId="article-listing-new-btn"
          />
        </div>
      </div>
    </PrivateLayout>
  );
};

export default ContentSyncManager;
