import React from "react";
import { ContainerSettings } from "./ContainerSettings";
import { Resizer } from "../../Render/Resizer";
import { getElementSize } from "../../utils/numToMeasurement";
import "./Container.scss";
import styled from "styled-components";

export type ContainerProps = {
  background: string;
  color: Record<"r" | "g" | "b" | "a", number>;
  flexDirection: string;
  alignItems: string;
  justifyContent: string;
  fillSpace: string;
  padding: string[];
  margin: string[];
  marginTop: number;
  marginLeft: number;
  marginBottom: number;
  marginRight: number;
  shadow: number;
  children: React.ReactNode;
  width: string;
  height: string;
  minWidth: string;
  minHeight: string;
  maxWidth: string;
  maxHeight: string;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

const defaultProps = {
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  fillSpace: "no",
  padding: ["0", "0", "0", "0"],
  margin: ["0", "0", "0", "0"],
  background: "#FFFFFF",
  color: { r: 0, g: 0, b: 0, a: 1 },
  shadow: 0,
  borderRadius: 0,
  width: "100%",
  height: "auto",
  display: "flex",
  minWidth: "unset",
  maxWidth: "unset",
  minHeight: "100%",
  maxHeight: "unset",
};

/**
 * @Deprecated
 */
export const DeprecatedContainer = (props: Partial<ContainerProps>) => {
  props = {
    ...defaultProps,
    ...props,
  };
  const {
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    background,
    color,
    padding,
    margin,
    shadow,
    borderRadius,
    children,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
  }: any = props;

  return (
    <Resizer
      propKey={{ width: "width", height: "height" }}
      className={"grid-container"}
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexBasis: "calc(40% - 10px)",
        justifyContent,
        flexDirection,
        alignItems,
        background,
        color: `rgba(${Object.values(color)})`,
        padding: getElementSize(padding),
        margin: getElementSize(margin),
        boxShadow: shadow === 0 ? "none" : `0px 3px 100px ${shadow}px rgba(0, 0, 0, 0.13)`,
        borderRadius: getElementSize(borderRadius),
        flex: fillSpace === "yes" ? 1 : "unset",
        minWidth: getElementSize(minWidth),
        maxWidth: getElementSize(maxWidth),
        minHeight: getElementSize(minHeight),
        maxHeight: getElementSize(maxHeight),
      }}
    >
      {children}
    </Resizer>
  );
};

const StyledResizer = styled(Resizer)`
  ${props =>
    props.xs &&
    `
    flex-basis: ${(props.xs / 12) * 100}%;
    max-width: ${(props.xs / 12) * 100}%;
  `};

  @media (min-width: 576px) {
    ${props =>
      props.sm &&
      `
      flex-basis: ${(props.sm / 12) * 100}%;
      max-width: ${(props.sm / 12) * 100}%;
    `}
  }
  @media (min-width: 768px) {
    ${props =>
      props.md &&
      `
      flex-basis: ${(props.md / 12) * 100}%;
      max-width: ${(props.md / 12) * 100}%;
    `}
  }
  @media (min-width: 992px) {
    ${props =>
      props.lg &&
      `
      flex-basis: ${(props.lg / 12) * 100}%;
      max-width: ${(props.lg / 12) * 100}%;
    `}
  }
  @media (min-width: 1200px) {
    ${props =>
      props.xl &&
      `
      flex-basis: ${(props.xl / 12) * 100}%;
      max-width: ${(props.xl / 12) * 100}%;
    `}
  }
`;

/**
 * @Deprecated
 */
export const GridItem = (props: Partial<ContainerProps>) => {
  props = {
    ...defaultProps,
    ...props,
  };
  const {
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    background,
    color,
    padding,
    margin,
    shadow,
    borderRadius,
    children,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
  }: any = props;

  const { xs, sm, md, xl, lg } = props;
  const getWidth = (size: number = 0) => (size ? `${(size / 12) * 100}%` : "auto");

  const mediaQueries = `
    @media (min-width: 576px) {
      .column-sm-${sm} { flex-basis: ${getWidth(sm)}; max-width: ${getWidth(sm)}; }
    }
    @media (min-width: 768px) {
      .column-md-${md} { flex-basis: ${getWidth(md)}; max-width: ${getWidth(md)}; }
    }
    @media (min-width: 1200px) {
      .column-lg-${lg} { flex-basis: ${getWidth(lg)}; max-width: ${getWidth(lg)}; }
    }
   
  `;
  const styles = {
    flexBasis: getWidth(xs),
    maxWidth: getWidth(xs),
  };

  return (
    <StyledResizer
      propKey={{ width: "width", height: "height" }}
      className={`column-xs-${xs} column-sm-${sm} column-md-${md} column-lg-${lg} column-xl-${xl}`}
      sm={sm}
      md={md}
      lg={lg}
      style={{
        display: "flex",
        flexWrap: "wrap",
        ...styles,
        flexBasis: "calc(40% - 10px)",
        justifyContent,
        flexDirection,
        alignItems,
        background,
        color: `rgba(${Object.values(color)})`,
        padding: getElementSize(padding),
        margin: getElementSize(margin),
        boxShadow: shadow === 0 ? "none" : `0px 3px 100px ${shadow}px rgba(0, 0, 0, 0.13)`,
        borderRadius: getElementSize(borderRadius),
        flex: fillSpace === "yes" ? 1 : "unset",
        minWidth: getElementSize(minWidth),
        maxWidth: getElementSize(maxWidth),
        minHeight: getElementSize(minHeight),
        maxHeight: getElementSize(maxHeight),
      }}
    >
      {children}
    </StyledResizer>
  );
};

DeprecatedContainer.craft = {
  displayName: "DeprecatedContainer",
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ContainerSettings,
  },
};

GridItem.craft = {
  displayName: "ContainerItem",
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ContainerSettings,
  },
};
