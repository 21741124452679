import PrivateLayout from "../../layout/PrivateLayout";
import SearchBar from "../../components/searchBar/SearchBar";
import DataGrid from "../../components/dataGrid/DataGrid";
import { IProgram } from "../../models/program";
import Checkbox from "../../components/checkbox/CheckBox";
import StandardButton from "../../components/button/StandardButton";
import React, { Dispatch, SetStateAction } from "react";
import useProgramListing from "../../hooks/ProgramListing";

const GRID_ID = "admin-content-sync";

export const SelectPrograms = ({
  selectedPrograms,
  setSelectedPrograms,
  onNextStep,
}: {
  selectedPrograms: number[];
  setSelectedPrograms: Dispatch<SetStateAction<number[]>>;
  onNextStep: () => void;
}) => {
  const _programListing = useProgramListing();

  return (
    <PrivateLayout contentClassName="article-listing">
      <h3 className="title">Content Sync - Select Programs</h3>
      <SearchBar
        placeHolder="Search by keyword, name of client/program"
        searchBarId="program-listing-search"
        onChangeText={_programListing.onSearchKeyChange}
        onSearch={_programListing.onSearch}
      />
      <DataGrid<IProgram>
        data={_programListing.data}
        gridId={GRID_ID}
        loading={_programListing.loading}
        rowIdAttribute="id"
        currentPage={_programListing.currentPage}
        onPageChange={_programListing.onPageChange}
        totalItems={_programListing.totalItems}
        onAction={_programListing.onGridAction}
      >
        <DataGrid.Column<IProgram> attributeName="schoolName" header="School Name" />
        <DataGrid.Column<IProgram> attributeName="programName" header="Program Name" />
        <DataGrid.Column<IProgram> attributeName="programUrl" header="Program Url" />
        <DataGrid.Column<any>
          attributeName="selected"
          header="selected"
          transform={(program: any) => (
            <Checkbox
              id={`program-listing-${program.id}`}
              checked={selectedPrograms.indexOf(program.id) > -1}
              onChange={val => {
                let newSelectedPrograms = [...selectedPrograms];
                if (!val) {
                  newSelectedPrograms.splice(selectedPrograms.indexOf(program.id), 1);
                } else {
                  newSelectedPrograms.push(program.id);
                }
                setSelectedPrograms(newSelectedPrograms);
              }}
            />
          )}
        />
      </DataGrid>

      <div
        className="justify-content-center"
        style={{
          marginTop: "2em",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StandardButton
            label="Next"
            variant="secondary"
            onClick={() => {
              window.scrollTo(0, 0);
              onNextStep();
            }}
            standardBtnId="content-sync"
          />
        </div>
      </div>
    </PrivateLayout>
  );
};
