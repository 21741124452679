import { ToolbarItem } from "../../Editor/Toolbar/ToolbarItem";
import { ToolbarSection } from "../../Editor/Toolbar/ToolbarSection";
import React from "react";

export const TextDecoration = () => {
  return (
    <ToolbarSection title="Decoration">
      <ToolbarItem type="text" label="Font family" propKey="fontFamily" />
      <div className="d-flex justify-content-between gap-3">
        <ToolbarItem type="number" label="Font size" propKey="fontSize" />
        <ToolbarItem type="text" label="Font weight" propKey="fontWeight" />
      </div>
      <div className="d-flex justify-content-between gap-3">
        <ToolbarItem type="text" label="Text decoration" propKey="textDecoration" />
        <ToolbarItem type="text" label="Text align" propKey="textAlign" />
      </div>
    </ToolbarSection>
  );
};
