import { IPaginationParams, IPaginationResponse } from "../../models/api";
import { AxiosRequestConfig } from "axios";
import { api } from "../adminApis";

export type ContentCategoryInterface = {
  id?: number;
  category_tag: string;
};

export const listContentMetadata = (
  metadata: string,
  params?: IPaginationParams,
  config?: AxiosRequestConfig
) => {
  const { page, limit } = params || ({} as IPaginationParams);
  const paginationParams = api.encodeQueryParams({
    page,
    limit,
  });
  return api.get<IPaginationResponse<ContentCategoryInterface>>(
    `/metadata/${metadata}${paginationParams}`,
    params,
    config
  );
};

export const postContentMetadata = (metadata: string, body: object) => {
  return api.post(`/metadata/${metadata}`, body);
};

export const updateContentMetadata = (id: number, metadata: string, body: object) => {
  return api.put(`/metadata/${id}/${metadata}`, body);
};

export const deleteContentMetadata = (id: number, metadata: string) => {
  return api.delete(`/metadata/${id}/${metadata}`);
};
