import PrivateLayout from "../../layout/PrivateLayout";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import useAnnouncementListing, {
  AnnouncementInitialValues,
} from "../../hooks/AdminAnnouncementListing";
import { Col, Row } from "react-bootstrap";
import Input from "../../components/input/Input";
import ScreenNotification from "../../components/screenNotification/ScreenNotification";
import StandardButton from "../../components/button/StandardButton";
import ContentEditor from "../../components/contentEditor/HtmlContentEditor";
import DropDown from "../../components/dropDown/DropDown";
import {
  AnnouncementLevel,
  AnnouncementStatus,
  AnnouncementType,
  IActiveStatus,
  IAnnouncementLevel,
  IAnnouncementType,
} from "../../models/adminAnnouncement";
import { useParams } from "react-router-dom";

export const AnnouncementForm = () => {
  const { formRef, onSubmitForm, populateForm, data } = useAnnouncementListing();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      populateForm(+params.id);
    }
  }, [params, params.id, data.length]);

  return (
    <PrivateLayout>
      <h3 className="title">Announcement form</h3>
      <ScreenNotification variant="danger" styles={{ container: { marginTop: "2rem" } }} />
      <Formik innerRef={formRef} initialValues={AnnouncementInitialValues} onSubmit={onSubmitForm}>
        {({ values, setFieldValue, isValid, dirty }) => {
          return (
            <Form>
              <Row>
                <Col md={7}>
                  <Col md={12}>
                    <Input id="announcement-title" label="Title" name="title" />
                  </Col>
                  <Col md={12}>
                    <ContentEditor
                      id="article-editor-content"
                      height="32rem"
                      onEditorChange={(_, editor) => {
                        const editorText = editor.getContent();
                        setFieldValue("content", editorText);
                      }}
                      value={values.content}
                    />
                  </Col>
                </Col>
                <Col md={4}>
                  <Col md={6}>
                    <DropDown<IActiveStatus>
                      label="Status"
                      id="status"
                      labelAttribute="label"
                      options={AnnouncementStatus}
                      selected={values.status}
                      valueAttribute="id"
                      placeholder="Status"
                      onOptionChange={val => setFieldValue("status", val)}
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown<IAnnouncementType>
                      label="Type"
                      id="type"
                      labelAttribute="label"
                      options={AnnouncementType}
                      selected={values.type}
                      valueAttribute="id"
                      placeholder="Type"
                      onOptionChange={val => setFieldValue("type", val)}
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown<IAnnouncementLevel>
                      label="Level"
                      id="level"
                      labelAttribute="label"
                      options={AnnouncementLevel}
                      selected={values.level}
                      valueAttribute="id"
                      placeholder="Level"
                      onOptionChange={val => setFieldValue("level", val)}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="datetime-local"
                      id="announcement-start-date"
                      label="Start date"
                      name="start_date"
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="datetime-local"
                      id="announcement-start-date"
                      label="End date"
                      name="end_date"
                    />
                  </Col>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="d-flex justify-content-end">
                  <StandardButton
                    type="submit"
                    variant="secondary"
                    label="Save Changes"
                    disabled={!dirty || !isValid}
                    className="save-button"
                    standardBtnId="announcement-builder-save-btn"
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </PrivateLayout>
  );
};
