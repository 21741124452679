import React from 'react';
import { Col, Row } from 'react-bootstrap';

import OutcomeSelector, {
  IOutcomeSelectorProps,
} from '../dropDown/OutcomeSelector';
import If from '../If';
import Input from '../input/Input';
import { IFamilyEditorV2 } from 'app/models/family';
import RoundButton from 'app/components/button/RoundButton';
import { ReactComponent as IcClose } from 'app/assets/icons/close.svg';
import './education-editor.scss';

const EducationEditor: React.FC<IEducationEditorProps> = ({
  index,
  variant,
  label,
  selected,
  onChange,
  onDelete,
}) => {
  const educationId = 'education-editor';

  return (
    <div className='education-editor-container'>
      <If condition={!!label}>
        <div className='education-member'>{`${label} ${index + 1}`}</div>
      </If>
      <Row className='align-items-center'>
        <Col>
          <Input
            id={`${educationId}-degree`}
            label='Degree'
            variant='secondary'
            name={`educations[${index}].degree`}
          />
        </Col>
        <Col>
          <Input
            id={`${educationId}-name`}
            label='Name'
            variant='secondary'
            name={`educations[${index}].name`}
          />
        </Col>
        <Col>
          <OutcomeSelector
            eltId={`${educationId}-outcome`}
            variant={variant}
            selected={selected}
            onChange={onChange}
          />
        </Col>
        <Col md='auto'>
          <div className='d-flex align-items-center'>
            <RoundButton
              roundBtnId={`${index}-education-delete`}
              icon={<IcClose />}
              className='button'
              onClick={() => onDelete && onDelete(index)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EducationEditor;

interface IEducationEditorProps
  extends Pick<IOutcomeSelectorProps, 'variant' | 'selected' | 'onChange'> {
  values?: IFamilyEditorV2;
  index: number;
  label?: string;
  onUserSelect?: (index: number, user: string | number) => void;
  onDelete?: (index: number) => void;
}
