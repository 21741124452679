import React, { Dispatch, SetStateAction } from "react";
import NewTicket from "../support/NewTicket";
import { Modal } from "react-bootstrap";
import "./NewTicketModal.scss";

export const NewTicketModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Modal
      onHide={() => setOpen(false)}
      fullscreen={true}
      hideFooter
      modalId={"NewticketModal"}
      show={open}
      title="New ticket"
      showHeaderBorder
      className="NewTicketModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>New ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxWidth: "unset" }}>
        <NewTicket />
      </Modal.Body>
    </Modal>
  );
};
