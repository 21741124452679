import SearchBar from "../../../components/searchBar/SearchBar";
import Checkbox from "../../../components/checkbox/CheckBox";
import Section from "../../../components/section/Section";
import React, { useState } from "react";
import { useFormikContext } from "formik";
import { IClubEditor } from "../../../models/clubs";
import { listFamily } from "../../../api/adminApis";

export const FamilySection = () => {
  const { values, setFieldValue } = useFormikContext<IClubEditor>();
  const [familySearch, setFamilySearch] = useState<string>();
  const [familyResults, setFamilyResults] = useState<Array<any>>([]);
  const { families } = values;

  const doFamilySearch = (search: string) => {
    listFamily({
      limit: 10,
      page: 0,
      searchKey: search,
    }).then((res: any) => {
      setFamilyResults(res.data);
    });
  };

  const onUpdateFamily = (isChecked: boolean, family: any) => {
    let newFamilies;
    if (!isChecked) {
      newFamilies = families?.filter(f => f.id !== family.id);
    } else {
      newFamilies = [...(families || []), family];
    }
    setFieldValue("families", newFamilies);
  };

  return (
    <Section sectionId="club-editor-staff" title="Families that are part of the club">
      Select the Families that are a part of this club
      <div className="letters">
        {"ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((letter, index) => {
          return (
            <div
              key={index}
              className="letter"
              onClick={() => {
                setFamilySearch(letter);
                doFamilySearch(letter);
              }}
            >
              {letter}
            </div>
          );
        })}
      </div>
      <SearchBar
        searchBarId="families-search"
        onChangeText={(val: string) => setFamilySearch(val)}
        onSearch={() => doFamilySearch(familySearch ? familySearch : "")}
      />
      <div className="families-table">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Family Name</th>
              <th>Family Members</th>
              <th>Grade</th>
            </tr>
          </thead>
          <tbody>
            {familyResults.map((family, index) => {
              let isMember = false;
              families?.forEach(fam => {
                if (fam.id === family.id) {
                  isMember = true;
                }
              });
              return (
                <tr key={index}>
                  <td>
                    <Checkbox
                      id="notification-check"
                      label=""
                      checked={isMember}
                      onChange={(isChecked: boolean) => onUpdateFamily(isChecked, family)}
                    />
                  </td>
                  <td className="family-name">{family.last_name}</td>
                  <td>{family.member_names.join(", ")}</td>
                  <td>
                    {family.members.reduce((acc: string, member: any) => {
                      member.grades?.forEach((grade: any) => {
                        if (acc.indexOf(grade.grade_band?.grade_band) === -1) {
                          acc += grade.grade_band?.grade_band + ", ";
                        }
                      });
                      return acc;
                    }, "")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Section>
  );
};
