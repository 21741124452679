import React from "react";
import { useNode } from "@craftjs/core";
import styled from "styled-components";
import { VideoSettings } from "./VideoSettings";
import { getElementSize } from "../../utils/numToMeasurement";

type IProps = {
  src?: string;
  alt?: string;
  height?: string | number;
  width?: string | number;
  margin?: any[];
  borderRadius?: string;
};

const StyledVideo = styled.video<IProps>`
  margin: ${({ margin }) => `${margin?.[0]}px ${margin?.[1]}px ${margin?.[2]}px ${margin?.[3]}px`};
  border-radius: ${({ borderRadius }) => getElementSize(borderRadius)};
`;

export const Video = ({ src, alt, width, height, ...otherProps }: IProps) => {
  const {
    connectors: { connect },
  } = useNode(node => ({
    selected: node.events.selected,
  }));

  return (
    <StyledVideo
      width={width}
      height={height}
      src={src}
      controls
      data-cy="toolbox-video"
      ref={connect as any}
      {...otherProps}
    >
      <source src={src} type="video/mp4" />
      <source src={src} type="video/ogg" />
    </StyledVideo>
  );
};

Video.craft = {
  displayName: "Video",
  props: {
    src: "",
    alt: "Upload image from customize",
    width: 150,
    height: 150,
    borderRadius: 0,
    margin: ["0", "0", "0", "0"],
  },
  related: {
    settings: VideoSettings,
    toolbar: VideoSettings,
  },
};
