import React from "react";
import { getElementSize } from "../../utils/numToMeasurement";

export const TEXT = ({
  text,
  style,
}: {
  text?: string;
  style: {
    height?: string | number;
    width?: string | number;
    margin?: any[];
    color?: string;
    background?: string;
    fontFamily?: string;
    fontSize?: string;
    fontWeight?: string;
    textDecoration?: string;
    textAlign?: any;
  };
}) => (
  <div
    dangerouslySetInnerHTML={{ __html: text as string }}
    style={{
      margin: `${style?.margin?.[0]}px ${style?.margin?.[1]}px ${style?.margin?.[2]}px ${style?.margin?.[3]}px`,
      height: isNaN(Number(style?.height)) ? style?.height : `${style?.height}px`,
      width: isNaN(Number(style?.width)) ? style?.width : `${style?.width}px`,
      background: style?.background,
      color: style?.color,
      fontFamily: style.fontFamily,
      fontSize: getElementSize(style.fontSize),
      fontWeight: style.fontWeight,
      textDecoration: style.textDecoration,
      textAlign: style.textAlign,
    }}
  />
);
