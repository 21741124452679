import React from "react";
import { useNavigate } from "react-router-dom";

import SideNavBarItem from "./SideNavBarItem";
import ROUTES from "app/constants/navigation";
import { logout } from "app/api/authApis";
import { INavOptions } from "app/models/common";
import { authStateSelectors, useAuthState } from "app/state/AuthState";
import { appStateSelectors, useAppState, useFeaturesGate } from "app/state/AppState";
import { ReactComponent as IcArticles } from "app/assets/icons/articles.svg";
import { ReactComponent as IcVideoCamera } from "app/assets/icons/video-camera.svg";
import { ReactComponent as IcFamily } from "app/assets/icons/family.svg";
import { ReactComponent as IcUserExecutive } from "app/assets/icons/user-executive.svg";
import { ReactComponent as IcConfiguration } from "app/assets/icons/configuration.svg";
import { ReactComponent as IcClubs } from "app/assets/icons/clubs.svg";
import { ReactComponent as IcLock } from "app/assets/icons/lock.svg";
import { ReactComponent as IcOutcomes } from "app/assets/icons/outcome.svg";
import { ReactComponent as IcHome } from "app/assets/icons/home.svg";
import { ReactComponent as IcGraduate } from "app/assets/icons/lock.svg";
import { ReactComponent as IcPhotos } from "app/assets/icons/photo.svg";
import { ReactComponent as IcSend } from "app/assets/icons/send.svg";
import { ReactComponent as IcReporting } from "app/assets/icons/reporting.svg";
import { ReactComponent as IcProgram } from "app/assets/icons/program.svg";
import { ReactComponent as IcMessages } from "app/assets/icons/messages.svg";
import { ReactComponent as IcUploadMgr } from "app/assets/icons/upload.svg";
import { ReactComponent as IcLogout } from "app/assets/icons/logout.svg";
import "./navbar.scss";

import { FEATURES } from "app/constants/features";
import { usePermission } from "../../utils/user";
import { SUPER_ADMIN } from "../../layout/FeatureGate";

const SideNavBar: React.FC = () => {
  const showLoader = useAppState(appStateSelectors.showLoader);
  const user = useAuthState(authStateSelectors.user);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const appConfig = useAppState(appStateSelectors.appConfig);
  const navigate = useNavigate();
  const { isSuperAdmin } = usePermission();
  const { features } = useFeaturesGate();

  const rolePermissions = appConfig?.rolePermissions || [];

  const _logout = async () => {
    showLoader();
    try {
      await logout();
    } catch (error) {
      console.log("Error in logout", error);
    } finally {
      localStorage.removeItem("token");
      navigate(ROUTES.SIGN_IN);
      hideLoader();
    }
  };

  const _isFeatureActive = (path: string) => {
    if (SUPER_ADMIN.includes(path as ROUTES) && !isSuperAdmin) {
      return false;
    }
    if (path === ROUTES.COLLEGE_MANAGER && !isSuperAdmin) {
      return false;
    }

    if (path === ROUTES.CONTENT_METADATA) {
      return !!user?.roles?.some(role => role.name === "Think12 Admin");
    }

    if (path === "/content-sync-manager") {
      return !!user?.roles?.some(role => role.name === "Think12 Admin");
    }
    const featureTag = FEATURES[path];
    if (!featureTag) return true;
    const matchedFeature = features.find(f => f.tag === featureTag && f.type === "admin");

    // If the feature isn't found, or not active/enabled, return false
    if (!matchedFeature || !matchedFeature.current_program_enabled) {
      return false;
    }

    if (user?.roles?.some(role => role.name === "Think12 Admin")) return true;

    const matchedRolePermission = rolePermissions.find(rp => rp.feature_id === matchedFeature.id);

    // If there's no rolePermission for the feature, the default is no access
    if (!matchedRolePermission) {
      return false;
    }

    const hasMatchingRole = user?.roles?.some(role => role.id === matchedRolePermission.role_id);
    console.log("User has matching role:", hasMatchingRole);

    return hasMatchingRole || false;
  };

  return (
    <div className="side-nav-bar">
      {items.map(item =>
        _isFeatureActive(item.to) ? (
          <SideNavBarItem key={`sidenavbar-item-${item.to}`} {...item} />
        ) : (
          []
        )
      )}
      <SideNavBarItem name="Logout" className="divider" icon={IcLogout} onClick={_logout} />
    </div>
  );
};

export default SideNavBar;

const items: INavOptions[] = [
  {
    name: "Admin Dashboard",
    to: ROUTES.ADMIN_DASHBOARD,
    icon: IcHome,
  },
  {
    name: "Articles",
    to: ROUTES.ARTICLE_CONTENT_MANAGER,
    icon: IcArticles,
    feature: "article",
  },
  {
    name: "Content Sync",
    to: ROUTES.CONTENT_SYNC_MANAGER,
    icon: IcArticles,
    feature: "content-sync",
  },
  {
    name: "Q & A",
    to: ROUTES.Q_AND_A_MANAGER,
    icon: IcVideoCamera,
    feature: "qa",
  },
  {
    name: "Q&A Content Sync",
    to: ROUTES.QA_CONTENT_SYNC_MANAGER,
    icon: IcVideoCamera,
    feature: "qa",
  },
  {
    name: "Family",
    to: ROUTES.FAMILY_MANAGER,
    icon: IcFamily,
    feature: "family",
  },
  {
    name: "Staff",
    to: ROUTES.STAFF_MANAGER,
    icon: IcUserExecutive,
    feature: "staff",
  },
  {
    name: "Configuration",
    to: ROUTES.CONFIGURATION_MANAGER,
    icon: IcConfiguration,
    feature: "configuration",
  },
  {
    name: "SSO Manager",
    to: ROUTES.SSO_MANAGER,
    icon: IcConfiguration,
    feature: "sso-manager",
  },
  {
    name: "Graduate",
    to: ROUTES.GRADUATE_MANAGER,
    icon: IcGraduate,
    feature: "graduate",
  },
  {
    name: "Alumni",
    to: ROUTES.ALUMNI_MANAGER,
    icon: IcGraduate,
    feature: "alumni_network",
  },
  {
    name: "Ambassador",
    to: ROUTES.AMBASSADOR_PROFILE_MANAGER,
    icon: IcOutcomes,
    feature: "ambassador",
  },
  {
    name: "Page builder",
    to: ROUTES.PAGE_BUILDER,
    icon: IcArticles,
    showDivider: true,
    feature: "admin",
  },
  {
    name: "Clubs",
    to: ROUTES.CLUBS_MANAGER,
    icon: IcClubs,
    feature: "club",
  },
  {
    name: "Admin",
    to: ROUTES.ADMIN_MANAGER,
    icon: IcLock,
    feature: "admin",
  },
  {
    name: "Outcomes",
    to: ROUTES.OUTCOMES_MANAGER,
    icon: IcOutcomes,
    feature: "outcome",
  },
  {
    name: "Homepage",
    to: ROUTES.HOMEPAGE_MANAGER,
    icon: IcHome,
    feature: "homepage",
  },
  {
    name: "Landing Page",
    to: ROUTES.LANDING_PAGE,
    icon: IcHome,
    feature: "landing_page",
  },
  {
    name: "Custom CSS",
    to: ROUTES.CUSTOM_CSS,
    icon: IcHome,
    feature: "admin",
  },
  {
    name: "User Account Manager",
    to: ROUTES.USER_PROFILE_MANAGER,
    icon: IcHome,
    feature: "user_profile",
  },
  {
    name: "Photos Library",
    to: ROUTES.PHOTOS_MANAGER,
    icon: IcPhotos,
    feature: "photo_library",
  },
  // TODO: Need to group Email navigation
  {
    name: "Email",
    to: ROUTES.EMAIL_MANAGER,
    icon: IcSend,
    feature: "email",
  },
  {
    name: "Email Schedule Manager",
    to: ROUTES.EMAIL_SCHEDULE_MANAGER,
    icon: IcSend,
    feature: "email_schedule",
  },
  {
    name: "Email Reporting",
    to: ROUTES.EMAIL_REPORTING,
    icon: IcSend,
    feature: "email_reporting",
  },
  {
    name: "Admin Triggered Email Manager",
    to: ROUTES.ADMIN_TRIGGERED_EMAIL_MANAGER,
    icon: IcSend,
    feature: "email_trigger",
  },
  {
    name: "Program Reporting",
    to: ROUTES.PROGRAM_REPORTING,
    icon: IcReporting,
    feature: "program_reporting",
  },
  {
    name: "Program Listing",
    to: ROUTES.PROGRAM_MANAGER,
    icon: IcProgram,
    feature: "program_listing",
  },
  {
    name: "Announcements",
    to: ROUTES.ADMIN_ANNOUNCEMENT_MANAGER,
    icon: IcUploadMgr,
    feature: "announcement",
  },
  {
    name: "Data Upload Manager",
    to: ROUTES.DATA_UPLOAD_MANAGER,
    icon: IcUploadMgr,
    feature: "data_upload",
  },
  {
    name: "College Manager",
    to: ROUTES.COLLEGE_MANAGER,
    icon: IcUploadMgr,
    feature: "data_upload",
  },
  {
    name: "Content metadata",
    to: ROUTES.CONTENT_METADATA,
    icon: IcUploadMgr,
    feature: "content_metadata",
  },
  {
    name: "Messages",
    to: ROUTES.MESSAGES_MANAGER,
    icon: IcMessages,
    feature: "message",
  },
];
