

export const buildJsonFromForm = (formCurrent: any) => {
  const formData = new FormData(formCurrent);
  return parseFormDataToNestedObject(formData);
}

function parseFormDataToNestedObject(formData: FormData) {
  const data: { [key: string]: any } = {};
  formData.forEach((value, key) => {
    // Split the key into parts (e.g., 'school.location.line1' -> ['school', 'location', 'line1'])
    const keys = key.split('.');
    keys.reduce((acc, cur, idx) => {
      // Check if we are at the last key, which means we should set the value
      if (idx === keys.length - 1) {
        acc[cur] = value;
        return value;
      }
      // Otherwise, proceed to create or traverse the next level of the object
      if (!acc[cur]) acc[cur] = {};
      return acc[cur];
    }, data);
  });
  return data;
}
