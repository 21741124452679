import { getUser } from 'app/api/authApis';
import {
  getAppConfig,
  getCommonAppData,
  getOutcomeList,
  getPreferenceList,
  getNotificationTypeList,
} from 'app/api/commonApis';
import { NAVIGATION_STATE } from 'app/constants/common';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { authStateSelectors, useAuthState } from 'app/state/AuthState';
import { useEffect } from 'react';
import useApi from './Api';

const useApp = () => {
  const navState = useAuthState(authStateSelectors.navState);
  const setUser = useAuthState(authStateSelectors.setUser);
  const setCommonAppData = useAppState(appStateSelectors.setCommonAppData);
  const setPreferenceData = useAppState(appStateSelectors.setPreferences);
  const setAppConfig = useAppState(appStateSelectors.setAppConfig);
  const setOutcomes = useAppState(appStateSelectors.setOutcomes);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);

  const [commonAppData] = useApi(getCommonAppData, undefined, false);

  useEffect(() => initializeApp(), []);

  useEffect(() => setCommonAppData(commonAppData), [commonAppData]);

  const initializeApp = () => {
    showLoader();
    try {
      const url = window.location.hostname;
      getConfigurations();
      getOutcomes();
      getPreferences();
      localStorage.getItem('token') && getCurrentUser();
    } catch (error) {
      console.error('Error initializing app', error);
    } finally {
      hideLoader();
    }
  };

  const getCurrentUser = async () => {
    showLoader();
    try {
      const { data } = await getUser();
      setUser(data);
    } catch (error) {
      console.error('Error getting current user', error);
    } finally {
      hideLoader();
    }
  };

  const getConfigurations = async () => {
    try {
      const { data } = await getAppConfig();
      setAppConfig(data);
    } catch (error) {
      console.error('Error getting configuration', error);
    }
  };

  const getOutcomes = async () => {
    try {
      const { data } = await getOutcomeList();
      setOutcomes(
        data.sort((outcome1, outcome2) =>
          outcome1.outcomeTitle.localeCompare(outcome2.outcomeTitle)
        )
      );
    } catch (error) {
      console.error('Error getting outcome list', error);
    }
  };

  const getPreferences = async () => {
    try {
      const response = await getPreferenceList();
      if (response?.data && response?.status === 200) {
        setPreferenceData(response.data);
      }
    } catch (error) {
      console.error('Error getting program preferences: ', error);
    }
  };

  return { navState };
};

export default useApp;
