import { useNavigate, useHref } from 'react-router-dom';
import moment from 'moment';

import { useAppState, appStateSelectors } from './../state/AppState';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import { ISelectedOption } from 'app/components/selectedOption/SelectedOptionContainer';
import { IEmailScheduleManager } from 'app/models/email';
import { getEmailSchedules, updateEmailSchedule } from 'app/api/adminApis';
import ROUTES from 'app/constants/navigation';
import { processErrorResponse, indexOf } from 'app/utils/common';
import usePagination from './Pagination';

const useEmailScheduleManagerListing = () => {
  const navigate = useNavigate();

  const previewHref = useHref(ROUTES.EMAIL_PREVIEW);

  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const contentDesignations = useAppState(
    appStateSelectors.contentDesignations
  );
  const categoryTags = useAppState(appStateSelectors.contentCategories);
  const approvalStatuses = useAppState(appStateSelectors.contentApprovals);
  const activeStatuses = useAppState(appStateSelectors.activeStatuses);
  const showNotification = useAppState(appStateSelectors.showNotification);
  const hideNotification = useAppState(appStateSelectors.hideNotification);
  const populations = [
    { id: 1, population: 'Registered' },
    { id: 2, population: 'Non-registered' },
    { id: 3, population: 'All' },
  ];

  const _pagination = usePagination<IEmailScheduleManager>({
    listFn: getEmailSchedules,
  });

  const onScheduleNewEmailClick = () => navigate(ROUTES.EMAIL_SCHEDULE_EDITOR);

  const onGridAction = (action: DATA_GRID_ACTIONS, id: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.PREVIEW:
        const hostname = window.location.hostname;
        const domain = window.location.host;
        const protocol = hostname === 'localhost' ? 'http' : 'https';

        window.open(`${protocol}://${domain}/${previewHref}/${id}`, '_blank');
        break;
      case DATA_GRID_ACTIONS.EDIT_SCHEDULE:
        navigate(`${ROUTES.EMAIL_SCHEDULE_EDITOR}/${id}`);
        break;
    }
  };

  const getCalendarFilterValues = () => {
    return {
      startDate: _pagination.filters?.startDate,
      endDate: _pagination.filters?.endDate,
    };
  };

  const getSelectedOptionsForCalendarFilter = (
    filters?: Record<string, any>
  ) => {
    if (!filters) return [{}] as ISelectedOption[];

    let options = [];
    if (filters.startDate) {
      options.push({
        id: -1,
        label: `From: ${moment(filters.startDate[0]).format('MM/DD/YY')}`,
        categ: 'startDate',
      });
    }

    if (filters.endDate) {
      options.push({
        id: -1,
        label: `To: ${moment(filters.endDate[0]).format('MM/DD/YY')}`,
        categ: 'endDate',
      });
    }

    return options as ISelectedOption[];
  };

  const onToggleActivateStatus =
    (schedule: IEmailScheduleManager) => async (checked: boolean) => {
      hideNotification();

      try {
        const result = await updateEmailSchedule(schedule.id, {
          is_enabled: checked,
        });
        if (result?.data?.id) {
          _pagination.updateData(schedule.id, 'id', { is_enabled: checked });
        } else {
          processErrorResponse({
            error: { message: 'Server error' },
            callback: showNotification,
          });
        }
      } catch (err) {
        processErrorResponse({ error: err, callback: showNotification });
      }
    };

  console.log({ approvalStatuses });
  return {
    ..._pagination,
    gradeBands,
    contentDesignations,
    categoryTags,
    approvalStatuses,
    populations,
    activeStatuses,
    onScheduleNewEmailClick,
    onToggleActivateStatus,
    onGridAction,
    getCalendarFilterValues,
    getSelectedOptionsForCalendarFilter,
  };
};

export default useEmailScheduleManagerListing;
