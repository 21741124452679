import { api, nonAdminApi } from "../adminApis";
import { IAPIResponse, IPaginationParams, IPaginationResponse } from "../../models/api";
import { AxiosRequestConfig } from "axios";
import { IOutcomeLogos } from "../../models/outcomeLogos";

export const listOutcomeLogos = (params?: IPaginationParams, config?: AxiosRequestConfig) => {
  const { page, limit } = params || ({} as IPaginationParams);
  const paginationParams = api.encodeQueryParams({
    page,
    limit,
  });
  return nonAdminApi.get<IPaginationResponse<IOutcomeLogos>>(
    `/v2/outcomes${paginationParams}`,
    params,
    config
  );
};

export const deleteOutcomeLogos = (id: number) => nonAdminApi.delete(`/v2/outcomes/${id}`);

export const getOutcomeLogoById = (id: number) =>
  nonAdminApi.get<IAPIResponse<IOutcomeLogos>>(`/v2/outcomes/${id}`);

export const createOutcomeLogo = (body: IOutcomeLogos) =>
  nonAdminApi.post<IOutcomeLogos, IAPIResponse<IOutcomeLogos>>("/v2/outcomes", body);

export const updateOutcomeLogo = (id: number, update: Partial<IOutcomeLogos>) =>
  nonAdminApi.put<Partial<IOutcomeLogos>, IAPIResponse<IOutcomeLogos>>(
    `/v2/outcomes/${id}`,
    update
  );
