import React from "react";
import { getElementSize } from "../../utils/numToMeasurement";

export const IMG = ({
  src,
  alt,
  style,
}: {
  src?: string;
  alt?: string;
  style: {
    height?: string | number;
    width?: string | number;
    margin?: any[];
    borderRadius?: number | string;
    borderWidth?: string;
    borderStyle?: string;
    borderColor?: string;
    boxSizing?: any;
    objectFit?: any;
    overflow?: string;
  };
}) => (
  <img
    style={{
      borderRadius: `${style?.borderRadius}px`,
      margin: `${style?.margin?.[0]}px ${style?.margin?.[1]}px ${style?.margin?.[2]}px ${style?.margin?.[3]}px`,
      height: isNaN(Number(style?.height)) ? style?.height : `${style?.height}px`,
      width: isNaN(Number(style?.width)) ? style?.width : `${style?.width}px`,
      borderWidth: getElementSize(style.borderWidth),
      borderStyle: style.borderStyle,
      borderColor: style.borderColor,
      boxSizing: style.boxSizing,
      objectFit: style.objectFit,
      overflow: style.overflow,
    }}
    alt={alt}
    src={src}
  />
);
