export type AnnouncementType = "info" | "warning" | "update";
export type AnnouncementLevel = "private" | "program" | "global";
export type AnnouncementStatus = "active" | "inactive";

export interface IAnnouncement {
  id?: number;
  title?: string;
  content?: string;
  type?: AnnouncementType;
  level?: AnnouncementLevel;
  start_date?: Date;
  end_date?: Date;
  status?: AnnouncementStatus;
}

export interface IActiveStatus {
  id: string;
  label: string;
}

export interface IAnnouncementType {
  id: string;
  label: string;
}

export interface IAnnouncementLevel {
  id: string;
  label: string;
}

export const AnnouncementLevel: IAnnouncementLevel[] = [
  { id: "private", label: "Private" },
  { id: "program", label: "Program" },
  { id: "global", label: "Global" },
];

export const AnnouncementStatus: IActiveStatus[] = [
  { id: "active", label: "Active" },
  { id: "inactive", label: "Inactive" },
];

export const AnnouncementType: IAnnouncementType[] = [
  { id: "info", label: "Info" },
  { id: "warning", label: "Warning" },
  { id: "update", label: "Update" },
];
