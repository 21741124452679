import { ReactComponent as IcEdit } from 'app/assets/icons/edit.svg';
import { ReactComponent as IcDelete } from 'app/assets/icons/delete.svg';
import { ReactComponent as IcImage } from 'app/assets/icons/images.svg';
import { IQAResponse } from 'app/models/qa';
import React, { useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import RoundButton from '../button/RoundButton';
import Input from '../input/Input';
import UploadFile from '../uploadFile/UploadFile';
import './qa-response-editor.scss';
import Checkbox from '../checkbox/CheckBox';
import FileUploader, { FileUploaderRef } from '../fileUploader/FileUploader';
import { IUploadResponse } from 'app/models/common';
import If from '../If';
import VideoPlay from '../videoPlay/VideoPlay';
import StandardButton from '../button/StandardButton';
import UploadPopup, {
  UploadPopUpRef,
} from '../../screens/sitePhotos/uploadPopup/UploadPopup';

const QAResponseEditor: React.FC<IQAResponseEditorProps> = ({
  index,
  responseId,
  responseApprovedStatus,
  responseUrl,
  thumbnail,
  onChange,
  onDelete,
  onEdit,
}) => {
  const _imageRef = useRef<UploadPopUpRef>(null);
  const _uploaderRef = useRef<FileUploaderRef>(null);
  const _fileRef = useRef<HTMLInputElement>(null);
  const eltId = `qa-response-editor-${index}`;

  const _onDelete = () => onDelete?.(index, responseId);
  const _onEdit = () => onEdit?.(index);

  const _onApproveChanged = (checked: boolean) =>
    onChange?.(`responses[${index}].responseApprovedStatus`, checked);


  const onUpload = (media: File, key: string) => {
    _uploaderRef.current?.upload(
      [media],
      key,
      null,
      null,
      null
    );
  }

  const _onCaptionFileSelected = (media: File) => {
    onUpload(media, `responses[${index}].uploadFileUrl`)
  };

  const _onVideoSelected: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onUpload(e.target!.files![0], `responses[${index}].responseUrl`)
  };

  const onSelectThumbnail = (file: File) => {
    onUpload(file, `responses[${index}].thumbnail`)
  }

  const _onVideoSelectorClick = () => {
    _imageRef.current?.show();
  };

  const _onFileUpload = (response: IUploadResponse[], extra?: string) => {
    onChange?.(extra!, response[0]?.fileUrl);
  };

  return (
    <div className='qa-response-editor-container'>
      <div className='d-flex justify-content-between'>
        <h3 className='title-photo-copy font-weight-600'>
          {`Response ${index + 1}`}
        </h3>
        <div className='action-container'>
          <RoundButton
            roundBtnId={`${eltId}-edit-click`}
            icon={<IcEdit />}
            size='sm'
            className='action-circle'
            onClick={_onEdit}
          />
          <RoundButton
            roundBtnId={`${eltId}-delete-click`}
            icon={<IcDelete />}
            size='sm'
            className='action-circle'
            onClick={_onDelete}
          />
        </div>
      </div>
      <Row className='qa-response-container'>
        <Col md={2}>
          <If condition={!responseUrl}>
            <button
              id={`${eltId}-upload-video`}
              type='button'
              onClick={_onVideoSelectorClick}
              className='video-selector'
            >
              <IcImage />
              Upload Video
            </button>
          </If>
          <If condition={!!responseUrl}>
            <VideoPlay
              videoPlayerId={`${eltId}-upload-video-player`}
              height='7.375rem'
              videoURL={responseUrl}
              previewImage={thumbnail}
              customStyles={{
                minWidth: 250,
                maxWidth: 250,
              }}
            />
            <StandardButton
              standardBtnId={`${eltId}-update-video`}
              label='Update'
              variant='outlined'
              className='mt-3'
              size='xs'
              onClick={_onVideoSelectorClick}
            />
          </If>
          <input
            type='file'
            hidden
            ref={_fileRef}
            onChange={_onVideoSelected}
          />
        </Col>
        <Col md={{ span: 9, offset: 1}}>
          <Row>
            <Col md={5}>
              <Input
                id={`${eltId}-name`}
                label='Name'
                variant='secondary'
                name={`responses[${index}].responseName`}
              />
            </Col>
            <Col md={5}>
              <Input
                id={`${eltId}-title`}
                label='Title'
                variant='secondary'
                name={`responses[${index}].responseTitle`}
              />
            </Col>
            <Col md={2} className='approval-container'>
              <Checkbox
                id={`${eltId}-approval-checkbox`}
                checked={responseApprovedStatus}
                onChange={_onApproveChanged}
                label='Approved'
                className='flex-row-reverse'
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <UploadFile
                label='Upload Close Caption File'
                placeholder='Upload file'
                uploadFileId={`${eltId}-caption`}
                onChange={_onCaptionFileSelected}
              />
            </Col>
            <Col md={6}>
              <UploadFile
                label='Upload video thumbnail'
                placeholder='Upload file'
                uploadFileId={`${eltId}-caption`}
                onChange={onSelectThumbnail}
              />
            </Col>
          </Row>
          <Row>
            <Col md={9} className='qa-progress-bar'>
              <FileUploader ref={_uploaderRef} onUploadComplete={_onFileUpload} />
            </Col>
          </Row>
        </Col>
      </Row>
      <UploadPopup
        id='response-video'
        onConfirm={(uploadedMedia: IUploadResponse) => {
          if (uploadedMedia) {
            onChange?.(
              `responses[${index}].responseUrl`,
              uploadedMedia?.fileUrl
            );
            onChange?.(
              `responses[${index}].thumbnail`,
              uploadedMedia?.thumbnail
            );
          }
        }}
        ref={_imageRef}
        mediaType='VIDEO'
      />
    </div>
  );
};

export default QAResponseEditor;
interface IQAResponseEditorProps
  extends Pick<
    IQAResponse,
    | 'responseId'
    | 'responseUrl'
    | 'responseApprovedStatus'
    | 'uploadFileUrl'
    | 'thumbnail'
  > {
  index: number;
  onDelete?: (index: number, id?: number) => void;
  onEdit?: (id?: number) => void;
  onChange?: (key: string, value: boolean | string) => void;
}
