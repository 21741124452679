import { ReactComponent as IcCheckConfig } from 'app/assets/icons/check-config.svg';
import { ReactComponent as IcExclamation } from 'app/assets/icons/exclamation.svg';
import { uploadImage } from 'app/api/commonApis';
import useModifiedState from 'app/hooks/ModifiedState';
import { IUploadResponse } from 'app/models/common';
import React, { useEffect, useImperativeHandle } from 'react';
import If from '../If';
import ProgressBar, { IProgressBarProps } from '../progressBar/ProgressBar';
import { IFileUploaderProps } from './../fileUploader/FileUploader';
import ImageUploaderRef from './ImageUploaderRef';
import './../fileUploader/file-uploader.scss';

interface IProgress {
  percent: number;
  secondsLeft: number;
  status?: 'success' | 'error';
}

const initialState: IProgress = {
  percent: 0,
  secondsLeft: 0,
  status: undefined,
};

export const ImageUploader = React.forwardRef<ImageUploaderRef, IFileUploaderProps & { current?: any }>(
  ({ hideProgress = false, horizontal, onUploadComplete, current }, ref) => {
    let startTimestamp: number;
    const [state, dispatch] = useModifiedState<IProgress>(initialState);
    const [temp, setTemp] = useModifiedState<File[] | null>(null);

    useEffect(() => {
      state.status &&
        setTimeout(() => {
          dispatch({ status: undefined });
        }, 2000);
    }, [state.status]);

    const _onUploadProgress = (progressEvent: ProgressEvent) => {
      const timeStamp = new Date().getTime();
      const speed = progressEvent.loaded / ((startTimestamp - timeStamp) / 1000);
      const secondsLeft = (progressEvent.total - progressEvent.loaded) / speed;
      const percent = Math.ceil((progressEvent.loaded * 100) / progressEvent.total);
      dispatch({ percent, secondsLeft });
    };

    useImperativeHandle(
      ref,
      () => ({
        tempFile: temp,
        holdFile: (files: File[]) => {
          setTemp(files);
        },
        upload: async (name: string, image: File) => {
          dispatch(initialState);
          startTimestamp = new Date().getTime();
          try {
            let data = await uploadImage(name, image);
            onUploadComplete && onUploadComplete(data.data);
            
            dispatch({ percent: 0, status: 'success' });
          } catch (error) {
            console.log('🚀 ~ file: FileUploader.tsx ~ upload: ~ error', error);
            dispatch({ percent: 0, status: 'error' });
          }
        },
      }),
      [onUploadComplete]
    );

    const Icon = state.status === 'success' ? IcCheckConfig : IcExclamation;

    return (
      <If condition={!hideProgress}>
        <If condition={!!state.percent}>
          <ProgressBar
            label='Uploading'
            percent={state.percent}
            secondsLeft={state.secondsLeft}
            horizontal={horizontal}
            className='my-4'
          />
        </If>
        <If condition={!!state.status}>
          <div className='status-container'>
            <div className={`status-container-icon ${state.status}`}>
              <Icon />
            </div>
            <span>
              {state.status === 'success'
                ? 'File uploaded successfully!'
                : 'File upload failed!!!'}
            </span>
          </div>
        </If>
      </If>
    );
  }
);
