import { Editor as CraftEditor, Frame, Element } from "@craftjs/core";
import React from "react";
import { DeprecatedContainer, GridItem } from "../components/Container/DeprecatedContainer";
import { Col, Container, Row } from "../components/Container/Container";
import "./Editor.scss";
import { Button } from "../components/Button/Button";
import { Image } from "../components/Image/Image";
import { Video } from "../components/Video/Video";
import { Text } from "../components/Text/Text";
import { RenderNode } from "../Render/RenderNode";
import { Viewport } from "../Render/ViewPort";
import { usePageBuilderContext } from "../PageBuilderProvider";
import { IFrame } from "../components/IFrameVideo/IFrame";

export const Editor = () => {
  const { page } = usePageBuilderContext();

  return (
    <div style={{ margin: "0 auto" }}>
      <CraftEditor
        resolver={{
          Container,
          Row,
          Col,
          DeprecatedContainer,
          GridItem,
          Button,
          Image,
          Video,
          IFrame,
          Text,
        }}
        enabled={false}
        onRender={RenderNode}
      >
        <Viewport>
          <Frame data={page?.pageContent}>
            <Element
              fillSpace="yes"
              canvas
              is={Container}
              width="100%"
              custom={{ displayName: "Main" }}
            >
              <Element height="300px" canvas is={Row} />
            </Element>
          </Frame>
        </Viewport>
      </CraftEditor>
    </div>
  );
};
