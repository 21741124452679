import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';

import {
  getEmailSchedule,
  getEmailTemplates,
  createEmailSchedule,
  updateEmailSchedule,
} from 'app/api/adminApis';
import { IDropdownOption } from '../models/program';
import { useAppState } from './../state/AppState';
import { appStateSelectors } from './../state/AppState';
import {
  IEmailManager,
  IEmailScheduleItem,
  IEmailScheduleItemBuilder,
  NotificationScheduleFrequency,
  IScheduleLimit,
} from 'app/models/email';
import { IGradeBand } from '../models/program';
import { IRole } from 'app/models/common';
import { ConfirmPopupRef } from 'app/screens/email/components/emailSchedules/emailScheduleReviewPopup/EmailScheduleReviewPopup';
import { processErrorResponse, indexOf } from 'app/utils/common';
import useApi from './Api';
import usePagination from './Pagination';

interface IFrequencyOptionDetail extends IDropdownOption {
  label: string;
  value: NotificationScheduleFrequency;
}

interface IFrequencyOptionBase {
  JUST_ONCE: IFrequencyOptionDetail;
  WEEKLY: IFrequencyOptionDetail;
  MONTHLY: IFrequencyOptionDetail;
}
interface IUserScheduleFrequency extends IFrequencyOptionBase {
  ALWAYS: IFrequencyOptionDetail;
}
interface IEmailScheduleFrequency extends IFrequencyOptionBase {
  DAILY: IFrequencyOptionDetail;
}

interface IRegistrationStatusOption extends IDropdownOption {
  registrationStatus: string;
}

export interface IEmailScheduleLimitOptions {
  label: string;
  type: 'Authenticated' | 'Registered' | 'Verified';
  title: string;
}

export interface IEmailScheduleLimitOperators {
  label: string;
  value: '>' | '<' | '=';
}

const useEmailScheduleEditor = () => {
  const navigate = useNavigate();

  const [sendNow, setSendNow] = useState<boolean>(true);
  

  const params = useParams();
  const [currentScheduleId, setCurrentScheduleId] = useState<any>(params.id);

  const formRef = useRef<FormikProps<IEmailScheduleItemBuilder>>(null);
  const confirmRef = useRef<ConfirmPopupRef>(null);

  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const showNotification = useAppState(appStateSelectors.showNotification);
  const hideNotification = useAppState(appStateSelectors.hideNotification);
  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const roles = useAppState(appStateSelectors.roles);

  const emailScheduleFrequencyOptions: IEmailScheduleFrequency = {
    JUST_ONCE: { id: 1, label: 'Just once', value: 'Once' },
    DAILY: { id: 2, label: 'Once a day', value: 'OncePerDay' },
    WEEKLY: { id: 3, label: 'Once a week', value: 'OncePerWeek' },
    MONTHLY: { id: 4, label: 'Once a month', value: 'OncePerMonth' },
  };

  const emailScheduleUserFrequencyOptions: IUserScheduleFrequency = {
    JUST_ONCE: { id: 1, label: 'Just once', value: 'Once' },
    ALWAYS: { id: 2, label: 'On every send', value: 'Always' },
    WEEKLY: { id: 3, label: 'Once a week', value: 'OncePerWeek' },
    MONTHLY: { id: 4, label: 'Once a month', value: 'OncePerMonth' },
  };

  const emailScheduleLimitOptions: IEmailScheduleLimitOptions[] = [
    {
      label: 'Is less than',
      type: 'Registered',
      title: 'Number of days since registration',
    },
    {
      label: 'Is greater than',
      type: 'Verified',
      title: 'Number of days since validation',
    },
    {
      label: 'Is equal to',
      type: 'Authenticated',
      title: 'Number of days since last log in',
    },
  ];

  const emailScheduleLimitOperators: IEmailScheduleLimitOperators[] = [
    { label: 'Is less than', value: '<' },
    { label: 'Is greater than', value: '>' },
    { label: 'Is equal to', value: '=' },
  ];

  const registrationStatuses: IRegistrationStatusOption[] = [
    {
      id: 1,
      registrationStatus: 'Register, Non-validated users',
      value: 'non-validated',
    },
    {
      id: 2,
      registrationStatus: 'Register, Validated users',
      value: 'validated',
    },
    {
      id: 3,
      registrationStatus: 'Register (Regardless of validation status)',
      value: 'all',
    },
  ];

  const timeZoneOptions: IDropdownOption[] = [
    { id: 1, value: 'AM' },
    { id: 2, value: 'PM' },
  ];

  const [_data, _error, _call] = useApi<IEmailScheduleItem>(
    getEmailSchedule,
    undefined,
    true,
    false
  );

  const paginatedTemplates = usePagination<IEmailManager>({
    listFn: getEmailTemplates,
  });

  const loadSchedule = (id: any) => {
    _call({ id });
  }

  useEffect(() => {
    currentScheduleId && loadSchedule(currentScheduleId);
  }, []);

  useEffect(() => {
    if (_data) {
      const formatted = formatData(_data);
      if (formatted)
        formRef.current?.setValues(formatted);
      }
  }, [_data]);

  const formatData = (data: IEmailScheduleItem): IEmailScheduleItemBuilder | null => {
    if (!data) return null;
  
    setSendNow(false);
    return {
      ...data,
      grade_band_ids: (data.grade_bands ?? []).map(
        (item: IGradeBand) => item.id
      ),
      roles: (data.roles ?? []).map((role) => ({
        id: role.id,
        is_inclusive: true,
        roleName: 'Example Role Name', // You'll need to adjust this to the correct value
      })),
      role_ids: (data.roles ?? []).map((role: IRole) => role.id),
      end_at: data.delivery_frequency === 'Once' ? undefined : data.end_at,
    };
  };

  const onEmailTemplateSearch = (searchText: string) => {
    const searchFilter = {
      'search[query]': searchText,
    };
    paginatedTemplates.onFilterChange(searchFilter as any);
  };

  const onSelect = (param: string) => (value: number | string | Date) => {
    if (param === 'end_at') {
      const endAtDate = moment(value).endOf('day').toDate();
      formRef.current?.setFieldValue(param, endAtDate);
      return;
    } else if (param === 'start_at') {
      let updatedEndDate = formRef.current?.values?.end_at
        ? moment(formRef.current?.values?.end_at)
        : undefined;
      const updatedStartDate = moment(value);

      if (updatedEndDate && updatedEndDate.isBefore(updatedStartDate)) {
        updatedEndDate = updatedStartDate;
      }

      formRef.current?.setValues((prevValues) => ({
        ...prevValues,
        start_at: updatedStartDate.toDate(),
        end_at: updatedEndDate?.toDate() ?? undefined,
      }));
    }

    formRef.current?.setFieldValue(param, value);
  };

  const onEmailTemplateSelection = (selected: number[]) => {
    const notification_template_id = selected?.[0];
    const template = (paginatedTemplates?.data ?? []).find(
      (t) => t.id === notification_template_id
    );

    formRef.current?.setValues((prevValues) => ({
      ...prevValues,
      notification_template_id,
      template,
    }));
  };

  const onChange = (param: keyof IEmailScheduleItemBuilder) => (value: any) => {
    if (
      param === 'delivery_frequency' &&
      (value as NotificationScheduleFrequency) === 'Once'
    ) {
      formRef.current?.setValues((prevValues) => ({
        ...prevValues,
        end_at: undefined,
        delivery_frequency: value,
      }));
      return;
    }

    formRef.current?.setFieldValue(param, value);
  };

  const onDropdownChange =
    (param: keyof IEmailScheduleItemBuilder) => (selected: number[]) => {
      formRef.current?.setFieldValue(param, selected);
    };

  const onRoleChange = (selected: number[]) => {
    formRef.current?.setFieldValue(
      'roles',
      selected.map((id) => ({ id: id, is_inclusive: true }))
    );
  };

  const onLimitChange =
    (name: 'Authenticated' | 'Registered' | 'Verified') =>
    (operator: '>' | '<' | '=') => {
      const existing = [...(formRef.current?.values?.limits ?? [])];
      let updatedDissociate = {
        ...(formRef.current?.values?.dissociate ?? {}),
      };

      const index = indexOf(existing, name, 'name');
      const update: IScheduleLimit = { name, operator, amount: 0, unit: 'Day' };

      let updated: any = [];
      if (index !== -1 && existing[index].operator === operator) {
        // Unselect existing item
        updatedDissociate.limits = [
          ...(updatedDissociate.limits ?? []),
          { id: existing[index].id! },
        ];
        updated = existing.filter((item) => item.name !== name);
      } else {
        updated =
          index === -1
            ? [...existing, update]
            : existing.map((item) =>
                item.name === name
                  ? ({
                      ...item,
                      operator,
                      amount: 0,
                    } as IScheduleLimit)
                  : item
              );
      }

      formRef.current?.setValues((prevValues) => ({
        ...prevValues,
        limits: updated,
        dissociate: updatedDissociate,
      }));
    };

  const onLimitAmountChange =
    (name: 'Authenticated' | 'Registered' | 'Verified') => (e: any) => {
      const existing = [...(formRef.current?.values?.limits ?? [])];
      const index = indexOf(existing, name, 'name');

      if (index !== -1) {
        existing[index].amount = e.target.value;
      }

      formRef.current?.setFieldValue('limits', existing);
    };

  const onGradeBandChange = (gradeBandId: number) => (checked: boolean) => {
    let updatedGradeBandIds = [
      ...(formRef.current?.values?.gradeBandIds || []),
    ];
    if (checked) {
      updatedGradeBandIds.push(gradeBandId);
    } else {
      const index = updatedGradeBandIds?.indexOf(gradeBandId);
      updatedGradeBandIds.splice(index, 1);
    }

    formRef.current?.setFieldValue('gradeBandIds', updatedGradeBandIds);
  };

  const onSelectSendOption = (option: string) => {
    const defaultStartTime =
      option !== 'Now'
        ? formRef.current?.values.start_at || moment().toDate()
        : undefined;

    const defaultEndTime = undefined;

    formRef.current?.setValues((state) => ({
      ...state,
      start_at: defaultStartTime,
      end_at: defaultEndTime,
      scheduleTime: option === 'Now' ? undefined : state.scheduleTime,
    }));
    setSendNow(option === 'Now');
  };

  const onTimeChange = (option: any) => {
    const updatedStart = moment(formRef.current?.values.start_at ?? undefined);

    const [hour_minute, amPm] = option.split(' ');
    const [hour, minute] = hour_minute.split(':');

    if (+hour === 12) {
      updatedStart.set('hour', amPm === 'pm' ? 12 : 0);
    } else {
      updatedStart.set('hour', amPm === 'pm' ? +hour + 12 : +hour);
    }

    updatedStart.set('minute', minute);

    formRef.current?.setValues((prevValues) => ({
      ...prevValues,
      start_at: updatedStart.toISOString(),
    }));
  };

  const _transformTime = (
    params: IEmailScheduleItemBuilder,
    type: 'start_at' | 'end_at'
  ) => {
    if (!params[type]) return undefined;

    try {
      return moment(params[type]).toISOString();
    } catch (err) {
      console.error(`Error formatting ${type} time string: ${err}`);
    }

    return undefined;
  };

  const formatParams = (
    params: IEmailScheduleItemBuilder
  ): IEmailScheduleItemBuilder => {
    const formatted = { ...params };

    formatted.start_at = _transformTime(params, 'start_at');
    formatted.end_at = _transformTime(params, 'end_at');

    return formatted;
  };

  const onShow = async (emailSchedule: any) => {
    const formattedParams = formatParams(emailSchedule);
    // console.log(formattedParams);
    try {
      if (currentScheduleId) {
        await updateEmailSchedule(+currentScheduleId, formattedParams)

        setTimeout(async () => {
          let schedule = await loadSchedule(currentScheduleId)
          if (_data && _data.total_notifiables_count) {
            let count = _data.total_notifiables_count;
          formRef.current?.setFieldValue("total_notifiables_count", count);
          }
        }, 1000);
      } else {
         let s = await createEmailSchedule(formattedParams)
         setCurrentScheduleId(s.data.id)
         setTimeout(async () => {
           let schedule = await loadSchedule(s.data.id)
          if (_data && _data.total_notifiables_count) {
            let count = _data.total_notifiables_count;
          formRef.current?.setFieldValue("total_notifiables_count", count);
          }
        }, 1000);
      }

    } catch (error) {
      processErrorResponse({ error, callback: showNotification });
    } finally {
      hideLoader();
    }
  }

  const onSubmit = async (emailSchedule: IEmailScheduleItemBuilder) => {
    showLoader();
    hideNotification();

    const formattedParams = formatParams(emailSchedule);
    try {
      currentScheduleId
        ? await updateEmailSchedule(currentScheduleId, formattedParams)
        : await createEmailSchedule(formattedParams);

      navigate(-1);
    } catch (error) {
      processErrorResponse({ error, callback: showNotification });
    } finally {
      hideLoader();
    }
  };

  return {
    initialValues,
    formRef,
    confirmRef,
    registrationStatuses,
    roles,
    gradeBands,
    sendNow,
    timeZoneOptions,
    emailScheduleFrequencyOptions,
    emailScheduleUserFrequencyOptions,
    emailScheduleLimitOptions,
    emailScheduleLimitOperators,
    selectableEmailTemplates: paginatedTemplates.data,
    selectableEmailTemplatesLoading: paginatedTemplates.loading,
    onChange,
    onDropdownChange,
    onSelect,
    onLimitChange,
    onLimitAmountChange,
    onGradeBandChange,
    onEmailTemplateSelection,
    onEmailTemplateSearch,
    onSelectSendOption,
    onSubmit,
    onTimeChange,
    onRoleChange,
    onShow,
  };
};

const initialValues: IEmailScheduleItemBuilder = {
  is_enabled: false,
  start_at: undefined,
  end_at: undefined,
  is_confirmed_correct: false,
};

export interface IEmailScheduleEditor {
  initialValues?: IEmailScheduleItemBuilder;
  formRef: React.RefObject<FormikProps<IEmailScheduleItemBuilder>>;
  confirmRef: React.RefObject<ConfirmPopupRef>;
  registrationStatuses: IRegistrationStatusOption[];
  gradeBands: IGradeBand[];
  roles: IRole[];
  sendNow: boolean;
  timeZoneOptions: IDropdownOption[];
  emailScheduleFrequencyOptions: IEmailScheduleFrequency;
  emailScheduleUserFrequencyOptions: IUserScheduleFrequency;
  emailScheduleLimitOptions: IEmailScheduleLimitOptions[];
  emailScheduleLimitOperators: IEmailScheduleLimitOperators[];
  selectableEmailTemplates: IEmailManager[];
  selectableEmailTemplatesLoading?: boolean;
  selecttableEmailTemplatesError?: any;
  onChange: (param: keyof IEmailScheduleItemBuilder) => (value: any) => void;
  onDropdownChange: (
    param: keyof IEmailScheduleItemBuilder
  ) => (ids: number[]) => void;
  onSelect: (param: string) => (value: number | string | Date) => void;
  onLimitChange: (
    name: 'Authenticated' | 'Registered' | 'Verified'
  ) => (operator: '>' | '<' | '=') => void;
  onLimitAmountChange: (
    name: 'Authenticated' | 'Registered' | 'Verified'
  ) => (event: any) => void;
  onGradeBandChange: (gradeBandId: number) => (checked: boolean) => void;
  onEmailTemplateSelection: (selected: number[]) => void;
  onEmailTemplateSearch: (searchKey: string) => void;
  onSelectSendOption: (option: string) => void;
  onSubmit: (emailSchedule: IEmailScheduleItemBuilder) => void;
  onTimeChange: (option: any) => void;
  onRoleChange: (selected: number[]) => void;
  onShow?: (emailSchedule: any) => void;
}

export default useEmailScheduleEditor;
