import React from "react";
import { Col, Row } from "react-bootstrap";
import { ToolbarItem } from "../../Editor/Toolbar/ToolbarItem";
import { ToolbarSection } from "../../Editor/Toolbar/ToolbarSection";
import { Dimensions } from "../Common/Dimensions";
import { Margin } from "../Common";

export const IFrameSettings = () => {
  return (
    <>
      <ToolbarSection title="IFrame Settings" props={["src", "alt"]}>
        <Row>
          <Col xs={12} sm={12} md={12}>
            <ToolbarItem propKey="src" type="text" label="Source" />
          </Col>
        </Row>
      </ToolbarSection>
      <Dimensions />
      <ToolbarSection title="Decorations" props={["borderRadius", "shadow"]}>
        <ToolbarItem propKey="borderRadius" type="text" label="Radius" />
      </ToolbarSection>
      <Margin />
    </>
  );
};
