import React from 'react';

import Section from 'app/components/section/Section';
import RadioButtonGroup from 'app/components/radioButtonGroup/RadioButtonGroup';
import RadioButtonOption from 'app/components/radioButtonGroup/RadioButtonOption';
import { IEmailScheduleItemBuilder } from 'app/models/email';
import { IEmailScheduleEditor } from 'app/hooks/EmailScheduleEditor';

interface IEmailFrequencySection {
  editor: IEmailScheduleEditor;
  values: IEmailScheduleItemBuilder;
}

export const EmailFrequencySection: React.FC<IEmailFrequencySection> = ({
  editor,
  values,
}) => {
  return (
    <Section
      title='Select the frequency with which an email should be sent'
      sectionId='emailScheduleFrequency'
      titleSize='md'
      className='email-schedule-frequency mt-4'
    >
      <RadioButtonGroup<number>
        id='test'
        onSelect={editor.onSelect('frequency')}
      >
        {Object.keys(editor.emailScheduleFrequencyOptions).map(
          (frequency: string) => {
            const key =
              frequency as keyof typeof editor.emailScheduleFrequencyOptions;
            const option = editor.emailScheduleFrequencyOptions[key];
            return (
              <RadioButtonOption
                id={`email-schedule-frequency-option-${option.id}`}
                label={option.label}
                value={option.value}
                checked={values.delivery_frequency === option.value}
                onChange={(e: any) =>
                  editor.onChange('delivery_frequency')(e.target.value)
                }
              />
            );
          }
        )}
      </RadioButtonGroup>
      <hr className='mt-5' />
    </Section>
  );
};
