import { Col, Row } from "react-bootstrap";
import { ToolbarItem } from "../../Editor/Toolbar/ToolbarItem";
import { ToolbarSection } from "../../Editor/Toolbar/ToolbarSection";
import React from "react";
import { MARGINS } from "./index";
import { getElementSize } from "../../utils/numToMeasurement";

export const Padding = () => {
  return (
    <ToolbarSection
      title="Padding"
      props={["padding"]}
      summary={({ padding }: any) => getElementSize(padding)}
    >
      <Row className={"MarginsGap"}>
        {MARGINS.map((margin, index) => (
          <Col xs={10} sm={10} md={5}>
            <ToolbarItem propKey="padding" index={index} type="text" label={margin} />
          </Col>
        ))}
      </Row>
    </ToolbarSection>
  );
};
