import { useNode } from "@craftjs/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Toolbar.scss";

export const ToolbarSection = ({ title, props, summary, children }: any) => {
  const { nodeProps } = useNode(node => ({
    nodeProps:
      props &&
      props.reduce((res: any, key: any) => {
        res[key] = node.data.props[key] || null;
        return res;
      }, {}),
  }));
  return (
    <details className="ToolbarSectionDetails">
      <summary className="ToolbarSectionSummary">
        <div className="ToolbarSectionTitle">
          <h6>{title}</h6>
          {summary && props ? (
            <small className="text-light-gray-2 text-sm text-right text-dark-blue">
              {summary(
                props.reduce((acc: any, key: any) => {
                  acc[key] = nodeProps[key];
                  return acc;
                }, {})
              )}{" "}
            </small>
          ) : null}
        </div>
      </summary>
      <Row className="Content">{children}</Row>
    </details>
  );
};
