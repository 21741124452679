import React from "react";

export const ImageIcon = () => (
  <svg width="100" height="100" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="3"
      y="3"
      width="18"
      height="18"
      rx="2"
      ry="2"
      fill="none"
      stroke="black"
      strokeWidth="2"
    />
    <circle cx="12" cy="10" r="3" fill="black" />
    <path d="M6,18c0,0,3-2,6-2s6,2,6,2v1H6V18z" fill="black" />
  </svg>
);
