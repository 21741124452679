import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Api } from ".";
import { IAPIResponse } from "../models/api";

const api = new Api("");

export const EnableReporting = function (admin: boolean) {
  let session = localStorage.getItem("session");
  if (!session) {
    //Create a random session ID
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz".split("");
    let str = "";
    while (str.length < 64) {
      str += chars[Math.floor(Math.random() * chars.length)];
    }
    session = str;
    localStorage.setItem("session", session);
  }
  let location = useLocation();
  let lastLocation: string = "";
  useEffect(() => {
    if (location.pathname !== lastLocation) {
      lastLocation = location.pathname;
      api.post<any, any>("/reporting/events", {
        event: "pageview",
        uri: location.pathname,
        admin: admin,
        session: session,
      });
    }
  }, [location]);
};

export const getReport = function (params: any): any {
  return api.get<any>("/reporting/events", params);
};

export const getReportData = function (params: any): any {
  return api.get<any>("/reporting/events-data", params);
};

type ReportingInterface = {
  userType: string;
  startDate: string;
  endDate: string;
  location: string;
  gradeBand: string;
};

const buildUri = (entity: string) => `/reporting/events/${entity}`;

export const getFamilyReport = (params: ReportingInterface) => {
  return api.get<IAPIResponse<unknown>>(buildUri("family"), params);
};
export const getAlumniReport = (params: ReportingInterface) => {
  return api.get<IAPIResponse<unknown>>(buildUri("alumni"), params);
};

export const getGraduateReport = (params: ReportingInterface) => {
  return api.get<IAPIResponse<unknown>>(buildUri("graduate"), params);
};

export const getQAReport = (params: ReportingInterface) => {
  return api.get<IAPIResponse<unknown>>(buildUri("qa"), params);
};

export const getPagesReport = (params: ReportingInterface) => {
  return api.get<IAPIResponse<unknown>>(buildUri("pages"), params);
};

export const getStaffReport = (params: ReportingInterface) => {
  return api.get<IAPIResponse<unknown>>(buildUri("staff"), params);
};
export const getHubReport = (params: ReportingInterface) => {
  return api.get<IAPIResponse<unknown>>(buildUri("hub"), params);
};
export const getArticlesReport = (params: ReportingInterface) => {
  return api.get<IAPIResponse<unknown>>(buildUri("articles"), params);
};
export const getOutcomesReport = (params: ReportingInterface) => {
  return api.get<IAPIResponse<unknown>>(buildUri("outcomes"), params);
};
export const getHomeReport = (params: ReportingInterface) => {
  return api.get<IAPIResponse<unknown>>(buildUri("home"), params);
};
