import React, { useEffect } from "react";
import useDataUploadManager, {
  ITableData,
} from "../../hooks/DataUploadManager";
import DataGrid from "app/components/dataGrid/DataGrid";
import "./importTableManager.scss";
import { Row } from "react-bootstrap";
import ImportSummary from "./ImportSummary";

import { Api } from "app/api";
const api = new Api("");

interface ImportedTableManagerProps {
  setValidation: (validation: any) => void;
  setDataUpload: (dataUpload: any) => void;
  dataUpload: any;
}
const ImportedTableManager: React.FC<ImportedTableManagerProps> = (
  props: any
) => {
  const [confirmed, setConfirmed] = React.useState(false);
  const [rows, setRows] = React.useState<any>([]);
  const [cols, setCols] = React.useState<any>([]);

  useEffect(() => {
    if (
      props.dataUpload.upload_preview &&
      props.dataUpload.upload_preview.length > 0
    ) {
      setRows(props.dataUpload.upload_preview);
      setCols(Object.keys(props.dataUpload.upload_preview[0]));
    }
  }, [props.dataUpload]);

  const _dataMangerHandle = useDataUploadManager();

  useEffect(() => {
    if (props.setValidation) {
      props.setValidation([
        () => {
          if (props.dataUpload.validated) {
            return true;
          }
          return false;
        },
      ]);
    }
  }, [props.dataUpload]);

  const getPreviewCsv = (rowIndexes: any) => {
    
  };

  const GRID_ID = "data-table-listing";
  return (
    <Row className="column-content f18-l-28">
      <p className="content-desc mb-6">
        This is how your data will look once imported:
      </p>
      <div className="table-content">
        <DataGrid<ITableData> data={rows} gridId={GRID_ID} rowIdAttribute="id">
          {cols.map((col: any) => {
            return (
              <DataGrid.Column<ITableData>
                attributeName={col}
                header={col}
                key={Math.random()}
              />
            );
          })}
        </DataGrid>

        <div style={{ textAlign: "center", margin: "20px" }}>
            <div>
              <h4 style={{ margin: "1em" }}>
                Please confirm these upload results are correct before
                continuing!
              </h4>

              <div style={{ color: "#2E75FF" }}>
                <div>
                  <ImportSummary dataUpload={props.dataUpload} />
                </div>
              </div>
            </div>
        </div>
      </div>
    </Row>
  );
};

export default ImportedTableManager;
