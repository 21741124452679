import React from "react";
import StandardButton from "app/components/button/StandardButton";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import DataGrid from "app/components/dataGrid/DataGrid";
import Filter from "app/components/filter/Filter";
import FilterOption from "app/components/filter/FilterOption";
import SearchBar from "app/components/searchBar/SearchBar";
import Switch from "app/components/switch/Switch";
import { ACTIVE_OPTIONS } from "app/utils/common";
import {
  formatUserPermissions,
  formatUserNotificationPreferences,
  formatGradeBands,
} from "app/utils/data";
import useStaffListing from "app/hooks/StaffListing";
import PrivateLayout from "app/layout/PrivateLayout";
import { IRole } from "app/models/common";
import { IGradeBand } from "app/models/program";
import { IStaffListItem } from "app/models/staff";
import { IActiveStatus } from "app/models/common";
import { IPreference } from "app/models/preference";
import { IUserV2 } from "app/models/user";
import AccountNotificationPopup from "app/components/accountNotification/AccountNotificationPopup";
import "./staff.scss";
import { useDownloadCsv } from "../../hooks/ExportApi";
import { exportStaffCsvFetcher } from "../../api/adminApis";

const GRID_ID = "staff-account-listing";

const StaffListing: React.FC = () => {
  const _staffListing = useStaffListing();
  const { exportCsv } = useDownloadCsv(exportStaffCsvFetcher);

  return (
    <PrivateLayout contentClassName="staff-listing">
      <div className="action-buttons">
        <StandardButton
          label="Export csv"
          variant="primary"
          className="new-staff-button"
          onClick={exportCsv}
          standardBtnId="staff-listing-new-btn"
        />
        <StandardButton
          label="+ New Staff Member"
          variant="secondary"
          className="new-staff-button"
          onClick={_staffListing.onNewStaffClick}
          standardBtnId="staff-listing-new-btn"
        />
      </div>
      <h3 className="title">Staff Account Manager</h3>
      <SearchBar
        placeHolder="Search by user name, email or title"
        searchBarId="staff-listing-search"
        onChangeText={_staffListing.onSearchKeyChange}
        onSearch={_staffListing.onSearch}
      />
      <Filter
        id="article-listing-filter"
        onFilterChange={_staffListing.onFilterChange}
        selectedFilters={_staffListing.filters}
      >
        <FilterOption<IPreference>
          labelAttribute="subcategory"
          valueAttribute="id"
          name="preference_ids"
          options={_staffListing.preferences}
          placeholder="Subscription Status"
        />
        <FilterOption<IRole>
          labelAttribute="roleName"
          valueAttribute="id"
          name="userPermission"
          options={_staffListing.roles}
          placeholder="User Permissions"
        />
        <FilterOption<IActiveStatus>
          labelAttribute="label"
          valueAttribute="isActive"
          name="isActive"
          options={ACTIVE_OPTIONS}
          placeholder="Active Status"
          disableMultipleSelection
        />
        <FilterOption<IGradeBand>
          labelAttribute="gradeBand"
          valueAttribute="id"
          // name='grades'
          name="gradeBandIds"
          options={_staffListing.grades}
          placeholder="Grade(s)"
        />
      </Filter>
      <DataGrid<IStaffListItem>
        data={_staffListing.data}
        gridId={GRID_ID}
        rowIdAttribute="id"
        loading={_staffListing.loading}
        currentPage={_staffListing.currentPage}
        onPageChange={_staffListing.onPageChange}
        totalItems={_staffListing.totalItems}
        onAction={_staffListing.onGridAction}
      >
        <DataGrid.Column<IStaffListItem>
          attributeName="last_name"
          header="Name"
          transform={(staff: IStaffListItem) =>
            `${staff.first_name}${staff.last_name ? ` ${staff.last_name}` : ""}`
          }
        />
        <DataGrid.Column<IStaffListItem> attributeName="id" header="Program ID" />
        <DataGrid.Column<IStaffListItem> attributeName="program_id" header="School ID" />
        <DataGrid.Column<IStaffListItem>
          attributeName="preferences"
          header="Subscription Status"
          transform={formatUserNotificationPreferences}
        />
        <DataGrid.Column<IStaffListItem>
          attributeName="roles"
          header="User Permissions"
          transform={(user: IUserV2) => formatUserPermissions([user])}
        />
        <DataGrid.Column<IStaffListItem>
          attributeName="grade_bands"
          header="Grade Band"
          transform={(user: IUserV2) => formatGradeBands([user])}
        />
        <DataGrid.Column<IStaffListItem>
          attributeName="is_enabled"
          header="Active Status"
          transform={staff => (
            <Switch
              checked={staff.is_enabled}
              onChange={_staffListing.onToggleActive(staff)}
              switchId="staff-listing-active-btn"
            />
          )}
        />
        <DataGrid.Column<IStaffListItem>
          action={DATA_GRID_ACTIONS.UNSUBSCRIBE}
          attributeName="GRID.ACTIONS"
        />
        <DataGrid.Column<IStaffListItem>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName="GRID.ACTIONS"
        />
      </DataGrid>
      <AccountNotificationPopup
        type="Staff"
        ref={_staffListing.subscriptionModalRef}
        id="staff-acccount-subscription"
        onSubmit={_staffListing.updateStaffSubscriptionSettings}
      />
    </PrivateLayout>
  );
};

export default StaffListing;
