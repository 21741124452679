import React from 'react';
import { Form, Formik, FormikTouched, FormikErrors } from 'formik';
import { Col, Row } from 'react-bootstrap';

import Input from 'app/components/input/Input';
import DropDown from 'app/components/dropDown/DropDown';
import Section from 'app/components/section/Section';
import Checkbox from 'app/components/checkbox/CheckBox';
import DropDownBase from 'app/components/dropDown/DropDownBase';
import UserAccountDropDownInput from 'app/components/userAccountDropdownInput/UserAccountDropdownInput';
import { IGradeBand } from 'app/models/program';
import { IUser, IUserV2 } from 'app/models/user';
import { IStaffBuilder } from 'app/models/staff';
import { IEmailDetail } from 'app/models/common';
import { getErrors } from 'app/utils/data';
import { YEARS } from 'app/constants/common';

interface IGeneralSection {
  editor: any;
  touched: FormikTouched<IStaffBuilder>;
  errors: FormikErrors<IStaffBuilder>;
  values: IStaffBuilder;
}

export const GeneralSection: React.FC<IGeneralSection> = ({
  editor,
  touched,
  errors,
  values,
}) => {
  return (
    <Section
      title='General'
      sectionId='staffBuilderGeneral'
      titleSize='lg'
      className='staff-builder-general mt-4'
      isEditable={true}
    >
      <Row className='user-select'>
        <Col>
          <UserAccountDropDownInput
            label='Select User *'
            value={values?.staff_profile?.user_id}
            scope='staff'
            dataKey='id'
            name='staff.user.firstname'
            onSelect={(user: Partial<IUserV2>) => editor.onAccountSelect(user)}
            persistedUser={values.id ? values : null}
            className='staff-user-dropdown'
            textField={(user: Partial<IUserV2>) => {
              const email = (user.email as unknown as IEmailDetail)?.value;
              return user.id
                ? `${user.first_name} ${user.last_name}${
                    email ? `- ${email}` : ''
                  }`
                : 'User account not found';
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            id='staff-builder-first-name'
            variant='secondary'
            label='Name'
            required
            placeholder='Enter your name here'
            name='first_name'
            error={getErrors(touched, errors, 'first_name')}
          />
        </Col>
        <Col>
          <Input
            id='staff-builder-last-name'
            variant='secondary'
            label='Last Name'
            required
            placeholder='Enter your last name here'
            name='last_name'
            error={getErrors(touched, errors, 'last_name')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            id='staff-builder-title'
            variant='secondary'
            label='Title'
            required
            placeholder='Enter your title here'
            name='title'
            error={getErrors(touched, errors, 'title')}
          />
        </Col>
        <Col>
          <DropDownBase<number>
            id='staff-builder-classYear'
            options={YEARS.map((year) => +year)}
            labelOnly
            selected={values.attending_school_since ?? 0}
            placeholder='Select Year'
            label={`Member of ${editor.schoolName} Since*`}
            onOptionChange={editor.onChange('attending_school_since')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <Input
                id='staff-builder-programId'
                variant='secondary'
                label='Program ID'
                placeholder='Program ID'
                name='programId'
                disabled
              />
            </Col>
            <Col>
              <Input
                id='staff-builder-school-id'
                variant='secondary'
                label='School ID'
                placeholder='School ID'
                name='school_code'
                error={getErrors(touched, errors, 'school_code')}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <DropDown<IGradeBand>
            id='staff-builder-grade-band'
            label='Grade Band'
            placeholder='Select Grade Band'
            options={editor.gradeBands}
            valueAttribute='id'
            labelAttribute='gradeBand'
            multiple
            selected={values.grade_band_ids ?? []}
            onOptionChange={editor.onSelectionChange('grade_band_ids')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            id='staff-builder-work-email'
            variant='secondary'
            label='Work Email'
            required
            placeholder='Enter your work email here'
            name='work_email'
            error={getErrors(touched, errors, 'work_email')}
          />
          <Checkbox
            id='workEmailConsent'
            label='Allow this email addresses to be visible in my profile'
            checked={(values?.is_work_email_visible ?? 0) === 1}
            onChange={editor.onChange('is_work_email_visible')}
            className='staff-builder-checkbox mb-5'
          />
        </Col>
        <Col>
          <Input
            id='staff-builder-other-email'
            variant='secondary'
            label='Other Email'
            placeholder='Enter your other email here'
            name='secondary_email'
            error={getErrors(touched, errors, 'secondary_email')}
          />
          <Checkbox
            id='workEmailConsent'
            label='Allow this email addresses to be visible in my profile'
            checked={(values?.is_secondary_email_visible ?? 0) === 1}
            onChange={editor.onChange('is_secondary_email_visible')}
            className='staff-builder-checkbox mb-5'
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            id='staff-builder-personal-website'
            variant='secondary'
            label='Personal Website'
            placeholder=''
            name='personal_website_url'
          />
        </Col>
        <Col>
          <Input
            id='staff-builder-linkedin'
            variant='secondary'
            label='LinkedIn Profile'
            placeholder=''
            name='social_media_accounts[0].url'
            onChange={editor.onChangeSocialMedia}
          />
        </Col>
      </Row>
      <hr className='mb-5 mt-4' />
    </Section>
  );
};
