import { useEffect, useRef, useState } from 'react';
import { createUser, getUser, updateUser, listUsers } from 'app/api/adminApis';
import { FileUploaderRef } from 'app/components/fileUploader/FileUploader';
import { IEducation, IUploadResponse } from 'app/models/common';
import { IUser, IUserV2, IUserBuilder } from 'app/models/user';
import { IRole } from 'app/models/common';
import { IMediaUpload } from 'app/models/media';
import { IPreference } from 'app/models/preference';
import { IAccountSubscriptionStatus } from 'app/models/accountNotification';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { processErrorResponse } from 'app/utils/common';
import { UploadPopUpRef } from 'app/screens/sitePhotos/uploadPopup/UploadPopupV2';
import { FormikProps } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { has } from 'lodash';

import usePagination from './Pagination';

const useProfileEditor = () => {
  const navigate = useNavigate();

  const formRef = useRef<FormikProps<IUserBuilder>>(null);
  const profileImageRef = useRef<UploadPopUpRef>(null);
  const [profileImageUrl, setProfileImageUrl] = useState<string | undefined>();

  const appConfig = useAppState(appStateSelectors.appConfig);
  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const roles = useAppState(appStateSelectors.roles);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const showNotification = useAppState(appStateSelectors.showNotification);
  const hideNotification = useAppState(appStateSelectors.hideNotification);
  const preferences = useAppState(appStateSelectors.preferences)?.filter(
    (pref) => pref.category === 'Contact'
  );

  const _pagination = usePagination<IUserV2>({
    listFn: listUsers,
  });

  const params = useParams();
  const gradeBandOptions = gradeBands.map((gb) => gb.gradeBand);

  useEffect(() => {
    return () => hideNotification();
  }, []);

  useEffect(() => {
    if (params.id) {
      _getUserDetails();
    } 
  }, [params]);

  const _getUserDetails = async () => {
    showLoader();
    try {
      const { data } = await getUser(+params.id!);
      let image = data.profile_image as any;
      if (image) {
        setProfileImageUrl(image.path);
      }
      formRef.current?.setValues({
        ...data,
        preference_ids: data.preferences.map((pref) => pref.id),
        grade_band_ids: data.grade_bands?.map((gb) => gb.id),
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: UserBuilder.ts  ~ const_getUserDetails= ~ error',
        error
      );
    } finally {
      hideLoader();
    }
  };

  const onSelectionChange =
    (key: keyof IUserBuilder) =>
    (value: string | string[] | number | number[]) =>
      formRef.current?.setFieldValue(key, value);

  const onPreferenceChange = (selected: string[]) => {
    const selectedPreferences = preferences.filter((preference) =>
      selected.includes(preference.subcategory)
    );

    formRef.current?.setValues((prevValues) => ({
      ...prevValues,
      preferences: selectedPreferences,
      preference_ids: selectedPreferences.map((pref) => pref.id),
    }));
  };

  const onRoleChange = (selected: string[]) => {
    console.log(' -- selected -- ');
    const selectedRoles = roles.filter((role) =>
      selected.includes(role.roleName)
    );
    // console.log(' -- selectedRoles -- ', selectedRoles);

    formRef.current?.setValues((prevValues) => ({
      ...prevValues,
      roles: selectedRoles.map((role) => ({ ...role, name: role.roleName })),
      role_ids: selectedRoles.map((role) => role.id),
    }));
  };

  const onGradeBandChange = (selected: string[]) => {
    const selectedBands = gradeBands.filter((band) =>
      selected.includes(band.gradeBand!)
    );

    formRef.current?.setValues((prevValues) => ({
      ...prevValues,
      grade_bands: selectedBands.map((band) => ({
        ...band,
        grade_band: band.gradeBand,
      })),
      grade_band_ids: selectedBands.map((band) => band.id),
    }));
  };

  const onProfileImageChange = (mediaUpload: IMediaUpload) => {
    formRef.current?.setFieldValue('profile_image', mediaUpload);
  };

  const formatParams = (user: IUserBuilder) => {
    return {
      ...user,
      // gradeBandIds: (user.tmpGradeBands || []).map((gradeBand) => {
      //   const band = gradeBands.find((gb) => gb.gradeBand === gradeBand);
      //   return band?.id || -1;
      // }),
    };
  };

  const onSubmit = async (user: IUserBuilder) => {
    showLoader();
    hideNotification();

    const formattedUser = user;
    // formatParams(user);
    try {
      const {} = await (params.id
        ? updateUser(+params.id, formattedUser)
        : createUser(formattedUser));
      navigate(-1);
    } catch (error: any) {
      processErrorResponse({ error, callback: showNotification });
      console.log('🚀 ~ file: UserBuilder.ts ~ onSubmit ~ error', error);
    } finally {
      hideLoader();
    }
  };

  console.log('Grade Bands: ', gradeBands);
  return {
    ..._pagination,
    initialValues: initialState(preferences),
    preferences,
    roles,
    gradeBands,
    formRef,
    profileImageRef,
    profileImageUrl,
    onRoleChange,
    onProfileImageChange,
    onPreferenceChange,
    onGradeBandChange,
    onSubmit,
    onSelectionChange,
    schoolName: appConfig?.schoolShortName || appConfig?.schoolName,
    gradeBandOptions,
  };
};

export default useProfileEditor;

const initialState = (preferences: IPreference[]): IUserBuilder => ({
  first_name: '',
  last_name: '',
  username: '',
  email: '',
  password: '',
  phone_number: '',
  is_enabled: true,
  attending_school_since: null,
  preferences,
  preference_ids: preferences.map((pref) => pref.id),
});
