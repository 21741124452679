import React from "react";
import { ContainerSettings } from "./ContainerSettings";
import { Resizer } from "../../Render/Resizer";
import { getElementSize } from "../../utils/numToMeasurement";
import "./Container.scss";
import { useNode } from "@craftjs/core";

export type ContainerProps = {
  background: string;
  color: Record<"r" | "g" | "b" | "a", number>;
  flexDirection: string;
  alignItems: string;
  justifyContent: string;
  fillSpace: string;
  padding: string[];
  margin: string[];
  marginTop: number;
  marginLeft: number;
  marginBottom: number;
  marginRight: number;
  shadow: number;
  children: React.ReactNode;
  width: string;
  height: string;
  minWidth: string;
  minHeight: string;
  maxWidth: string;
  maxHeight: string;
  lg?: number;
};

const defaultProps = {
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  fillSpace: "no",
  padding: ["0", "0", "0", "0"],
  margin: ["0", "0", "0", "0"],
  background: "#FFFFFF",
  color: { r: 0, g: 0, b: 0, a: 1 },
  shadow: 0,
  borderRadius: 0,
  width: "100%",
  height: "auto",
  display: "flex",
  minWidth: "unset",
  maxWidth: "unset",
  minHeight: "100%",
  maxHeight: "unset",
  lg: 1,
};

export const Container = (props: any) => {
  props = {
    ...defaultProps,
    ...props,
  };
  const {
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    background,
    color,
    padding,
    margin,
    shadow,
    borderRadius,
    children,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
  }: any = props;

  return (
    <Resizer
      propKey={{ width: "width", height: "height" }}
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent,
        flexDirection,
        alignItems,
        background,
        color: `rgba(${Object.values(color)})`,
        padding: getElementSize(padding),
        margin: getElementSize(margin),
        boxShadow: shadow === 0 ? "none" : `0px 3px 100px ${shadow}px rgba(0, 0, 0, 0.13)`,
        borderRadius: getElementSize(borderRadius),
        flex: fillSpace === "yes" ? 1 : "unset",
        minWidth: getElementSize(minWidth),
        maxWidth: getElementSize(maxWidth),
        minHeight: getElementSize(minHeight),
        maxHeight: getElementSize(maxHeight),
      }}
    >
      {children}
    </Resizer>
  );
};

export const Row = (props: Partial<ContainerProps>) => {
  props = {
    ...defaultProps,
    ...props,
  };
  const {
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    background,
    color,
    padding,
    margin,
    shadow,
    borderRadius,
    children,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
  }: any = props;

  return (
    <Resizer
      propKey={{ width: "width", height: "height" }}
      className={"grid"}
      style={{
        flexWrap: "wrap",
        justifyContent,
        flexDirection,
        alignItems,
        flex: fillSpace === "yes" ? 1 : "unset",
        background,
        color: `rgba(${Object.values(color)})`,
        padding: getElementSize(padding),
        margin: getElementSize(margin),
        boxShadow: shadow === 0 ? "none" : `0px 3px 100px ${shadow}px rgba(0, 0, 0, 0.13)`,
        borderRadius: getElementSize(borderRadius),
        minWidth: getElementSize(minWidth),
        maxWidth: getElementSize(maxWidth),
        minHeight: getElementSize(minHeight),
        maxHeight: getElementSize(maxHeight),
        overflow: "unset",
      }}
    >
      {children}
    </Resizer>
  );
};

export const Col = (props: Partial<ContainerProps>) => {
  const {
    connectors: { connect },
  } = useNode(node => ({
    selected: node.events.selected,
  }));
  props = {
    ...defaultProps,
    ...props,
  };
  const {
    height,
    minHeight,
    maxHeight,
    flexDirection,
    alignItems,
    justifyContent,
    background,
    color,
    padding,
    margin,
    shadow,
    borderRadius,
    children,
  }: any = props;

  const { lg } = props;

  return (
    <div
      data-cy="toolbox-col"
      ref={connect as any}
      className={`colz-${lg}-12`}
      style={{
        display: "flex",
        justifyContent,
        flexDirection,
        alignItems,
        background,
        color: `rgba(${Object.values(color)})`,
        padding: getElementSize(padding),
        margin: getElementSize(margin),
        boxShadow: shadow === 0 ? "none" : `0px 3px 100px ${shadow}px rgba(0, 0, 0, 0.13)`,
        borderRadius: getElementSize(borderRadius),
        height: getElementSize(height),
        minHeight: getElementSize(minHeight),
        maxHeight: getElementSize(maxHeight),
      }}
    >
      {children}
    </div>
  );
};

Container.craft = {
  displayName: "Container",
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ContainerSettings,
  },
};

Row.craft = {
  displayName: "Row",
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ContainerSettings,
  },
};

Col.craft = {
  displayName: "Item",
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ContainerSettings,
  },
};
