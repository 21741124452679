import React, { useEffect, useRef, useState } from "react";
import PrivateLayout from "../../layout/PrivateLayout";
import StandardButton from "../../components/button/StandardButton";
import { ContentMetadata } from "../../constants/content-metadata";
import "./ContentMetadata.scss";
import DropDown from "../../components/dropDown/DropDown";
import {
  deleteContentMetadata,
  listContentMetadata,
  postContentMetadata,
  updateContentMetadata,
} from "../../api/content-metadata/content-metadata.api";
import { IArticleListItem } from "../../models/article";
import DataGrid from "../../components/dataGrid/DataGrid";
import { DATA_GRID_ACTIONS } from "../../components/dataGrid/DataColumn";
import { ContentMetadataModalEditor } from "./ContentMetadataModalEditor";
import { T12ModalRef } from "../../components/modal/T12Modal";

export const ContentMetadataListing = () => {
  const [selectedMetadata, setSelectedMetadata] = useState(ContentMetadata[0]);
  const [listOptions, setListOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editMetadata, setEditMetadata] = useState(null);
  const modalRef = useRef<T12ModalRef>(null);

  useEffect(() => {
    fetch();
  }, [selectedMetadata.value]);

  const fetch = async () => {
    const response = await listContentMetadata(selectedMetadata.value);
    console.log(response.data);
    setListOptions(response.data || []);
    setIsLoading(false);
  };

  const onSubmitMetadata = async (metadata: string, id?: number) => {
    try {
      const newObject = { [selectedMetadata.value]: metadata };
      console.log(id);
      id
        ? await updateContentMetadata(id, selectedMetadata.value, newObject)
        : await postContentMetadata(selectedMetadata.value, newObject);
      await fetch();
    } finally {
      modalRef.current?.close();
      setEditMetadata(null);
    }
  };

  const onActionSelected = async (action: DATA_GRID_ACTIONS, rowId: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.EDIT:
        setEditMetadata(listOptions.find(opt => opt.id === rowId));
        modalRef.current?.show();
        return;
      case DATA_GRID_ACTIONS.DELETE:
        await deleteContentMetadata(rowId, selectedMetadata.value);
        await fetch();
        return;
    }
  };

  return (
    <PrivateLayout contentClassName="content-metadata-classname">
      <div className="action-buttons">
        <DropDown<any>
          id="article-editor-approval-status"
          labelAttribute="label"
          options={ContentMetadata}
          valueAttribute="value"
          placeholder="Select metadata type"
          className="override-mb"
          selected={selectedMetadata.value}
          onOptionChange={value =>
            setSelectedMetadata(ContentMetadata.find(mt => mt.value === value) as any)
          }
        />
        <StandardButton
          label={`+ New ${selectedMetadata.label}`}
          variant="secondary"
          className="new-graduate-profile-button"
          standardBtnId="graduate-listing-new-btn"
          size="sm"
          onClick={() => modalRef.current?.show()}
        />
      </div>
      <h3 className="title">{selectedMetadata.label} manager</h3>
      <DataGrid<any>
        data={listOptions || []}
        gridId="content-metadata-listing"
        loading={isLoading}
        confirmDeleteDescription="Please confirm that you would like to delete this item"
        rowIdAttribute="id"
        // currentPage={_articleListing.currentPage}
        // onPageChange={_articleListing.onPageChange}
        onAction={onActionSelected}
      >
        <DataGrid.Column attributeName="id" header="Content id" />
        <DataGrid.Column attributeName={selectedMetadata.value} header={selectedMetadata.label} />

        <DataGrid.Column<IArticleListItem>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName="GRID.ACTIONS"
        />
        <DataGrid.Column<IArticleListItem>
          action={DATA_GRID_ACTIONS.DELETE}
          attributeName="GRID.ACTIONS"
        />
      </DataGrid>
      {modalRef && (
        <ContentMetadataModalEditor
          editMetadata={editMetadata}
          onSave={onSubmitMetadata}
          metadataType={selectedMetadata.label}
          ref={modalRef}
          onClose={() => setEditMetadata(null)}
        />
      )}
    </PrivateLayout>
  );
};
