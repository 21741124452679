import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import "./button.scss";

const StandardButton: React.FC<IStandardButtonProps> = ({
  standardBtnId,
  className = "",
  size = "md",
  label = "",
  variant = "primary",
  children,
  icon: Icon,
  type = "button",
  onClick,
  form,
  ...props
}) => {
  return (
    <button
      id={standardBtnId}
      className={`standard-button ${variant} ${size} ${className}`}
      onClick={onClick}
      type={type}
      form={form}
      {...props}
    >
      {Icon && <Icon />}
      {children ?? label}
    </button>
  );
};

export default StandardButton;

interface IStandardButtonProps
  extends Pick<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "type" | "disabled"
  > {
  className?: string;
  size?: "xs" | "sm" | "md";
  standardBtnId: string;
  label?: string;
  variant?: "primary" | "secondary" | "outlined";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  form?: string;
}
