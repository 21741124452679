import React, { useEffect, useRef, useState } from "react";

import PrivateLayout from "app/layout/PrivateLayout";
import Section from "app/components/section/Section";
import { Form, Formik, FormikProps } from "formik";
import UploadPopup, { UploadPopUpRef } from "../sitePhotos/uploadPopup/UploadPopup";
import { appStateSelectors, useAppState } from "../../state/AppState";
import ScreenNotification from "../../components/screenNotification/ScreenNotification";
import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as DeleteIcon } from "app/assets/icons/delete.svg";
import { ReactComponent as IcImage } from "app/assets/icons/images.svg";
import Checkbox from "../../components/checkbox/CheckBox";
import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import Input from "../../components/input/Input";
import { IUploadResponse } from "../../models/common";
import RoundButton from "../../components/button/RoundButton";
import { LogosDemo } from "./LogosDemo";
import TextButton from "../../components/button/TextButton";
import StandardButton from "../../components/button/StandardButton";
import { CarouselType } from "../../constants/carousel";
import {
  createCarousel,
  getByProgramId,
  getCarouselById,
  updateCarousel,
} from "../../api/adminApis";
import outcomes from "../outcomes/Outcomes";
import { number } from "yup";

export type LogoScrollerInterface = {
  id?: number;
  carouselConfigurator: {
    dots: boolean;
    infinite: boolean;
    speed: number;
    slidesToShow: number;
    slidesToScroll: number;
    autoPlaySpeed: number;
    autoplay: boolean;
    width: number;
    height: number;
  };
  images: Array<{ image: string; tooltip?: string; href?: string }>;
  isActive: boolean;
};

const defaultValues: LogoScrollerInterface = {
  carouselConfigurator: {
    autoPlaySpeed: 0,
    speed: 0,
    slidesToScroll: 0,
    slidesToShow: 3,
    autoplay: false,
    dots: false,
    infinite: true,
    width: 100,
    height: 70,
  },
  images: [
    {
      image: "",
      tooltip: "",
      href: "",
    },
  ],
  isActive: true,
};

export const LogoConfigurator = ({
  type,
  onSaved,
  id,
}: {
  type: keyof typeof CarouselType;
  onSaved?: (carouselId: number) => void;
  id?: number;
}) => {
  const selectedIndex = useRef(0);
  const _imageRef = useRef<UploadPopUpRef>(null);
  const formRef = useRef<FormikProps<LogoScrollerInterface>>(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const hideNotification = useAppState(appStateSelectors.hideNotification);
  const showNotificationWithVariant = useAppState(appStateSelectors.showNotificationWithVariant);

  useEffect(() => {
    const fetchCarousel = async () => {
      if (type !== "outcomes") {
        const { data: carousel } = await getByProgramId(type);
        if (Object.keys(carousel).length !== 0 && formRef) {
          formRef.current?.setValues(JSON.parse(JSON.stringify(carousel)));
        }
      }
    };

    const fetchOutcomes = async () => {
      if (id) {
        const { data: carousel } = await getCarouselById(id);
        if (Object.keys(carousel).length !== 0 && formRef) {
          formRef.current?.setValues(JSON.parse(JSON.stringify(carousel)));
        }
      }
    };

    id ? fetchOutcomes() : fetchCarousel();
  }, [id]);

  const onSubmitForm = async (values: LogoScrollerInterface) => {
    try {
      const newModel = { ...values, type };

      const { data } = values.id ? await updateCarousel(newModel) : await createCarousel(newModel);
      showNotificationWithVariant("success", values.id ? "Logos updated" : "Logos created");
      onSaved?.(data.id as number);
    } catch (e: any) {
      showNotificationWithVariant("danger", e?.message ? e.message : "Something went wrong");
    } finally {
      setTimeout(() => {
        hideNotification();
      }, 3000);
    }
  };

  const addResponse = () => {
    const currentImages = formRef.current?.values?.images ?? [];
    formRef.current?.setFieldValue("images", [...currentImages, { image: "" }]);
  };

  function getFeaturedImage(image: string) {
    return image ? (
      <img src={image} style={{ width: "100%", borderRadius: 10 }} alt="carousel image" />
    ) : (
      <IcImage className="p-1 drop-icon" />
    );
  }

  const removeSlide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    const currentImages = formRef.current?.values?.images ?? [];
    formRef.current?.setFieldValue(
      "images",
      currentImages.filter((_, i) => i !== index)
    );
    e.stopPropagation();
  };

  return (
    <Section
      title="Logo configurator"
      sectionId="configuration-editor-landing-page"
      titleSize="md"
      className="configuration-editor-landing-page mt-4"
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={defaultValues}
        onSubmit={onSubmitForm}
      >
        {({ values, setFieldValue, dirty, isValid }) => {
          const { carouselConfigurator } = values;
          return (
            <Form>
              <ScreenNotification variant="danger" styles={{ container: { marginTop: "2rem" } }} />
              <Container className="mt-5" fluid>
                <Row>
                  <Col xs={2}>
                    <Checkbox
                      onChange={e => setFieldValue("isActive", e)}
                      checked={values?.isActive}
                      id="is-active-logos"
                      label="Is active?"
                    />
                  </Col>
                  <Col xs={2}>
                    <Checkbox
                      onChange={e => setFieldValue("carouselConfigurator.infinite", e)}
                      checked={carouselConfigurator?.infinite}
                      id="infinite-carousel"
                      label="Infinite scroll"
                    />
                  </Col>
                  <Col xs={2}>
                    <Checkbox
                      onChange={e => setFieldValue("carouselConfigurator.autoplay", e)}
                      checked={carouselConfigurator?.autoplay}
                      id="autoplay-carousel"
                      label="Autoplay logos"
                    />
                  </Col>
                  <Row className="mt-5">
                    <Col xs={2}>
                      <Input
                        type={"number"}
                        value={values.carouselConfigurator.autoPlaySpeed}
                        onChange={(e: any) =>
                          setFieldValue("carouselConfigurator.autoPlaySpeed", e.target.value)
                        }
                        id="autoplay-speed-interval"
                        label="Autoplay speed"
                      />
                    </Col>
                    <Col xs={2}>
                      <Input
                        type={"number"}
                        value={values.carouselConfigurator.speed}
                        onChange={(e: any) =>
                          setFieldValue("carouselConfigurator.speed", e.target.value)
                        }
                        id="autoplay-interval"
                        label="Speed"
                      />
                    </Col>
                    <Col xs={2}>
                      <Input
                        type={"number"}
                        value={values.carouselConfigurator.slidesToShow}
                        onChange={(e: any) =>
                          setFieldValue("carouselConfigurator.slidesToShow", e.target.value)
                        }
                        id="slides-to-show"
                        label="Slides to show"
                      />
                    </Col>
                    <Col xs={2}>
                      <Input
                        type={"number"}
                        value={values.carouselConfigurator.slidesToScroll}
                        onChange={(e: any) =>
                          setFieldValue("carouselConfigurator.slidesToScroll", e.target.value)
                        }
                        id="slides-to-scroll"
                        label="Slides to scroll"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2}>
                      <Input
                        value={values.carouselConfigurator.width}
                        onChange={(e: any) =>
                          setFieldValue("carouselConfigurator.width", e.target.value)
                        }
                        id="width-to-scroll"
                        label="Image width"
                      />
                    </Col>
                    <Col xs={2}>
                      <Input
                        value={values.carouselConfigurator.height}
                        onChange={(e: any) =>
                          setFieldValue("carouselConfigurator.height", e.target.value)
                        }
                        id="height-to-scroll"
                        label="Image height"
                      />
                    </Col>
                  </Row>
                </Row>
                <LogosDemo />
                <br />

                {activeIndex !== -1 && (
                  <Row>
                    <Col sm={12} md={4}>
                      <Input
                        value={values.images[activeIndex].href}
                        onChange={(e: any) =>
                          setFieldValue(`images.${activeIndex}.href`, e.target.value)
                        }
                        id="logo-image-redirect"
                        label="Logo link"
                        placeholder="Input logo href"
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <Input
                        value={values.images[activeIndex].tooltip}
                        onChange={(e: any) =>
                          setFieldValue(`images.${activeIndex}.tooltip`, e.target.value)
                        }
                        id="logo-tooltip-text"
                        label="Tooltip text"
                        placeholder="Add logo tooltip text"
                      />
                    </Col>
                  </Row>
                )}

                <RoundButton
                  onClick={addResponse}
                  icon={<IcPlus />}
                  variant="primary"
                  roundBtnId={"add-content"}
                  label="Add carousel image"
                />
                <Row className="mt-3">
                  {values.images.map((slide, index) => (
                    <Col key={index} className="mb-2" xs={2}>
                      <div
                        className="drop-file-container"
                        onClick={() => {
                          _imageRef?.current?.show();
                          selectedIndex.current = index;
                        }}
                        style={{
                          paddingLeft: 10,
                          paddingRight: 10,
                          cursor: "pointer",
                        }}
                      >
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          {getFeaturedImage(slide.image)}
                          {!slide.image && <div>Set Featured Image</div>}
                        </div>
                        <div onClick={e => removeSlide(e, index)} className="delete-slide">
                          <DeleteIcon />
                        </div>
                      </div>
                      {values.images[index].image && (
                        <div className="d-flex justify-center ">
                          <TextButton
                            className="w-100"
                            onClick={() => setActiveIndex(index)}
                            label="Edit slide label "
                            textBtnId={""}
                          />
                        </div>
                      )}
                    </Col>
                  ))}
                </Row>

                <UploadPopup
                  id="response-video"
                  onConfirm={(uploadedMedia: IUploadResponse) => {
                    setFieldValue(`images.${selectedIndex.current}.image`, uploadedMedia?.fileUrl);
                  }}
                  ref={_imageRef}
                  mediaType="IMAGE"
                />
                <Row className="justify-content-end mt-3">
                  <Col md="auto">
                    <StandardButton
                      standardBtnId="article-editor-save-btn"
                      label="Save Updates"
                      variant="outlined"
                      type="submit"
                      disabled={!dirty || !isValid}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </Section>
  );
};
