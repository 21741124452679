import React from "react";
import { useNode } from "@craftjs/core";
import styled from "styled-components";
import { ImageSettings } from "./ImageSettings";
import { getElementSize } from "../../utils/numToMeasurement";

type IProps = {
  src?: string;
  alt?: string;
  height?: string | number;
  width?: string | number;
  margin?: any[];
  borderRadius?: string;
  borderWidth?: string;
  borderStyle?: string;
  borderColor?: string;
  boxSizing?: string;
  objectFit?: string;
  overflow?: string;
};

const StyledImage = styled.img<IProps>`
  margin: ${({ margin }) => getElementSize(margin || [])};
  border-radius: ${({ borderRadius }) => getElementSize(borderRadius)};
  border-width: ${({ borderWidth }) => getElementSize(borderWidth)};
  border-style: ${({ borderStyle }) => borderStyle};
  border-color: ${({ borderColor }) => borderColor};
  box-sizing: ${({ boxSizing }) => boxSizing};
  object-fit: ${({ objectFit }) => objectFit};
  overflow: ${({ overflow }) => overflow};
`;

export const Image = ({ src, alt, width, height, ...otherProps }: IProps) => {
  const {
    connectors: { connect },
  } = useNode(node => ({
    selected: node.events.selected,
  }));

  return (
    <StyledImage
      width={width}
      height={height}
      src={src}
      alt={alt}
      data-cy="toolbox-image"
      ref={connect as any}
      {...otherProps}
    />
  );
};

Image.craft = {
  displayName: "Image",
  props: {
    src: "",
    alt: "Upload image from customize",
    width: 150,
    height: 150,
    borderRadius: 0,
    margin: ["0", "0", "0", "0"],
  },
  related: {
    settings: ImageSettings,
    toolbar: ImageSettings,
  },
};
