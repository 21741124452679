import { Form, getIn, useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import Input from "../../components/input/Input";
import Section from "../../components/section/Section";
import React, { FormEvent, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { IMainProgramConfigBuilder } from "../../models/config";
import { getApiDomain } from "../../utils/common";
import BorderedCheckBox from "../../components/checkbox/BorderedCheckBox";
import { buildJsonFromForm } from "../../utils/objectBuilder";
import Switch from "../../components/switch/Switch";

export const ProgramElements = () => {
  const { editor } = useOutletContext<any>();
  const { values, touched, errors } = useFormikContext<IMainProgramConfigBuilder>();
  const ref = useRef<HTMLFormElement>(undefined!);

  function _getErrors(path: string) {
    return getIn(touched, path) && getIn(errors, path) ? getIn(errors, path) : "";
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = buildJsonFromForm(ref.current);
    const body: Partial<IMainProgramConfigBuilder> = {
      scripts: JSON.parse(JSON.stringify(values.scripts)),
      show_header: values.show_header ? 1 : 0,
      show_footer: values.show_footer ? 1 : 0,
      ...data,
    };
    await editor?.onSubmit?.(body);
  };
  const scriptContent =
    '<script src="' + getApiDomain() + "/script/redirect?program_id=" + values.id + '"></script>';

  return (
    <Form ref={ref} id="config-form-submit" onSubmit={onSubmit}>
      <Section sectionId="program-layout-settings" title="Program layout">
        <Row>
          <Col md={3} sm={5} xs={6} lg={3}>
            <div className="admin-editor-toggle">
              <p>Show header</p>
              <Switch
                checked={!!values.show_header}
                onChange={() => {
                  editor.onChange("show_header")(!values.show_header);
                }}
                switchId="admin-editor-live-btn"
              />
            </div>
          </Col>
          <Col md={3} sm={5} xs={6} lg={3}>
            <div className="admin-editor-toggle">
              <p>Show footer</p>
              <Switch
                checked={!!values.show_footer}
                onChange={() => {
                  editor.onChange("show_footer")(!values.show_footer);
                }}
                switchId="admin-editor-live-btn"
              />
            </div>
          </Col>
        </Row>
      </Section>
      <Section
        title="Program Management Details"
        sectionId="configuration-editor-management-details"
        titleSize="md"
        className="configuration-editor-management-details mt-4"
      >
        <Row>
          <Col>
            <Input
              id="configuration-editor-assigned_staff"
              label="Think 12 Staff Assigned"
              variant="secondary"
              name="think12_staff_assigned"
              error={_getErrors("think12_staff_assigned")}
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-owner_name"
              label="School Program Owner Name"
              variant="secondary"
              name="school.program_owner"
              error={_getErrors("school.program_owner")}
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-contract_dates"
              label="Contract Dates"
              variant="secondary"
              name="program_contract_dates"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              id="configuration-editor-enroll_url"
              label="Enroll URL"
              variant="secondary"
              name="school.enrollment_url"
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-enroll_text"
              label="Enroll Button Text"
              variant="secondary"
              name="enroll_button_text"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              xs={12}
              id="configuration-editor-chatbot-script"
              label="Chatbot Script"
              variant="secondary"
              name="scripts[0].body"
              as="textarea"
              rows={10}
              onChange={(e: any) => editor?.onUpdateScript?.("Botsify", e.target.value)}
            />
          </Col>
        </Row>
        <hr className="mt-5" />
      </Section>
      <Section
        title="Program Redirect Script Details"
        sectionId="configuration-editor-redirect-script-details"
        titleSize="md"
        className="configuration-editor-redirect-script-details mt-4"
      >
        <Row>
          <Col>
            <div className="input-container secondary">
              <div className="input-label-row">Redirect Script</div>
              <div id="configuration-banner-script-container" className="field false">
                <pre>{scriptContent}</pre>
              </div>
              <div>
                <a
                  style={{ marginTop: "1em" }}
                  className="btn btn-primary"
                  href="#"
                  onClick={e => {
                    navigator.clipboard.writeText(scriptContent);
                    e.preventDefault();
                  }}
                >
                  Copy to Clipboard
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <hr className="mt-5" />
      </Section>
      <Section
        title="Program Grade Bands"
        sectionId="configuration-editor-grade-bands"
        titleSize="md"
        className="configuration-editor-grade-bands mt-4"
      >
        <Row className="gap-3">
          {editor.gradeBands?.map((grade_band: any) => {
            return (
              <Col xs={12} sm={4} md={3} lg={3} xl={2} key={grade_band.id}>
                <BorderedCheckBox
                  id="configuration-editor-grade-band"
                  label={grade_band.gradeBand}
                  onChange={(checked: boolean) =>
                    editor?.onChangeGradeBand?.(grade_band.id, checked)
                  }
                  checked={(values.grade_band_ids || []).includes(grade_band.id)}
                />
              </Col>
            );
          })}
        </Row>
        <Row className="mt-5">
          <Col>
            <Input
              id="configuration-editor-last-content-import"
              label="Last content import"
              variant="secondary"
              name="last_content_import"
              error={
                touched.last_content_import && errors.last_content_import
                  ? errors.last_content_import
                  : ""
              }
            />
          </Col>
          <Col>
            <Input
              id="configuration-editor-last-email-import"
              label="Last email import"
              variant="secondary"
              name="last_email_import"
              error={
                touched.last_email_import && errors.last_email_import
                  ? errors.last_email_import
                  : ""
              }
            />
          </Col>
        </Row>
        <hr className="mt-5" />
      </Section>
    </Form>
  );
};
