import { Element, useEditor } from "@craftjs/core";
import React from "react";
import "./Toolbox.scss";
import { Button } from "../components/Button/Button";
import { Col, Row } from "../components/Container/Container";
import { ContainerIcon, ButtonIcon, ImageIcon, VideoIcon, TextIcon } from "../assets";
import { Tooltip } from "../../../components/tooltip/Tooltip";
import styled from "styled-components";
import { Image } from "../components/Image/Image";
import { Video } from "../components/Video/Video";
import { Text } from "../components/Text/Text";
import { GridItemIcon } from "../assets/item";
import { IFrame } from "../components/IFrameVideo/IFrame";
import { IFrameIcon } from "../assets/IFrameIcon";

const ToolboxDiv = styled.div<{ enabled: string }>`
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  ${props => (!props.enabled ? `width: 0;` : "")}
  ${props => (!props.enabled ? `opacity: 0;` : "")}
`;

const Item = styled.a<{ move?: boolean }>`
  svg {
    width: 30px;
    height: 30px;
    fill: #707070;
  }
  ${props =>
    props.move &&
    `
    cursor: move;
  `}
`;

export const Toolbox = () => {
  const {
    enabled,
    connectors: { create },
  } = useEditor(state => ({
    enabled: state.options.enabled,
  }));

  return (
    <ToolboxDiv enabled={`${enabled}`} className="ToolboxRow">
      <div
        ref={ref =>
          create(
            ref as any,
            <Element background="#fff" color={{ r: 0, g: 0, b: 0, a: 1 }} canvas is={Row} />
          )
        }
      >
        <Tooltip text="Row">
          <Item>
            <ContainerIcon />
          </Item>
        </Tooltip>
      </div>

      <div
        ref={ref =>
          create(
            ref as any,
            <Element background="#fff" color={{ r: 0, g: 0, b: 0, a: 1 }} canvas is={Col} />
          )
        }
      >
        <Tooltip text="Item">
          <Item>
            <GridItemIcon />
          </Item>
        </Tooltip>
      </div>

      <div ref={ref => create(ref as HTMLDivElement, <Button />)}>
        <Tooltip text="Button">
          <Item>
            <ButtonIcon />
          </Item>
        </Tooltip>
      </div>
      <div ref={ref => create(ref as HTMLDivElement, <Image />)}>
        <Tooltip text="Image">
          <Item>
            <ImageIcon />
          </Item>
        </Tooltip>
      </div>
      <div ref={ref => create(ref as HTMLDivElement, <Video />)}>
        <Tooltip text="Video">
          <Item>
            <VideoIcon />
          </Item>
        </Tooltip>
      </div>
      <div ref={ref => create(ref as HTMLDivElement, <IFrame />)}>
        <Tooltip text="IFrame">
          <Item>
            <IFrameIcon />
          </Item>
        </Tooltip>
      </div>
      <div ref={ref => create(ref as HTMLDivElement, <Text />)}>
        <Tooltip text="Text">
          <Item>
            <TextIcon />
          </Item>
        </Tooltip>
      </div>
    </ToolboxDiv>
  );
};
