import { PdfViewer } from "./PdfViewer";
import React from "react";
import "./PdfCard.scss";
import RoundButton from "../button/RoundButton";
import { ReactComponent as IcClose } from "app/assets/icons/close.svg";

type PdfCardProps = {
  url: any;
  boxStyle?: object;
  cardStyles?: object;
  onRemove?: () => void;
};

export const PdfCard = ({ url, onRemove, boxStyle, cardStyles }: PdfCardProps) => {
  // Dynamically adjust the PDF page size here if necessary
  const pageWidth = 340;
  const pageScale = 0.7;

  return (
    <div style={{ position: "relative", ...boxStyle }}>
      <div
        style={{
          ...{
            height: 400,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          },
          ...cardStyles,
        }}
      >
        <PdfViewer file={url} pageWidth={pageWidth * pageScale} />
      </div>
      {onRemove && (
        <div style={{ position: "absolute", top: 10, right: 10 }}>
          <RoundButton
            className={"club-leader-remove"}
            variant="primary"
            roundBtnId={`delete`}
            icon={<IcClose style={{ stroke: "white" }} />}
            onClick={onRemove}
          />
        </div>
      )}
    </div>
  );
};
