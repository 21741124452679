import React from "react";
import ScreenNotification from "../../components/screenNotification/ScreenNotification";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import PrivateLayout from "../../layout/PrivateLayout";
import { useOutcomeEditor } from "./hooks/useOutcomeEditor";
import { IOutcomeLogos } from "../../models/outcomeLogos";
import { FeaturedUploader } from "../../components/FeaturedUploader/FeaturedUploader";
import Input from "../../components/input/Input";
import { getErrors } from "../../utils/data";
import StandardButton from "../../components/button/StandardButton";
import Checkbox from "../../components/checkbox/CheckBox";

export const OutcomeLogosEditor = () => {
  const _editor = useOutcomeEditor();
  return (
    <PrivateLayout contentClassName="new-profile-page-editor">
      <h3 className="title">College editor</h3>
      <ScreenNotification variant="danger" styles={{ container: { marginTop: "2rem" } }} />
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues as IOutcomeLogos}
        onSubmit={_editor.onSubmit}
      >
        {({ touched, setFieldValue, dirty, isValid, errors, values }) => (
          <Form>
            <Row>
              <Col md={3}>
                <FeaturedUploader
                  imageUrl={values?.media?.url || ""}
                  onFeaturedImageChange={_editor.onSetImage}
                />
              </Col>
              <Col>
                <Col sm={12} className="justify-content-end d-flex">
                  <StandardButton
                    type="submit"
                    variant="secondary"
                    label="Save Changes"
                    disabled={!dirty || !isValid}
                    className="save-button"
                    standardBtnId="graduate-builder-save-btn"
                  />
                </Col>
                <Row className="mt-4">
                  <Col className="fs-5 mb-4" xs={12}>
                    <Checkbox
                      id={"is_outcome-logo-active"}
                      label="Is active"
                      checked={values.is_active}
                      onChange={(checked: boolean) => setFieldValue(`is_active`, checked)}
                    />
                  </Col>
                  <Col>
                    <Input
                      id="outcome-logos-title"
                      variant="secondary"
                      label="School name*"
                      placeholder="Enter school name"
                      name="outcome_title"
                      error={getErrors(touched, errors, "outcome_title")}
                    />
                  </Col>
                  <Col>
                    <Input
                      id="outcome-logos-abbrevation"
                      variant="secondary"
                      label="School abbreviation"
                      placeholder="Enter school abbrevation"
                      name="outcome_abbreviation"
                      error={getErrors(touched, errors, "outcome_abbreviation")}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Input
                      id="outcome-logos-website-link"
                      variant="secondary"
                      label="School website link"
                      placeholder="Enter school website link"
                      name="outcome_url"
                      error={getErrors(touched, errors, "outcome_url")}
                    />
                  </Col>
                  <Col>
                    <Input
                      id="outcome-logos-logo-name"
                      variant="secondary"
                      label="Logo ID"
                      placeholder="Enter logo ID"
                      name="logo_name"
                      error={getErrors(touched, errors, "logo_name")}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Input
                      id="outcome-logos-zip-code"
                      variant="secondary"
                      label="School zip code"
                      placeholder="Enter school zip code"
                      name="zip_code"
                      error={getErrors(touched, errors, "zip_code")}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </PrivateLayout>
  );
};
