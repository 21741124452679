import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import DataGrid from "app/components/dataGrid/DataGrid";
import Switch from "app/components/switch/Switch";
import useQAListing from "app/hooks/QAListing";
import QuestionAnswerPreviewPopup from "./components/QuestionAnswerPreviewPopup";
import { IQAListItem } from "app/models/qa";
import React from "react";
import "./qa.scss";
import { QAListing } from "./QAListing";
import { appStateSelectors, useAppState } from "../../state/AppState";
import StandardButton from "../../components/button/StandardButton";

const GRID_ID = "qa-account-listing";

const QAContentManager = () => {
  const _qaListing = useQAListing();
  const appConfig = useAppState(appStateSelectors.appConfig);

  return (
    <>
      <QAListing _qaListing={_qaListing} newAction title="Q&amp;A Content Manager">
        <DataGrid<IQAListItem>
          data={_qaListing.data}
          gridId={GRID_ID}
          rowIdAttribute="id"
          loading={_qaListing.loading}
          currentPage={_qaListing.currentPage}
          onPageChange={_qaListing.onPageChange}
          totalItems={_qaListing.totalItems}
          onAction={_qaListing.onGridAction}
          confirmDeleteDescription="Please confirm that you would like to delete this question/topic."
        >
          <DataGrid.Column<IQAListItem> attributeName="topic" header="Question or Topic" />
          <DataGrid.Column<IQAListItem> attributeName="topicId" header="Content Id" />
          <DataGrid.Column<IQAListItem> attributeName="gradeBands" header="Grade Band" />

          <DataGrid.Column<IQAListItem> attributeName="categoryTags" header="Category Tags" />
          <DataGrid.Column<IQAListItem> attributeName="approveStatus" header="Approval Status" />

          <DataGrid.Column<IQAListItem>
            attributeName="activeStatus"
            header="Active Status"
            transform={question => {
              return (
                <Switch
                  disabled={question.programId !== appConfig?.id}
                  checked={question.activeStatus}
                  onChange={_qaListing.onToggleActive(question)}
                  switchId="question-listing-live-btn"
                />
              );
            }}
          />

          <DataGrid.Column<IQAListItem>
            attributeName="activeStatus"
            header="Assigned"
            transform={question => {
              return question.programId !== appConfig?.id ? (
                <StandardButton
                  standardBtnId="1"
                  size="xs"
                  variant="secondary"
                  label="Remove"
                  onClick={() => _qaListing.deleteSyncVideo(question.id)}
                />
              ) : (
                <div></div>
              );
            }}
          />

          <DataGrid.Column<IQAListItem>
            action={DATA_GRID_ACTIONS.PREVIEW}
            attributeName="GRID.ACTIONS"
          />
          <DataGrid.Column<IQAListItem>
            action={DATA_GRID_ACTIONS.EDIT}
            attributeName="GRID.ACTIONS"
          />
          <DataGrid.Column<IQAListItem>
            action={DATA_GRID_ACTIONS.DELETE}
            attributeName="GRID.ACTIONS"
          />
        </DataGrid>
      </QAListing>

      <QuestionAnswerPreviewPopup ref={_qaListing.previewPopupRef} id="qa-listing-preview" />
    </>
  );
};
export default QAContentManager;
