import React, { useImperativeHandle, useRef, useState } from "react";
import T12Modal, { T12ModalRef } from "../../components/modal/T12Modal";
import { PreviewModalType } from "../../hooks/AdminAnnouncementListing";
import { IAnnouncement } from "../../models/adminAnnouncement";

export const AnnouncementPreviewModal = React.forwardRef<PreviewModalType, any>(({ id }, ref) => {
  const modalRef = useRef<T12ModalRef>(null);
  const [state, setState] = useState<IAnnouncement | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      show: (announcement: IAnnouncement) => {
        setState(announcement);
        modalRef.current?.show();
      },
    }),
    []
  );

  return (
    <T12Modal
      modalId={id}
      hideFooter
      size="lg"
      ref={modalRef}
      title={state?.title}
      className="preview-popup"
    >
      <div dangerouslySetInnerHTML={{ __html: state?.content || "" }}></div>
    </T12Modal>
  );
});
