import { IGradeBand } from "./program";
import { IDesignation, ICategoryTag } from "./common";
import { IPreference } from "./preference";
import { IRole, ILaravelModel } from "./common";

export interface IEmailBase {
  id: number;
  emailId: string;
  // title: string;
  subject?: string;
  emailDescription?: string;
  preHeaderText?: string;
  // content?: IMailGunTemplate;
  mailgunTemplateName?: string;
  designationId?: number;
  categoryTags: string;
  categoryIds?: number[];
  designationType?: string;
  approvalStatus?: string;
  sendDate: string;
  population: string;
  isActive?: boolean;
  gradeBandIds?: number[];
  subscriptionType?: string;
  /** Api v2 */
  description?: string;
  name: string;
  body?: string;
  content?: string;
  approvalStatusId?: number;
  content_designation_id?: number;
  content_approval_status_id?: number;
  categories?: ICategoryTag[];
  category_ids?: number[];
  grade_bands?: IGradeBand[];
  grade_band_ids?: number[];
  preference_id?: number;
  preheader?: string;
}

export interface IEmailManager
  extends Omit<IEmailBase, "sendDate" | "population" | "activateStatus"> {
  emailListing: string;
}
export interface IEmailBuilder
  extends Omit<IEmailBase, "id" | "emailId" | "sendDate" | "population"> {
  id: number | null;
  emailId: number | null;
  type?: IPreference;
  type_id?: string;
  preference_id?: number;
}

export type NotificationScheduleUserRegistrationStatus =
  | "All"
  | "VerifiedFamily"
  | "UnverifiedFamily";

export const FrequencyValues = ["Always", "Once", "OncePerDay", "OncePerWeek", "OncePerMonth"];
export type NotificationScheduleFrequency = (typeof FrequencyValues)[number];
// export type NotificationScheduleFrequency =
//   | 'Always'
//   | 'Once'
//   | 'OncePerDay'
//   | 'OncePerWeek'
//   | 'OncePerMonth';

export interface IScheduleLimit {
  id?: number;
  name: "Authenticated" | "Registered" | "Verified";
  unit: "Day";
  operator: "=" | ">" | "<";
  amount: number;
}

export interface IEmailScheduleManager extends Omit<IEmailBase, "isActive"> {
  id: number;
  notification_template_id: number;
  template: IEmailManager;
  grade_bands: IGradeBand[];
  grade_band_ids: number[];
  roles: IRole[];
  role_ids: number[];
  user_registration_status: NotificationScheduleUserRegistrationStatus;
  delivery_frequency: NotificationScheduleFrequency;
  recipient_frequency: NotificationScheduleFrequency;
  limits: IScheduleLimit[];
  total_notifiables_count: number;
  start_at: string | Date;
  end_at: string | Date;
  next_delivery_at: string | null;
  is_enabled?: boolean;
  created_at: null | null;
  updated_at: string;
  class_year?: number[];
}

export interface IEmailScheduleItem extends Partial<IEmailScheduleManager> {
  programId?: number;
  isActive: boolean;
  emailTemplateIds: number[];
  sendNow?: boolean;
  startTime?: string;
  endTime?: string;
  registrationStatus: number | string | EmailScheduleRegistration;
  gradeBandIds: number[];
  registrationLimit?: IEmailScheduleLimitOption;
  validationLimit?: IEmailScheduleLimitOption;
  loginLimit?: IEmailScheduleLimitOption;
  frequency: number | string;
  userFrequency: number | string;
  is_confirmed_correct: boolean;
}

export interface IEmailScheduleItemBuilder extends ILaravelModel, Partial<IEmailScheduleManager> {
  is_confirmed_correct: boolean;
  programId?: number;
  emailTemplateIds?: number[];
  sendNow?: boolean;
  startTime?: string;
  endTime?: string;
  registrationStatus?: number | string | EmailScheduleRegistration;
  gradeBandIds?: number[];
  registrationLimit?: IEmailScheduleLimitOption;
  validationLimit?: IEmailScheduleLimitOption;
  loginLimit?: IEmailScheduleLimitOption;
  frequency?: number | string;
  userFrequency?: number | string;

  scheduleTime?: string;
  scheduleAmPm?: string;
}

export type LIMIT_TYPE = "registrationLimit" | "validationLimit" | "loginLimit";

export interface IEmailScheduleLimitOption {
  operation: ">" | "<" | "=";
  value: number;
}

export type EmailScheduleRegistration = "validated" | "non-validated" | "all";

export interface ITriggeredEmail
  extends Omit<IEmailBase, "sendDate" | "population" | "activateStatus"> {
  triggerDescription: string;
  populationReceivingEmail: string;
  isActive?: boolean;
}

export interface IEmailReporting
  extends Pick<IEmailBase, "emailId" | "emailDescription" | "description" | "sendDate"> {
  previewLink: string;
  criteria: string;
  emailsSent: string;
  emailsOpened: string;
  emailsClicked: string;
}

export interface IScheduleDate {
  id: number;
  scheduleDate: string;
}

export interface ILastSendDate {
  id: number;
  lastSendDate: string;
}

export interface ITriggeredDescription {
  id: number;
  triggeredDescription: string;
}

export interface IEmailTemplate {
  name: string;
  description: string;
  createdAt: string;
  createdBy: string;
  id: string;
  emailId: string;
  title: string;
  mailgunTemplate?: string;
  designationType: IDesignation;
  categoryTags: ICategoryTag[];
  approvalStatus: string;
  content?: IMailGunTemplate;
  gradeBandIds?: IGradeBand[];
}

export interface IMailGunTemplate {
  tag: string;
  template: string;
  createdAt: string;
  id: string;
}

export interface IEmailTemplateContent {
  name?: string;
  content?: IMailGunTemplate;
  // v2 Api
  data: string;
}

export interface IEmailTemplateTest {
  recipients: {
    email: string;
  }[];
}
