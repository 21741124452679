import React from "react";
import { useState } from "react";
import ReportingChart from "./ReportingChart";

import { ReactComponent as DownArrowIcon } from "app/assets/icons/down-arrow.svg";

type Props = {
  graph: any;
  pages: any;
  count: number;
  title: string;
  onDownload: (url?: string) => void;
};

const ReportingSection = (props: Props) => {
  const graph = props.graph;
  const pages = props.pages ? props.pages : [];
  const count = props.count;
  const title = props.title;

  const [expand, setExpand] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const pageSize = 6;

  return (
    <div className="detail-container">
      <div className="detail-summary">
        <div className="left">
          <h5>{title}</h5>
          <div className="graph-container">
            <ReportingChart data={graph} maxData={5} type="Bar" />
          </div>
          <div className="total-views report-download" onClick={() => props.onDownload()}>
            Total Views
            <br />
            <span>{count}</span>
          </div>
        </div>
        <div className="right">
          {pages.slice(0, 4).map((item: any, i: number) => {
            return (
              <div
                key={item.label}
                className="report-download"
                onClick={() => props.onDownload(item.uri)}
              >
                <span className="value">{item.value}</span>{" "}
                <span className="label">{item.label}</span>
              </div>
            );
          })}
        </div>
        <div
          className={"view-all " + (expand ? "expanded" : "")}
          onClick={() => setExpand(!expand)}
        >
          View All{" "}
          <span className="view-all-arrow">
            <DownArrowIcon />
          </span>
        </div>
      </div>
      {expand ? (
        <div className="detail-expanded">
          <ReportingChart data={graph} type="Bar" className="bar-fullwidth" />
          <ReportingChart data={pages} type="Pie" className="pie-small" />
          <div className="items">
            {pages
              .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
              .map((item: any, i: number) => {
                return (
                  <div className="item" key={i} onClick={() => props.onDownload(item.uri)}>
                    <span className="report-download">{item.pageviews}</span> {item.label}
                  </div>
                );
              })}
          </div>

          <div
            className="pagination"
            style={{
              width: "80%",
              marginLeft: "20%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {pages.length > pageSize &&
              Array.from({
                length: Math.floor(pages.length / pageSize) + 1,
              }).map((_, index) => (
                <span
                  key={index}
                  onClick={() => setCurrentPage(index)}
                  style={{
                    color: "#2E75FF",
                    borderRadius: "50%",
                    background: currentPage === index ? "#EAEBEE" : "#FFFFFF",
                    width: "2em",
                    height: "2em",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    margin: ".5em",
                    marginBottom: "2em",
                    cursor: "pointer",
                  }}
                >
                  {index + 1}
                </span>
              ))}
          </div>
        </div>
      ) : (
        []
      )}
    </div>
  );
};
export default ReportingSection;
