import { IQA, IQAResponse } from "app/models/qa";
import { createQA, getQA, getQATopicId, updateQA, getAvailableUsersFor } from "app/api/adminApis";
import { useAppState, appStateSelectors } from "app/state/AppState";
import { processErrorResponse } from "app/utils/common";
import { FormikHelpers, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const useQAEditor = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<IQA>>(null);

  const [programUsers, setProgramUsers] = useState<
    | {
        id: number;
        name: string;
      }[]
    | null
  >(null);

  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const contentCategories = useAppState(appStateSelectors.contentCategories);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const showNotification = useAppState(appStateSelectors.showNotification);
  const hideNotification = useAppState(appStateSelectors.hideNotification);
  const appConfig = useAppState(appStateSelectors.appConfig);
  const programId = useAppState(appStateSelectors.programId);

  const params = useParams();

  useEffect(() => {
    return () => hideNotification();
  }, []);

  useEffect(() => {
    params.id ? _getQADetails(+params.id) : _getQATopicId();
    _getProgramUsers();
  }, [params]);

  const _getQATopicId = async () => {
    showLoader();
    try {
      addResponse();
      const {
        data: { topicId },
      } = await getQATopicId();
      formRef.current?.setFieldValue("topicId", topicId);
      formRef.current?.setFieldValue("url", `/q-and-a/${topicId}`);
    } catch (error) {
      console.log("🚀 ~ file: QAEditor.ts ~ _getQATopicId= ~ error", error);
    } finally {
      hideLoader();
    }
  };

  const _getQADetails = async (id: number) => {
    showLoader();
    try {
      const { data } = await getQA(id);
      formRef.current?.setValues(data);
    } catch (error) {
      console.log("🚀 ~ file: QAEditor.ts ~ _getQADetails= ~ error", error);
    } finally {
      hideLoader();
    }
  };

  const _getProgramUsers = async () => {
    const id = appConfig?.id;
    try {
      if (id) {
        const { data } = await getAvailableUsersFor("all");
        const userOptions = (data || []).map(user => ({
          id: user.id,
          name: user.full_name || "N/A",
        }));
        setProgramUsers(userOptions);
      }
    } catch (error) {
      console.log("🚀 ~ file: QAEditor.ts ~ _getProgramUsers= ~ error", error);
    } finally {
      hideLoader();
    }
  };

  const onQuoteInputUserChange = (user: string) => {
    formRef.current?.setFieldValue("quoteUser", user);
    formRef.current?.setFieldValue("quoteUserId", null);
  };

  const onQuoteSelectUserChange = (user: { id: number; name: string }) => {
    formRef.current?.setFieldValue("quoteUser", user.name);
    formRef.current?.setFieldValue("quoteUserId", (user as { id: number; name: string }).id);
  };

  const onSelectionChange =
    (
      key: keyof Pick<
        IQA,
        "categoryIds" | "gradeBandIds" | "quoteApprove" | "quoteUser" | "quoteUserId"
      >
    ) =>
    (value: number | number[] | boolean) =>
      formRef.current?.setFieldValue(key, value);

  const onChange = (key: string, checked: boolean | string) =>
    formRef.current?.setFieldValue(key, checked);

  const addResponse = () => {
    const currentResponses = formRef.current?.values?.responses ?? [];
    formRef.current?.setFieldValue("responses", [...currentResponses, initialResponse]);
  };

  const onDeleteResponse = async (index: number, responseId?: number) => {
    showLoader();
    try {
      _deleteResponse(index, responseId);
    } catch (error) {
      console.log("🚀 ~ file: QAEditor.ts ~ onDeleteResponse ~ error", error);
    } finally {
      hideLoader();
    }
  };

  const _deleteResponse = (index: number, responseId?: number) => {
    const currentResponses = formRef.current?.values?.responses ?? [];
    const deletedResponses = formRef.current?.values.deletedResponses || [];

    formRef.current?.setFieldValue(
      "responses",
      currentResponses.filter((_, _index) => index !== _index)
    );

    formRef.current?.setFieldValue("deletedResponses", [...deletedResponses, responseId]);
  };

  const _deriveApprovalStatus = (qa: IQA) => {
    const noResponses = !qa.responses || (qa.responses && qa.responses.length < 1);
    const quoteNotApproved = !qa.quoteApprove;
    if (noResponses || quoteNotApproved) return "Approval Pending";

    const approvedResponses = qa.responses.filter(response => response.responseApprovedStatus);

    return approvedResponses.length > 0 ? "Approved" : "Approval Pending";
  };

  const pruneParams = (qa: IQA) => {
    const approveStatus = _deriveApprovalStatus(qa);
    const responsesStr = qa.responses
      .filter((response: IQAResponse) => !!response.responseUrl)
      .map((response: IQAResponse) => JSON.stringify(response));
    return { ...qa, programId, responsesStr, approveStatus };
  };

  const onSubmit = async (qa: IQA) => {
    if (!qa) return;

    hideNotification();
    showLoader();

    const formattedParams = pruneParams(qa);
    try {
      const response = await (formattedParams.id
        ? updateQA(formattedParams.id || 0, formattedParams)
        : createQA(formattedParams));

      navigate(-1);
    } catch (error: any) {
      processErrorResponse({ error, callback: showNotification });
      console.log("🚀 ~ file: QAEditor.ts ~ onSubmit ~ error", error);
    } finally {
      hideLoader();
    }
  };

  return {
    initialValues,
    formRef,
    gradeBands,
    programUsers,
    contentCategories,
    addResponse,
    onSubmit,
    onSelectionChange,
    onDeleteResponse,
    onQuoteInputUserChange,
    onQuoteSelectUserChange,
    onChange,
  };
};

export default useQAEditor;

const initialValues: IQA = {
  categoryIds: [],
  gradeBandIds: [],
  quoteText: "",
  quoteTitle: "",
  quoteUser: "",
  responses: [],
  topic: "",
  topicId: "",
  url: "",
  activeStatus: false,
  quoteApprove: false,
  deletedResponses: [],
};

const initialResponse: IQAResponse = {
  responseName: "",
  responseTitle: "",
  responseUrl: "",
  uploadFileUrl: "",
  responseApprovedStatus: false,
  responseId: undefined,
  thumbnail: null,
};
