import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormikTouched, FormikErrors } from 'formik';

import Section from 'app/components/section/Section';
import SearchBar from 'app/components/searchBar/SearchBar';
import DataGrid from 'app/components/dataGrid/DataGrid';
import { IEmailManager, IEmailScheduleItemBuilder } from 'app/models/email';
import { IEmailScheduleEditor } from 'app/hooks/EmailScheduleEditor';
import { getErrors } from 'app/utils/data';

interface IEmailTemplateSelectionSection {
  editor: IEmailScheduleEditor;
  values: IEmailScheduleItemBuilder;
  errors: FormikErrors<IEmailScheduleItemBuilder>;
  touched: FormikTouched<IEmailScheduleItemBuilder>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const EmailTemplateSelectionSection: React.FC<
  IEmailTemplateSelectionSection
> = ({ editor, values, errors, touched, setFieldTouched }) => {
  const errorString = getErrors(touched, errors, 'notification_template_id');

  return (
    <Section
      title='Select the email you would like to schedule'
      sectionId='emailScheduleTemplates'
      titleSize='md'
      className='email-schedule-templates mt-4'
    >
      <Row>
        <Col>
          <SearchBar
            placeHolder='Search by description, keyword or email ID'
            searchBarId='email-schedule-template-search'
            onChangeText={editor.onEmailTemplateSearch}
            liveSearch
          />
          <DataGrid<IEmailManager>
            data={editor.selectableEmailTemplates}
            gridId='email-schedule-email-templates mt4'
            loading={editor.selectableEmailTemplatesLoading}
            rowIdAttribute='id'
            selectionType='single'
            selectedRows={
              values.notification_template_id
                ? [values.notification_template_id]
                : []
            }
            totalItems={editor.selectableEmailTemplates?.length || 0}
            onSelectionChange={(notification_template_ids: number[]) => {
              setFieldTouched('notification_template_id');
              editor.onEmailTemplateSelection(notification_template_ids);
            }}
            gridHeight={400}
          >
            <DataGrid.Column<IEmailManager>
              attributeName='name'
              header='Email Listing'
            />
            <DataGrid.Column<IEmailManager>
              attributeName='description'
              header='Description'
            />
            <DataGrid.Column<IEmailManager>
              attributeName='id'
              header='Email ID'
            />
          </DataGrid>
        </Col>
      </Row>
      {errorString && (
        <Row className='email-schedule-notification-template-error'>
          <Col>{errorString}</Col>
        </Row>
      )}
      <hr className='mt-5' />
    </Section>
  );
};
