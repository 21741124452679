import PrivateLayout from "app/layout/PrivateLayout";
import useProfileEditor from "app/hooks/UserProfileEditor";
import { Form, Formik, getIn } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Input from "app/components/input/Input";
import StandardButton from "app/components/button/StandardButton";
import Section from "app/components/section/Section";
import EditProfileImage from "app/components/profileSelector/EditProfileImage";
import UploadPopup from "../sitePhotos/uploadPopup/UploadPopupV2";
import DropDownBase from "app/components/dropDown/DropDownBase";
import { YEARS } from "app/constants/common";
import ScreenNotification from "app/components/screenNotification/ScreenNotification";
import { IGradeBand } from "app/models/program";
import { IMediaUpload } from "app/models/media";
import { IRole } from "app/models/common";
import { IPreference } from "app/models/preference";
import IcPlaceholder from "app/assets/images/placeholder.png";
import "./user-profile-manager.scss";

const UserProfileEditor: React.FC = () => {
  const _editor = useProfileEditor();
  return (
    <PrivateLayout contentClassName="user-editor">
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues}
        onSubmit={_editor.onSubmit}
      >
        {({ values, isValid, dirty, errors, touched }) => {
          console.log("Values: ", values);

          function _getErrors(path: string) {
            return getIn(touched, path) && getIn(errors, path)
              ? getIn(errors, path)
              : "";
          }
          return (
            <>
              <Form>
                <Row className="my-5">
                  <Col>
                    <h3 className="title">User Account Editor</h3>
                  </Col>
                </Row>
                <ScreenNotification
                  label="Errors"
                  variant="danger"
                  styles={{ container: { marginTop: "2rem" } }}
                />
                <Row>
                  <Col md="auto">
                    {/* <EditProfileImage
                      mode='vertical'
                      // url={values.profile_image}
                      url={''}
                      id='user-builder-profile'
                      onConfirm={_editor.onSelectionChange('profile_image')}
                    /> */}
                    <div className={`edit-profile vertical`}>
                      <img
                        src={_editor.profileImageUrl ?? IcPlaceholder}
                        alt=""
                      />
                      <div className={`edit-profile-btn`}>
                        <StandardButton
                          onClick={() =>
                            _editor.profileImageRef.current?.show()
                          }
                          label="Edit Profile Image"
                          size="sm"
                          type="button"
                          standardBtnId="edit-profile-upload"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <Section
                      title=""
                      sectionId="staffBuilderGeneral"
                      titleSize="lg"
                      className="staff-builder-general"
                      isEditable={true}
                    >
                      <Row>
                        <Col>
                          <Input
                            id="user-builder-programId"
                            variant="secondary"
                            label="Program ID"
                            placeholder="Program ID"
                            name="program_id"
                            disabled
                            error={_getErrors("program_id")}
                          />
                        </Col>
                        <Col>
                          <Input
                            id="user-builder-family-code"
                            variant="secondary"
                            label="Family Code"
                            placeholder="Family Code"
                            name="family.code"
                            error={_getErrors("family.code")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            id="user-builder-first-name"
                            variant="secondary"
                            label="Name"
                            required
                            placeholder="Enter user's name here"
                            name="first_name"
                            error={_getErrors("first_name")}
                          />
                        </Col>
                        <Col>
                          <Input
                            id="user-builder-last-name"
                            variant="secondary"
                            label="Last Name"
                            required
                            placeholder="Enter user's last name here"
                            name="last_name"
                            error={_getErrors("last_name")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            id="staff-builder-title"
                            variant="secondary"
                            label="Username"
                            required
                            placeholder="Enter user's username here"
                            name="username"
                            autocomplete="off"
                            error={_getErrors("username")}
                          />
                        </Col>
                        <Col>
                          <DropDownBase<number>
                            id="user-builder-classYear"
                            options={YEARS.map((year) => +year)}
                            labelOnly
                            selected={values.attending_school_since || 0}
                            placeholder="Select Year"
                            label={`Member of ${_editor.schoolName} Since*`}
                            onOptionChange={_editor.onSelectionChange(
                              "attending_school_since"
                            )}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            id="user-builder-email"
                            variant="secondary"
                            label="Email Address"
                            required
                            placeholder="Enter your email here"
                            name="email"
                            error={_getErrors("email")}
                          />
                        </Col>
                        <Col>
                          <Input
                            id="user-builder-phone"
                            variant="secondary"
                            label="Contact Number"
                            required
                            placeholder="Enter your phone number here"
                            name="phone_number"
                            error={_getErrors("phone_number")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            id="user-builder-class"
                            variant="secondary"
                            label="Class"
                            name="class"
                            error={_getErrors("class")}
                          />
                        </Col>
                        <Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <DropDownBase<string>
                            id="staff-builder-subscriptions"
                            options={_editor.preferences.map(
                              (pref) => pref.subcategory
                            )}
                            labelOnly={false}
                            selected={values.preferences.map(
                              (pref) => pref.subcategory
                            )}
                            placeholder="Select subscription statuses"
                            allSelectedLabel="Full"
                            noneSelectedLabel="None"
                            label="Subscription Status"
                            onOptionChange={_editor.onPreferenceChange}
                            multiple
                          />
                        </Col>
                        <Col>
                          <Input
                            id="user-builder-verification-code"
                            variant="secondary"
                            label="Verification Code"
                            required
                            placeholder="Enter user's verification code here"
                            name="verification_code"
                            error={_getErrors("verification_code")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <DropDownBase<string>
                            id="user-builder-roles"
                            options={_editor.roles.map((role) => role.roleName)}
                            labelOnly={false}
                            selected={(values.roles || [])?.map(
                              (role) => role.name!
                            )}
                            placeholder="Select user roles"
                            allSelectedLabel="All"
                            noneSelectedLabel="No User Roles Set"
                            label="User Roles"
                            onOptionChange={_editor.onRoleChange}
                            multiple
                          />
                        </Col>
                        <Col>
                          <DropDownBase<string>
                            id="user-builder-grade-bands"
                            options={_editor.gradeBands.map(
                              (gb) => gb.gradeBand!
                            )}
                            labelOnly={false}
                            selected={(values.grade_bands || []).map(
                              (gb) => gb.grade_band!
                            )}
                            placeholder="Select grade bands"
                            allSelectedLabel="All"
                            noneSelectedLabel="No Grade Bands Set"
                            label="Grade Bands"
                            onOptionChange={_editor.onGradeBandChange}
                            multiple
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            id="user-builder-password"
                            variant="secondary"
                            label="Password"
                            required
                            placeholder="Enter your password here"
                            name="password"
                            type="password"
                            togglePassword
                            autocomplete="new-password"
                            error={_getErrors("password")}
                          />
                        </Col>
                      </Row>
                    </Section>
                    <StandardButton
                      type="submit"
                      variant="secondary"
                      label="Save Changes"
                      disabled={!dirty || !isValid}
                      className="save-button"
                      standardBtnId="user-builder-save-btn"
                    />
                  </Col>
                </Row>
                <UploadPopup
                  id="configuration-editor-upload-logo"
                  ref={_editor?.profileImageRef}
                  onConfirm={() => {}}
                  mediaType="IMAGE"
                  owner={{
                    id: 0,
                    type: "User",
                  }}
                  autoUpload={false}
                  onFileSelect={(
                    type,
                    file?: File,
                    tmpFileStr?: string,
                    folderId?: number
                  ) => {
                    console.log("Type : ", type);
                    console.log("File : ", file);
                    console.log("Folder Id : ", folderId);
                    // _editor.onProfileImageChange({
                    //   image: file,
                    //   folder_id: folderId,
                    // } as IMediaUpload);
                  }}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </PrivateLayout>
  );
};

export default UserProfileEditor;
