import { IPaginationParams, IPaginationResponse } from "../../models/api";
import { IAnnouncement } from "../../models/adminAnnouncement";
import { nonAdminApi } from "../adminApis";

export const listAdminAnnouncement = (params?: IPaginationParams) =>
  nonAdminApi.get<IPaginationResponse<IAnnouncement>>("/announcements", params);

export const updateAnnouncement = (id: number, update: Partial<IAnnouncement>) =>
  nonAdminApi.put<Partial<IAnnouncement>, IAnnouncement>(`/announcements/${id}`, update);

export const createAnnouncement = (announcement: IAnnouncement) =>
  nonAdminApi.post<IAnnouncement, IAnnouncement>(`/announcements`, announcement);

export const deleteAnnouncement = (id: number) => nonAdminApi.delete(`/announcements/${id}`);
