import React from "react";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { getIn } from "formik";

import StandardButton from "app/components/button/StandardButton";
import EducationEditor from "app/components/educationEditor/EducationEditor";
import FamilyMembersEditor from "app/components/familyMembersEditor/FamilyMembersEditor";
import ActivityContainer from "app/components/activitySelector/ActivityContainer";
import RoundButton from "app/components/button/RoundButton";
import Checkbox from "app/components/checkbox/CheckBox";
import Input from "app/components/input/Input";
import Section from "app/components/section/Section";
import Switch from "app/components/switch/Switch";
import ConfirmPopup from "app/components/confirmPopup/ConfirmPopup";
import EditProfileImage from "app/components/profileSelector/EditProfileImage";
import DropDownBase from "app/components/dropDown/DropDownBase";
import ScreenNotification from "app/components/screenNotification/ScreenNotification";
import UserAccountDropDownInput from "app/components/userAccountDropdownInput/UserAccountDropdownInput";
import AlbumContainer from "app/components/album/AlbumContainer";
import useFamilyEditor from "app/hooks/FamilyEditor";
import PrivateLayout from "app/layout/PrivateLayout";
import { IUser, IUserV2 } from "app/models/user";
import { emptyFunction } from "app/utils/common";
import { formatUserFullName } from "app/utils/data";
import { YEARS } from "app/constants/common";
import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import { ReactComponent as IcUser } from "app/assets/icons/user.svg";
import { ReactComponent as IcCheck } from "app/assets/icons/check.svg";
import { ReactComponent as IcClose } from "app/assets/icons/close.svg";
import "./family.scss";
import { useParams } from "react-router-dom";

const FamilyAccountEditor: React.FC = () => {
  const _editor = useFamilyEditor();
  const params = useParams();

  return (
    <PrivateLayout contentClassName="family-editor">
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues}
        onSubmit={_editor.onSubmit}
      >
        {({ values, errors, touched }) => {
          function _getErrors(path: string) {
            return getIn(touched, path) && getIn(errors, path) ? getIn(errors, path) : "";
          }
          return (
            <>
              <Form>
                <div className="family-editor-switch">
                  <h3 className="title">Family Account Editor</h3>
                  <div className="family-editor-toggle">
                    <p>Ambassador Profile</p>
                    <Switch
                      checked={values.is_standard}
                      onChange={(checked: boolean) => {
                        const description = `Plese confirm that you would like to change the type of user to ${
                          checked ? "Standard" : "Ambassador"
                        }`;
                        _editor.accountTypePopupRef?.current?.show({
                          description,
                        });
                      }}
                      switchId="family-editor-live-btn"
                    />
                    <p>Standard Profile</p>
                  </div>
                </div>
                <ScreenNotification label="Errors" variant="danger" />
                <Row>
                  <Col md="auto">
                    <EditProfileImage
                      ownerType="Family"
                      ownerId={Number(params.id)}
                      mode="vertical"
                      url={values?.profile_image?.path}
                      id="family-editor-profile"
                      onConfirm={_editor.onChangeImage("profile_image")}
                    />
                  </Col>
                  <Col>
                    <Section
                      title="General"
                      sectionId="family-editor-general"
                      titleSize="lg"
                      className="family-editor-general"
                      isEditable
                    >
                      <Row className="user-select">
                        <Col>
                          <UserAccountDropDownInput
                            label="Select User *"
                            value={values?.head?.id || null}
                            scope="family"
                            dataKey="id"
                            excludeAccounts={(values.members || []).map(member => member.id)}
                            textField={(user: Partial<IUserV2>) => {
                              return user && user.id
                                ? `${formatUserFullName(user)}${
                                    user.email ? `- ${user.email}` : ""
                                  }`
                                : "User account not found";
                            }}
                            name="staff.user.firstname"
                            onSelect={(user: IUserV2) => {
                              _editor.onFamilyHeadSelect(user);
                            }}
                            persistedUser={values.head}
                            className="staff-user-dropdown"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            id="family-editor-last-name"
                            variant="secondary"
                            label="Family Last Name"
                            name="last_name"
                            required
                            error={_getErrors("last_name")}
                          />
                        </Col>
                        <Col>
                          <DropDownBase<number>
                            id="family-editor-classYear"
                            options={YEARS.map(y => +y)}
                            labelOnly
                            selected={values.attending_school_since}
                            placeholder="Select Year"
                            label={`Member of ${_editor.schoolName} Since*`}
                            onOptionChange={_editor.onChange("attending_school_since")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            id="family-editor-program-id"
                            variant="secondary"
                            label="Program ID"
                            name="programId"
                            readOnly
                          />
                        </Col>
                        <Col>
                          <Input
                            id="family-editor-school-id"
                            variant="secondary"
                            label="School ID"
                            name="school_code"
                            error={_getErrors("school_code")}
                          />
                        </Col>
                      </Row>
                      <Input
                        id="family-editor-email"
                        variant="secondary"
                        label="Primary Email Address"
                        required
                        name="primary_email"
                        error={_getErrors("primary_email")}
                      />
                      <Checkbox
                        id="family-email-consent"
                        label="Allow this email addresses to be visible in our profile"
                        checked={values.is_primary_email_visible}
                        onChange={_editor.onChange("is_primary_email_visible")}
                        className="family-editor-checkbox"
                      />
                      <Input
                        id="family-other-email"
                        variant="secondary"
                        label="Other Email Address"
                        name="secondary_email"
                        error={_getErrors("secondary_email")}
                      />
                      <Checkbox
                        id="family-other-email-consent"
                        label="Allow this email addresses to be visible in our profile"
                        checked={values.is_secondary_email_visible}
                        onChange={_editor.onChange("is_secondary_email_visible")}
                        className="family-editor-checkbox"
                      />
                    </Section>

                    <Section
                      title="Family Members*"
                      sectionId="family-editor-members"
                      titleSize="sm"
                      className="family-editor-members"
                    >
                      {(values.members || []).map((member, index) => (
                        <FamilyMembersEditor
                          index={index}
                          key={`family-editor-members-${index}`}
                          age={`${member.age || ""}`}
                          gender={member.gender}
                          iconId={member.icon_id}
                          excludeAccounts={(values.members || []).map(member => member.id)}
                          members={values.members || []}
                          onDelete={_editor.deleteFamilyMembers}
                          onChange={_editor.onChange}
                          onUpdateMembers={_editor.onUpdateMembers}
                        />
                      ))}
                      <div className="add-new-family-member-section">
                        <RoundButton
                          icon={<IcPlus />}
                          variant="primary"
                          size="lg"
                          roundBtnId="family-editor-member-add-btn"
                          label="Add a new family member"
                          onClick={_editor.addFamilyMember}
                        />
                        <div className="legend">
                          <IcUser className="user-account-icon" />
                          <div>* User Account</div>
                        </div>
                      </div>
                    </Section>

                    <Section
                      title="Social Media Links"
                      sectionId="family-editor-socialLinks"
                      titleSize="sm"
                      className="family-editor-socialLinks"
                    >
                      <Row>
                        {(values.social_media_accounts || [])?.map((socialMedia, index) => (
                          <Col md={4} key={`family-editor-social-media-${socialMedia.media}`}>
                            {socialMedia.status === "edit" && (
                              <Input
                                id={`family-editor-social-media-provider-${socialMedia.media}`}
                                label="New Entry"
                                variant="secondary"
                                name={`social_media_accounts[${index}].provider`}
                                className="with-close-icon"
                                placeholder="Social media provider"
                              />
                            )}
                            <Input
                              id={`family-editor-social-media-${socialMedia.media}`}
                              label={
                                socialMedia.status === "confirmed" || !socialMedia.status
                                  ? socialMedia.provider || ""
                                  : ""
                              }
                              variant="secondary"
                              name={`social_media_accounts[${index}].url`}
                              placeholder="Social media link"
                              onCancel={
                                socialMedia.status === "confirmed"
                                  ? () => _editor.onSocialMediaDeleted(index)
                                  : () => {
                                      socialMedia.id &&
                                        _editor.onSocialMediaDeleted(index, socialMedia.id);
                                    }
                                // : undefined
                              }
                            />
                            {socialMedia.status === "edit" && (
                              <Row style={{ marginBottom: 12 }}>
                                <Col>
                                  <RoundButton
                                    icon={<IcClose />}
                                    label="Cancel"
                                    variant="secondary"
                                    size="sm"
                                    className="cancel-social"
                                    roundBtnId={`family-editor-save-social-${index}`}
                                    onClick={() => {
                                      _editor.onSocialMediaDeleted(index);
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <RoundButton
                                    icon={<IcCheck stroke="white" />}
                                    label="Save"
                                    variant="primary"
                                    size="sm"
                                    className="confirm-social"
                                    roundBtnId={`family-editor-save-social-${index}`}
                                    onClick={() => {
                                      _editor.onSocialMediaConfirmed(index);
                                    }}
                                  />
                                </Col>
                              </Row>
                            )}
                          </Col>
                        ))}
                      </Row>
                      <Row
                        className={
                          values.social_media_accounts?.some(account => account.status === "edit")
                            ? "mt-4"
                            : ""
                        }
                      >
                        <Col md={4} key={`family-editor-social-media-other-1`}>
                          <RoundButton
                            icon={<IcPlus />}
                            variant="primary"
                            size="lg"
                            roundBtnId="family-editor-member-add-btn"
                            label="Add a social link"
                            onClick={_editor.addSocialLink}
                          />
                        </Col>
                      </Row>
                    </Section>
                    <hr className="mb-5 mt-5" />
                    <Section
                      title="Education"
                      sectionId="family-editor-family-education"
                      titleSize="lg"
                      className="family-editor-education"
                      isEditable
                    >
                      {(values.educations || []).map((education, index) => (
                        <EducationEditor
                          key={`family-editor-education-${index}`}
                          index={index}
                          label="Family Member"
                          variant="secondary"
                          values={values}
                          selected={education.outcomeId}
                          onChange={_editor.onChange(`educations[${index}].outcomeId`)}
                          onUserSelect={_editor.onEducationUserSelect}
                          onDelete={_editor.onEducationDeleted}
                        />
                      ))}
                      <RoundButton
                        icon={<IcPlus />}
                        variant="primary"
                        size="lg"
                        roundBtnId="family-editor-add-education-btn"
                        label="Add education"
                        onClick={_editor.addEducation}
                      />
                      <hr className="my-5" />
                    </Section>
                    {/* <Section
                    title='Albums'
                    sectionId='family-editor-family-albums'
                    titleSize='lg'
                    className='family-editor-albums'
                  >
                    <AlbumContainer
                      eltId='family-editor-album'
                      albums={values.albums}
                      onCreate={_editor.onAlbumCreated}
                      onDelete={_editor.onAlbumDeleted}
                    />
                    <hr className='my-5' />
                  </Section> */}
                    <Section
                      sectionId="family-editor-activities"
                      title="Activities We Like"
                      className="family-editor-activities"
                      titleSize="lg"
                    >
                      {/* <p className='family-editor-activities-condition'>
                        Select at least 3
                      </p> */}
                      <ActivityContainer
                        activites={[]}
                        onSelectionChange={emptyFunction}
                        // selectedActivities={values.selectedActivities}
                        id="family-editor-activities-we-like"
                      />
                      <Row>
                        <Col>
                          <Input
                            id="family-editor-fav-book"
                            variant="secondary"
                            label="Our Favorite Book to Read"
                            name="favorite_book"
                          />
                          <Input
                            id="family-editor-favorite-food"
                            variant="secondary"
                            label="Our Favorite Food to Cook Together"
                            name="favorite_food"
                          />
                          <Input
                            id="family-editor-favorite-sport"
                            variant="secondary"
                            label="Our Favorite Sport to Practice Together"
                            name="favorite_sport"
                          />
                          <Input
                            id="family-editor-favorite-place"
                            variant="secondary"
                            label="Our Favorite Vacation Place"
                            name="favorite_vacation_place"
                          />
                        </Col>
                        <Col>
                          <Input
                            id="family-editor-fav-restaurant"
                            variant="secondary"
                            label="Our Favorite Restaurant"
                            name="favorite_restaurant"
                          />
                          <Input
                            id="family-editor-fav-holiday"
                            variant="secondary"
                            label="Our Favorite Holiday"
                            name="favorite_holiday"
                          />
                          <Input
                            id="family-editor-family-plan"
                            variant="secondary"
                            label="Perfect Family Plan"
                            name="perfect_family_plan"
                          />
                          <Input
                            id="family-editor-family-movie"
                            variant="secondary"
                            label="Our Favorite Family Movie"
                            name="favorite_movie"
                          />
                        </Col>
                      </Row>
                      <hr className="mb-5 mt-5" />
                    </Section>
                    {/* <Section
                  sectionId="family-editor-clubs"
                  title="Clubs We Are Part of"
                  className="family-editor-clubs-partof"
                  titleSize="lg"
                ></Section> */}
                    <div className="family-editor-wrap">
                      <StandardButton
                        label="Save Changes"
                        variant="secondary"
                        className="family-editor-save"
                        type="submit"
                        standardBtnId="family-editor-save-btn"
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
              <ConfirmPopup
                id="family-acccount-type"
                confirmTitle="Confirm user type change"
                confirmDescription="Plese confirm that you would like to change the type of user to Ambassador."
                onConfirm={() => _editor.onChange("is_standard")(!values.is_standard)}
                ref={_editor.accountTypePopupRef}
              />
            </>
          );
        }}
      </Formik>
    </PrivateLayout>
  );
};

export default FamilyAccountEditor;
