import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Input from "../../components/input/Input";
import T12Modal, { T12ModalRef } from "../../components/modal/T12Modal";

type EditorFormType = {
  metadataType: string;
  editMetadata?: any;
  onSave?: (metadata: string, id?: number) => void;
  onClose?: () => void;
};

export const ContentMetadataModalEditor = React.forwardRef<T12ModalRef, EditorFormType>(
  ({ onSave, onClose, metadataType, editMetadata }, modalRef) => {
    const [metadataValue, setMetadataValue] = useState("");

    useEffect(() => {
      if (editMetadata) {
        setMetadataValue(editMetadata?.category_tag);
      }
    }, [editMetadata]);

    const onSubmit = () => {
      onSave?.(metadataValue, editMetadata?.id);
    };

    const handleClose = () => {
      setMetadataValue("");
      onClose?.();
    };

    return (
      <T12Modal
        onHide={handleClose}
        modalId="edit-create-category-metadata"
        ref={modalRef}
        title={`Create new ${metadataType}`}
        confirmButtonLabel={editMetadata?.id ? "Update" : "Create"}
        showHeaderBorder
        closeOnConfirm={false}
        onConfirm={onSubmit}
      >
        <Row>
          <Col xs={12}>
            <Input
              onChange={(e: any) => setMetadataValue(e.target.value)}
              value={metadataValue}
              id="create-album-name"
              variant="secondary"
              label={metadataType}
              as="text"
            />
          </Col>
        </Row>
      </T12Modal>
    );
  }
);
