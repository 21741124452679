import React from "react";

export const IFrameIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30">
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      fill="#000"
      d="M20 16h2v6h-6v-2H8v2H2v-6h2V8H2V2h6v2h8V2h6v6h-2v8zm-2 0V8h-2V6H8v2H6v8h2v2h8v-2h2zM4 4v2h2V4H4zm0 14v2h2v-2H4zM18 4v2h2V4h-2zm0 14v2h2v-2h-2z"
    />
    <text
      x="12"
      y="13.5"
      dominantBaseline="middle"
      textAnchor="middle"
      fontFamily="Arial"
      fontSize="12"
      fontWeight="bold"
      fill="black"
    >
      iF
    </text>
  </svg>
);
