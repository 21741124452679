import { useEditor } from "@craftjs/core";
import cx from "classnames";
import React, { useEffect } from "react";
import { Toolbox } from "../Editor/Toolbox";
import { Col, Row } from "react-bootstrap";
import "./Viewport.scss";
import { Sidebar } from "../Editor/Sidebar/SidebarDiv";
import { PageBuilderSettings } from "../PageBuilderSettings";

export const Viewport: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    enabled,
    connectors,
    actions: { setOptions },
  } = useEditor(state => ({
    enabled: state.options.enabled,
  }));

  useEffect(() => {
    if (!window) {
      return;
    }

    window.requestAnimationFrame(() => {
      // Notify doc site
      window.parent.postMessage(
        {
          LANDING_PAGE_LOADED: true,
        },
        "*"
      );

      setTimeout(() => {
        setOptions(options => {
          options.enabled = true;
        });
      }, 200);
    });
  }, [setOptions]);

  return (
    <Row className="Viewport">
      <PageBuilderSettings />
      <br />
      <Col className="ComponentToolbox" xs={1}>
        <label className="ComponentTitle">Components</label>
        <Toolbox />
      </Col>
      <Col className="page-container" xs={8}>
        <div
          className={cx([
            "craftjs-renderer ElementsIn",
            {
              "bg-renderer-gray": enabled,
            },
          ])}
          // @ts-ignore
          ref={ref => connectors.select(connectors.hover(ref as any, null), null)}
        >
          <div className="Children">{children}</div>
        </div>
      </Col>
      <Col xs={3}>
        <Sidebar />
      </Col>
    </Row>
  );
};
