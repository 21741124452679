import React from "react";
import { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { PdfCard } from "../../../components/PdfFiles/PdfCard";
import { FileType } from "../../../models/media";
import RoundButton from "../../../components/button/RoundButton";
import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import { uploadFile } from "../../../api/file/uploadFile";

type ClubFilesProps = {
  files: FileType[];
  onChange: any;
  onRemoveFile: any;
};

export const ClubFiles = ({ files, onChange, onRemoveFile }: ClubFilesProps) => {
  const fileRef = useRef<any>();

  const handleImageChange = async (e: any) => {
    if (e.target.files[0]) {
      const response = (await doUploadFile(e.target.files[0])) as any;

      if (response.data) {
        onChange(response.data[0]);
        fileRef.current.value = null;
      }
    }
  };

  const doUploadFile = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("file[]", file);

      return await uploadFile(formData);
    } catch (error) {}
  };

  return (
    <Row maxWidth="xl" className="club-page-leaders">
      <Row container style={{ gap: 24 }}>
        {files.map((file, index) => (
          <Col xs={2} style={{ boxShadow: "0px 4px 10px 7px #00000008" }}>
            <PdfCard onRemove={() => onRemoveFile(index)} key={index} url={file.url} />
          </Col>
        ))}
      </Row>

      <input
        type="file"
        id="pdfsInputFile"
        className="fileElem"
        ref={fileRef}
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
      <Row item xs={12} sx={{ mt: 2 }}>
        <Col
          onClick={() => fileRef.current.click()}
          display="flex"
          alignItems="center"
          ml={"35%"}
        ></Col>
        <Row>
          <Col sm={4}></Col>
          <Col sm={8} className="add-event">
            <RoundButton
              onClick={() => fileRef.current.click()}
              icon={<IcPlus />}
              variant="primary"
              roundBtnId={"add-event"}
              label="Add file"
            />
          </Col>
        </Row>
      </Row>
    </Row>
  );
};
